import { useEffect, useState } from 'react';
import { AuthContext } from './AuthContext';
import firebase from 'firebase/compat/app';
import { auth } from '../auth/fireBaseSetup';
type ContextProviderProps = {
  children: React.ReactNode;
};
const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({
  prompt: 'select_account',
  redirect_uri: 'http://localhost:3000/dashboard',
});
export const signInWithGoogle = () => auth.signInWithPopup(provider);

export const GoogleAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
