import {
  Box,
  Button,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  HStack,
  Divider,
  useBoolean,
  Input,
  InputRightAddon,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Search2Icon, CloseIcon } from '@chakra-ui/icons';

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '..';
import { Note } from '../interfaces/Note';
import { UserMeta } from './../interfaces/UserMeta';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
import { PortalProps } from '../interfaces/PortalProps';
import getAllRowsById from '../utils/getAllRowsById';
import { BackButton } from './BackButton';
import sortDataByParameter from '../utils/sortDataByParameter';
import sorterIcon from '../utils/sorterIcon';
const accountType = localStorage.getItem('accountType');
const accountNumber = Number(localStorage.getItem('accountNumber'));
const today = new Date();
export interface PortalPropsNew {
  schoolsStudents: any;
  setSchoolsStudents: Function;
  signedInUserId?: number;
  setSignedInUserId?: Function;
  signedInUserEmail: string;
  setSignedInUserEmail: Function;
  userMeta: UserMeta;
  setUserMeta: Function;
  match?: any;
  navWidth: number;
  setNavWidth: Function;
  sessionId?: number;
  signedInUserType: string;
  setSignedInUserType: Function;
}

const BookingsContentStudentsMainstream = ({
  schoolsStudents,
  setSchoolsStudents,
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
}: PortalPropsNew) => {
  const newNoteDefault = {
    nid: 0,
    session_id: 0,
    note_type: 'brief_note',
    note_owner: Number(signedInUserId),
    note_details: '',
    posted_at: today,
    modified_at: today,
  };
  const [sorter, setSorter] = useBoolean();

  const [newNote, setNewNote] = useState<Note>(newNoteDefault);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueLowerCase, setSearchValueLowerCase] = useState<string>('');
  const [caseNotes, setCaseNotes] = useState<any>([]);
  const [schoolFilter, setSchoolFilter] = useState<number>(Number(signedInUserId));
  const [activeSort, setActiveSort] = useState<string>('None');
  const [activeSearch, setActiveSearch] = useState(0);
  const handleSearch = (e: any) => {
    let searchInput = e.target.value;
    let lowerSearchInput = searchInput.toLowerCase();
    setSearchValue(searchInput);
    setSearchValueLowerCase(lowerSearchInput);
    setActiveSearch(1);
  };
  const navigate = useNavigate();
  const userAccount = useContext(MyContext);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allProfilesFullUrl = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const userStats = userMeta && userMeta?.stats;
  const therapistsTable = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const mainstreamStudents = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const senStudents = process.env.REACT_APP_UL_STUDENTS_SEN;
  function clearSearch() {
    // console.log('We clearing');
    setSearchValue('');
    // searchValueLowerCase('');

    const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
    const baseMsAllStudentsQuery = `let school_id := ${Number(
      localStorage.getItem('accountNumber')
    )};
    let students := (select Students)['Mainstream School'.Id = school_id];
    concat(students)`;
    const msSchoolStudents = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;

    const baseSsAllStudentsQuery = `let school_id := ${Number(
      localStorage.getItem('accountNumber')
    )};
    let students := (select Students)['SEN schools'.Id = school_id];
    concat(students)`;
    const ssSchoolStudents = process.env.REACT_APP_UL_STUDENTS_SEN;
    const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;
    accountType === 'Mainstream'
      ? getAllRowsByEncodedQuery(msQueryEP, baseMsAllStudentsQuery).then((data: any) => {
          const allStudents = data.split(',');
          data?.length > 0 &&
            getMultipleApiData(msSchoolStudents!, bearerToken!, allStudents).then((myData: any) => {
              setSchoolsStudents(
                myData.sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['Student/Patient Name'].localeCompare(
                        a?.fields['Student/Patient Name']
                      )
                    : a?.fields['Student/Patient Name'].localeCompare(
                        b?.fields['Student/Patient Name']
                      )
                )
              );
            });
        })
      : getAllRowsByEncodedQuery(ssQueryEP, baseSsAllStudentsQuery).then((data: any) => {
          const allStudents = data.split(',');
          data?.length > 0 &&
            getMultipleApiData(ssSchoolStudents!, bearerToken!, allStudents).then((myData: any) => {
              setSchoolsStudents(
                myData.sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['Student/Patient Name'].localeCompare(
                        a?.fields['Student/Patient Name']
                      )
                    : a?.fields['Student/Patient Name'].localeCompare(
                        b?.fields['Student/Patient Name']
                      )
                )
              );
            });
        });
    // setSchoolsStudents([]);

    // getAllRowsById(getCaseNotes).then((data) => {
    //   const myData = data;
    //   setCaseNotes(
    //     myData
    //       .filter(
    //         (i: any) =>
    //           i?.fields[accountType === 'Mainstream' ? 'Mainstream School' : 'SEN Schools'] ===
    //           schoolFilter
    //       )
    //       .sort((a: any, b: any) =>
    //         sorter === true
    //           ? b?.fields['Student/Patient Name'].localeCompare(a?.fields['Student/Patient Name'])
    //           : a?.fields['Student/Patient Name'].localeCompare(b?.fields['Student/Patient Name'])
    //       )
    //   );
    // });
  }

  useEffect(() => {
    const therapistCaseNotesEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
    setNewNote(newNoteDefault);
    setSchoolFilter(Number(signedInUserId));

    // getAllRowsById(getCaseNotes).then((data) => {
    // const myData = data;
    // setCaseNotes(
    //   myData
    //     .filter(
    //       (i: any) =>
    //         i?.fields[accountType === 'Mainstream' ? 'Mainstream School' : 'SEN Schools'] ===
    //         // Number(signedInUserId)
    //         Number(signedInUserId)
    //     )
    //     .sort((a: any, b: any) =>
    //       sorter === true
    //         ? b?.fields['Student/Patient Name'].localeCompare(a?.fields['Student/Patient Name'])
    //         : a?.fields['Student/Patient Name'].localeCompare(b?.fields['Student/Patient Name'])
    //     )
    // );
    // });

    const delayDebounceFn = setTimeout(() => {
      searchValueLowerCase.length >= 1 &&
        setSchoolsStudents(
          schoolsStudents.filter((caseNote: any) => {
            return caseNote?.fields['Student/Patient Name']
              .toLocaleLowerCase()
              .includes(searchValueLowerCase);
          })
        );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValueLowerCase]);
  // , searchValue
  useEffect(() => {}, [searchValue, schoolsStudents]);
  const headings = [
    'Student/Patient Name',
    'View student',
    'TOMS Rating',
    'DOB',
    'Class year',
    'Class name',
    'Gender',
    'Ethnicity',
    'Referral Date',
    'Language 1',
    'Language 2',
    'Language 3',
    'Other Languages',
    'Local Patient Identifier',
    'Date of Assessment',
    'Report',
    'Report sent',
    'Report Link',
    'Notes',
    'Date of review',
    'Name of Therapist',
    'Primary Medical Diagnosis',
    'Additional Medical Diagnosis',
    'Other Medical Diagnosis',
    'Primary Communication/ Swallowing Disorder',
    'Additional Communication/ Swallowing Disorder',
    'Other Communication/ Swallowing Disorder',
    'EHCP',
    'Direct Hours',
    'Indirect Hours',
    'Hours completed',
    'Hours remaining',
    'Plan',
    'Primary TOMs Scale',
    'Secondary TOMs Scale',
    'End of Episode/ Discharge Code/ Description',
    'Date of Discharge',
  ];

  return (
    <>
      <Box ml={{ base: 0, md: 0 }}>
        <Box p={2} bg="white">
          <Box p={2} mb={5}>
            <HStack spacing={2}>
              <Box width={'50%'}>
                <HStack spacing={2}>
                  <Heading pb={1} mt={1} size={'md'}>
                    Students ({schoolsStudents.length})
                  </Heading>
                  {/* <Box>{JSON.stringify(students)}</Box> */}
                </HStack>
              </Box>
              <Box width={'50%'}>
                <HStack>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Search2Icon color="gray.600" />}
                    />
                    <Input
                      pr="4.5rem"
                      placeholder="Search for a student"
                      onChange={(e) => handleSearch(e)}
                      type="text"
                      value={searchValue}
                      size="xl"
                      borderRadius={5}
                    />
                    {activeSearch === 1 ? (
                      <InputRightAddon
                        children={<CloseIcon color="gray.600" />}
                        onClick={() => {
                          clearSearch();
                        }}
                        cursor={'pointer'}
                      />
                    ) : (
                      <InputRightAddon children="Search" cursor={'pointer'} />
                    )}
                  </InputGroup>
                </HStack>
              </Box>
            </HStack>
          </Box>
          <TableContainer className="studentTableContainer" maxH={'350px'}>
            <Table variant="striped">
              <Thead>
                <Tr style={{ backgroundColor: 'gray.300' }}>
                  {headings.map((i: any, k: number) => (
                    <Th
                      key={k}
                      style={
                        k === 0
                          ? {
                              position: 'sticky',
                              left: '0',
                              width: 'auto',
                              zIndex: 999,
                              cursor: 'pointer',
                            }
                          : {}
                      }
                      onClick={() => {
                        sortDataByParameter(
                          setCaseNotes,
                          caseNotes,
                          schoolFilter,
                          i,
                          accountType!,
                          sorter
                        );
                        setActiveSort(i);
                        setSorter.toggle();
                      }}
                    >
                      {i} {sorterIcon(sorter, activeSort, i)}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {schoolsStudents &&
                  schoolsStudents.map((i: any, k: any) => {
                    return (
                      <Tr key={k} style={{ cursor: 'pointer' }}>
                        <Td
                          style={
                            k % 2 == 0
                              ? {
                                  position: 'sticky',
                                  left: '0',
                                  width: 'auto',
                                  zIndex: 9,
                                  fontWeight: 'bold',
                                  background: 'gray.100',
                                }
                              : {
                                  position: 'sticky',
                                  left: '0',
                                  width: 'auto',
                                  zIndex: 9,
                                  fontWeight: 'bold',
                                  background: 'white',
                                }
                          }
                        >
                          {i?.fields['Student/Patient Name']}
                        </Td>
                        <Td>
                          <Button
                            bg={'brand.300'}
                            color={'white'}
                            _hover={{
                              background: 'brand.200',
                            }}
                            onClick={() => {
                              navigate('/student-session/' + i.id + '?schoolType=RegularSchool');
                            }}
                          >
                            View student
                          </Button>
                        </Td>
                        <Td>
                          <Button
                            bg={'brand.300'}
                            color={'white'}
                            _hover={{
                              background: 'brand.200',
                            }}
                            onClick={() => {
                              navigate('/toms-rating/' + i.id + '?schoolType=RegularSchool');
                            }}
                          >
                            View TOMS Rating
                          </Button>
                        </Td>
                        <Td>{i?.fields['DOB'] === undefined ? null : i?.fields['DOB']}</Td>
                        <Td>{i?.fields['Class year']}</Td>
                        <Td>{i?.fields['Class name']}</Td>
                        <Td>{i?.fields['Gender'] === undefined ? null : i?.fields['Gender']}</Td>
                        <Td>{i?.fields['Ethnicity']}</Td>
                        <Td>{i?.fields['Referral Date']}</Td>
                        <Td>{i?.fields['Language 1']}</Td>
                        <Td>{i?.fields['Language 2']}</Td>
                        <Td>{i?.fields['Language 3']}</Td>
                        <Td>{i?.fields['Other Languages']}</Td>
                        <Td>{i?.fields['Local Patient Identifier']}</Td>
                        <Td>{i?.fields['Date of Assessment']}</Td>
                        <Td>{i?.fields['Report date']}</Td>
                        <Td>{i?.fields['Report sent'] === true ? 'Yes' : 'No'}</Td>
                        <Td>{i?.fields['Report Link']}</Td>
                        <Td>{i?.fields['Notes']}</Td>
                        <Td>{i?.fields['Date of review']}</Td>
                        <Td>{i?.fields['Name of Therapist']}</Td>
                        <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                        <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                        <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                        <Td>{i?.fields['Primary Communication/ Swallowing Disorder']}</Td>
                        <Td>{i?.fields['Additional Communication/ Swallowing Disorder']}</Td>
                        <Td>{i?.fields['Other Communication/ Swallowing Disorder']}</Td>
                        <Td>{i?.fields['EHCP']}</Td>
                        <Td>{i?.fields['Direct Hours']}</Td>
                        <Td>{i?.fields['Indirect Hours']}</Td>
                        <Td>{i?.fields['Hours completed']}</Td>
                        <Td>{i?.fields['Hours remaining']}</Td>
                        <Td>{i?.fields['Plan']}</Td>
                        <Td>{i?.fields['Primary TOMs Scale']}</Td>
                        <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                        <Td>{i?.fields['End of Episode/ Discharge Code/ Description']}</Td>
                        <Td>{i?.fields['Date of Discharge']}</Td>
                      </Tr>
                    );
                  })}
                {/* {caseNotes === undefined && <TableCaption>No students yet!</TableCaption>} */}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentStudentsMainstream;
