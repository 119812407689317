import {
  Box,
  Container,
  Checkbox,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  Stack,
  Text,
  Textarea,
  useAccordion,
  HStack,
  Link,
  List,
  ListItem,
  Image,
  Flex,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
import UL_logo_large from './../logo/unlocking_language_logo_white.png';
import moment from 'moment';

// import htmlToDraft from 'html-to-draftjs';
// import draftToHtml from 'draftjs-to-html';
import { stateToHTML } from 'draft-js-export-html';
import { dropdownIndicatorCSS } from 'react-select/dist/declarations/src/components/indicators';
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

const MessagesContentUsersDashboard = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
}: SignedInUserProps) => {
  interface MessageFields {
    Data: string;
    Message: string;
    Number: string;
    'Data sent': string;
  }
  const toast = useToast();

  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const messagesTableEP = process.env.REACT_APP_MESSAGES_TABLE;
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

  const [queryMsgData, setQueryMsgData] = useState<any>([]);
  const [readMsg, setReadMsg] = useState<any>([]);
  const [readMsgArray, setReadMsgArray] = useState<any>([]);
  const [currentMsg, setCurrentMsg] = useState<any>('none');
  const [emptyArray, setEmptyArray] = useState<any>(['empty']);

  // {useId: 1357, accountType: 'Private Client'}
  // const test = [13, 1357];
  // console.log(test.includes(13));
  const GetMsgAndMarkAsRead = (e: number) => {
    try {
      ninoxTableGetRow(messagesTableEP! + '/', e, bearerToken!).then((data: any) => {
        setCurrentMsg(data);
        setReadMsgArray([data?.fields['UsersThatReadMsg']]);
        // console.log('Clicked msg:', data);
        const testidea =
          data?.fields['UsersThatReadMsg'] === undefined
            ? []
            : data?.fields['UsersThatReadMsg'].split(',');
        // console.log('testidea:', testidea);
        let numArray = testidea?.map(Number);
        // console.log('numArray:', numArray);
        const currentUser = localStorage.getItem('accountNumber');
        data?.fields['UsersThatReadMsg'] === undefined
          ? ninoxTableUpdateRow(
              messagesTableEP! + '/',
              e,
              {
                fields: {
                  UsersThatReadMsg: [currentUser],
                },
              },
              bearerToken!
            )
          : !numArray.includes(Number(currentUser)) &&
            ninoxTableUpdateRow(
              messagesTableEP! + '/',
              e,
              {
                fields: {
                  UsersThatReadMsg: [...numArray, currentUser],
                },
              },
              bearerToken!
            );
      });
    } catch {
      toast({
        title: 'Message error',
        description:
          'You message was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const startDate = localStorage.getItem('messagesStart');
  let day = Number(moment(startDate).format('DD'));
  let month = Number(moment(startDate).format('MM'));
  let year = Number(moment(startDate).format('YYYY'));

  const msgsStdQuery = `concat((select 'Client App wide messaging')[contains(text(accountType), "${localStorage.getItem(
    'accountType'
  )}") and
contains(text(accessLevel), "${localStorage.getItem('accessLevel')}") and
'Date and Time' >= date(${year}, ${month}, ${day})])`;

  const msgsClientsQuery = `concat((select 'Client App wide messaging')[contains(text(accountType), "${localStorage.getItem(
    'accountType'
  )}") and
contains(text(accessLevel), "${localStorage.getItem('accessLevel')}") and
contains(text(accountTypeSubList), "${localStorage.getItem('accountTypeSubList')}") and
'Date and Time' >= date(${year}, ${month}, ${day})])`;

  const subListPresent = localStorage.getItem('accountTypeSubList');
  // console.log(subListPresent);
  useEffect(() => {
    getAllRowsByEncodedQuery(
      clientsQueryEP,
      localStorage.getItem('accountTypeSubList') === null ||
        localStorage.getItem('accountTypeSubList') === undefined
        ? msgsStdQuery
        : msgsClientsQuery
    ).then((data: any) => {
      // console.log('the msg ids:', data);
      // let msgArrayIds = data.split(', ');
      let msgArrayIds = data === '' ? emptyArray : data?.split(', ');

      // console.log('msgArrayIds:', msgArrayIds.length);
      msgArrayIds[0] !== 'empty'
        ? getMultipleApiData(messagesTableEP! + '/', bearerToken!, msgArrayIds).then(
            (msgData: any) => {
              // console.log('msgData', msgData);
              setQueryMsgData(msgData);
            }
          )
        : setQueryMsgData([]);
    });
  }, []);

  useEffect(() => {
    getAllRowsByEncodedQuery(
      clientsQueryEP,
      localStorage.getItem('accountTypeSubList') === null ||
        localStorage.getItem('accountTypeSubList') === undefined
        ? msgsStdQuery
        : msgsClientsQuery
    ).then((data: any) => {
      // console.log('the msg ids:', data);
      // let msgArrayIds = data.split(', ');
      let msgArrayIds = data === '' ? emptyArray : data?.split(', ');

      // console.log('msgArrayIds:', msgArrayIds.length);
      msgArrayIds[0] !== 'empty'
        ? getMultipleApiData(messagesTableEP! + '/', bearerToken!, msgArrayIds).then(
            (msgData: any) => {
              // console.log('msgData', msgData);
              setQueryMsgData(msgData);
            }
          )
        : setQueryMsgData([]);
    });
  }, [readMsgArray]);

  useEffect(() => {
    const value =
      queryMsgData &&
      queryMsgData?.filter(
        (i: any) =>
          i?.fields['UsersThatReadMsg'] === undefined ||
          !i?.fields['UsersThatReadMsg']
            .split(',')
            .map(Number)
            .includes(Number(localStorage.getItem('accountNumber')))
      ).length;
    localStorage.setItem('notificationsCount', value);
  }, [queryMsgData]);

  // Checkboxes to dropdowns
  // Sort latest sent
  // Admin panel all actions
  // Read receipts
  // Notifications
  // const notificationsCheck =
  //   queryMsgData?.fields['UsersThatReadMsg'] === undefined
  //     ? []
  //     : queryMsgData?.fields['UsersThatReadMsg'].split(',');
  // let itemArrayNotifications = notificationsCheck.map(Number);
  return (
    <Box width={'100%'} height={'auto'} bg={'gray.100'} borderRadius={20}>
      <Box
        bg={'white'}
        borderBottom={'1px gray.300 solid'}
        p={5}
        color={'black'}
        _hover={{ background: 'gray.100', cursor: 'pointer' }}
        listStyleType={'none'}
        fontWeight={'bold'}
        borderTopRadius={20}
        onClick={() => {
          setCurrentMsg('none');
        }}
      >
        Total&nbsp;({queryMsgData && queryMsgData.length}) / Unread &nbsp;(
        {queryMsgData &&
          queryMsgData?.filter(
            (i: any) =>
              i?.fields['UsersThatReadMsg'] === undefined ||
              !i?.fields['UsersThatReadMsg']
                .split(',')
                .map(Number)
                .includes(Number(localStorage.getItem('accountNumber')))
          ).length}
        ){/* {JSON.stringify(queryMsgData)} */}
      </Box>
      <VStack>
        <List w="100%" m={0} borderRadius={20}>
          <Box bg={'gray.100'} height={'80vh'}>
            {queryMsgData.length >= 1 ? (
              queryMsgData.map((i: any, k: any) => {
                const testidea =
                  i?.fields['UsersThatReadMsg'] === undefined
                    ? []
                    : i?.fields['UsersThatReadMsg'].split(',');
                // console.log('testidea:', testidea);
                let itemArray = testidea.map(Number);
                return (
                  <Link href={'messages'} key={k}>
                    <ListItem
                      bg={currentMsg?.id === i.id ? 'gray.100' : 'white'}
                      borderBottom={'1px gray.300 solid'}
                      p={5}
                      color={'black'}
                      _hover={{ background: 'gray.100', cursor: 'pointer' }}
                      listStyleType={'none'}
                      // onClick={() => {
                      //   GetMsgAndMarkAsRead(i.id);
                      // }}
                      fontWeight={
                        itemArray.includes(Number(localStorage.getItem('accountNumber')))
                          ? 'normal'
                          : 'bold'
                      }
                      // fontWeight={
                      //   i?.fields['UsersThatReadMsg'] === undefined
                      //     ? ''
                      //     : [i?.fields['UsersThatReadMsg']]?.includes(i.id)
                      //     ? 'normal'
                      //     : 'bold'
                      // }
                    >
                      {i?.fields['Subject']}
                    </ListItem>
                  </Link>
                );
              })
            ) : (
              <Box p={5}>No messages found</Box>
            )}
          </Box>
        </List>
      </VStack>
    </Box>
  );
};

export default MessagesContentUsersDashboard;
