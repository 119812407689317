export default async function getAllDriveFoldersFromClientUploadFolder(
  parentFolder: string,
  endPoint: string | any,
  myToken: string
) {
  const data = new URLSearchParams();
  data.append('q', `'${parentFolder}' in parents`);
  // data.append('includeLabels', 'labels/oTewCNdxOCjbHM4mzd9bY21tnm3ZrB7mOmbSNNEbbFcb');
  data.append('mimeType', 'application/vnd.google-apps.folders');
  data.append(
    'fields',
    'files(labelInfo, id, name, description, fileExtension, webContentLink, webViewLink, mimeType, thumbnailLink, exportLinks)'
    // '*'
  );
  const response = await fetch(endPoint + data, {
    headers: {
      Authorization: myToken,
      'Content-Type': 'application/json',
    },
  });
  const allFiles = await response.json();
  // console.log(allFiles.files);
  // setDriveFiles(allFiles.files);
  // allFiles?.files?.forEach((element: any) => {
  //   getFilesLabels(element.id, myToken).then((data) => {});
  // });
  // console.log(`We got ${allFiles?.files.length} files from Google Drive`);
  return allFiles.files;
}
