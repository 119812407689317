export default async function getAllRowsByEncodedQuery(url: any, query: any) {
  const headers = {
    Authorization: process.env.REACT_APP_NINOX_API_BEARER_TOKEN!,
    'Content-Type': 'application/json',
  };
  const encodedQuery = encodeURIComponent(query);
  return fetch(url + `?query=${encodedQuery}&perPage=1000`, {
    method: 'GET',
    headers: headers,
  })
    .then((response) => response.json())
    .catch((error) => {
      console.log('Error:', error);
    });
}

// // Call fetchData1 to get the data
// fetchData1()
//   .then((data) => {
//     // Map each item to a promise returned by fetchData2
//     const promises = data.map((item) => fetchData2(item));

//     // Wait for all promises to resolve
//     return Promise.all(promises);
//   })
//   .then((results) => {
//     // Handle the response data here
//     console.log(results);
//   })
//   .catch((error) => {
//     console.error('Error:', error);
//   });
