import { Box } from '@chakra-ui/react';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';

const ReportingContentNotAllowed = (
  { signedInUserId, signedInUserEmail, userMeta }: SignedInUserProps,
  props: any
) => {
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6" fontWeight={'bold'} fontSize={20}>
        Page not found
      </Box>
    </>
  );
};

export default ReportingContentNotAllowed;
