import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Stack,
  StackDivider,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { NinoxData } from '../interfaces/NinoxData';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import { AuthContext } from '../context/AuthContext';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { NinoxContext } from '../context/NinoxContext';
import { BackButton } from './BackButton';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import getNinoxData from '../utils/getNinoxData';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import ninoxTableDeleteRow from '../utils/ninoxTableDeleteRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ProfileClientDashboard from './ProfileClientDashboard';
import ProfileClientTxBio from './ProfileClientTxBio';
import ProfileClientDiscoveryCall from './ProfileClientDiscoveryCall';
import ProfileClientInformation from './ProfileClientInformation';
import ProfileClientInvoiceInformation from './ProfileClientInvoiceInformation';

type StateContextType = {
  userDetails: number | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};

const ProfileContentSubscriber = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  const user = useContext(AuthContext);
  const userData = useContext(NinoxContext);
  const navigate = useNavigate();

  const [ninoxData, setNinoxData] = useState<NinoxData | null>(null);
  const [newNinoxData, setNewNinoxData] = useState<any | null>(null);
  const { userDetails } = useContext<StateContextType>(userDetailsContext);
  const [tabIndex, setTabIndex] = useState(0);
  const queryParameters = new URLSearchParams(window.location.search);
  const activeTab = queryParameters.get('activeTab');

  const endPoint = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  console.log('stripeCustomerId', localStorage.getItem('stripeCustomerId')!);
  const stripeCustomerId = localStorage.getItem('stripeCustomerId')!;
  const DiscoveryCall = () => {
    return <Heading></Heading>;
  };

  useEffect(() => {}, []);
  return (
    <Box ml={{ base: 0, md: 20 }} p="6">
      <Box p={6} bg="white">
        <HStack spacing={5} mb={10}>
          <BackButton navigate={navigate} />
          <Heading>Book a discovery call</Heading>
        </HStack>
        <Box>
          <Box width={'100%'}>
            {/* <Tabs
              variant="soft-rounded"
              colorScheme="green"
              index={activeTab === 'profile' ? 3 : tabIndex}
            >
              <TabList>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(4);
                    navigate('/profile');
                  }}
                >
                  Book a Discovery Call
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel bg={'gray.100'} mt={5} borderRadius={5}> */}
            <ProfileClientDiscoveryCall />
            {/* </TabPanel>
              </TabPanels>
            </Tabs> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileContentSubscriber;
