export default function getFileSizeFromBase64(base64String: any) {
  // Remove base64 prefix (data:image/jpeg;base64,) if it exists
  const base64Data = base64String.split(',')[1] || base64String;

  // Calculate the length of the base64 string
  const length = base64Data.length;

  // Count padding characters
  const padding =
    base64Data.charAt(length - 2) === '=' ? 2 : base64Data.charAt(length - 1) === '=' ? 1 : 0;

  // Calculate the original file size in bytes
  const fileSizeInBytes = (length * 3) / 4 - padding;

  return String(fileSizeInBytes);
}
