import React from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
} from '@chakra-ui/react';
export const totalPrivateClientsHint = (
  <Box p={5}>
    <Heading size={'md'}>Total Private Clients</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>Total number of clients assigned to you.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>Count of current students, excluding those discharged.</Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>All-time.</Text>
  </Box>
);
export const nextSessionHint = (
  <Box p={5}>
    <Heading size={'md'}>Next Session</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>The forthcoming scheduled appointment.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>
      This is determined based on the scheduled date and time of the next planned session in Ninox..
    </Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>It reflects the next session in chronological order.</Text>
  </Box>
);
export const avgInteractionsHint = (
  <Box p={5}>
    <Heading size={'md'}>Avg. interactions</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>Average number of interactions recorded for each private client.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>
      Sum of interactions for all private clients, excluding those discharged, divided by the number
      of current private clients.
    </Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>All time.</Text>
  </Box>
);
export const showDischargedHint = //Should show discharged instead
  (
    <Box p={5}>
      <Heading size={'md'}>Total Discharged</Heading>
      <Text fontWeight={'bold'}>What is it?</Text>
      <Text mb={2}>The cumulative count of clients who have been discharged</Text>
      <Text fontWeight={'bold'}>How is it calculated?</Text>
      <Text mb={2}>The count of all discharged private clients who were assigned to you.</Text>
      <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
      <Text mb={2}>Current acadmic year.</Text>
    </Box>
  );
export const gendersHint = //Heading is wrong
  (
    <Box p={5}>
      <Heading size={'md'}>Genders</Heading>
      <Text fontWeight={'bold'}>What is it?</Text>
      <Text mb={2}>
        Pie chart showcasing the distribution of genders among current students, excluding those
        discharged.
      </Text>
      <Text fontWeight={'bold'}>How is it represented?</Text>
      <Text mb={2}>Each gender category is depicted as a proportionate slice of the pie.</Text>
      <Text fontWeight={'bold'}>Timeframe of representation?</Text>
      <Text mb={2}>Current academic year.</Text>
    </Box>
  );
export const therapyLocationHint = (
  <Box p={5}>
    <Heading size={'md'}>Therapy location</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>
      Bar chart illustrating the distribution of private clients across different therapy location.
    </Text>
    <Text fontWeight={'bold'}>How is it represented?</Text>
    <Text mb={2}>
      Each location is depicted as a separate bar, with its height indicating the number of clients.
    </Text>
    <Text fontWeight={'bold'}>Timeframe of representation?</Text>
    <Text mb={2}>All-time for Current clients.</Text>
  </Box>
);
