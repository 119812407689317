import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  Stack,
  StackDivider,
  Text,
  Wrap,
  WrapItem,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { NinoxData } from '../interfaces/NinoxData';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import { AuthContext } from './../context/AuthContext';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { NinoxContext } from './../context/NinoxContext';
import { BackButton } from './BackButton';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import getNinoxData from './../utils/getNinoxData';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import ninoxTableDeleteRow from '../utils/ninoxTableDeleteRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ProfileClientDashboard from './ProfileClientDashboard';
import ProfileClientTxBio from './ProfileClientTxBio';
import ProfileClientDiscoveryCall from './ProfileClientDiscoveryCall';
import ProfileClientInformation from './ProfileClientInformation';
import ProfileClientInvoiceInformation from './ProfileClientInvoiceInformation';

type StateContextType = {
  userDetails: number | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};

const ProfileContent = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  const user = useContext(AuthContext);
  const userData = useContext(NinoxContext);
  const navigate = useNavigate();

  const [ninoxData, setNinoxData] = useState<NinoxData | null>(null);
  const [newNinoxData, setNewNinoxData] = useState<any | null>(null);
  const { userDetails } = useContext<StateContextType>(userDetailsContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const activeTab = queryParameters.get('activeTab');
  const profileData =
    queryParameters.get('profileData') === null ? 0 : queryParameters.get('profileData');
  const [tabIndex, setTabIndex] = useState<any | null>(Number(profileData));

  const endPoint = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  // console.log('stripeCustomerId', localStorage.getItem('stripeCustomerId')!);
  const stripeCustomerId = localStorage.getItem('stripeCustomerId')!;
  // const DiscoveryCall = () => {
  //   return <Heading></Heading>;
  // };
  // console.log('profileData: ', profileData);
  useEffect(() => {
    setTabIndex(profileData);
    // console.log('there was a change');
  }, []);

  return (
    <Box ml={{ base: 0, md: 20 }} p="6">
      <Box p={6} bg="white">
        <HStack spacing={5} mb={10}>
          <BackButton navigate={navigate} />
          <Heading>
            Profile
            {/* {profileData} === {tabIndex} */}
          </Heading>
        </HStack>
        <Box>
          <Box width={'100%'}>
            <Tabs
              variant="soft-rounded"
              colorScheme="green"
              // index={activeTab === 'profile' ? 3 : tabIndex}
            >
              <TabList>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(0);
                    navigate('/profile?profileData=0');
                  }}
                  style={
                    Number(profileData) == 0
                      ? { backgroundColor: '#C6F6D5', color: '#276749' }
                      : { backgroundColor: '#edf2f7', color: '#4a5568' }
                  }
                >
                  Website Login Details
                </Tab>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(1);
                    navigate('/profile?profileData=1');
                  }}
                  style={
                    Number(profileData) == 1
                      ? { backgroundColor: '#C6F6D5', color: '#276749' }
                      : { backgroundColor: '#edf2f7', color: '#4a5568' }
                  }
                >
                  Client Information
                </Tab>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(2);
                    navigate('/profile?profileData=2');
                  }}
                  style={
                    Number(profileData) == 2
                      ? { backgroundColor: '#C6F6D5', color: '#276749' }
                      : { backgroundColor: '#edf2f7', color: '#4a5568' }
                  }
                >
                  Invoicing Information
                </Tab>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(3);
                    navigate('/profile?profileData=3');
                  }}
                  style={
                    Number(profileData) == 3
                      ? { backgroundColor: '#C6F6D5', color: '#276749' }
                      : { backgroundColor: '#edf2f7', color: '#4a5568' }
                  }
                >
                  Your Therapist
                </Tab>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(4);
                    navigate('/profile?profileData=4');
                  }}
                  style={
                    Number(profileData) == 4
                      ? { backgroundColor: '#C6F6D5', color: '#276749' }
                      : { backgroundColor: '#edf2f7', color: '#4a5568' }
                  }
                >
                  Book a Discovery Call
                </Tab>
                <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(5);
                    navigate('/profile?profileData=5');
                  }}
                  style={
                    Number(profileData) == 5
                      ? { backgroundColor: '#C6F6D5', color: '#276749' }
                      : { backgroundColor: '#edf2f7', color: '#4a5568' }
                  }
                >
                  Manage subscription
                </Tab>
              </TabList>
              <TabPanels>
                {/* <TabPanel bg={'white'} mt={5} borderRadius={5}>
                  {Number(profileData) === 0 && (
                    <ProfileClientDashboard profileDataType={'website info'} />
                  )}
                </TabPanel>
                <TabPanel bg={'white'} mt={5} borderRadius={5}>
                  {Number(profileData) === 1 && (
                    <ProfileClientInformation profileDataType={'clinical info'} />
                  )}
                </TabPanel>
                <TabPanel bg={'white'} mt={5} borderRadius={5}>
                  {Number(profileData) === 2 && (
                    <ProfileClientInvoiceInformation profileDataType={'invoicing'} />
                  )}
                </TabPanel>
                <TabPanel bg={'white'} mt={5} borderRadius={5}>
                  {Number(profileData) === 3 && <ProfileClientTxBio />}
                </TabPanel>
                <TabPanel bg={'white'} mt={5} borderRadius={5}>
                  {Number(profileData) === 4 && <ProfileClientDiscoveryCall />}
                </TabPanel>
                <TabPanel bg={'white'} mt={5} borderRadius={5}>
                  {Number(profileData) === 5 && (
                    <Box w="100%">
                      <Heading as={'h3'} size={'md'} my={5}>
                        Manage your resources subscription
                      </Heading>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'left'}
                        justifyContent={'left'}
                        alignContent={'left'}
                      >
                        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={5} p={5}>
                          <Heading as={'h4'} size={'md'} my={5}>
                            Subscription - Bronze - Client
                          </Heading>
                          <Box>
                            <Text>
                              Unlock the basics with our Bronze Tier package, offering essential
                              support with limited resources. Enjoy free access to our video library
                              featuring introductory exercises and expert tips. Ideal for clients
                              starting their therapy journey.
                            </Text>
                            <Box width={'100%'}>
                              <Text fontWeight={'bold'} fontSize={40}>
                                £0.00
                              </Text>
                              <Text mt={-2}>per month</Text>
                            </Box>
                            <Button
                              variant={'defaultButton'}
                              onClick={() => {
                                const stripe = require('stripe')(
                                  process.env.REACT_APP_UL_STRIPE_SK
                                );

                                const session = stripe.billingPortal.sessions
                                  .create({
                                    customer: stripeCustomerId,
                                    configuration: 'bpc_1PIpcJJwVKnkDHqHzUhlwccZ',
                                  })
                                  .then((portalData: any) =>
                                    window?.open(portalData.url, '_blank')
                                  );
                              }}
                              mt={5}
                            >
                              Manage subscription
                            </Button>
                          </Box>
                        </Box>

                        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={5} p={5}>
                          <Heading as={'h4'} size={'md'} my={5}>
                            Subscription - Sliver - Client
                          </Heading>
                          <Box>
                            <Text>
                              Silver Tier package. This tier offers a broader selection of
                              resources, including technical and specialized materials tailored to
                              diverse needs. Perfect for deepening your understanding and accessing
                              targeted exercises for effective sessions.
                            </Text>
                            <Box width={'100%'}>
                              <Text fontWeight={'bold'} fontSize={40}>
                                £3.99
                              </Text>
                              <Text mt={-2}>per month</Text>
                            </Box>
                            <Button
                              variant={'defaultButton'}
                              onClick={() => {
                                const stripe = require('stripe')(
                                  process.env.REACT_APP_UL_STRIPE_SK
                                );

                                const session = stripe.billingPortal.sessions
                                  .create({
                                    customer: stripeCustomerId,
                                    configuration: 'bpc_1PIpcJJwVKnkDHqHzUhlwccZ',
                                  })
                                  .then((portalData: any) =>
                                    window?.open(portalData.url, '_blank')
                                  );
                              }}
                              mt={5}
                            >
                              Manage subscription
                            </Button>
                          </Box>
                        </Box>

                        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={5} p={5}>
                          <Heading as={'h4'} size={'md'} my={5}>
                            Subscription - Gold - Client
                          </Heading>
                          <Box>
                            <Text>
                              Experience the full spectrum of therapy resources with our Gold Tier
                              package. Gain unlimited access to all advanced materials, ensuring the
                              best outcomes for your therapy journey. Ideal for clients seeking
                              maximum support and value.
                            </Text>
                            <Box width={'100%'}>
                              <Text fontWeight={'bold'} fontSize={40}>
                                £4.99
                              </Text>
                              <Text mt={-2}>per month</Text>
                            </Box>
                            <Button
                              variant={'defaultButton'}
                              onClick={() => {
                                const stripe = require('stripe')(
                                  process.env.REACT_APP_UL_STRIPE_SK
                                );

                                const session = stripe.billingPortal.sessions
                                  .create({
                                    customer: stripeCustomerId,
                                    configuration: 'bpc_1PIpcJJwVKnkDHqHzUhlwccZ',
                                  })
                                  .then((portalData: any) =>
                                    window?.open(portalData.url, '_blank')
                                  );
                              }}
                              mt={5}
                            >
                              Manage subscription
                            </Button>
                          </Box>
                        </Box>

                     
                      </Box>
                    </Box>
                  )}
                </TabPanel> */}
                <Box>
                  {Number(profileData) === 0 && (
                    <ProfileClientDashboard profileDataType={'website info'} />
                  )}
                  {Number(profileData) === 1 && (
                    <ProfileClientInformation profileDataType={'clinical info'} />
                  )}
                  {Number(profileData) === 2 && (
                    <ProfileClientInvoiceInformation profileDataType={'invoicing'} />
                  )}
                  {Number(profileData) === 3 && <ProfileClientTxBio />}
                  {Number(profileData) === 4 && <ProfileClientDiscoveryCall />}
                  {Number(profileData) === 5 && (
                    <Box w="100%">
                      <Heading as={'h3'} size={'md'} my={5}>
                        Manage your resources subscription
                      </Heading>
                      <Box
                        display={'flex'}
                        flexDirection={'row'}
                        alignItems={'left'}
                        justifyContent={'left'}
                        alignContent={'left'}
                      >
                        <Box
                          w={'30%'}
                          mr={5}
                          bg={'gray.100'}
                          borderRadius={5}
                          p={5}
                          height={400}
                          position={'relative'}
                        >
                          <Heading as={'h4'} size={'md'} my={5}>
                            Subscription - Bronze - Client
                          </Heading>
                          <Box>
                            <Text>
                              Enjoy access to a range of entry level resources to learn from and
                              utilise. Enjoy free access to our video library featuring introductory
                              exercises and expert tips. Ideal for clients starting their therapy
                              journey.
                            </Text>
                            <Box width={'100%'} position={'absolute'} bottom={5}>
                              <Text fontWeight={'bold'} fontSize={40}>
                                £0.00
                              </Text>
                              <Text mt={-2}>per month</Text>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  const stripe = require('stripe')(
                                    process.env.REACT_APP_UL_STRIPE_SK
                                  );

                                  const session = stripe.billingPortal.sessions
                                    .create({
                                      customer: stripeCustomerId,
                                      configuration: 'bpc_1PIpcJJwVKnkDHqHzUhlwccZ',
                                    })
                                    .then((portalData: any) =>
                                      window?.open(portalData.url, '_blank')
                                    );
                                }}
                                mt={5}
                              >
                                Manage subscription
                              </Button>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          w={'30%'}
                          mr={5}
                          bg={'gray.100'}
                          borderRadius={5}
                          p={5}
                          height={400}
                          position={'relative'}
                        >
                          <Heading as={'h4'} size={'md'} my={5}>
                            Subscription - Sliver - Client
                          </Heading>
                          <Box>
                            <Text>
                              Access a broader selection of resources, including technical and
                              specialised materials tailored to diverse needs. Perfect for deepening
                              your understanding and accessing targeted exercises for effective
                              sessions.
                            </Text>
                            <Box width={'100%'} position={'absolute'} bottom={5}>
                              <Text fontWeight={'bold'} fontSize={40}>
                                £3.99
                              </Text>
                              <Text mt={-2}>per month</Text>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  const stripe = require('stripe')(
                                    process.env.REACT_APP_UL_STRIPE_SK
                                  );

                                  const session = stripe.billingPortal.sessions
                                    .create({
                                      customer: stripeCustomerId,
                                      configuration: 'bpc_1PIpcJJwVKnkDHqHzUhlwccZ',
                                    })
                                    .then((portalData: any) =>
                                      window?.open(portalData.url, '_blank')
                                    );
                                }}
                                mt={5}
                              >
                                Manage subscription
                              </Button>
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          w={'30%'}
                          mr={5}
                          bg={'gray.100'}
                          borderRadius={5}
                          p={5}
                          height={400}
                          position={'relative'}
                        >
                          <Heading as={'h4'} size={'md'} my={5}>
                            Subscription - Gold - Client
                          </Heading>
                          <Box>
                            <Text>
                              Experience unlimited access to all advanced materials, ensuring the
                              best outcomes for your therapy journey. Ideal for clients seeking
                              maximum support and value as well as latest videos.
                            </Text>
                            <Box width={'100%'} position={'absolute'} bottom={5}>
                              <Text fontWeight={'bold'} fontSize={40}>
                                £4.99
                              </Text>
                              <Text mt={-2}>per month</Text>
                              <Button
                                variant={'defaultButton'}
                                onClick={() => {
                                  const stripe = require('stripe')(
                                    process.env.REACT_APP_UL_STRIPE_SK
                                  );

                                  const session = stripe.billingPortal.sessions
                                    .create({
                                      customer: stripeCustomerId,
                                      configuration: 'bpc_1PIpcJJwVKnkDHqHzUhlwccZ',
                                    })
                                    .then((portalData: any) =>
                                      window?.open(portalData.url, '_blank')
                                    );
                                }}
                                mt={5}
                              >
                                Manage subscription
                              </Button>
                            </Box>
                          </Box>
                        </Box>

                        {/* <Button
                              variant={'defaultButton'}
                              onClick={() => {
                                const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
      
                                stripe.billingPortal.configurations.create({
                                  business_profile: {
                                    headline: 'Unlocking Language',
                                    privacy_policy_url: 'https://example.com/privacy',
                                    terms_of_service_url: 'https://example.com/tod',
                                  },
      
                                  features: {
                                    customer_update: {
                                      allowed_updates: ['email', 'tax_id'],
                                      enabled: true,
                                    },
                                    invoice_history: {
                                      enabled: true,
                                    },
                                    payment_method_update: {
                                      enabled: true,
                                    },
                                    subscription_cancel: {
                                      cancellation_reason: {
                                        enabled: true,
                                        options: [
                                          'too_expensive',
                                          'missing_features',
                                          'switched_service',
                                          'unused',
                                          'other',
                                        ],
                                      },
                                      enabled: false,
                                      mode: 'at_period_end',
                                      proration_behavior: 'none',
                                    },
                                    subscription_pause: {
                                      enabled: false,
                                    },
                                    subscription_update: {
                                      default_allowed_updates: ['price'],
                                      enabled: true,
                                      products: [
                                        {
                                          prices: ['price_1PHNSHJwVKnkDHqHr9waj23c'],
                                          product: 'prod_Q7chlJWu5dj1gG',
                                        },
                                        {
                                          prices: ['price_1PHNSJJwVKnkDHqH1zl9Ngrs'],
                                          product: 'prod_Q7chihYUq3Iwe1',
                                        },
                                        {
                                          prices: ['price_1PHNSKJwVKnkDHqHQj9bBS1z'],
                                          product: 'prod_Q7chqj85Urgjg1',
                                        },
                                      ],
                                    },
                                  },
                                });
      
                                // Bronze
                                // price_1PHNSHJwVKnkDHqHr9waj23c
                                // prod_Q7chlJWu5dj1gG
      
                                // Silver
                                // price_1PHNSJJwVKnkDHqH1zl9Ngrs
                                // prod_Q7chihYUq3Iwe1
      
                                // Gold
                                // price_1PHNSKJwVKnkDHqHQj9bBS1z
                                // prod_Q7chqj85Urgjg1
                              }}
                            >
                              {' '}
                              Create configuration
                            </Button>
                            <Button
                              variant={'defaultButton'}
                              onClick={() => {
                                const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
                                const configurations = stripe.billingPortal.configurations.list({
                                  limit: 10,
                                });
                              }}
                            >
                              {' '}
                              List customer portals configured
                            </Button>
                            <Button
                              variant={'defaultButton'}
                              onClick={() => {
                                const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);
                                const session = stripe.billingPortal.sessions.create({
                                  customer: 'cus_Q7H7h6oPE8QobQ',
                                  configuration: 'bpc_1PIpa5JwVKnkDHqHZGh0aDkp',
                                });
                              }}
                            >
                              {' '}
                              Create Customer Portal Link
                            </Button> */}
                      </Box>
                    </Box>
                  )}
                </Box>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileContent;
