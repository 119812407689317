import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Select,
  Text,
  Textarea,
  HStack,
  Stack,
  Flex,
  Link,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  InputRightAddon,
  InputGroup,
  InputLeftElement,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Tooltip,
  Icon,
  useBoolean,
} from '@chakra-ui/react';
import orderSchools from '../utils/orderSchools';

import React, { ReactNode, useEffect, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getMultipleApiData from '../utils/getMultipleApiData';
import getAllRowsById from '../utils/getAllRowsById';
import getAllProfiles from '../utils/getAllProfiles';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { json } from 'stream/consumers';
import { color } from 'framer-motion';
import { BackButton } from './BackButton';
import { addStudent } from '../interfaces/addStudent';
import { AddClientsForm } from './AddClientsForm';
import sortDataByParameter from '../utils/sortDataByParameter';
import sorterIcon from '../utils/sorterIcon';
import { Search2Icon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import { GiNotebook } from 'react-icons/gi';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import { clientFields } from '../constants/clientFields';
import {
  totalStudentsHint,
  ehcpHint,
  avgInteractionsHint,
  avgAgeHint,
  genderHint,
  classYearsHint,
} from '../constants/dashboardHints';
const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
// type StateContextType = {
//   userDetails: userSessionDataProps | null;
//   setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
// };
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

interface UserMeta {
  firstName: string;
  lastName: string;
  accessLevel: string;
  accountType: string;
  messages: number[];
  stats: number[];
  invoices: number[];
}
interface Note {
  nid: number;
  session_id: number;
  note_type: string;
  note_owner: number;
  note_details: string;
  posted_at: Date;
  modified_at: Date;
}
const today = new Date();
const formattedToday = moment(new Date()).format('YYYY-MM-DD');
// const now = moment(new Date()); //todays date

const BookingsContentPreviewClients = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  // updateUser(signedInUserId);

  const newNoteDefault = {
    nid: 0,
    session_id: 0,
    note_type: 'brief_note',
    note_owner: signedInUserId,
    note_details: '',
    posted_at: today,
    modified_at: today,
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [genders, setGenders] = useState<any>({});
  const [notes, setNotes] = useState<Note[] | []>([]);
  const [newNote, setNewNote] = useState<Note>(newNoteDefault);
  const [patients, setPatients] = useState<any>([]);
  const [caseNotes, setCaseNotes] = useState<any>([]);
  const [sessionNotes, setSessionNotes] = useState<any>([]);
  const [schools, setSchools] = useState<any>([]);
  const [senSchools, setSenSchools] = useState<any>([]);
  const [tomsRating, setTomsRating] = useState<any>([]);
  const [allClassYears, setAllClassYears] = useState<[]>([]);
  const [showAddStudent, setShowAddStudent] = useState<number>(0);
  const [schoolFilter, setSchoolFilter] = useState(0);
  const [size, setSize] = useState('md');
  const [rows, setRows] = useState<any>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueLowerCase, setSearchValueLowerCase] = useState<string>('');
  const [activeSort, setActiveSort] = useState<string>('None');
  const [activeSearch, setActiveSearch] = useState(0);
  const [notesPerSchool, setNotesPerSchool] = useState(0);
  const [sorter, setSorter] = useBoolean();
  const [schoolSelected, setSchoolSelected] = useState<any>('');
  const [caseNoteCount, setCaseNoteCount] = useState<any>([]);
  const [studentAges, setStudentAges] = useState<any>([]);
  const [showAllColumns, setShowAllColumns] = useState<number>(0);

  const [caseNoteCountTracker, setCaseNoteCountTracker] = useState<number>(0);

  const handleSearch = (e: any) => {
    let searchInput = e.target.value;
    let lowerSearchInput = searchInput.toLowerCase();
    setSearchValue(searchInput);
    setSearchValueLowerCase(lowerSearchInput);
    console.log(lowerSearchInput);
    setActiveSearch(1);
  };

  function clearSearch() {
    setSearchValue('');
    setSearchValueLowerCase('');
    setActiveSearch(0);
    getAllRowsByEncodedQuery(getAllStudentsEP, getAllStudentsQuery).then((data: any) => {
      const allStudents = data.split(',');
      console.log(allStudents);
      data?.length > 0 &&
        getMultipleApiData(getCurrentSchoolsStudents!, bearerToken!, allStudents).then(
          (data: any) => {
            setCaseNotes(
              data
                .filter(
                  (z: any) =>
                    z.fields['Date discharged'] === undefined ||
                    z.fields['Date discharged'] === null ||
                    z.fields['Date discharged'] === ''
                )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['First Name'].localeCompare(a?.fields['First Name'])
                    : a?.fields['First Name'].localeCompare(b?.fields['First Name'])
                )
            );
            getCaseNoteCount(
              data
                .filter(
                  (i: any) =>
                    i.fields['Date of Discharge'] === undefined ||
                    i.fields['Date of Discharge'] === null ||
                    i.fields['Date of Discharge'] === ''
                )
                .sort((a: any, b: any) =>
                  sorter === true
                    ? b?.fields['First Name'].localeCompare(a?.fields['First Name'])
                    : a?.fields['First Name'].localeCompare(b?.fields['First Name'])
                )
            );
          }
        );
      // getCaseNoteCount(allStudents);
    });
  }

  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const studentClasses = ['N', 'R', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const IsolatedModal = ({ modalState }: any) => {
    return (
      <Box as="section">
        <Modal
          onClose={() => {
            setTheModal(0);
            setOpenedModal(false);
          }}
          size={'2xl'}
          isOpen={modalState}
        >
          <ModalOverlay />
          <AddClientsForm
            schoolFilter={schoolFilter}
            setSchoolFilter={setSchoolFilter}
            setCaseNotes={setCaseNotes}
            regularSchoolsEP={regularSchoolsEP}
            senSchoolsEP={senSchoolsEP}
            therapistCaseNotesRegularSchoolsSEP={therapistCaseNotesRegularSchoolsSEP}
            therapistCaseNotesSENSchoolsEP={therapistCaseNotesSENSchoolsEP}
            studentClasses={studentClasses}
            tabIndex={tabIndex}
            setTheModal={setTheModal}
            setOpenedModal={setOpenedModal}
          />
        </Modal>
      </Box>
    );
  };

  function handleModalOpen(school: string) {
    // console.log('We added a student to ' + school);
    setOpenedModal(true);
    // console.log(openedModal);
  }
  function handleModalClose(rowId: any, rowSession: any) {
    // console.log('We tried to close ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
    setOpenedModal(false);
    setTheModal(rowId);
    // console.log(openedModal);
  }
  // const [overlay, setOverlay] = useState<ReactNode>(<IsolatedModal session={0} />);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allProfilesFullUrl = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const userStats = userMeta && userMeta?.stats;
  const therapistsTable = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolID = queryParameters.get('schoolID');
  const schoolType = queryParameters.get('schoolType');
  const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const accountType = schoolType === 'RegularSchool' ? 'Mainstream' : 'SEN Schools';
  const schoolInView = Number(schoolID);
  const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;

  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

  // console.log(clientFields.fields?.map((i: any) => i.name));
  // ${Number(schoolID)}
  const baseMsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['Mainstream School'.Id = school_id];
concat(students)`;

  const baseSsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['SEN schools'.Id = school_id];
concat(students)`;

  const getAllStudentsEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;
  const getAllStudentsQuery = schoolType === 'RegularSchool' ? baseMsQuery : baseSsQuery;
  const getCurrentSchoolsStudents =
    schoolType === 'RegularSchool' ? caseNotesMainstream : caseNotesSEN;

  const txAccountNumber = Number(localStorage.getItem('accountNumber'));

  // Clients query EP:
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

  // Clients query:
  const therapist_id = txAccountNumber;
  const clientsQuery = `let therapist_id := ${therapist_id};
let students := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.id = therapist_id]
concat(students);
`;

  // Client query data EP:
  const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

  // const getAllStudents = tabIndex === 0 ? caseNotesMainstream : caseNotesSEN;

  const NotesEP =
    schoolType == 'RegularSchool'
      ? therapistSessionNotesRegularSchoolsEP
      : therapistSessionNotesSENSchoolsEP;

  function getCaseNoteCount(students: any) {
    // console.log('students: ', students);
    getAllRowsById(NotesEP).then((data) => {
      const myData = data;
      // setSessionNotes(myData);
      setCaseNoteCount([]);
      students.map((i: any, k: any) => {
        // for each student get array of casenotes
        setStudentAges(
          students.map((t: any, k: any) => {
            const born = moment(t?.fields['DOB']).format('YYYY-MM-DD');
            var a = moment(formattedToday);
            var b = moment(born);
            const dobTest = a.diff(b, 'years');

            return dobTest;
          })
        );
        myData.filter((z: any) => Number(z.fields['Caseload']) === i.id)?.length > 0 &&
          setCaseNoteCount((caseNoteCount: any) => [
            ...caseNoteCount,
            myData.filter((z: any) => Number(z.fields['Caseload']) === i.id)?.length,
          ]);
        // setCaseNoteCountTracker(1);
        // console.log(myData.filter((z: any) => Number(z.fields['Caseload']) === i.id));
      });
    });
  }

  useEffect(() => {
    setSignedInUserType('clinical');

    const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
    const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
    const EP = process.env.REACT_APP_USER_DATA;
    let params = new URLSearchParams();
    let table = 'Sessions + Notes';
    params.set('query', table);
    const notesCountRegSchool = `${process.env.REACT_APP_MS_QUERY_EP}?` + params;
    const notesCountSenSchool = `${process.env.REACT_APP_SS_QUERY_EP}?query=let%20a%20%3A%3D%20${schoolID}%3B%20count((select%20'Sessions%20%2B%20Notes')%5BCaseload.'SEN%20Schools'.Id%20%3D%20a%5D)`;
    const NotesCountEP = schoolType == 'RegularSchool' ? notesCountRegSchool : notesCountSenSchool;

    setNewNote(newNoteDefault);
    getAllRowsById(regularSchoolsEP).then((data) => {
      const myData = data;
      setSchools(myData);
    });
    getAllRowsById(senSchoolsEP).then((data) => {
      const myData = data;
      setSenSchools(myData);
    });

    const delayDebounceFn = setTimeout(() => {
      searchValueLowerCase?.length >= 1 &&
        setCaseNotes(
          caseNotes.filter((caseNote: any) => {
            return caseNote?.fields['First Name']
              .toLocaleLowerCase()
              .includes(searchValueLowerCase);
          })
        );
    }, 500);

    setSchoolFilter(txAccountNumber);
    setShowAddStudent(Number(schoolID));
    setSchoolSelected(schools.filter((i: schoolsFil) => i.id === schoolInView));
    // setSchoolFilter(Number(51));
    // navigate('/clients?schoolID=' + txAccountNumber + '&schoolType=RegularSchool');
    return () => clearTimeout(delayDebounceFn);
  }, [searchValueLowerCase]);

  useEffect(() => {
    getAllRowsByEncodedQuery(clientsQueryEP, clientsQuery).then((data: any) => {
      const allStudents = data.split(',');
      // console.log(allStudents);
      // console.log(getAllStudentsEP);
      // console.log(getAllStudentsQuery);
      data?.length > 0 &&
        getMultipleApiData(clientsListEP!, bearerToken!, allStudents).then((data: any) => {
          setCaseNotes(
            data.filter(
              (z: any) =>
                z.fields['Date discharged'] === undefined ||
                z.fields['Date discharged'] === null ||
                z.fields['Date discharged'] === ''
            )
            // .filter(
            //   (i: any) =>
            //     i.fields['Date of Discharge'] === undefined ||
            //     i.fields['Date of Discharge'] === null ||
            //     i.fields['Date of Discharge'] === ''
            // )
            // .sort((a: any, b: any) =>
            //   sorter === true
            //     ? b?.fields['First Name'].localeCompare(
            //         a?.fields['First Name']
            //       )
            //     : a?.fields['First Name'].localeCompare(
            //         b?.fields['First Name']
            //       )
            // )
          );
          getCaseNoteCount(
            data
            // .filter(
            //   (i: any) =>
            //     i.fields['Date of Discharge'] === undefined ||
            //     i.fields['Date of Discharge'] === null ||
            //     i.fields['Date of Discharge'] === ''
            // )
            // .sort((a: any, b: any) =>
            //   sorter === true
            //     ? b?.fields['First Name'].localeCompare(
            //         a?.fields['First Name']
            //       )
            //     : a?.fields['First Name'].localeCompare(
            //         b?.fields['First Name']
            //       )
            // )
          );
        });
    });
  }, [userStats, schoolFilter, genders]);

  interface schoolsFil {
    id: number;
  }

  const handleNoteType = (e: any) => {
    setNewNote({ ...newNote, note_type: e.target.value });
  };

  const handleNoteDetails = (e: any) => {
    let inputValue = e.target.value;
    setNewNote({ ...newNote, note_details: inputValue });
  };

  let currentUrlParams = new URLSearchParams(window.location.search);
  // const clientHeadings = [
  //   'First Name & Last Name',
  //   'Date of next therapy session',
  //   'Have you seen a Speech Therapist',
  //   'Date discharged',
  //   'Diagnosis (Notes)',
  //   'Parents Last Name',
  //   'Therapy Length',
  //   'Type of Speech Therapist seen?',
  //   'Date / Time of Enquiry',
  //   'Assessment Rate',
  //   'If reassigned, Previous Therapist?',
  //   'Xero Contact First Name',
  //   'Sex',
  //   'Report Rate',
  //   'Xero Contact Surname',
  //   'Diagnosis',
  //   'Xero Invoice Line Item Amount',
  //   'Stats',
  //   'Therapy Rate',
  //   'Age (Enquiry)',
  //   'Therapy Frequency',
  //   'Do you have a written report? Date?',
  //   'Status',
  //   'Mobile 1',
  //   'Invoices',
  //   'Record iD Field for Zapier',
  //   'First Name',
  //   'Client type',
  //   'Last Name',
  //   'Enquiry - Concerns',
  //   'SLA Template',
  //   'Date of Birth',
  //   'Xero Contact Street Address',
  //   'Parents First Name',
  //   'Email 2',
  //   'Adult or Child?',
  //   'Any other information (i.e. partial address)',
  //   'Docusign Template Name for Integration',
  //   'Phone',
  //   'Additonal party involved: Solicitor, Court, School, Local Authority?',
  //   'Email 1',
  //   'Xero Contact Area Address',
  //   'Current Therapist Assigned',
  //   'Address',
  //   'Docusign',
  //   'Invoice Frequency',
  //   'Assessment undertaken?',
  //   'Report provided?',
  //   'Additional party details',
  //   'Xero Contact City/County Address',
  //   'Assessment Date',
  //   'Email Template - Child',
  //   'Email Template - Adult',
  //   'Therapy Location',
  //   'Session Period Start',
  //   'Session Period End',
  //   'Block of 8 Invoices Paid For?',
  //   'Re-assessment - If applicable?',
  //   'Re-assessment Date',
  //   'Docusign Returned Full Name',
  //   'Docusign Returned Email Address',
  //   'Docusign Returned Gender',
  //   'Docusign Returned Telephone',
  //   'Docusign Returned Address',
  //   'Docusign Returned Other',
  //   'Docusign Returned Mobile',
  //   'Docusign Returned D.O.B (Text)',
  //   'Report paid for?',
  //   'Choose your Docusign Template',
  //   'Reason if none scheduled?',
  //   'Discharge letter sent',
  //   'Pricing notes',
  //   'Sessions in Block',
  //   'Xero Overview',
  //   'Date of Last Sync',
  //   'School or Employer',
  //   'Session Block Start Date',
  //   'Copied Client To Tribunal DB',
  //   'Who is the Payee for the Invoice in Xero?',
  //   'LA Contact Name',
  //   'LA Contact Email',
  //   'Xero Contact Email',
  //   'Session Block End Date',
  //   'Start Date - Link Sessions To Invoice',
  //   'End Date - Link Sessions To Invoice',
  //   'Xero Invoice',
  //   'Mobile 2',
  //   'PO Number',
  //   'Rate?',
  //   'Notes - rate',
  //   'Xero Contact ID',
  //   'Month',
  //   'Invoice instructions',
  //   'Type',
  //   'Primary Email Address',
  //   'Select Primary Email Address',
  //   'Override',
  //   'Copied to Unlocking Language Tribunal DB',
  //   'Select number',
  //   'Message (plain-text)',
  //   'Templates',
  //   'Select Invoice',
  //   'Messages',
  //   'Access level',
  //   'Agreed to T&Cs - website',
  //   'Docusign sent',
  //   'Gocardless active',
  //   'Auto message trigger',
  //   'Stripe first payment',
  //   'Has this person unsubscribed to Marketing SMS',
  //   'Uploads folder',
  //   'HTML Contract',
  //   'Type of NEW invoice',
  //   'Account status',
  //   'Car reg',
  //   'Car reg 2',
  //   'Mode of transport',
  //   'TOMs rating',
  //   'Primary TOMs Scale',
  //   'Secondary TOMs Scale',
  //   'Case notes',
  //   'Manage',
  //   'TOMS',
  // ];
  const clientHeadings = [
    'First Name',
    'Last Name',
    'Next session',
    'Last session',
    'Current Therapist Assigned',
    'If reassigned, Previous Therapist?',
    'Status',
    'Client type',
    'Therapy Location',
    'Adult or Child?',
    'Sex',
    'Date of Birth',
    'Age (Enquiry)',
    'Parents First Name',
    'Parents Last Name',
    'School or Employer',
    'Phone',
    'Mobile 1',
    'Mobile 2',
    'Primary Email Address',
    'Email 1',
    'Email 2',
    'Address',
    'Any other information (i.e. partial address)',
    'Additonal party involved: Solicitor, Court, School, Local Authority?',
    'Additional party details',
    'Have you seen a Speech Therapist',
    'Type of Speech Therapist seen?',
    'Do you have a written report? Date?',
    'Enquiry - Concerns',
    'Assessment undertaken?',
    'Assessment Date',
    'Re-assessment - If applicable?',
    'Re-assessment Date',
    'Report provided?',
    'Report paid for?',
    'Diagnosis',
    'Diagnosis (Notes)',
    'Date discharged',
    'Discharge letter sent',
    'Case notes',
    'Manage',
    'TOMS',
  ];
  const clientHeadingsHidden = ['First Name', 'Last Name', 'Case notes', 'Manage', 'TOMS'];

  const headingsHidden = ['Student name', 'Case notes', 'Manage', 'TOMS'];
  const headings = [
    'Student name',
    'Case notes',
    'Manage',
    'TOMS',
    'DOB',
    'Age',
    'Class year',
    'Class name',
    'Gender',
    'Ethnicity',
    'Referral Date',
    'Language 1',
    'Language 2',
    'Language 3',
    'Other Languages',
    'Local Patient Identifier',
    'Date of Assessment',
    'Report Date',
    'Report sent',
    'Report Link',
    'Notes',
    'Date of review',
    'Name of Therapist',
    'Primary Medical Diagnosis',
    'Additional Medical Diagnosis',
    'Other Medical Diagnosis',
    'Primary Communication/ Swallowing Disorder',
    'Additional Communication/ Swallowing Disorder',
    'Other Communication/ Swallowing Disorder',
    'EHCP',
    'Direct Hours',
    'Indirect Hours',
    'Hours completed',
    'Hours remaining',
    'Plan',
    'Primary TOMs Scale',
    'Secondary TOMs Scale',
  ];
  const optionsGenders: ApexOptions = {
    chart: {
      width: 'auto',
      type: 'pie',
    },
    labels: ['Males', 'Females'],
    legend: {
      position: 'bottom',
    },
    colors: ['#40A83D', '#D6D944'],
    stroke: {
      curve: 'smooth',
      colors: ['transparent'],
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    dataLabels: {
      formatter: function (val, opts) {
        return opts.w.config.series[opts.seriesIndex];
      },
      enabled: true,
      style: {
        fontSize: '18px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
      },
    },
  };
  let classYears = [
    caseNotes
      ? caseNotes?.map((i: any, k: any) => {
          return i?.fields['Therapy Location'] === undefined
            ? 'Location Unknown'
            : i?.fields['Therapy Location'];
        })
      : 0,
  ];
  let classYearsFilterList = classYears[0];
  let uniqueClassYears = classYearsFilterList?.sort().filter((element: any, index: any) => {
    return classYearsFilterList?.indexOf(element) === index;
  });
  // console.log('uniqueClassYears: ', uniqueClassYears);

  function getNextSession(clientId: any) {
    const nextSession = `
let now := today();
let sess := (select 'Session Stats')['Session Start' >= now and 'Central Client Database - ALL STAFF USAGE'.Id = ${clientId}].'Session Start';
format(first(sort(sess)), "x")`;

    const nextSessionVal = getAllRowsByEncodedQuery(clientsQueryEP, nextSession).then(
      (data: any) => {
        console.log(data);
        return data;
      }
    );

    return JSON.stringify(nextSessionVal);
  }

  function getLastSession(clientId: any) {
    const lastSession = `
    let now := today();
    let sess := (select 'Session Stats')['Session Start' < now and 'Central Client Database - ALL STAFF USAGE'.Id = ${clientId}].'Session Start';
    format(last(sort(sess)), "x")`;

    const lastSessionVal = getAllRowsByEncodedQuery(clientsQueryEP, lastSession).then(
      (data: any) => {
        console.log(data);
        return data;
      }
    );

    return JSON.stringify(lastSessionVal);
  }

  function displayDataForClass(uniqueClassYearsArray: any) {
    const newArray = uniqueClassYearsArray?.map(
      (z: any, k: any) =>
        caseNotes.filter((i: any) =>
          z === 'Location Unknown'
            ? i?.fields['Therapy Location'] === undefined
            : i?.fields['Therapy Location'] === z
        )?.length
    );
    // console.log('newArrayNumbers: ', newArray);
    return newArray;
  }

  function displayGenderCountForClass(gendersArray: any) {
    const newArray = gendersArray?.map(
      (z: any, k: any) => caseNotes.filter((i: any) => i?.fields['Sex'] === z)?.length
    );
    return newArray;
  }
  const genderTypesArray = ['Male', 'Female'];

  const barChartOptions: any = {
    // Define your chart options here
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
      style: {
        color: '#444',
      },
    },
    series: [
      {
        name: 'No of locations',
        data: displayDataForClass(uniqueClassYears),
      },
    ],
    xaxis: {
      categories: uniqueClassYears,
    },
    colors: ['#0C7348'],
  };

  return (
    <>
      <Box w={'100%'} display={'flex'} flexDirection={'column'}>
        <Box width={'100%'}>
          <TableContainer className="caseloadContainer" width={'100%'}>
            {localStorage.getItem('accountType') !== undefined &&
              localStorage.getItem('accountType') === 'Therapist Staff' && (
                <Table variant="striped" width={'100%'}>
                  <Thead>
                    <Tr>
                      {showAllColumns === 0
                        ? clientHeadingsHidden.map((i: any, k: number) => (
                            <Th
                              key={k}
                              style={
                                k === 0
                                  ? {
                                      position: 'sticky',
                                      left: '0',
                                      width: 'auto',
                                      zIndex: 999,
                                      cursor: 'pointer',
                                    }
                                  : {}
                              }
                              onClick={() => {
                                sortDataByParameter(
                                  setCaseNotes,
                                  caseNotes,
                                  schoolFilter,
                                  i,
                                  accountType!,
                                  sorter
                                );
                                setActiveSort(i);
                                setSorter.toggle();
                              }}
                            >
                              {i}
                              {sorterIcon(sorter, activeSort, i)}
                            </Th>
                          ))
                        : clientHeadings.map((i: any, k: number) => (
                            <Th
                              key={k}
                              style={
                                k === 0
                                  ? {
                                      position: 'sticky',
                                      left: '0',
                                      width: '150px',
                                      zIndex: 999,
                                      cursor: 'pointer',
                                    }
                                  : k === 1
                                  ? {
                                      position: 'sticky',
                                      left: '140px',
                                      width: '150px',
                                      zIndex: 999,
                                      cursor: 'pointer',
                                    }
                                  : k === 40
                                  ? { position: 'sticky', right: '240px', width: '120px' }
                                  : k === 41
                                  ? { position: 'sticky', right: '120px', width: '120px' }
                                  : k === 42
                                  ? { position: 'sticky', right: '0', width: '120px' }
                                  : {}
                              }
                              onClick={() => {
                                sortDataByParameter(
                                  setCaseNotes,
                                  caseNotes,
                                  schoolFilter,
                                  i,
                                  accountType!,
                                  sorter
                                );
                                setActiveSort(i);
                                setSorter.toggle();
                              }}
                            >
                              {i}
                              {sorterIcon(sorter, activeSort, i)}
                            </Th>
                          ))}
                    </Tr>
                  </Thead>
                  {showAllColumns === 0 ? (
                    <Tbody>
                      {caseNotes &&
                        caseNotes.map((i: any, k: any) => {
                          const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                          var a = moment(formattedToday);
                          var b = moment(born);
                          const dobTest = a.diff(b, 'years');
                          return (
                            <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        left: '0',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        left: '0',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                {/* {k} - {i?.id} - */}
                                {i?.fields['First Name']}{' '}
                              </Td>
                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        left: '140px',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        left: '140px',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                {i?.fields['Last Name']}
                              </Td>
                              <Td>
                                <Button
                                  bg={'brand.300'}
                                  color={'white'}
                                  _hover={{
                                    background: 'brand.200',
                                  }}
                                  onClick={() => {
                                    schoolType === 'RegularSchool'
                                      ? navigate(
                                          '/therapist-client-session/' +
                                            i.id +
                                            '?schoolType=RegularSchool'
                                        )
                                      : navigate(
                                          '/therapist-client-session/' +
                                            i.id +
                                            '?schoolType=SENSchool'
                                        );
                                  }}
                                >
                                  View
                                </Button>
                              </Td>
                              <Td>
                                <Button
                                  bg={'brand.300'}
                                  color={'white'}
                                  _hover={{
                                    background: 'brand.200',
                                  }}
                                  onClick={() => {
                                    navigate('/edit-client/' + i.id);
                                  }}
                                >
                                  Manage
                                </Button>
                              </Td>
                              <Td>
                                <Button
                                  bg={'brand.300'}
                                  color={'white'}
                                  _hover={{
                                    background: 'brand.200',
                                  }}
                                  onClick={() => {
                                    navigate('/client-toms-rating/' + i.id);
                                  }}
                                >
                                  TOMS
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  ) : (
                    <Tbody>
                      {caseNotes &&
                        caseNotes.map((i: any, k: any) => {
                          const born = moment(i?.fields['DOB']).format('YYYY-MM-DD');
                          var a = moment(formattedToday);
                          var b = moment(born);
                          const dobTest = a.diff(b, 'years');
                          return (
                            <Tr key={k} style={{ backgroundColor: 'gray.300' }}>
                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        left: '0',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        left: '0',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                {/* {k} - {i?.id} - */}
                                {i?.fields['First Name']}{' '}
                              </Td>
                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        left: '140px',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        left: '140px',
                                        width: '150px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                {i?.fields['Last Name']}
                              </Td>
                              <Td>{i?.id && getNextSession(i?.id)}</Td>
                              <Td>{i?.id && getLastSession(i?.id)}</Td>
                              <Td>{i?.fields['Current Therapist Assigned']}</Td>
                              <Td>{i?.fields['If reassigned, Previous Therapist?']}</Td>
                              <Td>{i?.fields['Status']}</Td>
                              <Td>{i?.fields['Client type']}</Td>
                              <Td>{i?.fields['Therapy Location']}</Td>
                              <Td>{i?.fields['Adult or Child?']}</Td>
                              <Td>{i?.fields['Sex']}</Td>
                              <Td>{i?.fields['Date of Birth']}</Td>
                              <Td>{i?.fields['Age (Enquiry)']}</Td>
                              <Td>{i?.fields['Parents First Name']}</Td>
                              <Td>{i?.fields['Parents Last Name']}</Td>
                              <Td>{i?.fields['School or Employer']}</Td>
                              <Td>{i?.fields['Phone']}</Td>
                              <Td>{i?.fields['Mobile 1']}</Td>
                              <Td>{i?.fields['Mobile 2']}</Td>
                              <Td>{i?.fields['Primary Email Address']}</Td>
                              <Td>{i?.fields['Email 1']}</Td>
                              <Td>{i?.fields['Email 2']}</Td>
                              <Td>{i?.fields['Address']}</Td>
                              <Td>{i?.fields['Any other information (i.e. partial address)']}</Td>
                              <Td>
                                {
                                  i?.fields[
                                    'Additonal party involved: Solicitor, Court, School, Local Authority?'
                                  ]
                                }
                              </Td>
                              <Td>{i?.fields['Additional party details']}</Td>
                              <Td>{i?.fields['Have you seen a Speech Therapist']}</Td>
                              <Td>{i?.fields['Type of Speech Therapist seen?']}</Td>
                              <Td>{i?.fields['Do you have a written report? Date?']}</Td>
                              <Td>{i?.fields['Enquiry - Concerns']}</Td>
                              <Td>{i?.fields['Assessment undertaken?']}</Td>
                              <Td>{i?.fields['Assessment Date']}</Td>
                              <Td>{i?.fields['Re-assessment - If applicable?']}</Td>
                              <Td>{i?.fields['Re-assessment Date']}</Td>
                              <Td>{i?.fields['Report provided?']}</Td>
                              <Td>{i?.fields['Report paid for?']}</Td>
                              <Td>{i?.fields['Diagnosis']}</Td>
                              <Td>{i?.fields['Diagnosis (Notes)']}</Td>
                              <Td>{i?.fields['Date discharged']}</Td>
                              <Td>{i?.fields['Discharge letter sent']}</Td>

                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        right: '240px',
                                        width: '120px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        right: '240px',
                                        width: '120px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                <Button
                                  bg={'brand.300'}
                                  color={'white'}
                                  _hover={{
                                    background: 'brand.200',
                                  }}
                                  onClick={() => {
                                    schoolType === 'RegularSchool'
                                      ? navigate(
                                          '/therapist-client-session/' +
                                            i.id +
                                            '?schoolType=RegularSchool'
                                        )
                                      : navigate(
                                          '/therapist-client-session/' +
                                            i.id +
                                            '?schoolType=SENSchool'
                                        );
                                  }}
                                >
                                  View
                                </Button>
                              </Td>
                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        right: '120px',
                                        width: '120px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        right: '120px',
                                        width: '120px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                <Button
                                  bg={'brand.300'}
                                  color={'white'}
                                  _hover={{
                                    background: 'brand.200',
                                  }}
                                  onClick={() => {
                                    navigate('/edit-client/' + i.id);
                                  }}
                                >
                                  Manage
                                </Button>
                              </Td>
                              <Td
                                style={
                                  k % 2 == 0
                                    ? {
                                        position: 'sticky',
                                        right: '0',
                                        width: '120px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'gray.100',
                                      }
                                    : {
                                        position: 'sticky',
                                        right: '0',
                                        width: '120px',
                                        zIndex: 9,
                                        fontWeight: 'bold',
                                        background: 'white',
                                      }
                                }
                              >
                                <Button
                                  bg={'brand.300'}
                                  color={'white'}
                                  _hover={{
                                    background: 'brand.200',
                                  }}
                                  onClick={() => {
                                    // tabIndex === 0
                                    schoolType === 'RegularSchool'
                                      ? navigate(
                                          '/toms-rating/' + i.id + '?schoolType=RegularSchool'
                                        )
                                      : navigate('/toms-rating/' + i.id + '?schoolType=SENSchool');
                                  }}
                                >
                                  TOMS
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  )}

                  {caseNotes === undefined && <TableCaption>Your sessions history</TableCaption>}
                </Table>
              )}
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentPreviewClients;
