import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Flex,
  Stack,
  Heading,
  Text,
  Link,
  Button,
  Box,
  ButtonGroup,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  FormHelperText,
  Image,
  VStack,
  Grid,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  InputRightElement,
  InputGroup,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import UL_logo from './../logo/unlocking_language_logo_white.png';
import { AuthContext } from '../context/AuthContext';
import { privateForm } from '../interfaces/privateForm';
import { useNavigate } from 'react-router-dom';
import { auth } from './../auth/fireBaseSetup';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;

const steps = [{ title: 'School Details', description: 'Check your details', content: '' }];
const queryParameters = new URLSearchParams(window.location.search);
const schoolID = queryParameters.get('schoolID');
const schoolType = queryParameters.get('schoolType');
const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;

export default function SchoolSignUp(): JSX.Element {
  const defaultForm = {
    schoolName: '',
    contactNumber: '',
    SENCO: '',
    email: '',
    financeContact: '',
    financeEmail: '',
    signInEmail: '',
    accessLevel: 'bronze',
    accountStatus: false,
    password: '',
    cpassword: '',
  };
  const [form, setForm] = useState<privateForm | any>(defaultForm);
  const [user, setUser]: any = useState();

  const [signContract, setSignContract] = useState(0);
  const [edit, setEdit] = useState(0);
  const [signUpError, setSignUpError] = useState<any>('');
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const schoolToUpdate = {
    'School Name': form.schoolName,
    Phone: form.contactNumber,
    SENCO: form.SENCO,
    // 'SENCO Email': form.email,
    'Finance Contact': form.financeContact,
    'Finance Email': form.financeEmail,
    Location: form.location,
    'Location text': form.locationText,
  };
  const updateAccountStatus = {
    'Account Status': true,
  };
  const createAccount = async () => {
    console.log('Account creation triggered');
    try {
      await auth.createUserWithEmailAndPassword(form.email, form.password);
      console.log('School Account created');
      ninoxTableUpdateRow(
        schoolType === 'RegularSchool' ? regularSchoolsEP! : senSchoolsEP!,
        Number(schoolID),
        { fields: updateAccountStatus },
        String(bearerToken)
      ).then((data: any) => data);
      console.log('School info updated!');
      schoolType === 'RegularSchool'
        ? navigate('/your-school-account-has-been-setup?schoolType=RegularSchool')
        : navigate('/your-school-account-has-been-setup?schoolType=SENSchool');
    } catch (error) {
      console.error(error);
      setSignUpError(error);
      console.log('Account creation failed');
    }
  };

  function goBackOneStep(activeStep: number) {
    setActiveStep(activeStep - 1);
  }

  function getUserData() {
    ninoxTableGetRow(
      schoolType === 'RegularSchool' ? regularSchoolsEP! : senSchoolsEP!,
      Number(schoolID),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUser(data);
      setForm({
        ...form,
        schoolName: data?.fields['School Name'],
        contactNumber: data?.fields['Phone'],
        SENCO: data?.fields['SENCO'],
        email: data?.fields['SENCO Email'],
        financeContact: data?.fields['Finance Contact'],
        financeEmail: data?.fields['Finance Email'],
        signInEmail: data?.fields['SENCO Email'],
        location: data?.fields['Location'],
        locationText: data?.fields['Location text'],
        accessLevel: 'bronze',
        accountStatus: false,
        password: '',
        cpassword: '',
      });
      // console.log('email', data?.fields['SENCO Email']);
      // console.log('School ID: ', schoolID, ': ', data);
      // console.log('School type: ', schoolType);
    });
  }
  const stepSelect = steps[activeStep].title;
  function sighUpAndRedirect() {
    createAccount();
  }
  function updateSchoolInfo() {
    setEdit(0);
    ninoxTableUpdateRow(
      schoolType === 'RegularSchool' ? regularSchoolsEP! : senSchoolsEP!,
      Number(schoolID),
      { fields: schoolToUpdate },
      String(bearerToken)
    ).then((data: any) => data);
    console.log('School info updated!');
    getUserData();
  }
  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Flex minH={'100vh'} align={'center'} justify={'center'} bg={'gray.100'}>
      <Stack w={'full'} maxW={'6xl'} p={6} mt={2} alignContent={'end'} bg={'white'} rounded={'xl'}>
        <Box justifyContent={'left'} bg={'white'} rounded={'xl'} w={'auto'} p={5}>
          <HStack>
            <Box width="50%">
              <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
            <Box width="50%" pt={5}>
              <Heading as="h3" size="lg" mb={10} textAlign={'right'} color={'brand.200'}>
                School onboarding process
              </Heading>
            </Box>
          </HStack>
          {/* <Box
            width={'70%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'start'}
            alignItems={'start'}
            textAlign={'left'}
          >
            <Text>
              Please check your schools information and set a password in order to access your
              schools dashboard
            </Text>
          </Box> */}
        </Box>

        <Stack>
          <Box w="100%" mt={-10}>
            <Grid>
              <Box>
                {user?.fields['Account status'] === true ? (
                  <Box p={10}>
                    You have already completed onboarding, please{' '}
                    <Link href={'school-login'}>
                      <Button variant={'defaultButton'}>Login</Button>
                    </Link>
                  </Box>
                ) : (
                  <>
                    <Accordion
                      allowToggle
                      mt={'10'}
                      defaultIndex={[0]}
                      borderRadius={10}
                      width={'100%'}
                      bg="white"
                    >
                      <AccordionItem mb={5} borderRadius={10}>
                        <AccordionButton
                          _expanded={{ bg: 'brand.300', color: 'white' }}
                          _hover={{ bg: 'brand.300', color: 'white' }}
                          bg={'brand.200'}
                          color={'white'}
                          borderRadius={10}
                        >
                          <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                            School details
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel
                          bg={'white'}
                          border={'1px'}
                          borderColor={'gray.100'}
                          borderRadius={10}
                        >
                          <Box width={'100%'} mt={'10'}>
                            <Box
                              width={'100%'}
                              p={2}
                              bg={'white'}
                              textAlign={'right'}
                              display={'flex'}
                              flexDirection={'column'}
                              alignContent={'end'}
                              alignItems={'end'}
                            >
                              <HStack width={'100%'}>
                                <Box
                                  width={'70%'}
                                  display={'flex'}
                                  flexDirection={'column'}
                                  justifyContent={'start'}
                                  alignItems={'start'}
                                  textAlign={'left'}
                                  mt={-5}
                                >
                                  <Text>
                                    Please check your schools information and set a password in
                                    order to access your schools dashboard
                                  </Text>
                                </Box>
                                <Box
                                  display={'flex'}
                                  flexDirection={'column'}
                                  justifyContent={'end'}
                                  alignItems={'end'}
                                  width={'30%'}
                                >
                                  {user?.fields['Account status'] !== true ? (
                                    edit === 0 ? (
                                      <Box>
                                        <HStack>
                                          <Button
                                            variant={'defaultButton'}
                                            mt={'-10'}
                                            onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
                                          >
                                            Edit
                                          </Button>
                                        </HStack>
                                      </Box>
                                    ) : (
                                      <Box>
                                        <HStack>
                                          <Button
                                            variant={'defaultButton'}
                                            mt={'-10'}
                                            onClick={() => updateSchoolInfo()}
                                          >
                                            Save
                                          </Button>
                                          <Button
                                            variant={'defaultButton'}
                                            mt={'-10'}
                                            onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
                                          >
                                            Cancel
                                          </Button>
                                        </HStack>
                                      </Box>
                                    )
                                  ) : (
                                    <Text width={'100%'} textAlign={'center'}>
                                      Account already registered
                                    </Text>
                                  )}
                                </Box>
                              </HStack>
                            </Box>

                            {signUpError && (
                              <Box color={'red.500'} mb={10} fontWeight={'bold'}>
                                {signUpError?.code === 'auth/email-already-in-use'
                                  ? 'You already have an account with us. Have you tried sigining in?'
                                  : signUpError?.code === 'auth/missing-password'
                                  ? 'Missing password! Password is a mandatory field. Please enter a password'
                                  : signUpError?.code === 'auth/weak-password'
                                  ? 'Weak password! Please enter a stronger password'
                                  : signUpError?.code}
                              </Box>
                            )}
                          </Box>
                          <HStack width={'100%'} alignItems={'start'}>
                            <Box width={'100%'} p={2}>
                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="school-name">
                                  School name
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['School Name']}
                                  </Box>
                                ) : (
                                  <Input
                                    id="school-name"
                                    placeholder="School name"
                                    value={form.schoolName}
                                    onChange={(e) =>
                                      setForm({ ...form, schoolName: e.target.value })
                                    }
                                    bg={'white'}
                                  />
                                )}
                              </FormControl>

                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="senco">
                                  SENCO Contact
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['SENCO']}
                                  </Box>
                                ) : (
                                  <Input
                                    id="senco"
                                    placeholder="SENCO"
                                    value={form.SENCO}
                                    isRequired={true}
                                    bg={'white'}
                                    onChange={(e) => setForm({ ...form, SENCO: e.target.value })}
                                  />
                                )}
                              </FormControl>

                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="emailSenco">
                                  SENCO Email{' '}
                                  <Tooltip
                                    label={
                                      'Please reach out to us if you need to update your login email.'
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <Icon mt={0} />
                                  </Tooltip>
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['SENCO Email']}
                                  </Box>
                                ) : (
                                  // <Input
                                  //   id="emailSenco"
                                  //   placeholder="Email address"
                                  //   value={form.email}
                                  //   onChange={(e) => setForm({ ...form, email: e.target.value })}
                                  //   isRequired={true}
                                  //   bg={'white'}
                                  // />
                                  <b>{user?.fields['SENCO Email']}</b>
                                )}
                              </FormControl>

                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="location">
                                  Full address
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['Location']}
                                  </Box>
                                ) : (
                                  <Input
                                    id="location"
                                    placeholder="Location"
                                    value={form.location}
                                    onChange={(e) => setForm({ ...form, location: e.target.value })}
                                    isRequired={true}
                                    bg={'white'}
                                  />
                                )}
                              </FormControl>
                              {/* <FormControl mb="5">
                                <FormLabel fontWeight='bold' 
htmlFor="email" fontWeight={'normal'}>
                                  Sign In Email
                                </FormLabel>
                                {edit === 0 ? (
                                  <b>{form.email}</b>
                                ) : (
                                  <Input
                                    id="email"
                                    placeholder="Email"
                                    value={form.email}
                                    isRequired={true}
                                    bg={'white'}
                                    isDisabled={true}
                                  />
                                )}
                              </FormControl> */}
                            </Box>

                            <Box width={'100%'} p={2}>
                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="phone">
                                  Contact number
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['Phone']}
                                  </Box>
                                ) : (
                                  <Input
                                    id="phone"
                                    placeholder="Contact number"
                                    value={form.contactNumber}
                                    onChange={(e) =>
                                      setForm({ ...form, contactNumber: e.target.value })
                                    }
                                    isRequired={true}
                                    bg={'white'}
                                  />
                                )}
                              </FormControl>

                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="finance-contact">
                                  Finance contact
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['Finance Contact']}
                                  </Box>
                                ) : (
                                  <Input
                                    id="finance-contact"
                                    placeholder="Finance contact"
                                    value={form.financeContact}
                                    onChange={(e) =>
                                      setForm({ ...form, financeContact: e.target.value })
                                    }
                                    isRequired={true}
                                    bg={'white'}
                                  />
                                )}
                              </FormControl>

                              <FormControl mb="5%">
                                <FormLabel fontWeight="bold" htmlFor="finance-email">
                                  Finance email
                                </FormLabel>
                                {edit === 0 ? (
                                  <Box lineHeight={10} height={10}>
                                    {user?.fields['Finance Email']}
                                  </Box>
                                ) : (
                                  <Input
                                    id="address-line-2"
                                    placeholder="Finance Email"
                                    value={form.financeEmail}
                                    onChange={(e) =>
                                      setForm({ ...form, financeEmail: e.target.value })
                                    }
                                    isRequired={true}
                                    bg={'white'}
                                  />
                                )}
                              </FormControl>
                            </Box>
                          </HStack>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                    <Accordion allowToggle mt={'10'} borderRadius={10}>
                      <AccordionItem mb={5} borderRadius={10}>
                        <AccordionButton
                          _expanded={{ bg: 'brand.300', color: 'white' }}
                          _hover={{ bg: 'brand.300', color: 'white' }}
                          bg={'brand.200'}
                          color={'white'}
                          borderRadius={10}
                        >
                          <Box as="span" flex="1" textAlign="left" fontWeight={'bold'}>
                            Password
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                        <AccordionPanel
                          bg={'white'}
                          border={'1px'}
                          borderColor={'gray.100'}
                          borderRadius={10}
                        >
                          <HStack>
                            {user?.fields['Account status'] === false ? (
                              <>
                                <Box w={'50%'} bg="white" p={2}>
                                  <FormControl>
                                    <FormLabel fontWeight="bold" htmlFor="password">
                                      Password
                                    </FormLabel>
                                    <Input
                                      id="password"
                                      placeholder="Enter password"
                                      onChange={(e) =>
                                        setForm({ ...form, password: e.target.value })
                                      }
                                      value={form?.password}
                                      type="password"
                                      isRequired={true}
                                      bg={'white'}
                                    />
                                  </FormControl>
                                </Box>
                                <Box w={'50%'} bg="white" p={2}>
                                  <FormControl>
                                    <FormLabel fontWeight="bold" htmlFor="password">
                                      Confirm Password
                                    </FormLabel>
                                    <Input
                                      id="cpassword"
                                      placeholder="Enter password again"
                                      onChange={(e) =>
                                        setForm({ ...form, cpassword: e.target.value })
                                      }
                                      value={form?.cpassword}
                                      type="password"
                                      isRequired={true}
                                      bg={'white'}
                                    />
                                  </FormControl>
                                </Box>
                                <Box w={'100%'} textAlign={'right'} mt={2.5}>
                                  <FormControl>
                                    <Button
                                      bg={'brand.300'}
                                      color={'white'}
                                      _hover={{
                                        bg: 'brand.200',
                                      }}
                                      width={'200px'}
                                      onClick={() => {
                                        sighUpAndRedirect();
                                      }}
                                      isDisabled={
                                        form.password === form.cpassword ||
                                        form.password === null ||
                                        form.cpassword === null
                                          ? false
                                          : true
                                      }
                                    >
                                      Sign up
                                    </Button>
                                  </FormControl>
                                </Box>
                              </>
                            ) : (
                              <Text>Account already setup</Text>
                            )}
                          </HStack>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>
                  </>
                )}
              </Box>
            </Grid>
          </Box>
        </Stack>

        <Outlet />
      </Stack>
    </Flex>
  );
}
