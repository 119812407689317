import { Link, Button } from '@chakra-ui/react';
import { FiArrowLeft } from 'react-icons/fi';

interface BackProps {
  navigate: Function;
}
export const BackButton = ({ navigate }: BackProps) => (
  <Link onClick={() => navigate(-1)}>
    <Button variant={'defaultButton'}>
      <FiArrowLeft />
      &nbsp; Back
    </Button>
  </Link>
);
