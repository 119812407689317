import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../auth/fireBaseSetup';

interface FpProps {
  isOpen: boolean;
  onClose: () => void;
}
export const ForgotPasswordModal = ({ isOpen, onClose }: FpProps) => {
  const [message, setMessage] = useState<string>('');
  const [emailEntered, setEmailEntered] = useState<number>(0);

  const emailRef = useRef<HTMLInputElement>(null);

  //   const user = useContext(AuthContext);
  const forgotPassword = async (email: string): Promise<void> => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully!');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await forgotPassword(emailRef.current!.value);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setMessage('Failed to send password reset email. Please try again.');
    }
  };

  // Function to handle changes in the input field
  const handleEmailChange = () => {
    // Check if emailRef and its current value are defined
    if (emailRef.current) {
      // Access the current value of the input
      const email = emailRef.current.value;

      console.log('Current email:', emailRef.current && emailRef.current.value.length);
      setEmailEntered(emailRef.current && emailRef.current.value.length);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Forgot Your Password?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Enter your email address below, and we'll send you instructions to reset your
              password.
            </Text>
            {message !== '' ? (
              <Text mt={5} p={10} backgroundColor={'green.200'} borderRadius={10}>
                {message}
              </Text>
            ) : (
              <FormControl id="email" mt={10}>
                <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                  Email address
                </FormLabel>
                <Input type="email" ref={emailRef} onChange={handleEmailChange} />
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              background={'brand.200'}
              color={'white'}
              _hover={{ backgroundColor: 'brand.300' }}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            {message !== '' ? (
              ''
            ) : (
              <Button
                background={'brand.300'}
                color={'white'}
                _hover={{ backgroundColor: 'brand.200' }}
                onClick={handleForgotPassword}
                isDisabled={emailEntered >= 10 ? false : true}
              >
                Send Reset Link
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
