import React, { ReactNode, useRef, useEffect, useState, useCallback, useReducer } from 'react';

import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
  Avatar,
  HStack,
  Image,
  StackDivider,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function UserTherapistProfilePic({ profilePic }: any) {
  return (
    <Box
      bg={'white'}
      width={'144px'}
      height={'144px'}
      borderRadius={'144px'}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
      boxShadow={'3px 3px 6px grey'}
    >
      {profilePic && (
        <Box
          backgroundImage={String(profilePic!)}
          backgroundSize={'126px'}
          width={'126px'}
          height={'126px'}
          borderRadius={'126px'}
        />
      )}
    </Box>
  );
}
