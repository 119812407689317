import {
  Box,
  Drawer,
  DrawerContent,
  Heading,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useLocation, matchPath, useParams } from 'react-router-dom';
import AdminContent from './../components/AdminContent';
import AddClientSession from './AddClientSession';
import BookingsContentSchools from './BookingsContentSchools';
import BookingsContentEditStudent from './BookingsContentEditStudent';
import BookingsContentEditClient from './BookingsContentEditClient';
import BookingsContentSinglePrivateClient from './BookingsContentSinglePrivateClient';
import BookingsContentTomsRating from './BookingsContentSingleTomsRating';
import BookingsContentSingleTomsRatingClient from './BookingsContentSingleTomsRatingClient';
import BookingsContentSingleTherapist from './../components/BookingsContentSingleTherapist';
import BookingsContentSingleTherapistClient from './../components/BookingsContentSingleTherapistClient';
import BookingsContentSingleSchool from './../components/BookingsContentSingleSchool';
import BookingsContentClients from './../components/BookingsContentClients';
import BookingsContentVideoSingle from './BookingsContentVideoSingle';
import MessagesContent from './MessagesContent';
import MessagesContentUsers from './MessagesContentUsers';
import InvoicesContent from './../components/InvoicesContent';
import PayInvoice from './../components/PayInvoice';
import Help from './../components/Help';
import InvoicesContentSchools from './../components/InvoicesContentSchools';
import DashboardContent from './../components/DashboardContent';
import DashboardTherapistContent from './../components/DashboardTherapistContent';
import DashboardClientContent from './../components/DashboardClientContent';
import DashboardTherapistLog from './../components/DashboardTherapistLog';
import DashboardSubscriberContent from './../components/DashboardSubscriberContent';
import DashboardAdminContent from './../components/DashboardAdminContent';
import ReportingContent from './../components/ReportingContent';
import BookingsContentStudents from './BookingsContentStudents';
import ResourcesContent from './../components/ResourcesContent';
import ResourcesContentTherapist from './../components/ResourcesContentTherapist';
import ResourcesContentTherapistSingle from './../components/ResourcesContentTherapistSingle';
import ResourcesNewContent from './../components/ResourcesNewContent';
import DashboardDoctifyReviews from './../components/DashboardDoctifyReviews';
import ProfileContent from './../components/ProfileContent';
import ProfileContentAcademy from './../components/ProfileContentAcademy';
import ProfileTxSchools from './../components/ProfileTxSchools';
import ProfileContentSubscriber from './../components/ProfileContentSubscriber';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { UserDetailsProvider } from '../context/UserDetailsProvider';
import { UserMeta } from './../interfaces/UserMeta';
import { SignedInUserPropsTherapist } from './../interfaces/SignedInUserPropsTherapist';
// import { userTypeContext } from '../context/UserTypeProvider';
import { MyContext } from '..';
import BookingsContentTherapist from './BookingsContentSchools';
import BookingsContentPrivateClient from './BookingsContentPrivateClient';
import DashboardSchoolContentMainstream from './DashboardSchoolContentMainstream';
import DashboardSchoolContentSENCO from './DashboardSchoolContentSENCO';
import ClientInvoices from './ClientInvoices';
import ReportingContentNotAllowed from './ReportingContentNotAllowed';
export default function SidebarWithHeader({
  signedInUserId,
  signedInUserEmail,
  setSignedInUserId,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserPropsTherapist) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  // const { userType, updateUserType } = useContext(userTypeContext);
  // console.log('userType: ', userType);
  // const idx = signedInUserEmail?.indexOf('@unlockinglanguage.co.uk');

  const { sessionId } = useParams();
  const userAccount = useContext(MyContext);

  return (
    <Box minH="100vh" bg={useColorModeValue('gray.200', 'gray.700')}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
        navWidth={navWidth}
        setNavWidth={setNavWidth}
        style={{ zIndex: 99 }}
        userMeta={userMeta}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            onClose={onClose}
            navWidth={navWidth}
            setNavWidth={setNavWidth}
            userMeta={userMeta}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      {/* <MobileNav
        onOpen={onOpen}
        signedInUserId={signedInUserId}
        signedInUserEmail={signedInUserEmail}
        navWidth={navWidth}
        setNavWidth={setNavWidth}
      /> */}
      {/* Display content here */}
      {location.pathname === '/admin-dashboard' && (
        <DashboardAdminContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/dashboard' && (
        <DashboardContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/dashboard-subscriber' && (
        <DashboardSubscriberContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/dashboard-mainstream-school' && (
        <DashboardSchoolContentMainstream
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/dashboard-sen-school' && (
        <DashboardSchoolContentSENCO
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/dashboard-therapist' && (
        <DashboardTherapistContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/dashboard-client' && (
        <DashboardClientContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/logging-in-tx' && (
        <DashboardTherapistLog
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/profile' && (
        <ProfileContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/ul-academy-profile' && (
        <ProfileContentAcademy
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/therapist-profile' && (
        <ProfileTxSchools
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}

      {location.pathname === '/book-discovery-call' && (
        <ProfileContentSubscriber
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {/* {location.pathname === '/admin' && (
        <AdminContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
          approveOrReject={approveOrReject}
          setApproveOrReject={setApproveOrReject}
        />
      )} */}
      {/* {location.pathname === '/resources' && (
        <ResourcesContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )} */}
      {/* {location.pathname === '/therapist-resources' && (
        <ResourcesContentTherapist
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/therapist-resources/' + sessionId && (
        <ResourcesContentTherapist
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )} */}

      {location.pathname === '/resources' && (
        <ResourcesContentTherapist
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/resources/' + sessionId && (
        <ResourcesContentTherapist
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}

      {location.pathname === '/dashboard-reviews' && (
        <DashboardDoctifyReviews
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/students' && (
        <BookingsContentStudents
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          setSignedInUserId={setSignedInUserId}
          setSignedInUserEmail={setSignedInUserEmail}
          userMeta={userMeta}
          setUserMeta={setUserMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/private-client-sessions' && (
        <BookingsContentPrivateClient
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/clients' && (
        <BookingsContentClients
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/schools' && (
        <BookingsContentSchools
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/edit-student/' + sessionId && (
        <BookingsContentEditStudent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/edit-client/' + sessionId && (
        <BookingsContentEditClient
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/toms-rating/' + sessionId && (
        <BookingsContentTomsRating
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/client-toms-rating/' + sessionId && (
        <BookingsContentSingleTomsRatingClient
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/resource/' && (
        <ResourcesContentTherapistSingle
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/video/' && (
        <BookingsContentVideoSingle
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/private-client-session/' + sessionId && (
        <BookingsContentSinglePrivateClient
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/therapist-session/' + sessionId && (
        <BookingsContentSingleTherapist
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/therapist-client-session/' + sessionId && (
        <BookingsContentSingleTherapistClient
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/student-session/' + sessionId && (
        <BookingsContentSingleSchool
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          sessionId={Number(sessionId)}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/message-center' && (
        <MessagesContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/messages' && (
        <MessagesContentUsers
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}

      {location.pathname === '/invoices' && (
        <InvoicesContent
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/client-invoices' && (
        <ClientInvoices
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}

      {location.pathname === '/pay-invoice' && (
        <PayInvoice
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/help' && (
        <Help
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/school-invoices' && (
        <InvoicesContentSchools
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
      {location.pathname === '/reportingapp' &&
        (localStorage.getItem('accountType') === 'Admin' ||
        localStorage.getItem('accountType') === 'Therapist Staff' ? (
          <ReportingContent
            signedInUserId={signedInUserId}
            signedInUserEmail={signedInUserEmail}
            userMeta={userMeta}
            navWidth={navWidth}
            setNavWidth={setNavWidth}
            signedInUserType={signedInUserType}
            setSignedInUserType={setSignedInUserType}
          />
        ) : (
          <ReportingContentNotAllowed
            signedInUserId={signedInUserId}
            signedInUserEmail={signedInUserEmail}
            userMeta={userMeta}
            navWidth={navWidth}
            setNavWidth={setNavWidth}
            signedInUserType={signedInUserType}
            setSignedInUserType={setSignedInUserType}
          />
        ))}
      {location.pathname === '/add-client-session' && (
        <AddClientSession
          signedInUserId={signedInUserId}
          signedInUserEmail={signedInUserEmail}
          userMeta={userMeta}
          navWidth={navWidth}
          setNavWidth={setNavWidth}
          signedInUserType={signedInUserType}
          setSignedInUserType={setSignedInUserType}
        />
      )}
    </Box>
  );
}
