import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Grid,
  Text,
  Button,
  Select,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import postDataToEndPoint from '../utils/postDataToEndPoint';

const ProfileClientInvoiceInformation = ({ profileDataType }: any) => {
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);
  const userAccountNumber = localStorage.getItem('accountNumber');

  const defaultForm = {
    therapyFrequency: '',
    therapyLength: '',
    therapyRate: '',
    invoiceFrequency: '',
  };
  const [form, setForm] = useState<any>(defaultForm);

  function getUserData() {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUser(data);
      setForm({
        ...form,
        therapyFrequency: data?.fields['Therapy Frequency'],
        therapyLength: data?.fields['Therapy Length'],
        therapyRate: data?.fields['Therapy Rate'],
        invoiceFrequency: data?.fields['Invoice Frequency'],
        webInfoReqChangesPending: ['Website info change request pending'],
        clientInfoReqChangesPending: ['Client info change request pending'],
        invoiceInfoReqChangesPending: ['Invoice info change request pending'],
      });
    });
  }
  const requestProfileChangeViaMakeEP = process.env.REACT_APP_REQUEST_PROFILE_CHANGES_VIA_MAKE_EP;
  const requestBody = {
    data: {
      type: [profileDataType],
      id: [Number(userAccountNumber)],
      therapyFrequency: [form.therapyFrequency],
      therapyLength: [form.therapyLength],
      therapyRate: [form.therapyRate],
      invoiceFrequency: [form.invoiceFrequency],
    },
  };
  // function sendDataToMake() {
  //   postDataToEndPoint(requestProfileChangeViaMakeEP, requestBody).then(() => setEdit(0));
  // }

  const updateInvoiceInfoReqChangesPending = {
    'Invoice info change request pending': true,
  };

  function sendDataToMake() {
    postDataToEndPoint(requestProfileChangeViaMakeEP, requestBody).then(() => setEdit(0));
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      { fields: updateInvoiceInfoReqChangesPending },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      getUserData();
      setEdit(0);
    });
  }

  const clientToUpdate = {
    'Therapy Frequency': form.therapyFrequency,
    'Therapy Length': form.therapyLength,
    'Therapy Rate': form.therapyRate,
    'Invoice Frequency': form.invoicerequency,
  };

  const therapyLengthChoices = ['30 mins', '45 mins', '60 mins'];
  const therapyFrequencyChoices = ['Weekly', 'Fortnightly', 'Monthly', 'One off'];
  const invoiceFrequencyChoices = [
    'Pay as you go',
    'Monthly',
    'Blocks of 8',
    'Pay as you go - GoCardless',
    'Blocks of 8 - GoCardless',
  ];

  function updateClientInfo() {
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      { fields: clientToUpdate },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      console.log('saved:' + data);
      getUserData();
      setEdit(0);
    });
  }

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Box w="100%">
      <Heading as={'h3'} size={'md'} my={5}>
        Invoice Information
      </Heading>
      {/* <Box>{JSON.stringify(form)}</Box> */}
      <Box display={'flex'} flexDirection={'row'}>
        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="first-name" fontWeight={'bold'}>
              Therapy Frequency
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Therapy Frequency']
            ) : (
              // <Input
              //   id="therapy-frequency"
              //   placeholder="Therapy Frequency"
              //   value={form.therapyFrequency}
              //   onChange={(e) => setForm({ ...form, therapyFrequency: e.target.value })}
              // />

              <Select
                id="therapy-frequency"
                onChange={(e) => setForm({ ...form, therapyFrequency: e.target.value })}
                value={form.therapyFrequency}
                bg={'white'}
              >
                {therapyFrequencyChoices?.map((i: any, k: number) => (
                  <option value={i} key={k}>
                    {i}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="therapy-length" fontWeight={'bold'}>
              Therapy Length
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Therapy Length']
            ) : (
              // <Input
              //   id="therapy-length"
              //   placeholder="Therapy Length"
              //   value={form.therapyLength}
              //   onChange={(e) => setForm({ ...form, therapyLength: e.target.value })}
              // />
              <Select
                id="therapy-length"
                onChange={(e) => setForm({ ...form, therapyLength: e.target.value })}
                value={form.therapyLength}
                bg={'white'}
              >
                {therapyLengthChoices?.map((i: any, k: number) => (
                  <option value={i} key={k}>
                    {i}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>

        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="therapy-rate" fontWeight={'bold'}>
              Therapy Rate
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Therapy Rate']
            ) : (
              <Input
                id="therapy-rate"
                placeholder="Therapy Rate"
                value={form.therapyRate}
                onChange={(e) => setForm({ ...form, therapyRate: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="invoice-frequency" fontWeight={'bold'}>
              Invoice Frequency
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Invoice Frequency']
            ) : (
              // <Input
              //   id="invoice-frequency"
              //   placeholder="Invoice Frequency"
              //   value={form.invoiceFrequency}
              //   onChange={(e) => setForm({ ...form, invoiceFrequency: e.target.value })}
              // />

              <Select
                id="invoice-frequency"
                onChange={(e) => setForm({ ...form, invoiceFrequency: e.target.value })}
                value={form.invoiceFrequency}
                bg={'white'}
              >
                {invoiceFrequencyChoices?.map((i: any, k: number) => (
                  <option value={i} key={k}>
                    {i}
                  </option>
                ))}
              </Select>
            )}
          </FormControl>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'right'} justifyContent={'right'}>
        <FormControl>
          <FormLabel htmlFor="" fontWeight={'bold'}></FormLabel>
          {edit === 0 ? (
            user?.fields['Invoice info change request pending'] !== true ? (
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
              >
                Request changes
              </Button>
            ) : (
              <Button isDisabled>Request changes awaiting approval</Button>
            )
          ) : (
            <HStack>
              <Button variant={'defaultButton'} onClick={() => sendDataToMake()}>
                Save
              </Button>
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
              >
                Cancel
              </Button>
            </HStack>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};
export default ProfileClientInvoiceInformation;
