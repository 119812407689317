import { useEffect, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import firebase from 'firebase/compat/app';
import { auth } from './../auth/fireBaseSetup';
type ContextProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, []);
  // value={{ name: [name, setName], color: [color, setColor] }}

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
