import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
  Avatar,
  HStack,
  Image,
  StackDivider,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Link,
  Spinner,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import ReactApexChart from 'react-apexcharts';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';
import React, { ReactNode, useContext, useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '..';
import { LanguageContext } from '../App';
import { auth } from '../auth/fireBaseSetup';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getAllRowsById from '../utils/getAllRowsById';
import getAllRowsByIdPaginate from '../utils/getAllRowsByIdPaginate';
import getUserNinoxDataByEmail from '../utils/getUserNinoxDataByEmail';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import { signOut } from '../utils/signOut';
import BookingsContentStudentsDashboard from './BookingsContentStudentsDashboard';
import { DashboardVideoList } from './DashboardVideoList';
import { DashboardVideoListSmall } from './DashboardVideoListSmall';
import DashboardTherapistDays from './DashboardTherapistDays';
import { BsBookmark, BsBookmarkFill, BsFillBookmarkFill } from 'react-icons/bs';
import { RiBookMarkFill } from 'react-icons/ri';
import { FaBell } from 'react-icons/fa';
import { FaRegBell } from 'react-icons/fa6';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import moment from 'moment';
import BookingsContentPreviewClients from './BookingsContentPreviewClients';
import getMultipleApiData from '../utils/getMultipleApiData';
import UserProfilePic from './UserProfilePic';
import UserTherapistProfilePic from './UserTherapistProfilePic';
import BookingsContentUpcomingClientDates from './BookingsContentUpcomingClientDates';
import MessagesContentUsersDashboard from './MessagesContentUsersDashboard';
import getRadialChartData from '../utils/getRadialChartData';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import ClientInvoicesDashboard from './ClientInvoicesDashboard';
import PopularResources from './PopularResources';

const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const adsEP = process.env.REACT_APP_ADS_LIST;
const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const studentsFromSchool = caseNotesMainstream;
const saveResourceEP = process.env.REACT_APP_UL_SAVED_RESOURCES;
const txDatesEP = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS_DATES;
const msSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const ssSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const EP = process.env.REACT_APP_USER_DATA;
const DashboardClientContent = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserPropsTherapist) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dbLoad, setDbLoad] = useState(0);
  const [accessLevel, setAccessLevel] = useState('none');
  const [adsList, setAdsList] = useState<any>([]);
  const [txClients, setTxClients] = useState<any>([]);
  const [txSchoolsMs, setTxSchoolsMs] = useState<any>([]);
  const [txSchoolsSs, setTxSchoolsSs] = useState<any>([]);
  const [msSchools, setMsSchools] = useState<any>([]);
  const [ssSchools, setSsSchools] = useState<any>([]);
  const [txAllDates, setTxAllDates] = useState<any>([]);
  const [myPop, setMyPop] = useState<any>(0);

  const [txSchoolUpcomingDatesSs, setTxSchoolUpcomingDatesSs] = useState<any>([]);
  const [txSchoolUpcomingDatesMs, setTxSchoolUpcomingDatesMs] = useState<any>([]);
  const [combinedUpcomingDates, setCombinedUpcomingDates] = useState<any>([]);
  const [txRadialDataMsSchools, setTxRadialDataMsSchools] = useState<any>([]);
  const [txRadialDataSsSchools, setTxRadialDataSsSchools] = useState<any>([]);
  const [txRadialDataMsFinal, setTxRadialDataMsFinal] = useState<any>([]);
  const [txRadialDataSsFinal, setTxRadialDataSsFinal] = useState<any>([]);
  const [clientSessionsCount, setClientSessionsCounts] = useState<any>([]);
  const [clientNextSession, setClientNextSession] = useState<any>([]);
  const [clientSessions, setClientSessions] = useState<any>([]);
  const [loadingClientSessions, setLoadingClientSessions] = useState<any>(0);
  const [txSessionNotesMs, setTxSessionNotesMs] = useState<any>([]);
  const [txSessionNotesSs, setTxSessionNotesSs] = useState<any>([]);
  const [allSavedResources, setAllSavedResources] = useState<any>([]);

  const [invoices, setInvoices] = useState<any>([]);
  const [invoicesLineItems, setInvoiceLineItems] = useState<any>([]);
  const [invoicesLineItemsData, setInvoicesLineItemsData] = useState<any>([]);
  const loggedInUsersInvoices = localStorage.getItem('invoices')?.split(',').map(Number);

  const [txDates, setTxDates] = useState<any>([]);
  const [schoolsTherapists, setSchoolsTherapists] = useState<any>([]);
  const accNumber = localStorage.getItem('accountNumber');
  const [schoolFilter, setSchoolFilter] = useState(signedInUserId);
  const userAccount = useContext(MyContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');
  const [sorter, setSorter] = useBoolean();
  const navigate = useNavigate();
  const [therapists, setTherapists] = useState<any>();
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulTherapistsTable = process.env.REACT_APP_UL_ALL_THERAPIST_PROFILES;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allClients = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const allMsStudents = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const allSsStudents = process.env.REACT_APP_UL_STUDENTS_SEN;
  const mSSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
  const ssSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;

  const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
  const sessionNotesMs = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const sessionNotesSs = process.env.REACT_APP_UL_SESSION_NOTES_SEN;

  const upcomingDatesMsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM_BOOKING_DATES;
  const upcomingDatesSsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN_BOOKING_DATES;

  const upcomingDatesMsQueryEp = process.env.REACT_APP_UL_UPCOMING_DATES_MAINSTREAM_QUERY;
  const upcomingDatesSsQueryEp = process.env.REACT_APP_UL_UPCOMING_DATES_SEN_QUERY;

  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

  // Clients query EP:
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

  const clientsQueryMsEP = process.env.REACT_APP_MS_QUERY_EP;
  const clientsQuerySsEP = process.env.REACT_APP_SS_QUERY_EP;

  const ulClientsInvoicesTable = process.env.REACT_APP_UL_INVOICES_TABLE;
  const ulClientsInvoicesLineItemsTable = process.env.REACT_APP_UL_INVOICES_LINE_ITEM_TABLE;

  const txAccountNumber = Number(localStorage.getItem('accountNumber'));

  // Clients query:
  const therapist_id = txAccountNumber;
  const clientsQuery = `let therapist_id := ${therapist_id};
  let students := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.id = therapist_id]
  concat(students);
  `;
  const therapistName = Number(localStorage.getItem('firstName'));

  const totalSchoolsQuery = `let name := "${therapistName}";
let today_date := today();
let today_year := year(today_date);
let today_month := month(today_date);
let school_years_array := if today_month >= 9 then
		[today_year, number(today_year + 1)]
	else
		[number(today_year - 1), today_year]
	end;
let school_dates := (select 'School Dates')[('Mainstream School'.lower(text('Therapist 1')) = lower(name) or
				'Mainstream School'.lower(text('Therapist 3')) = lower(name) or
			'Mainstream School'.lower(text('Therapist 2')) = lower(name)) and
		(Start >= today_date and Start <= date(number(item(school_years_array, 1)), 8, 1))];
concat(school_dates)`;

  // Client query data EP:
  const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

  // const getAllStudentsEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;

  const endPointTherapist = `${process.env.REACT_APP_ALL_THERAPISTS_EP}?filters={"G":"${userAccount?.userEmail}"}`;
  const allTherapists = process.env.REACT_APP_ALL_THERAPISTS_EP;

  const user = useContext(AuthContext);

  const [txProfilePic, setTxProfilePic] = useState<any>('');
  const [loadingIcon, setLoadingIcon] = useState<any>(0);
  const [loadingUpcomingClientSessions, setLoadingUpcomingClientSessions] = useState<number>(0);
  const [loadingNextClientsNextSession, setLoadingNextClientsNextSession] = useState<number>(0);

  const [loadingUpcomingBookings, setLoadingUpcomingBookings] = useState<number>(0);

  // function getSavedResource() {
  //   getAllRowsByEncodedQuery(clientsQueryEP, savedResourcesQuery).then((data: any) => {
  //     const validJsonString = `[${data.replace(/'/g, '"')}]`;
  //     const array = JSON.parse(validJsonString);
  //     setAllSavedResources(array);
  //   });
  // }

  function downloadFile(at: any, fileURL: any) {
    // console.log('at: ', at);
    // console.log('fileURL: ', fileURL);
    const options = {
      headers: {
        Authorization: at,
      },
    };
    fetch(fileURL, options)
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        // console.log(file);
        setTxProfilePic(file);
        // setDownloadedFile(file);
        setLoadingIcon(1);
        // window.location.assign(file);
      });
  }
  const today = new Date();
  const currentDateTime = moment(today).format('DD/MM/YYYY HH:mm');
  const allAnalyticsEP = process.env.REACT_APP_ALL_ANALYTICS_EP;
  const initialized = useRef(false);
  const accountType = localStorage.getItem('accountType');

  const enterTxSession = async (sessionType: string) => {
    const sessionToAdd = {
      email: String(localStorage.getItem('accountEmail')),
      accountNumber: String(localStorage.getItem('accountNumber')),
      Time: currentDateTime,
      Type: sessionType,
    };
    if (!initialized.current) {
      initialized.current = true;
      try {
        ninoxTableAddRow(allAnalyticsEP, { fields: sessionToAdd }, bearerToken).then(
          (data: any) => {
            return data;
          }
        );
      } catch (error) {
        console.error(error);
      }
    }
  };
  const savedResourcesQuery = `let item := (select 'Saved / liked resources')[lower(text(userEmail)) = lower("${localStorage.getItem(
    'accountEmail'
  )!}")];
  let array := [""];
  for i in item do
    let userEmail := i.userEmail;
    let userId := i.userId;
    let resourceId := i.resourceId;
    let saveStatus := i.saveStatus;
    let fileExt := i.fileExt;
    let fileTitle := i.fileTitle;
    array := array(array, ["{'id':'" + i.Id + "','createdAt':'" + i.'Created on' + "','userEmail':'" +
    userEmail +
    "','fields':{'userId':'" +
    userId +
    "','resourceId':'" +
    resourceId +
    "','saveStatus':'" +
    saveStatus +
    "','fileExt':'" +
    fileExt +
    "','fileTitle':'" +
    fileTitle +
    "'}}"])
  end;
  join(slice(array, 1, length(array)), ",")`;

  function loginClinicalAndSetUser(firebaseUser: any, data: any) {
    setSignedInUserEmail(firebaseUser?.email!);
    if (userAccount.userAccountType === 'client') {
      if (firebaseUser?.email !== undefined) {
        // const endPointTherapist = `${process.env.REACT_APP_ALL_THERAPISTS_EP}?filters={"G":"${firebaseUser?.email}"}`;
        const endPointClientsCentral = `${process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS}?filters={"C8":"${firebaseUser?.email}"}`;
        getUserNinoxDataByEmail(endPointClientsCentral, bearerToken).then((data: any) => {
          setSignedInUserId(data?._id);
          localStorage.setItem('accountNumber', data?._id);
          // localStorage.setItem('accountNumber', '1358');

          ninoxTableGetRow(
            process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
            data?._id,
            process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
          ).then((data: any) => {
            // console.log(data);
            setUserMeta({
              firstName: data?.fields['First Name'],
              lastName: data?.fields['Last Name'],
              accessLevel: data?.fields['Access Level'],
              accountType: userAccount.userAccountType === 'client' && 'Client',
              signedInUserEmail: firebaseUser?.email,
            });
            localStorage.setItem('firstName', data?.fields['First Name']);
            localStorage.setItem('lastName', data?.fields['Last Name']);
            localStorage.setItem('accessLevel', data?.fields['Access level']);
            // localStorage.setItem('accessLevel', 'Bronze');
            localStorage.setItem('accountType', 'Private Client');
            localStorage.setItem('accountTypeSubList', data?.fields['Client type']);
            localStorage.setItem('messagesStart', data?.fields['Messages start']);
            localStorage.setItem('stripeCustomerId', data?.fields['Stripe Customer id']);

            localStorage.setItem('mobileNumber', data?.fields['Mobile 1']);
            localStorage.setItem('phoneNumber', data?.fields['Phone']);

            localStorage.setItem('messages', data?.fields['Messages']);
            localStorage.setItem('stats', data?.fields['Stats']);
            localStorage.setItem('invoices', data?.fields['Invoices']);

            localStorage.setItem('accountEmail', String(firebaseUser?.email));
            setMyPop(1);

            // getAllRowsById(adsEP).then((ad_data: any) => {
            //   const ads = ad_data;
            //   setAdsList(
            //     ads.filter(
            //       (i: any) =>
            //         String(i.fields['subscription_level']) === data?.fields['Access level'] &&
            //         String(i.fields['ad_location']) === 'Private Client'
            //     )
            //   );
            // });

            const queryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
            const adsQuery = `let item := (select Ads)[text(subscription_level) = "${String(
              localStorage.getItem('accessLevel')
            )}" and ad_location = "${String(localStorage.getItem('accountType'))}"];
            let array := [""];
            for i in item do
              let id := number(i.Id);
              let ad_title := i.ad_title;
              let ad_image_url := i.ad_image_url;
              let target_url := i.target_url;
              let ad_location := i.ad_location;
              let subscription_level := i.subscription_level;
              array := array(array, ["{'id': " + id + ",'fields':{'ad_title':'" + ad_title + "','ad_image_url':'" +
                  ad_image_url +
                  "','target_url':'" +
                  target_url +
                  "','ad_location':'" +
                  ad_location +
                  "','subscription_level':'" +
                  subscription_level +
                  "'}}"])
            end;
             join(slice(array, 1, length(array)), ",")`;
            getAllRowsByEncodedQuery(queryEP, adsQuery).then((data: any) => {
              const validJsonString = `[${data.replace(/'/g, '"')}]`;
              const currentUsersAds = JSON.parse(validJsonString);
              setAdsList(currentUsersAds);
            });
            // localStorage.setItem('data', JSON.stringify(data));
            // localStorage.setItem(
            //   'Headshot',
            //   process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
            //     data?.id +
            //     '/files/' +
            //     data?.fields['Current Therapist Assigned']
            // );
            // downloadFile(
            //   bearerToken,
            //   process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
            //     data?.id +
            //     '/files/' +
            //     data?.fields['Current Therapist Assigned']
            // );
            // getSavedResource();

            data &&
              ninoxTableGetRow(
                process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                data?.fields['Current Therapist Assigned'],
                process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
              ).then((txdata: any) => {
                // console.log('Setting image');
                localStorage.setItem(
                  'Headshot',
                  process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                    data?.fields['Current Therapist Assigned'] +
                    '/files/' +
                    txdata?.fields['Headshot']
                );
                localStorage.setItem(
                  'Therapist',
                  txdata?.fields['First Name'] + ' ' + txdata?.fields['Surname']
                );
                localStorage.setItem('TherapistBio', txdata?.fields['Bio']);
                localStorage.setItem('ShortBio', txdata?.fields['Short Bio']);
                downloadFile(
                  bearerToken,
                  process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                    data?.fields['Current Therapist Assigned'] +
                    '/files/' +
                    txdata?.fields['Headshot']
                );
              });
            // https://api.ninoxdb.de/v1/teams/:teamid/databases/:databaseid/tables/:tableid/records/:recordid/files/:filename

            const savedResourcesQueryOnLoad = `let item := (select 'Saved / liked resources')[lower(text(userEmail)) = lower("${String(
              firebaseUser?.email
            )}")];
            let array := [""];
            for i in item do
              let userEmail := i.userEmail;
              let userId := i.userId;
              let resourceId := i.resourceId;
              let saveStatus := i.saveStatus;
              let fileExt := i.fileExt;
              let fileTitle := i.fileTitle;
              array := array(array, ["{'id':'" + i.Id + "','createdAt':'" + i.'Created on' + "','userEmail':'" +
              userEmail +
              "','fields':{'userId':'" +
              userId +
              "','resourceId':'" +
              resourceId +
              "','saveStatus':'" +
              saveStatus +
              "','fileExt':'" +
              fileExt +
              "','fileTitle':'" +
              fileTitle +
              "'}}"])
            end;
            join(slice(array, 1, length(array)), ",")`;

            getAllRowsByEncodedQuery(clientsQueryEP, savedResourcesQueryOnLoad).then(
              (data: any) => {
                const validJsonString = `[${data.replace(/'/g, '"')}]`;
                const array = JSON.parse(validJsonString);
                setAllSavedResources(array);
              }
            );

            const baseQuerySessionToDate = `let client_id := ${localStorage.getItem(
              'accountNumber'
            )};
            let today_date := today();
            let sessions := (select 'Session Stats')['Central Client Database - ALL STAFF USAGE'.Id = client_id and
                'Session Start' <= today_date];
            count(sessions)`;
            getAllRowsByEncodedQuery(clientsQueryEP, baseQuerySessionToDate).then(
              (clientData: any) => {
                const myData = clientData;
                // console.log('MyData', myData);
                // console.log('client', data?._id);
                setClientSessionsCounts(myData);
                setLoadingUpcomingClientSessions(1);
              }
            );

            const baseQueryNextSession = `let client_id := ${localStorage.getItem('accountNumber')};
            let now := today();
            let sess := (select 'Session Stats')['Session Start' >= now and 'Central Client Database - ALL STAFF USAGE'.Id = client_id].'Session Start';
            first(sort(sess))`;

            getAllRowsByEncodedQuery(clientsQueryEP, baseQueryNextSession).then(
              (clientData: any) => {
                const myData = clientData;
                // console.log('NExt sessions', JSON.stringify(myData));
                // console.log('client', data?._id);
                setClientNextSession(myData);
                setLoadingNextClientsNextSession(1);
              }
            );

            const baseQueryListClientSessionsByDate = `let client_id := ${data?._id};
let today_date := today();
let sessions := (select 'Session Stats')['Central Client Database - ALL STAFF USAGE'.Id = client_id and
		'Session Start' <= today_date];
count(sessions)`;

            getAllRowsByEncodedQuery(clientsQueryEP, baseQueryListClientSessionsByDate).then(
              (clientData: any) => {
                const myData = clientData;
                const statsStrings: any =
                  localStorage.getItem('accountNumber') !== undefined &&
                  localStorage.getItem('stats');
                const statsList = statsStrings.split(',').map(Number);
                getMultipleApiData(EP!, bearerToken!, data?.fields['Stats']).then((values) => {
                  setClientSessions(values);
                  setLoadingClientSessions(1);
                  // console.log('Client sessions', JSON.stringify(values));
                  // console.log('client', data?._id);
                });
              }
            );
            const accessLevel = data?.fields['Access level'];

            getMultipleApiData(
              ulClientsInvoicesTable!,
              bearerToken!,
              data?.fields['Invoices']
            ).then(async (data: any) => {
              let values = data
                .filter((i: any) => i.fields['Invoice Line Item'] !== undefined)
                .map((z: any, k: any) => z.fields['Invoice Line Item']);
              // console.log(values.toString());
              setInvoicesLineItemsData(values.toString()?.split(',').map(Number));

              const thisData = values.toString()?.split(',').map(Number);
              setInvoiceLineItems(
                await getMultipleApiData(ulClientsInvoicesLineItemsTable!, bearerToken!, thisData)
              );
              setInvoices(data);
            });

            // getAllRowsByEncodedQuery(clientsQueryMsEP, sessionNotesCountMs).then((data: any) => {
            //   const myData = data;
            //   setTxSessionNotesMs(myData);
            // });
            // getAllRowsByEncodedQuery(clientsQuerySsEP, sessionNotesCountSs).then((data: any) => {
            //   const myData = data;
            //   setTxSessionNotesSs(myData);
            // });
            // getAllRowsByEncodedQuery(upcomingDatesMsQueryEp, baseQueryMs).then((data: any) => {
            //   // console.log('UpcomingDatesMs: ', data);
            //   // console.log('UpcomingDatesMs len: ', data.length);

            //   const datesArray = data.split(',');
            //   // console.log('Ms datesArray: ', datesArray);
            //   data.length > 0 &&
            //     getMultipleApiData(upcomingDatesMsEP!, bearerToken!, datesArray).then(
            //       (data: any) => {
            //         // console.log(
            //         //   'THE UPCOMING DATES FOR MS ',
            //         //   data.filter((i: any) =>
            //         //     i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //         //   ).length
            //         // );
            //         setTxSchoolUpcomingDatesMs(
            //           data.filter((i: any) =>
            //             i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //           )
            //         );
            //         setCombinedUpcomingDates([
            //           ...combinedUpcomingDates,
            //           ...data.filter((i: any) =>
            //             i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //           ),
            //         ]);
            //         // setTxAllDates((txad: any) => [...txad, ...data]);
            //       }
            //     );
            // });
            // getAllRowsByEncodedQuery(upcomingDatesSsQueryEp, baseQuerySs).then((data: any) => {
            //   const datesArray = data.split(',');
            //   // console.log('Ss datesArray: ', datesArray);
            //   // console.log('Ss datesArray.length: ', datesArray.length);
            //   // console.log('UpcomingDatesSs len: ', data.length);
            //   data.length > 0 &&
            //     getMultipleApiData(upcomingDatesSsEP!, bearerToken!, datesArray).then(
            //       (data: any) => {
            //         // console.log(
            //         //   'THE UPCOMING DATES FOR SS ',
            //         //   data.filter((i: any) =>
            //         //     i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //         //   ).length
            //         // );

            //         setTxSchoolUpcomingDatesSs(
            //           data.filter((i: any) =>
            //             i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //           )
            //         );
            //         setCombinedUpcomingDates([
            //           ...combinedUpcomingDates,
            //           ...data.filter((i: any) =>
            //             i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //           ),
            //         ]);
            //         setLoadingUpcomingBookings(1);
            //         // console.log(
            //         //   'COMBINED UPCOMING DATES',
            //         //   combinedUpcomingDates.length +
            //         //     data.filter((i: any) =>
            //         //       i?.fields['Event Created By']?.includes(String(firebaseUser?.email))
            //         //     ).length
            //         // );
            //         // setTxAllDates((txad: any) => [...txad, ...data]);
            //       }
            //     );
            // });
            // getAllRowsById(mSSchoolsEP).then((data: any) => {
            //   const myData = data;
            //   setTxSchoolsMs(
            //     myData.filter(
            //       (i: any) =>
            //         i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Additional Therapists']?.includes(
            //           localStorage.getItem('accountEmail')
            //         )
            //     ).length
            //   );
            //   setMsSchools(myData);
            // });
            // getAllRowsById(senSchoolsEP).then((data: any) => {
            //   const myData = data;
            //   setTxSchoolsSs(
            //     myData.filter(
            //       (i: any) =>
            //         i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Additional Therapists']?.includes(
            //           localStorage.getItem('accountEmail')
            //         )
            //     ).length
            //   );
            //   setSsSchools(myData);
            // });
            // getAllRowsById(txDatesEP).then((data: any) => {
            //   const myData = data;
            //   setTxDates(myData);
            // });
            // getAllRowsById(saveResourceEP).then((data: any) => {
            //   setAllSavedResources(
            //     data?.filter(
            //       (i: any) => i?.fields['userEmail'] === localStorage.getItem('accountEmail')
            //     )
            //   );
            // });
            // getSavedResource();

            // getAllRowsById(msSchoolsEP).then((data) => {
            //   const myData = data;
            //   setTxRadialDataMsSchools(
            //     myData.filter(
            //       (i: any) =>
            //         i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Additional Therapists']?.includes(
            //           localStorage.getItem('accountEmail')
            //         )
            //     )
            //   );
            //   const schoolsDaysMs = myData
            //     .filter(
            //       (i: any) =>
            //         i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Additional Therapists']?.includes(
            //           localStorage.getItem('accountEmail')
            //         )
            //     )
            //     .map((i: any) => {
            //       return {
            //         school: i.id,
            //         annualFullDays:
            //           i?.fields['Annual (full) days'] === undefined
            //             ? 0
            //             : i?.fields['Annual (full) days'],
            //         schoolName:
            //           i?.fields['School Name'] === undefined
            //             ? 'No school name'
            //             : i?.fields['School Name'],
            //       };
            //     });
            //   const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;

            //   getRadialChartData(schoolsDaysMs, msQueryEP, 'Ms').then((data: any) => {
            //     setTxRadialDataMsFinal(data);
            //     setLoadingUpcomingDates(1);
            //   });
            // });
            // getAllRowsById(ssSchoolsEP).then((data) => {
            //   const myData = data;
            //   setTxRadialDataSsSchools(
            //     myData.filter(
            //       (i: any) =>
            //         i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Additional Therapists']?.includes(
            //           localStorage.getItem('accountEmail')
            //         )
            //     )
            //   );
            //   const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

            //   const schoolsDaysSs = myData
            //     .filter(
            //       (i: any) =>
            //         i?.fields['Therapist 1']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 2']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Therapist 3']?.includes(localStorage.getItem('firstName')) ||
            //         i?.fields['Additional Therapists']?.includes(
            //           localStorage.getItem('accountEmail')
            //         )
            //     )
            //     .map((i: any) => {
            //       return {
            //         school: i.id,
            //         annualFullDays:
            //           i?.fields['Annual (full) days'] === undefined
            //             ? 0
            //             : i?.fields['Annual (full) days'],
            //         schoolName:
            //           i?.fields['School Name'] === undefined
            //             ? 'No school name'
            //             : i?.fields['School Name'],
            //       };
            //     });
            //   getRadialChartData(schoolsDaysSs, ssQueryEP, 'Ss').then((data: any) => {
            //     setTxRadialDataSsFinal(data);
            //     // console.log('SS DATA: ', data);
            //   });
            // });
            // getAllRowsById(allClients).then((data: any) => {
            //   const myData = data;
            //   setTxClients(
            //     myData.filter(
            //       (i: any) =>
            //         i?.fields['Current Therapist Assigned'] ===
            //         Number(localStorage.getItem('accountNumber'))
            //     )
            //   );
            // });

            // getAllRowsByEncodedQuery(clientsQueryEP, clientsQuery).then((data: any) => {
            //   const allStudents = data.split(',');
            //   setTxClients(allStudents);
            // });
            // enterTxSession(accountType!);
          });
        });
      }
    }
  }
  function checkOutAndLogout() {
    signOut();
    navigate('/login-error');
  }
  // `https://drive.google.com/uc?export=view&id=${adsList[3]?.fields?.ad_image_url}`;
  const AdCarousel = () => {
    const getConfigurableProps = () => ({
      showArrows: false,
      showStatus: false,
      showIndicators: true,
      infiniteLoop: true,
      showThumbs: false,
      useKeyboardArrows: true,
      autoPlay: true,
      stopOnHover: true,
      swipeable: true,
      dynamicHeight: false,
      emulateTouch: true,
      autoFocus: false,
      // thumbWidth: 100,
      selectedItem: 1,
      interval: 3000,
      transitionTime: 500,
      swipeScrollTolerance: 5,
      ariaLabel: 'Unlocking Language Advert',
    });

    return (
      <Carousel {...getConfigurableProps()}>
        {adsList.length <= 0
          ? 'No ads to show'
          : adsList.map((i: any, k: any) => (
              <Link target="_blank" href={i.fields.target_url}>
                <Box key={k} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Box
                    backgroundImage={`https://drive.google.com/thumbnail?id=${i.fields?.ad_image_url}&sz=w400`}
                    backgroundSize={'contain'}
                    backgroundPosition={'center'}
                    backgroundRepeat={'no-repeat'}
                    width={'400px'}
                    height={'600px'}
                  />
                </Box>
              </Link>
            ))}
      </Carousel>
    );
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      const endPointClientsCentral = `${process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS}?filters={"C8":"${firebaseUser?.email}"}`;

      getUserNinoxDataByEmail(endPointClientsCentral, bearerToken)
        .then((data: any) =>
          data === undefined ? checkOutAndLogout() : loginClinicalAndSetUser(firebaseUser, data)
        )
        .catch((error) => console.log(error));
    });
    return unsubscribe;
  }, []);

  useEffect(() => {}, [combinedUpcomingDates]);

  // useEffect(() => {
  // }, []);

  // const allDates = [
  //   Boolean(txSDS) !== false && txSchoolDatesSs[0]?.fields['Term dates'],
  //   Boolean(txSDM) !== false && txSchoolDatesMs[0]?.fields['Term dates'],
  // ];
  // useEffect(() => {}, [txSchoolUpcomingDatesMs, txSchoolUpcomingDatesSs]);

  return (
    <Box height={'100vh'} overflowY={'scroll'}>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        px={10}
        maxW="750xl"
        columns={{ base: 2, md: 2 }}
        mb={'-10'}
        mt={'-5'}
      >
        <Box
          display={'flex'}
          alignItems={'left'}
          flexDirection={'column'}
          width={'100%'}
          borderRadius={10}
        >
          <Image width="260px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
        </Box>
        <HStack justifyContent={'right'}>
          {loadingIcon === 0 ? (
            <Box
              width={'76px'}
              height={'76px'}
              alignContent={'center'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.200"
                size="xl"
              />
              loading...
            </Box>
          ) : (
            // txProfilePic && <UserProfilePic profilePic={txProfilePic!} />
            ''
          )}

          <Box textAlign={'left'} ml={3} mr={5}>
            Welcome back
            <Heading textAlign={'right'} as="h4" size="md" mt={'0'}>
              {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}{' '}
            </Heading>
          </Box>
          <HStack>
            {localStorage.getItem('notificationsCount') === undefined ||
            Number(localStorage.getItem('notificationsCount')) === 0 ? (
              <Link href={'messages'}>
                <HStack>
                  <FaRegBell size={30} />
                  <Text fontSize={20}>(0)</Text>
                </HStack>
              </Link>
            ) : (
              <Link href={'messages'}>
                <HStack>
                  <FaBell size={30} />
                  <Text fontSize={20}>({localStorage.getItem('notificationsCount')})</Text>
                </HStack>
              </Link>
            )}
          </HStack>
        </HStack>
      </SimpleGrid>
      <Flex ml={{ base: 0 }} mt={-10}>
        <SimpleGrid
          ml={{ base: 0, md: 20 }}
          p="10"
          // maxW="50xl"
          columns={[1, 2, 5, 5]}
          spacing={{ base: 5, lg: 8 }}
          width={'100%'}
        >
          <Box
            background={'white'}
            borderRadius={10}
            p={5}
            alignContent={'center'}
            textAlign={'center'}
            height={280}
          >
            <Text fontWeight={'bold'} pb={5}>
              Access level
            </Text>

            <CircularProgress
              value={100}
              size="160px"
              // color="blue.700"
              color="#40a83d"
              // color="brand.100"
            >
              <CircularProgressLabel fontSize={26} fontWeight={'bold'}>
                {localStorage.getItem('accessLevel')}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
          <Box
            background={'white'}
            borderRadius={10}
            p={5}
            alignContent={'center'}
            textAlign={'center'}
            height={280}
          >
            <Text fontWeight={'bold'} pb={5}>
              Sessions to date
            </Text>
            <CircularProgress
              value={100}
              size="160px"
              // color="brand.200"
              // color="blue.500"
              color="#d6d944"
            >
              <CircularProgressLabel fontSize={50} fontWeight={'bold'}>
                {clientSessionsCount}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>{' '}
          <Box
            background={'white'}
            borderRadius={10}
            p={5}
            alignContent={'center'}
            textAlign={'center'}
            height={280}
          >
            <Text fontWeight={'bold'} mb={5}>
              Next session
            </Text>
            <CircularProgress
              value={100}
              size="160px"
              // color="brand.300"
              // color="blue.300"
              color="#0c7348"
            >
              <CircularProgressLabel
                fontSize={16}
                fontWeight={'bold'}
                mt={'2'}
                display={'flex'}
                flexDirection={'column'}
                textAlign={'center'}
                alignItems={'center'}
              >
                <Box width={'70%'}>
                  {clientNextSession === null
                    ? 'None'
                    : moment(clientNextSession).format('DD/MM/YYYY HH:mm')}
                </Box>
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
          <Box
            background={'white'}
            borderRadius={10}
            p={5}
            alignContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            display={'flex'}
            flexDirection={'column'}
            height={280}
          >
            <Text fontWeight={'bold'} pb={5} mt={5}>
              Your therapist
            </Text>
            {loadingIcon === 0 ? (
              <Box
                width={'150px'}
                height={'150px'}
                alignContent={'center'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Spinner
                  thickness="8px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand.200"
                  size="xl"
                />
                loading...
              </Box>
            ) : (
              txProfilePic && <UserTherapistProfilePic profilePic={txProfilePic!} />
            )}
          </Box>
          <Box>
            <Box
              background={'white'}
              borderRadius={10}
              px={5}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              pt={5}
              pb={5}
              height={280}
            >
              <Text fontWeight={'bold'} mt={5} pb={5} textAlign={'center'}>
                Therapist bio
              </Text>
              <Box
                width={'100%'}
                // height={'220px'}
                bg={'white'}
                overflow={'hidden'}
                textAlign={'center'}
                pb={5}
              >
                <Box
                  width={'100%'}
                  dangerouslySetInnerHTML={{ __html: localStorage.getItem('ShortBio')! }}
                  mt={0}
                  pr={5}
                />
              </Box>
              <Link href={'profile?activeTab=profile'}>
                <Button variant={'defaultButton'}> View full bio</Button>
              </Link>
            </Box>
          </Box>
        </SimpleGrid>
      </Flex>

      <PopularResources myPop={myPop} setMyPop={setMyPop} />

      <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
        <Box pl={5} width={'25%'} justifyContent={'right'} ml={-5}>
          <Box
            bg={'white'}
            minH={'300px'}
            height={'700'}
            pt={5}
            pb={10}
            pr={5}
            pl={5}
            borderRadius={10}
          >
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab>Sessions ({clientSessionsCount && clientSessionsCount})</Tab>
                <Tab>Saved resources ({allSavedResources.length})</Tab>
              </TabList>
              <TabPanels pt={5}>
                <TabPanel>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    height={540}
                    overflowY={'hidden'}
                  >
                    {/* <Heading as={'h3'} size={'md'} mb={10} mt={0}>
                      Sessions ({clientSessionsCount && clientSessionsCount})
                    </Heading> */}
                    {loadingUpcomingClientSessions === 0 ? (
                      <>
                        {' '}
                        <Spinner
                          thickness="8px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="brand.200"
                          size="xl"
                        />
                        loading...
                      </>
                    ) : (
                      <Box>
                        <BookingsContentUpcomingClientDates clientSessions={clientSessions} />
                      </Box>
                    )}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'640px'}>
                    {/* <Heading as={'h3'} size={'md'} mb={10} mt={0}>
                      Saved resources
                    </Heading> */}
                    <Box bg={'white'} pl={0} height={'440px'} width={'100%'}>
                      <Stack
                        divider={<StackDivider />}
                        spacing="4"
                        height={'420px'}
                        overflowY={'scroll'}
                        overflowX={'hidden'}
                        wrap={'wrap'}
                      >
                        {allSavedResources.map((i: any, k: any) => (
                          <Link
                            href={`/resource/?res_id=${i?.fields['resourceId']}&fileExt=${i?.fields['fileExt']}`}
                            key={k}
                            textDecoration={'none'}
                            cursor={'pointer'}
                          >
                            <Box cursor={'pointer'}>
                              <Heading size="xs" textTransform="uppercase">
                                <HStack>
                                  <BsBookmarkFill fill="black" />
                                  {/* <Text width={'100%'}>{i?.fields['fileTitle']?.slice(0, 20)}&nbsp;...</Text> */}
                                  <Text width={'100%'}>{i?.fields['fileTitle']}&nbsp;...</Text>
                                </HStack>
                              </Heading>
                              <Text pt="2" fontSize="sm" pl={6}>
                                Date saved: {moment(new Date(i.createdAt)).format('DD/MM/YYYY')}
                              </Text>
                            </Box>
                          </Link>
                        ))}
                        {/* {JSON.stringify(allSavedResources)} */}
                        {allSavedResources?.length === 0 && <Text>No saved resources</Text>}
                      </Stack>
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        <Box bg={'white'} borderRadius={10} p={5} width={'37.5%'} height={'700'} ml={5}>
          <Heading as={'h3'} size={'md'} mb={10} mt={0}>
            Latest videos
          </Heading>
          <Box width={'100%'} overflowY={'scroll'} height={'600px'}>
            <DashboardVideoList
              accountType={localStorage.getItem('accountType')!}
              accessLevel={localStorage.getItem('accessLevel')!}
            />
          </Box>
        </Box>

        <Box
          // pl={5}
          width={'37.5%'}
          // bg="gray.100"
          // mt={-20}
          // display={'flex'}
          // flexDirection={'column'}
          // alignItems={'center'}
          // justifyContent={'center'}
          // // height={'600px'}
          bg="white"
          borderRadius={10}
          p={5}
          ml={5}
        >
          <Heading as={'h3'} size={'md'} mb={10} mt={0}>
            More information
          </Heading>
          <AdCarousel />
        </Box>
      </Flex>
      <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
        <Box bg={'white'} borderRadius={10} p={5} width={'62.5%'} mr={5}>
          <ClientInvoicesDashboard
            invoices={invoices}
            setInvoices={setInvoices}
            invoicesLineItems={invoicesLineItems}
            setInvoiceLineItems={setInvoiceLineItems}
            invoicesLineItemsData={invoicesLineItemsData}
            setInvoicesLineItemsData={setInvoicesLineItemsData}
          />
          {/* <Heading as={'h3'} size={'md'} mb={10}>
            Invoices
          </Heading>
          <Box height={'430px'} overflowY={'scroll'}> */}

          {/* <Box w="100%" bg="white" color={'black'} p={5} borderRadius={10}>
              <BookingsContentStudentsDashboard
                signedInUserId={Number(localStorage.getItem('accountNumber'))}
                setSignedInUserId={setSignedInUserId}
                signedInUserEmail={signedInUserEmail}
                setSignedInUserEmail={setSignedInUserEmail}
                userMeta={userMeta}
                setUserMeta={setUserMeta}
                navWidth={navWidth}
                setNavWidth={setNavWidth}
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            </Box> */}
          {/* <BookingsContentPreviewClients
              signedInUserId={signedInUserId}
              signedInUserEmail={signedInUserEmail}
              userMeta={userMeta}
              navWidth={navWidth}
              setNavWidth={setNavWidth}
              signedInUserType={signedInUserType}
              setSignedInUserType={setSignedInUserType}
            /> */}
          {/* </Box> */}
        </Box>
        <Box width={'37.5%'} justifyContent={'right'}>
          <Box
            bg={'white'}
            minH={'515px'}
            pt={10}
            pb={10}
            pr={2.5}
            pl={2.5}
            overflow={'hidden'}
            borderRadius={10}
            width={'100%'}
          >
            <Heading as={'h3'} size={'md'} mb={10} mt={-5}>
              Messages
            </Heading>
            <Box bg={'white'}>
              <Stack
                divider={<StackDivider />}
                spacing="4"
                height={'360px'}
                overflowY={'scroll'}
                width={'100%'}
              >
                <MessagesContentUsersDashboard
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  userMeta={userMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
                {/* {loadingUpcomingClientSessions === 0 ? (
                  <Box
                    width={'100%'}
                    height={'100%'}
                    alignContent={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    pt={'33.3%'}
                  >
                    <Spinner
                      thickness="8px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="brand.200"
                      size="xl"
                    />
                    loading...
                  </Box>
                ) : (
                  <DashboardTherapistDays
                    txRadialDataMsFinal={txRadialDataMsFinal}
                    txRadialDataSsFinal={txRadialDataSsFinal}
                  />
                )} */}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardClientContent;
