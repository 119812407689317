import React, { useEffect, useState, useContext } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
  matchPath,
  Navigate,
  Outlet,
} from 'react-router-dom';
import { auth } from './../src/auth/fireBaseSetup';
import SidebarWithHeader from './../src/components/SidebarWithHeader';
// import { AuthContext } from './../src/context/AuthContext';
// import { NinoxContext } from './../src/context/NinoxContext';
import getUserNinoxDataByEmail from './../src/utils/getUserNinoxDataByEmail';
import './App.css';
import { UserDetailsProvider } from './context/UserDetailsProvider';

import Home from './pages/Home';
import Login from './pages/Login';
import TherapistLogin from './pages/TherapistLogin';
import AdminLogin from './pages/AdminLogin';
import { hotjar } from 'react-hotjar';

import NoPage from './pages/NoPage';
import ninoxTableGetRow from './../src/utils/ninoxTableGetRow';
import SubscriberLogin from './pages/SubscriberLogin';
import SchoolLogin from './pages/SchoolLogin';
import SubscriberThankYou from './pages/SubscriberThankYou';
import ClientInvoicePaymentReceived from './pages/ClientInvoicePaymentReceived';
import ClinicalSignUpThankYou from './pages/ClinicalSignUpThankYou';
import ClinicalSignUpThankYouExpress from './pages/ClinicalSignUpThankYouExpress';
import SchoolSignUpThankYou from './pages/SchoolSignUpThankYou';
import PrivateClinicalClientLogin from './pages/PrivateClinicalClientLogin';
import SubscriberSignup from './pages/SubscriberSignup';
import PrivateClinicalSignUp from './pages/PrivateClinicalSignUp';
import PrivateClinicalSignUpExpress from './pages/PrivateClinicalSignUpExpress';
import { userTypeContext } from './context/UserTypeProvider';
import { MyContext } from '.';
import { UserMeta } from './interfaces/UserMeta';
import SubscriberSignUp from './pages/SubscriberSignup';
import SchoolSignUp from './pages/SchoolSignUp';
import LoginError from './pages/LoginError';
import ClientOnboardingEntry from './pages/ClientOnboardingEntry';
import AddClientSession from './components/AddClientSession';
// set the defaults
export const LanguageContext = React.createContext({
  language: 'en',
  setLanguage: (value: any) => value,
});
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const ulDb = process.env.REACT_APP_UL_DB;
const ulTherapistsTable = process.env.REACT_APP_UL_ALL_THERAPIST_PROFILES;
const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
const allTherapists = process.env.REACT_APP_ALL_THERAPISTS_EP;
function App() {
  // const user = useContext(AuthContext);
  // const signedInUserIduserId = useContext(NinoxContext);
  const [signedInUserId, setSignedInUserId] = useState<number>(0);
  const [signedInUserEmail, setSignedInUserEmail] = useState<string | any>();
  const [signedInUserType, setSignedInUserType] = useState<string>('none');
  const [userMeta, setUserMeta] = useState<UserMeta | null>(null);
  const [userType, setUserType] = useState<string>('none');
  const [language, setLanguage] = useState<any>('en');
  const [navWidth, setNavWidth] = useState(20);
  const value = { language, setLanguage };
  const ProtectedRoute = ({ signedInUserEmail, redirectPath = '/landing' }: any) => {
    if (!signedInUserEmail) {
      return <Navigate to={redirectPath} replace />;
    }

    return <Outlet />;
  };

  // Check if Hotjar has been initialized before calling its methods
  if (hotjar.initialized()) {
    hotjar.identify('3278088', { userProperty: '6' });
  }

  return (
    <BrowserRouter basename="/">
      <LanguageContext.Provider value={value}>
        <Routes>
          <Route
            index
            path="/"
            element={
              <UserDetailsProvider>
                <Home />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/subscriber-sign-up"
            element={
              <UserDetailsProvider>
                <SubscriberSignUp />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/thank-you-for-subscribing"
            element={
              <UserDetailsProvider>
                <SubscriberThankYou />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/client-invoice-paid"
            element={
              <UserDetailsProvider>
                <ClientInvoicePaymentReceived />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/client-onboarding"
            element={
              <UserDetailsProvider>
                <ClientOnboardingEntry />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/private-client-clinical-sign-up"
            element={
              <UserDetailsProvider>
                <PrivateClinicalSignUp />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/private-client-clinical-express-onboarding"
            element={
              <UserDetailsProvider>
                <PrivateClinicalSignUpExpress />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/thank-you-for-completing-the-onboarding-process"
            element={
              <UserDetailsProvider>
                <ClinicalSignUpThankYou />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/thank-you-for-completing-the-express-onboarding-process"
            element={
              <UserDetailsProvider>
                <ClinicalSignUpThankYouExpress />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/school-sign-up"
            element={
              <UserDetailsProvider>
                <SchoolSignUp />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/your-school-account-has-been-setup"
            element={
              <UserDetailsProvider>
                <SchoolSignUpThankYou />
              </UserDetailsProvider>
            }
          />
          {/* <Route element={<ProtectedRoute signedInUserEmail={signedInUserEmail} />}> */}
          <Route
            path="/resources"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/resources/:sessionId"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/reportingapp"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'admin',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard-subscriber"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'subscriber',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard-mainstream-school"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'school',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard-sen-school"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'school',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'clinical',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard-reviews"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'subscriber',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/students"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'therapist',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />{' '}
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/private-client-sessions"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/schools"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/clients"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/private-client-session/:sessionId"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            // this path will match URLs like
            // - /teams/hotspur
            // - /teams/real
            path="/therapist-session/:sessionId"
            // the matching param will be available to the loader
            // loader={({ params }) => {
            //   console.log(params.sessionId); // "hotspur"
            // }}
            // and the action
            // action={({ params }) => {}}
            // element={<ULSession />}
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            // this path will match URLs like
            // - /teams/hotspur
            // - /teams/real
            path="/therapist-client-session/:sessionId"
            // the matching param will be available to the loader
            // loader={({ params }) => {
            //   console.log(params.sessionId); // "hotspur"
            // }}
            // and the action
            // action={({ params }) => {}}
            // element={<ULSession />}
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            // this path will match URLs like
            // - /teams/hotspur
            // - /teams/real
            path="/student-session/:sessionId"
            // the matching param will be available to the loader
            // loader={({ params }) => {
            //   console.log(params.sessionId); // "hotspur"
            // }}
            // and the action
            // action={({ params }) => {}}
            // element={<ULSession />}
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/edit-student/:sessionId"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/edit-client/:sessionId"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/toms-rating/:sessionId"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/client-toms-rating/:sessionId"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/resource/"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/video/"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/pay-invoice"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/help"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/invoices"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/client-invoices"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/school-invoices"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/message-center"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/messages"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/profile"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/ul-academy-profile"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/therapist-profile"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/book-discovery-call"
            element={
              <UserDetailsProvider>
                <SidebarWithHeader
                  signedInUserId={signedInUserId}
                  signedInUserEmail={signedInUserEmail}
                  setSignedInUserId={setSignedInUserId}
                  setSignedInUserEmail={setSignedInUserEmail}
                  userMeta={userMeta!}
                  setUserMeta={setUserMeta}
                  navWidth={navWidth}
                  setNavWidth={setNavWidth}
                  signedInUserType={signedInUserType}
                  setSignedInUserType={setSignedInUserType}
                />
              </UserDetailsProvider>
            }
          />
          <Route
            path="/admin"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'admin',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard-therapist"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'therapist',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/dashboard-client"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'client',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          <Route
            path="/add-client-session"
            element={
              <UserDetailsProvider>
                <MyContext.Provider
                  value={{
                    userEmail: signedInUserEmail,
                    userAccountNumber: signedInUserId,
                    userAccountType: 'client',
                  }}
                >
                  <SidebarWithHeader
                    signedInUserId={signedInUserId}
                    signedInUserEmail={signedInUserEmail}
                    setSignedInUserId={setSignedInUserId}
                    setSignedInUserEmail={setSignedInUserEmail}
                    userMeta={userMeta!}
                    setUserMeta={setUserMeta}
                    navWidth={navWidth}
                    setNavWidth={setNavWidth}
                    signedInUserType={signedInUserType}
                    setSignedInUserType={setSignedInUserType}
                  />
                </MyContext.Provider>
              </UserDetailsProvider>
            }
          />
          {/* <Route
          path="/login"
          element={
            <Login signedInUserType={signedInUserType} setSignedInUserType={setSignedInUserType} />
          }
        /> */}
          <Route
            path="/therapist-login"
            element={
              <TherapistLogin
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            }
          />
          <Route
            path="/admin-login"
            element={
              <AdminLogin
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            }
          />
          <Route
            path="/subscriber-login"
            element={
              <SubscriberLogin
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            }
          />
          <Route
            path="/school-login"
            element={
              <SchoolLogin
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            }
          />
          <Route
            path="/private-client-clinical-login"
            element={
              <PrivateClinicalClientLogin
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            }
          />
          <Route path="/login-error" element={<LoginError />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </LanguageContext.Provider>
    </BrowserRouter>
  );
}

export default App;
