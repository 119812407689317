export default function ninoxTableGetRow(endpoint: string, rowId: number, bearerToken: any) {
  const headers = {
    Authorization: bearerToken,
    'Content-Type': 'application/json',
  };

  const requestOptions = {
    method: 'GET',
    headers: headers,
  };
  let mydata = fetch(endpoint + rowId, requestOptions)
    .then((response) => response.json())
    .then((data) => data);
  return mydata;
}

// export default async function ninoxTableGetRow(endpoint: string, rowId: number, bearerToken: any) {
//   const headers = {
//     Authorization: bearerToken,
//     'Content-Type': 'application/json',
//   };

//   const requestOptions = {
//     method: 'GET',
//     headers: headers,
//   };
//   const response = await fetch(endpoint + rowId, requestOptions);
//   const data = await response.json();

//   return data;
// }
