export const studentFields = {
  fields: [
    { id: 'A', name: 'Student/Patient Name', type: 'string' },
    { id: 'B', name: 'DOB', type: 'date' },
    {
      id: 'C',
      name: 'Class year',
      type: 'choice',
      choices: [
        'Please select an option', // Note: You cannot leave this field blank in the "add student" modal window
        'N',
        'R',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
      ],
    },
    {
      id: 'E',
      name: 'Gender',
      type: 'choice',
      choices: ['Please select an gender', 'Male', 'Female'],
    },
    { id: 'F', name: 'Ethnicity', type: 'string' },
    { id: 'G', name: 'Date of Assessment', type: 'date' },
    { id: 'H', name: 'Report date', type: 'date' },
    { id: 'K', name: 'Report sent?', type: 'boolean' }, //Should have a blank option
    { id: 'M', name: 'Report Link', type: 'link' },
    { id: 'N', name: 'Date of review', type: 'date' },
    {
      id: 'Q',
      name: 'Mainstream School',
      type: 'ref',
      referenceToTable: 'A',
      reverseField: 'F5',
    },
    { id: 'S', name: 'Other Medical Diagnosis', type: 'string' },
    { id: 'T', name: 'Class name', type: 'string' },
    {
      id: 'Y',
      name: 'Other Communication/ Swallowing Disorder',
      type: 'string',
    },
    { id: 'Z', name: 'Name of Therapist', type: 'string' },
    { id: 'A1', name: 'EHCP', type: 'boolean' }, //Should have a blank option
    { id: 'B1', name: 'Plan', type: 'html' },
    { id: 'C1', name: 'Notes', type: 'html' },
    { id: 'D1', name: 'Referral Date', type: 'date' },
    { id: 'H1', name: 'Local Patient Identifier', type: 'string' },
    {
      id: 'N1',
      name: 'TOMs rating',
      type: 'rev',
      referenceFromTable: 'DB',
      referenceFromField: 'C',
    },
    {
      id: 'U1',
      name: 'End of Episode/ Discharge Code/ Description',
      type: 'string',
    },
    { id: 'V1', name: 'Date of Discharge', type: 'date' },
    {
      id: 'X1',
      name: 'Sessions + Notes',
      type: 'rev',
      referenceFromTable: 'EB',
      referenceFromField: 'F',
    },
    { id: 'Y1', name: 'Direct Hours', type: 'number' },
    { id: 'Z1', name: 'Indirect Hours', type: 'number' },
    { id: 'A2', name: 'Hours completed', type: 'number' },
    { id: 'C2', name: 'Hours remaining', type: 'number' },
    {
      id: 'M2',
      name: 'Primary Medical Diagnosis',
      type: 'choice',
      choices: [
        'Select Primary Medical Diagnosis',
        'Attention Deficit Hyperactivity Disorder',
        'Auditory Processing Disorder',
        'Autistic Spectrum Disorder',
        'Chromosome Deletion',
        'Dyscalculia',
        'Dyslexia',
        'Dyspraxia',
        'Epilepsy',
        'Foetal Alcohol Syndrome',
        'Fragile X',
        'Global Developmental Delay',
        'Other',
        'Traumatic Brain Injury',
        'Cerebral Palsy',
      ],
    },
    {
      id: 'N2',
      name: 'Additional Medical Diagnosis',
      type: 'choice',
      choices: [
        'Select Additional Medical Diagnosis',
        'Attention Deficit Hyperactivity Disorder',
        'Auditory Processing Disorder',
        'Autistic Spectrum Disorder',
        'Chromosome Deletion',
        'Dyscalculia',
        'Dyslexia',
        'Dyspraxia',
        'Epilepsy',
        'Foetal Alcohol Syndrome',
        'Fragile X',
        'Global Developmental Delay',
        'Other',
        'Traumatic Brain Injury',
        'Cerebral Palsy',
      ],
    },
    {
      id: 'O2',
      name: 'Primary Communication/ Swallowing Disorder',
      type: 'choice',
      choices: [
        'Select Primary Communication/ Swallowing Disorder',
        'Anomia',
        'Aphasia - expressive',
        'Aphasia - global',
        'Aphasia - receptive',
        'Articulation Disorder',
        'Consistent Phonological Disorder',
        'Delayed Phonological\xa0Development',
        'Developmental Language Disorder - expressive',
        'Developmental Language Disorder - receptive',
        'Developmental Language Disorder - receptive and expressive',
        'Developmental Stammer',
        'Developmental Verbal Dyspraxia',
        'Dysarthria',
        'Dysfluency (stammering)',
        'Functional Dysphonia',
        'Inconsistent Phonological Disorder',
        'Language Disorder associated with ASD/ADHD/GDD',
        'Muscle Tension Dysphonia',
        'Psychogenic\xa0Stammer',
        'Selective Mutism',
        'Social Communication Difficulties',
        'Social Communication Difficulties associated with ASD',
        'Verbal Dyspraxia',
        'Vocal Nodules',
        'Language Disorder',
      ],
    },
    {
      id: 'P2',
      name: 'Additional Communication/ Swallowing Disorder',
      type: 'choice',
      choices: [
        'Select Additional Communication/ Swallowing Disorder',
        'Anomia',
        'Aphasia - expressive',
        'Aphasia - global',
        'Aphasia - receptive',
        'Articulation Disorder',
        'Consistent Phonological Disorder',
        'Delayed Phonological\xa0Development',
        'Developmental Language Disorder - expressive',
        'Developmental Language Disorder - receptive',
        'Developmental Language Disorder - receptive and expressive',
        'Developmental Stammer',
        'Developmental Verbal Dyspraxia',
        'Dysarthria',
        'Dysfluency (stammering)',
        'Functional Dysphonia',
        'Inconsistent Phonological Disorder',
        'Language Disorder associated with ASD/ADHD/GDD',
        'Muscle Tension Dysphonia',
        'Psychogenic\xa0Stammer',
        'Selective Mutism',
        'Social Communication Difficulties',
        'Social Communication Difficulties associated with ASD',
        'Verbal Dyspraxia',
        'Vocal Nodules',
        'Language Disorder',
      ],
    },
    {
      id: 'S2',
      name: 'Primary TOMs Scale',
      type: 'choice',
      choices: [
        'Select primary scale',
        '0- Core Scale',
        '10- Cognition',
        '11- Complex and Multiple Difficulty',
        '2- Augmentative and Alternative Communication',
        '22- Dysarthria',
        '23- Dysfluency',
        '24- Dysphagia',
        '25- Dysphasia/Aphasia',
        '26- Dysphonia',
        '27- Dyspraxia',
        '29- Head Injury',
        '3- Autistic Spectrum Disorder',
        '30- Hearing Impairment',
        '33- Learning Disability: Communication',
        '36- Multifactorial Conditions',
        '38- Neurological Disorders',
        '40- Phonological Disorder',
        '5- Cerebral Palsy',
        '50- Paediatric Dysphagia',
        '51- Selective Mutism',
        '6- Developmental Language Disorder',
        '63- Attention Deficit Hyperactivity Disorder',
        '64- Auditory Impairment',
        '66- Social Communication Difficulty',
        '67- Pragmatic Communiction Disorder',
        '68- Articulation Disorder',
        '7- Challenging Behaviour nd Forensic Mental Health',
        '9- Cleft Lip and Palate',
      ],
    },
    {
      id: 'T2',
      name: 'Secondary TOMs Scale',
      type: 'choice',
      choices: [
        'Select secondary scale',
        '0- Core Scale',
        '10- Cognition',
        '11- Complex and Multiple Difficulty',
        '2- Augmentative and Alternative Communication',
        '22- Dysarthria',
        '23- Dysfluency',
        '24- Dysphagia',
        '25- Dysphasia/Aphasia',
        '26- Dysphonia',
        '27- Dyspraxia',
        '29- Head Injury',
        '3- Autistic Spectrum Disorder',
        '30- Hearing Impairment',
        '33- Learning Disability: Communication',
        '36- Multifactorial Conditions',
        '38- Neurological Disorders',
        '40- Phonological Disorder',
        '5- Cerebral Palsy',
        '50- Paediatric Dysphagia',
        '51- Selective Mutism',
        '6- Developmental Language Disorder',
        '63- Attention Deficit Hyperactivity Disorder',
        '64- Auditory Impairment',
        '66- Social Communication Difficulty',
        '67- Pragmatic Communiction Disorder',
        '68- Articulation Disorder',
        '7- Challenging Behaviour nd Forensic Mental Health',
        '9- Cleft Lip and Palate',
      ],
    },
    {
      id: 'X2',
      name: 'Language 1',
      type: 'choice',
      choices: [
        'Please select a language',
        'Abkhaz',
        'Adyghe',
        'Afrikaans',
        'Akan',
        'Albanian',
        'American Sign Language',
        'Amharic',
        'Ancient Greek',
        'Arabic',
        'Aragonese',
        'Aramaic',
        'Armenian',
        'Assamese',
        'Aymara',
        'Azerbaijani',
        'Balinese',
        'Basque',
        'Bengali',
        'Betawi',
        'Bosnian',
        'Breton',
        'Bulgarian',
        'Cantonese',
        'Catalan',
        'Cherokee',
        'Chickasaw',
        'Chinese',
        'Coptic',
        'Cornish',
        'Corsican',
        'Crimean Tatar',
        'Croatian',
        'Czech',
        'Danish',
        'Dawro',
        'Dutch',
        'English',
        'Esperanto',
        'Estonian',
        'Ewe',
        'Fiji Hindi',
        'Filipino',
        'Finnish',
        'French',
        'Galician',
        'Georgian',
        'German',
        'Greek, Modern',
        'Greenlandic',
        'Gujerati',
        'Haitian Creole',
        'Hawaiian',
        'Hebrew',
        'Hindi',
        'Hungarian',
        'Icelandic',
        'Indonesian',
        'Interlingua',
        'Inuktitut',
        'Irish',
        'Italian',
        'Japanese',
        'Javanese',
        'Kabardian',
        'Kalasha',
        'Kannada',
        'Kashubian',
        'Khmer',
        'Kinyarwanda',
        'Korean',
        'Krio',
        'Kurdish/Kurdî',
        'Ladin',
        'Latgalian',
        'Latin',
        'Lingala',
        'Livonian',
        'Lojban',
        'Low German',
        'Lower Sorbian',
        'Macedonian',
        'Malay',
        'Malayalam',
        'Mandarin',
        'Manx',
        'Maori',
        'Mauritian Creole',
        'Middle English',
        'Middle Low German',
        'Min Nan',
        'Mongolian',
        'Norwegian',
        'Oriya',
        'Pangasinan',
        'Papiamentu',
        'Pashto',
        'Persian',
        'Pitjantjatjara',
        'Polish',
        'Portuguese',
        'Proto-Slavic',
        'Punjabi',
        'Quenya',
        'Rajasthani',
        'Rapa Nui',
        'Romanian',
        'Russian',
        'Sanskrit',
        'Scots',
        'Scottish Gaelic',
        'Semai',
        'Serbian',
        'Serbo-Croatian',
        'Sinhalese',
        'Slovak',
        'Slovene',
        'Spanish',
        'Swahili',
        'Swedish',
        'Tagalog',
        'Tajik',
        'Tamil',
        'Tarantino',
        'Telugu',
        'Thai',
        'Tok Pisin',
        'Turkish',
        'Twi',
        'Ukrainian',
        'Upper Sorbian',
        'Urdu',
        'Uyghur',
        'Uzbek',
        'Venetian',
        'Vietnamese',
        'Vilamovian',
        'Volapük',
        'Võro',
        'Welsh',
        'Xhosa',
        'Yiddish',
        'Yoruba',
        'Zazaki',
        'Zulu',
      ],
    },
    {
      id: 'Y2',
      name: 'Language 2',
      type: 'choice',
      choices: [
        'Please select any additional languages',
        'Abkhaz',
        'Adyghe',
        'Afrikaans',
        'Akan',
        'Albanian',
        'American Sign Language',
        'Amharic',
        'Ancient Greek',
        'Arabic',
        'Aragonese',
        'Aramaic',
        'Armenian',
        'Assamese',
        'Aymara',
        'Azerbaijani',
        'Balinese',
        'Basque',
        'Bengali',
        'Betawi',
        'Bosnian',
        'Breton',
        'Bulgarian',
        'Cantonese',
        'Catalan',
        'Cherokee',
        'Chickasaw',
        'Chinese',
        'Coptic',
        'Cornish',
        'Corsican',
        'Crimean Tatar',
        'Croatian',
        'Czech',
        'Danish',
        'Dawro',
        'Dutch',
        'English',
        'Esperanto',
        'Estonian',
        'Ewe',
        'Fiji Hindi',
        'Filipino',
        'Finnish',
        'French',
        'Galician',
        'Georgian',
        'German',
        'Greek, Modern',
        'Greenlandic',
        'Gujerati',
        'Haitian Creole',
        'Hawaiian',
        'Hebrew',
        'Hindi',
        'Hungarian',
        'Icelandic',
        'Indonesian',
        'Interlingua',
        'Inuktitut',
        'Irish',
        'Italian',
        'Japanese',
        'Javanese',
        'Kabardian',
        'Kalasha',
        'Kannada',
        'Kashubian',
        'Khmer',
        'Kinyarwanda',
        'Korean',
        'Krio',
        'Kurdish/Kurdî',
        'Ladin',
        'Latgalian',
        'Latin',
        'Lingala',
        'Livonian',
        'Lojban',
        'Low German',
        'Lower Sorbian',
        'Macedonian',
        'Malay',
        'Malayalam',
        'Mandarin',
        'Manx',
        'Maori',
        'Mauritian Creole',
        'Middle English',
        'Middle Low German',
        'Min Nan',
        'Mongolian',
        'Norwegian',
        'Oriya',
        'Pangasinan',
        'Papiamentu',
        'Pashto',
        'Persian',
        'Pitjantjatjara',
        'Polish',
        'Portuguese',
        'Proto-Slavic',
        'Punjabi',
        'Quenya',
        'Rajasthani',
        'Rapa Nui',
        'Romanian',
        'Russian',
        'Sanskrit',
        'Scots',
        'Scottish Gaelic',
        'Semai',
        'Serbian',
        'Serbo-Croatian',
        'Sinhalese',
        'Slovak',
        'Slovene',
        'Spanish',
        'Swahili',
        'Swedish',
        'Tagalog',
        'Tajik',
        'Tamil',
        'Tarantino',
        'Telugu',
        'Thai',
        'Tok Pisin',
        'Turkish',
        'Twi',
        'Ukrainian',
        'Upper Sorbian',
        'Urdu',
        'Uyghur',
        'Uzbek',
        'Venetian',
        'Vietnamese',
        'Vilamovian',
        'Volapük',
        'Võro',
        'Welsh',
        'Xhosa',
        'Yiddish',
        'Yoruba',
        'Zazaki',
        'Zulu',
      ],
    },
    {
      id: 'Z2',
      name: 'Language 3',
      type: 'choice',
      choices: [
        'Please select any additional languages',
        'Abkhaz',
        'Adyghe',
        'Afrikaans',
        'Akan',
        'Albanian',
        'American Sign Language',
        'Amharic',
        'Ancient Greek',
        'Arabic',
        'Aragonese',
        'Aramaic',
        'Armenian',
        'Assamese',
        'Aymara',
        'Azerbaijani',
        'Balinese',
        'Basque',
        'Bengali',
        'Betawi',
        'Bosnian',
        'Breton',
        'Bulgarian',
        'Cantonese',
        'Catalan',
        'Cherokee',
        'Chickasaw',
        'Chinese',
        'Coptic',
        'Cornish',
        'Corsican',
        'Crimean Tatar',
        'Croatian',
        'Czech',
        'Danish',
        'Dawro',
        'Dutch',
        'English',
        'Esperanto',
        'Estonian',
        'Ewe',
        'Fiji Hindi',
        'Filipino',
        'Finnish',
        'French',
        'Galician',
        'Georgian',
        'German',
        'Greek, Modern',
        'Greenlandic',
        'Gujerati',
        'Haitian Creole',
        'Hawaiian',
        'Hebrew',
        'Hindi',
        'Hungarian',
        'Icelandic',
        'Indonesian',
        'Interlingua',
        'Inuktitut',
        'Irish',
        'Italian',
        'Japanese',
        'Javanese',
        'Kabardian',
        'Kalasha',
        'Kannada',
        'Kashubian',
        'Khmer',
        'Kinyarwanda',
        'Korean',
        'Krio',
        'Kurdish/Kurdî',
        'Ladin',
        'Latgalian',
        'Latin',
        'Lingala',
        'Livonian',
        'Lojban',
        'Low German',
        'Lower Sorbian',
        'Macedonian',
        'Malay',
        'Malayalam',
        'Mandarin',
        'Manx',
        'Maori',
        'Mauritian Creole',
        'Middle English',
        'Middle Low German',
        'Min Nan',
        'Mongolian',
        'Norwegian',
        'Oriya',
        'Pangasinan',
        'Papiamentu',
        'Pashto',
        'Persian',
        'Pitjantjatjara',
        'Polish',
        'Portuguese',
        'Proto-Slavic',
        'Punjabi',
        'Quenya',
        'Rajasthani',
        'Rapa Nui',
        'Romanian',
        'Russian',
        'Sanskrit',
        'Scots',
        'Scottish Gaelic',
        'Semai',
        'Serbian',
        'Serbo-Croatian',
        'Sinhalese',
        'Slovak',
        'Slovene',
        'Spanish',
        'Swahili',
        'Swedish',
        'Tagalog',
        'Tajik',
        'Tamil',
        'Tarantino',
        'Telugu',
        'Thai',
        'Tok Pisin',
        'Turkish',
        'Twi',
        'Ukrainian',
        'Upper Sorbian',
        'Urdu',
        'Uyghur',
        'Uzbek',
        'Venetian',
        'Vietnamese',
        'Vilamovian',
        'Volapük',
        'Võro',
        'Welsh',
        'Xhosa',
        'Yiddish',
        'Yoruba',
        'Zazaki',
        'Zulu',
      ],
    },
    { id: 'A3', name: 'Other Languages', type: 'string' },
  ],
};
