import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  VStack,
  Heading,
  Image,
  Link,
  HStack,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { FcGoogle } from 'react-icons/fc';
import { useNavigate } from 'react-router-dom';
import { signInWithGoogle } from '../context/GoogleAuthProvider';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import { LoginProps } from '../interfaces/LoginProps';
import UL_pattern_bg from './../logo/UL_pattern_bg.png';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';
const loginImage =
  'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80';

export default function TherapistLogin({ signedInUserType, setSignedInUserType }: LoginProps) {
  const user = useContext(AuthContext);
  const navigate = useNavigate();

  const signOut = async () => {
    await auth.signOut();
    localStorage.clear();
    navigate('/therapist-login');
  };

  let userType = 'therapist';
  useEffect(() => {
    setSignedInUserType(userType);
    console.log(userType);
  }, []);

  const LeftData = () => (
    <Stack spacing={10} width={'80%'}>
      <Text fontSize={50} fontWeight={'black'} color={'brand.300'} lineHeight={1} mt={50}>
        Therapist Login
      </Text>
      {user && (
        <VStack>
          <Button onClick={() => navigate('/dashboard-therapist')}>
            You're already logged in, Go to Therapist Dashboard
          </Button>
          <Text>or</Text>
          <Button onClick={() => signOut()}>Sign Out</Button>
        </VStack>
      )}
      {!user && (
        <Box rounded={'lg'}>
          <Stack align={'left'}>
            <Text fontSize={'lg'} color={'gray.600'} pb={'5'}>
              Login to get started
            </Text>
          </Stack>
          <Stack spacing={4}>
            <Stack>
              {!user && (
                <Button
                  colorScheme={'gray'}
                  leftIcon={<FcGoogle />}
                  variant={'solid'}
                  onClick={() => {
                    signInWithGoogle().then(() => navigate('/dashboard-therapist'));
                  }}
                >
                  Sign in with Google
                </Button>
              )}
              <Box textAlign={'left'} mt={3}>
                <Link href="/">← Back home</Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
  return (
    <Box
      position="relative"
      background={'white'}
      height={'100vh'}
      backgroundImage={UL_pattern_bg}
      backgroundRepeat={'repeat'}
      backgroundSize={'cover'}
    >
      <Box
        bg={'white'}
        position={'absolute'}
        left={0}
        bottom={'0'}
        minH={'90vh'}
        borderTopRightRadius="40"
        width={'94%'}
      >
        <Flex align={'center'} justify={'center'}>
          <HStack spacing={8} mx={'auto'} width={'90%'} py={6} px={6}>
            <Box rounded={'lg'} p={8} mt={30} background={'white'} width={'50%'}>
              <LeftData />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'50%'}
            >
              <Image width="600px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
}
