import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
  Avatar,
  HStack,
  Image,
  StackDivider,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import ReactApexChart from 'react-apexcharts';

import React, { ReactNode, useContext, useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '..';
import { LanguageContext } from '../App';
import { auth } from '../auth/fireBaseSetup';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getAllRowsById from '../utils/getAllRowsById';
import getAllRowsByIdPaginate from '../utils/getAllRowsByIdPaginate';
import getUserNinoxDataByEmail from '../utils/getUserNinoxDataByEmail';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import { signOut } from '../utils/signOut';
import BookingsContentStudentsDashboard from './BookingsContentStudentsDashboard';
import { DashboardVideoList } from './DashboardVideoList';
import { DashboardVideoListSmall } from './DashboardVideoListSmall';
import DashboardTherapistDays from './DashboardTherapistDays';
import { BsBookmark, BsBookmarkFill, BsFillBookmarkFill } from 'react-icons/bs';
import { RiBookMarkFill } from 'react-icons/ri';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import moment from 'moment';
import BookingsContentPreviewClients from './BookingsContentPreviewClients';
import getMultipleApiData from '../utils/getMultipleApiData';
import UserProfilePic from './UserProfilePic';
import BookingsContentUpcomingDates from './BookingsContentUpcomingDates';
import getRadialChartData from '../utils/getRadialChartData';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const adsEP = process.env.REACT_APP_ADS_LIST;
const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const studentsFromSchool = caseNotesMainstream;
const saveResourceEP = process.env.REACT_APP_UL_SAVED_RESOURCES;
const txDatesEP = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS_DATES;
const msSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const ssSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;

const DashboardTherapistLog = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserPropsTherapist) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dbLoad, setDbLoad] = useState(0);
  const [accessLevel, setAccessLevel] = useState('none');
  const [adsList, setAdsList] = useState<any>([]);
  const [txClients, setTxClients] = useState<any>([]);
  const [txSchoolsMs, setTxSchoolsMs] = useState<any>([]);
  const [txSchoolsSs, setTxSchoolsSs] = useState<any>([]);
  const [msSchools, setMsSchools] = useState<any>([]);
  const [ssSchools, setSsSchools] = useState<any>([]);
  const [txAllDates, setTxAllDates] = useState<any>([]);
  const [txSchoolUpcomingDatesSs, setTxSchoolUpcomingDatesSs] = useState<any>([]);
  const [txSchoolUpcomingDatesMs, setTxSchoolUpcomingDatesMs] = useState<any>([]);
  const [combinedUpcomingDates, setCombinedUpcomingDates] = useState<any>([]);
  const [txRadialDataMsSchools, setTxRadialDataMsSchools] = useState<any>([]);
  const [txRadialDataSsSchools, setTxRadialDataSsSchools] = useState<any>([]);
  const [txRadialDataMsFinal, setTxRadialDataMsFinal] = useState<any>([]);
  const [txRadialDataSsFinal, setTxRadialDataSsFinal] = useState<any>([]);

  const [txSessionNotesMs, setTxSessionNotesMs] = useState<any>([]);
  const [txSessionNotesSs, setTxSessionNotesSs] = useState<any>([]);
  const [allSavedResources, setAllSavedResources] = useState<any>([]);
  const [txDates, setTxDates] = useState<any>([]);
  const [schoolsTherapists, setSchoolsTherapists] = useState<any>([]);
  const accNumber = localStorage.getItem('accountNumber');
  const [schoolFilter, setSchoolFilter] = useState(signedInUserId);
  const userAccount = useContext(MyContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');
  const [sorter, setSorter] = useBoolean();
  const navigate = useNavigate();
  const [therapists, setTherapists] = useState<any>();
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulTherapistsTable = process.env.REACT_APP_UL_ALL_THERAPIST_PROFILES;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allClients = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const allMsStudents = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const allSsStudents = process.env.REACT_APP_UL_STUDENTS_SEN;
  const mSSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
  const ssSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;

  const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
  const sessionNotesMs = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
  const sessionNotesSs = process.env.REACT_APP_UL_SESSION_NOTES_SEN;

  const upcomingDatesMsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM_BOOKING_DATES;
  const upcomingDatesSsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN_BOOKING_DATES;

  const upcomingDatesMsQueryEp = process.env.REACT_APP_UL_UPCOMING_DATES_MAINSTREAM_QUERY;
  const upcomingDatesSsQueryEp = process.env.REACT_APP_UL_UPCOMING_DATES_SEN_QUERY;

  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

  // Clients query EP:
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
  const txAccountNumber = Number(localStorage.getItem('accountNumber'));

  // Clients query:
  const therapist_id = txAccountNumber;
  const clientsQuery = `let therapist_id := ${therapist_id};
  let students := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.id = therapist_id]
  concat(students);
  `;
  const therapistName = Number(localStorage.getItem('firstName'));

  const totalSchoolsQuery = `let name := "${therapistName}";
let today_date := today();
let today_year := year(today_date);
let today_month := month(today_date);
let school_years_array := if today_month >= 9 then
		[today_year, number(today_year + 1)]
	else
		[number(today_year - 1), today_year]
	end;
let school_dates := (select 'School Dates')[('Mainstream School'.lower(text('Therapist 1')) = lower(name) or
				'Mainstream School'.lower(text('Therapist 3')) = lower(name) or
			'Mainstream School'.lower(text('Therapist 2')) = lower(name)) and
		(Start >= today_date and Start <= date(number(item(school_years_array, 1)), 8, 1))];
concat(school_dates)`;

  // Client query data EP:
  const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

  // const getAllStudentsEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;

  const endPointTherapist = `${process.env.REACT_APP_ALL_THERAPISTS_EP}?filters={"G":"${userAccount?.userEmail}"}`;
  const allTherapists = process.env.REACT_APP_ALL_THERAPISTS_EP;

  const user = useContext(AuthContext);

  const [txProfilePic, setTxProfilePic] = useState<any>('');
  const [loadingIcon, setLoadingIcon] = useState<any>(0);
  const [loadingUpcomingDates, setLoadingUpcomingDates] = useState<number>(0);
  const [loadingUpcomingBookings, setLoadingUpcomingBookings] = useState<number>(0);

  function downloadFile(at: any, fileURL: any) {
    // console.log('at: ', at);
    // console.log('fileURL: ', fileURL);
    const options = {
      headers: {
        Authorization: at,
      },
    };
    fetch(fileURL, options)
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        // console.log(file);
        setTxProfilePic(file);
        // setDownloadedFile(file);
        setLoadingIcon(1);
        // window.location.assign(file);
      });
  }
  const today = new Date();
  const currentDateTime = moment(today).format('DD/MM/YYYY HH:mm');
  const allAnalyticsEP = process.env.REACT_APP_ALL_ANALYTICS_EP;
  const initialized = useRef(false);
  const accountType = localStorage.getItem('accountType');

  const enterTxSession = (sessionType: string) => {
    // ======{id: 'PB', name: 'Analytics', fields: Array(5)}
    // {id: 'A', name: 'Email', type: 'email'}
    // {id: 'B', name: 'accountNumber', type: 'string'}
    // {id: 'E', name: 'Type', type: 'string'}
    // {id: 'G', name: 'First name', type: 'string'}
    // {id: 'H', name: 'Last name', type: 'string'}

    const sessionToAdd = {
      Email: String(localStorage.getItem('accountEmail')),
      accountNumber: String(localStorage.getItem('accountNumber')),
      Type: sessionType,
      Time: currentDateTime,
    };

    try {
      ninoxTableAddRow(allAnalyticsEP, { fields: sessionToAdd }, bearerToken).then((data: any) => {
        return data;
      });
    } catch (error) {
      console.error(error);
    }
  };

  function loginTherapistAndSetUser(firebaseUser: any, data: any) {
    setSignedInUserEmail(firebaseUser?.email!);
    if (userAccount.userAccountType === 'therapist') {
      if (firebaseUser?.email !== undefined) {
        const endPointTherapist = `${process.env.ulTherapistsTable}?filters={"G":"${firebaseUser?.email}"}`;
        getUserNinoxDataByEmail(endPointTherapist, bearerToken).then((data: any) => {
          setSignedInUserId(data?._id);
          localStorage.setItem('accountNumber', data?._id);
          ninoxTableGetRow(
            process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
            data?._id,
            process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
          ).then((data: any) => {
            // console.log(data);
            setUserMeta({
              firstName: data?.fields['First Name'],
              lastName: data?.fields['Surname'],
              accessLevel: data?.fields['Access Level'],
              accountType: userAccount.userAccountType === 'therapist' && 'Therapist Staff',
              signedInUserEmail: firebaseUser?.email,
            });
            localStorage.setItem('firstName', data?.fields['First Name']);
            localStorage.setItem('lastName', data?.fields['Surname']);
            localStorage.setItem('accessLevel', data?.fields['Access Level']);
            localStorage.setItem('accountType', 'Therapist Staff');
            localStorage.setItem('accountEmail', String(firebaseUser?.email));
            localStorage.setItem('Bio', data?.fields['Bio']);
            localStorage.setItem('data', JSON.stringify(data));
            localStorage.setItem(
              'Headshot',
              process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                data?.id +
                '/files/' +
                data?.fields['Headshot']
            );
            downloadFile(
              bearerToken,
              process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                data?.id +
                '/files/' +
                data?.fields['Headshot']
            );

            enterTxSession('Therapist Staff');
            navigate('/dashboard-therapist');
          });
        });
      }
    }
  }
  function checkOutAndLogout() {
    signOut();
    navigate('/login-error');
  }
  // `https://drive.google.com/uc?export=view&id=${adsList[3]?.fields?.ad_image_url}`;
  const AdCarousel = () => {
    const tooglesGroupId = 'Toggles';
    const valuesGroupId = 'Values';
    const mainGroupId = 'Main';

    const getConfigurableProps = () => ({
      showArrows: false,
      showStatus: false,
      showIndicators: true,
      infiniteLoop: true,
      showThumbs: false,
      useKeyboardArrows: true,
      autoPlay: true,
      stopOnHover: true,
      swipeable: true,
      dynamicHeight: false,
      emulateTouch: true,
      autoFocus: false,
      // thumbWidth: 100,
      selectedItem: 1,
      interval: 3000,
      transitionTime: 500,
      swipeScrollTolerance: 5,
      ariaLabel: 'Unlocking Language Advert',
    });

    return (
      <Carousel {...getConfigurableProps()}>
        {adsList.map((i: any, k: any) => (
          <Box p={5} key={k}>
            <Image src={`https://drive.google.com/uc?export=view&id=${i.fields?.ad_image_url}`} />
            {/* <Text className={i.fields.ad_title}>
                {k} {i.fields.ad_title} - {i.fields.ad_location}
              </Text> */}
          </Box>
        ))}
      </Carousel>
    );
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      const endPointClientsCentral = `${process.env.REACT_APP_ALL_THERAPISTS_EP}?filters={"C8":"${firebaseUser?.email}"}`;

      getUserNinoxDataByEmail(endPointClientsCentral, bearerToken)
        .then((data: any) =>
          data === undefined ? checkOutAndLogout() : loginTherapistAndSetUser(firebaseUser, data)
        )
        .catch((error) => console.log(error));
    });
    return unsubscribe;
  }, []);

  useEffect(() => {}, [combinedUpcomingDates]);

  // const allDates = [
  //   Boolean(txSDS) !== false && txSchoolDatesSs[0]?.fields['Term dates'],
  //   Boolean(txSDM) !== false && txSchoolDatesMs[0]?.fields['Term dates'],
  // ];
  // useEffect(() => {}, [txSchoolUpcomingDatesMs, txSchoolUpcomingDatesSs]);

  return (
    <Box height={'100vh'} overflowY={'scroll'}>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        maxW="750xl"
        columns={{ base: 2, md: 2 }}
        mb={'-10'}
        mt={'-5'}
      >
        <Heading textAlign={'left'} as="h4" size="xl">
          Unlocking Language
        </Heading>
        <HStack justifyContent={'right'}>
          {loadingIcon === 0 ? (
            <Box
              width={'76px'}
              height={'76px'}
              alignContent={'center'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Spinner
                thickness="8px"
                speed="0.65s"
                emptyColor="gray.200"
                color="brand.200"
                size="xl"
              />
              loading...
            </Box>
          ) : (
            txProfilePic && <UserProfilePic profilePic={txProfilePic!} />
          )}

          <Box textAlign={'left'} ml={3} mr={5}>
            Welcome back,
            <Heading textAlign={'right'} as="h4" size="md" mt={'0'}>
              {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}{' '}
            </Heading>
          </Box>
        </HStack>
      </SimpleGrid>
      <Flex ml={{ base: 0 }} mt={-10}>
        <SimpleGrid
          ml={{ base: 0, md: 20 }}
          p="10"
          // maxW="50xl"
          columns={[1, 2, 4, 4]}
          spacing={{ base: 5, lg: 8 }}
          width={'75%'}
        >
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            alignContent={'center'}
            textAlign={'center'}
          >
            <Text fontWeight={'bold'} mb={5}>
              Access level
            </Text>

            <CircularProgress
              value={100}
              size="140px"
              color="purple.700"
              // color="brand.100"
            >
              <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
                {localStorage.getItem('accessLevel')}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            alignContent={'center'}
            textAlign={'center'}
          >
            <Text fontWeight={'bold'} mb={5}>
              Total Clients
            </Text>
            <CircularProgress
              value={100}
              size="140px"
              // color="brand.200"
              color="purple.500"
            >
              <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
                {txClients.length}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>{' '}
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            alignContent={'center'}
            textAlign={'center'}
          >
            <Text fontWeight={'bold'} mb={5}>
              Total Schools
            </Text>
            <CircularProgress
              value={100}
              size="140px"
              // color="brand.300"
              color="purple.300"
            >
              <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
                {Number(txSchoolsMs) + Number(txSchoolsSs)}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
          <Box
            background={'white'}
            borderRadius={10}
            padding={5}
            alignContent={'center'}
            textAlign={'center'}
          >
            <Text fontWeight={'bold'} mb={5}>
              Total school notes
            </Text>
            <CircularProgress
              value={100}
              size="140px"
              // color="brand.300"
              color="purple.200"
            >
              <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
                {Number(txSessionNotesMs) + Number(txSessionNotesSs)}
              </CircularProgressLabel>
            </CircularProgress>
          </Box>
        </SimpleGrid>
        <Box width={'25%'} justifyContent={'right'} pr={10}>
          <Box
            width={'100%'}
            height={'220px'}
            bg={'white'}
            borderRadius={10}
            mt={10}
            pt={10}
            pl={5}
            pr={5}
            pb={5}
            minH={'220px'}
            overflow={'hidden'}
            textAlign={'left'}
          >
            <Heading as={'h3'} size={'md'} mb={5} mt={-5}>
              Upcoming bookings (
              {Number(txSchoolUpcomingDatesMs.concat(txSchoolUpcomingDatesSs)?.length)})
            </Heading>
            {loadingUpcomingBookings === 0 ? (
              <>
                {' '}
                <Spinner
                  thickness="8px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand.200"
                  size="xl"
                />
                loading...
              </>
            ) : (
              <BookingsContentUpcomingDates
                txSchoolUpcomingDatesMs={txSchoolUpcomingDatesMs}
                txSchoolUpcomingDatesSs={txSchoolUpcomingDatesSs}
                combinedUpcomingDates={combinedUpcomingDates}
                setCombinedUpcomingDates={setCombinedUpcomingDates}
                msSchools={msSchools}
                ssSchools={ssSchools}
              />
            )}
          </Box>
        </Box>
      </Flex>

      <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
        <Box bg={'white'} borderRadius={10} p={5} width={'75%'}>
          <Heading as={'h3'} size={'md'} mb={10}>
            Clients
          </Heading>
          <Box height={'430px'} overflowY={'scroll'}>
            {/* <Box w="100%" bg="white" color={'black'} p={5} borderRadius={10}>
              <BookingsContentStudentsDashboard
                signedInUserId={Number(localStorage.getItem('accountNumber'))}
                setSignedInUserId={setSignedInUserId}
                signedInUserEmail={signedInUserEmail}
                setSignedInUserEmail={setSignedInUserEmail}
                userMeta={userMeta}
                setUserMeta={setUserMeta}
                navWidth={navWidth}
                setNavWidth={setNavWidth}
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            </Box> */}
            <BookingsContentPreviewClients
              signedInUserId={signedInUserId}
              signedInUserEmail={signedInUserEmail}
              userMeta={userMeta}
              navWidth={navWidth}
              setNavWidth={setNavWidth}
              signedInUserType={signedInUserType}
              setSignedInUserType={setSignedInUserType}
            />
          </Box>
        </Box>
        <Box pl={5} width={'25%'} justifyContent={'right'}>
          <Box
            bg={'white'}
            minH={'350px'}
            pt={10}
            pb={10}
            pr={2.5}
            pl={2.5}
            overflow={'hidden'}
            borderRadius={10}
            width={'100%'}
          >
            <Heading as={'h3'} size={'md'} mb={10} mt={-5}>
              Remaining school days
            </Heading>
            <Box bg={'white'}>
              <Stack
                divider={<StackDivider />}
                spacing="4"
                height={'400px'}
                overflowY={'scroll'}
                width={'100%'}
              >
                {loadingUpcomingDates === 0 ? (
                  <Box
                    width={'100%'}
                    height={'100%'}
                    alignContent={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    pt={'33.3%'}
                  >
                    <Spinner
                      thickness="8px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="brand.200"
                      size="xl"
                    />
                    loading...
                  </Box>
                ) : (
                  <DashboardTherapistDays
                    txRadialDataMsFinal={txRadialDataMsFinal}
                    txRadialDataSsFinal={txRadialDataSsFinal}
                  />
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Flex>

      <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
        <Box bg={'white'} borderRadius={10} p={5} width={'75%'}>
          <Heading as={'h3'} size={'md'} mb={10}>
            Latest videos
          </Heading>
          <HStack>
            <DashboardVideoList
              accountType={localStorage.getItem('accountType')!}
              accessLevel={localStorage.getItem('accessLevel')!}
            />
            <Box pl={5} width={'30%'} justifyContent={'right'}>
              <AdCarousel />
            </Box>
          </HStack>
        </Box>

        <Box pl={5} width={'25%'} justifyContent={'right'}>
          <Box bg={'white'} minH={'300px'} pt={10} pb={10} pr={5} pl={5} borderRadius={10}>
            <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'500px'}>
              <Heading as={'h3'} size={'md'} mb={10} mt={-5}>
                Saved resources ({allSavedResources.length})
              </Heading>
              <Box bg={'white'} pl={0} height={'440px'} width={'100%'}>
                <Stack
                  divider={<StackDivider />}
                  spacing="4"
                  height={'420px'}
                  overflowY={'scroll'}
                  overflowX={'hidden'}
                  wrap={'wrap'}
                >
                  {allSavedResources.map((i: any, k: any) => (
                    <Link
                      href={`/resource/?res_id=${i?.fields['resourceId']}&fileExt=${i?.fields['fileExt']}`}
                      key={k}
                      target="_blank"
                      textDecoration={'none'}
                      cursor={'pointer'}
                    >
                      <Box cursor={'pointer'}>
                        <Heading size="xs" textTransform="uppercase">
                          <HStack>
                            <BsBookmarkFill fill="black" />
                            {/* <Text width={'100%'}>{i?.fields['fileTitle']?.slice(0, 20)}&nbsp;...</Text> */}
                            <Text width={'100%'}>{i?.fields['fileTitle']}&nbsp;...</Text>
                          </HStack>
                        </Heading>
                        <Text pt="2" fontSize="sm" pl={6}>
                          Date saved: {moment(new Date(i.createdAt)).format('DD/MM/YYYY')}
                        </Text>
                      </Box>
                    </Link>
                  ))}
                  {/* {JSON.stringify(allSavedResources)} */}
                  {allSavedResources?.length === 0 && <Text>No saved resources</Text>}
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default DashboardTherapistLog;
