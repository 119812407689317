import { useEffect, useState } from 'react';

import { Box, Flex, Heading, Link, Text, HStack } from '@chakra-ui/react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';

import getAllRowsById from '../utils/getAllRowsById';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
const myFavouritesEP = process.env.REACT_APP_MY_FAVOURITES_EP;
const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

export default function PopularResources({ myPop, setMyPop }: any) {
  const [myFavouritesList, setMyFavouritesList] = useState<any>([]);
  // const [myPop, setMyPop] = useState<any>(0);
  // useEffect(() => {
  //   setMyPop(1);
  // }, []);

  const popResQuery = `let item := (select 'Popular resources')[text('Access Level') = "${localStorage.getItem(
    'accessLevel'
  )}" and 'Ad Location' = "${localStorage.getItem('accountType')}"];
  let array := [""];
  for i in item do
    let id := number(i.Id);
    let name := i.Name;
    let picture := i.Picture;
    let target := i.'Target URL';
    let caption := i.Caption;
    let adloc := i.'Ad Location';
    let access := i.'Access Level';
    array := array(array, ["{'id': " + id + ",'fields':{'Name':'" + name + "','Picture':'" + picture +
        "','Target URL':'" +
        target +
        "','Caption':'" +
        caption +
        "','Ad Location':'" +
        adloc +
        "','Access Level':'" +
        access +
        "'}}"])
  end;
  join(slice(array, 1, length(array)), ",")`;

  useEffect(() => {
    getAllRowsByEncodedQuery(clientsQueryEP, popResQuery).then((data: any) => {
      // console.log('QUERY RECEIVED DATA: ', data);
      const validJsonString = `[${data.replace(/'/g, '"')}]`;
      const jsonArray = JSON.parse(validJsonString);
      setMyFavouritesList(jsonArray);
    });
  }, [myPop]);

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    appendDots: (dots: any) => (
      <div
        style={{
          position: 'absolute',
          bottom: '-90px',
          width: '100%',
        }}
      >
        <ul style={{ margin: '0', padding: '0' }}> {dots} </ul>
      </div>
    ),
    // swipeToSlide: true,
    // variableWidth: true,

    // dots: false,
    // infinite: false,
    // speed: 500,
    // slidesToShow: 3,
    // slidesToScroll: 1,
    // variableWidth: true,
  };

  const titleLength = 35;
  const captionLength = 70;

  // const trimmedString = string.substring(0, length);
  return (
    <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
      <Box width={'100%'} bg="white" borderRadius={10} p={5} pb={10}>
        <HStack>
          <Heading as={'h3'} size={'md'} mb={10}>
            Popular resources ({myFavouritesList.length}){' '}
          </Heading>
          {/* <Text fontStyle={'italic'} fontWeight={'300'} mt={-10}>
            Click and drag to scroll horizontally
          </Text> */}
        </HStack>

        {/* <AdCarouselFavourites /> */}
        {myFavouritesList.length <= 0 ? (
          <Box>
            No popular resources added for {localStorage.getItem('accountType')} at{' '}
            {localStorage.getItem('accessLevel')} access level.
            {/* At minimum there should be 3 resources added fors
            the slider to initialise */}
          </Box>
        ) : (
          // <Box maxWidth={'100%'} overflow={'auto'} whiteSpace={'nowrap'}>
          // className="slider-container" style={{ width: '100%', overflowX: 'hidden' }}
          <Box pb={16}>
            <Slider {...slickSettings}>
              {myFavouritesList?.map((i: any, k: any) => (
                <Link href={i.fields['Target URL']} key={k}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    bg={'green.100'}
                    mr={5}
                    borderRadius={10}
                    p={5}
                    height={300}
                  >
                    <Text
                      py={'2'}
                      fontWeight={'bold'}
                      height={16}
                      width={'100%'}
                      textAlign={'center'}
                    >
                      {i.fields['Name'].substring(0, titleLength)}{' '}
                      {Number(i.fields['Name'].length) >= 35 && '...'}
                    </Text>
                    <Box
                      backgroundImage={`https://drive.google.com/thumbnail?id=${i.fields['Picture']}&sz=w200`}
                      backgroundSize={'contain'}
                      backgroundPosition={'center'}
                      backgroundRepeat={'no-repeat'}
                      width={'200px'}
                      height={'150px'}
                      display={'flex'}
                      flexDirection={'column'}
                      textAlign={'center'}
                      alignItems={'center'}
                    />
                    <Text py={'2'} fontStyle={'italic'} textAlign={'center'} fontSize={14}>
                      {i.fields['Caption'].substring(0, captionLength)}{' '}
                      {Number(i.fields['Caption'].length) >= 35 && '...'}
                    </Text>
                  </Box>
                </Link>
              ))}
            </Slider>
          </Box>
        )}
      </Box>
    </Flex>
  );
}
