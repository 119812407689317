import {
  Box,
  Flex,
  Heading,
  Icon,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Link,
  Text,
  Button,
  Tag,
} from '@chakra-ui/react';
import { BsPerson } from 'react-icons/bs';
import { FiServer } from 'react-icons/fi';
import { GoLocation } from 'react-icons/go';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import React, { useEffect, useState, ReactNode, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import getAllProfiles from '../utils/getAllProfiles';

import ninoxTableGetRow from './../utils/ninoxTableGetRow';
import { userDetailsContext } from './../context/UserDetailsProvider';
// import { userTypeContext } from './../context/UserTypeProvider';
import { LanguageContext } from '../App';
import { SignedInUserProps } from './../interfaces/SignedInUserProps';
import { UserMeta } from './../interfaces/UserMeta';
import { MyContext } from '..';
import { auth } from './../../src/auth/fireBaseSetup';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';
import getUserNinoxDataByEmail from './../../src/utils/getUserNinoxDataByEmail';
import { signOut } from './../utils/signOut';

interface StatsCardProps {
  title: string;
  stat?: string | number | undefined;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

function StatsCard(props: StatsCardProps) {
  const { title, stat, icon, color } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
      bg={color}
      w="100%"
      p={4}
      color="brand.25"
      borderRadius="md"
      _hover={{
        textDecoration: 'none',
      }}
    >
      <Flex justifyContent={'space-between'}>
        <Box w="100%" p={4} color="brand.25" borderRadius="md" pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={'medium'} fontSize={'2xl'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'4xl'} fontWeight={'extrabold'} color="white" as="b">
            {stat}
          </StatNumber>
        </Box>
        <Box my={'auto'} color={'white'} alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const ulDb = process.env.REACT_APP_UL_DB;
const ulTherapistsTable = process.env.REACT_APP_UL_ALL_THERAPIST_PROFILES;
const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
const DashboardContent = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
}: SignedInUserPropsTherapist) => {
  // const [userMeta, setUserMeta] = useState<UserMeta | null>();
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  // const { userType, updateUserType } = useContext(userTypeContext);
  const { language, setLanguage } = useContext(LanguageContext);
  const userAccount = useContext(MyContext);
  const navigate = useNavigate();

  function loginClinicalAndSetUser(firebaseUser: any, data: any) {
    // console.log(firebaseUser);
    console.log('signedInUserType:', userAccount.userAccountType);
    setSignedInUserEmail(firebaseUser?.email!);
    if (userAccount.userAccountType === 'clinical') {
      if (firebaseUser?.email !== undefined) {
        console.log('Getting user ID for ' + firebaseUser?.email);
        const endPointClientsCentral = `${process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS}?filters={"C8":"${firebaseUser?.email}"}`;
        getUserNinoxDataByEmail(endPointClientsCentral, bearerToken).then((data: any) => {
          console.log('setSignedInUserId: ', data?._id);
          setSignedInUserId(data?._id);
          localStorage.setItem('accountNumber', data?._id);
          ninoxTableGetRow(
            process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
            data?._id,
            process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
          ).then((data: any) => {
            console.log('THE CENTRAL CLIENTS ROW: ', signedInUserId, ': ', data);
            setUserMeta({
              firstName: data?.fields['First Name'],
              lastName: data?.fields['Last Name'],
              accessLevel: data?.fields['Access level'],
              accountType: data?.fields['Client type'],
              messages: data?.fields['Messages'],
              stats: data?.fields['Stats'],
              invoices: data?.fields['Invoices'],
            });
            localStorage.setItem('firstName', data?.fields['First Name']);
            localStorage.setItem('lastName', data?.fields['Last Name']);
            localStorage.setItem('accessLevel', data?.fields['Access level']);
            localStorage.setItem('accountType', data?.fields['Client type']);
            localStorage.setItem('messages', data?.fields['Messages']);
            localStorage.setItem('stats', data?.fields['Stats']);
            localStorage.setItem('invoices', data?.fields['Invoices']);
          });
        });
      }
    }
  }
  function checkOutAndLogout() {
    signOut();
    navigate('/login-error');
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      const endPointClientsCentral = `${process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS}?filters={"C8":"${firebaseUser?.email}"}`;

      getUserNinoxDataByEmail(endPointClientsCentral, bearerToken)
        .then((data: any) =>
          data === undefined ? checkOutAndLogout() : loginClinicalAndSetUser(firebaseUser, data)
        )
        .catch((error) => console.log(error));
    });
    return unsubscribe;
  }, []);

  return (
    <>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        maxW="50xl"
        columns={{ base: 1, md: 1 }}
        // spacing={{ base: 5, lg: 8 }}
        mb={'-10'}
        mt={'-10'}
      >
        <Box p="6" textAlign={'right'}>
          <Tag textAlign={'right'} bg={'brand.200'} color={'white'} mb={5}>
            {localStorage.getItem('accountType')}
          </Tag>
          <Heading textAlign={'right'} as="h4" size="md">
            Welcome back,
            {' ' + userMeta?.firstName} {userMeta?.lastName}
          </Heading>
        </Box>
        <Link
          _hover={{
            textDecoration: 'none',
          }}
          href={'https://unlockinglanguage.co.uk/'}
          target={'_blank'}
        >
          <Box bg={'#fff'} h={'auto'} borderRadius={'6px'} p={'10'}>
            <Heading>This months deals</Heading>
            <Text pt={'5'}>🎉 Hooray, we just released a new version. Check out what's new.</Text>
          </Box>
        </Link>
      </SimpleGrid>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        maxW="50xl"
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 5, lg: 8 }}
      >
        <StatsCard
          title={'Account number'}
          stat={String(signedInUserId)}
          icon={''}
          color={'brand.400'}
        />
        <StatsCard
          title={'Access level'}
          stat={String(userMeta?.accessLevel)}
          icon={''}
          color={'brand.300'}
        />
        <StatsCard
          title={'Account type'}
          stat={String(userMeta?.accountType)}
          icon={''}
          color={'brand.200'}
        />
        <Link
          href={'/sessions'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard title={'Upcoming session'} stat={'01/09/2023'} icon={''} color={'brand.400'} />
        </Link>
        <Link
          href={'/'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Buy now pay later'}
            stat={'Get started'}
            icon={''}
            color={'brand.300'}
          />
        </Link>

        <Link
          href={'/invoices'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Outstanding Invoices(2)'}
            stat={'Pay now'}
            icon={''}
            color={'brand.200'}
          />
        </Link>
        <Link
          href={'/sessions'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Sessions'}
            stat={userMeta?.stats?.length}
            icon={''}
            color={'brand.400'}
          />
        </Link>
        <Link
          href={'/messages'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Messages'}
            stat={userMeta?.messages?.length}
            icon={''}
            color={'brand.300'}
          />
        </Link>

        <Link
          href={'/invoices'}
          _hover={{
            textDecoration: 'none',
          }}
        >
          <StatsCard
            title={'Invoices'}
            stat={userMeta?.invoices?.length}
            icon={''}
            color={'brand.200'}
          />
        </Link>
      </SimpleGrid>
    </>
  );
};

export default DashboardContent;
