import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  FormControl,
  FormLabel,
  Input,
  IconButton,
  Select,
  VStack,
  Textarea,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
} from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getAllRowsById from '../utils/getAllRowsById';
import orderItems from '../utils/orderItems';
import { BackButton } from './BackButton';
import { TomsRating } from '../interfaces/TomsRating';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import ninoxTableDeleteRow from '../utils/ninoxTableDeleteRow';
import Moment from 'react-moment';
import getDataFromEndPoint from './../utils/getDataFromEndPoint';
import { drive } from '@googleapis/drive';
import RatingStars from './RatingStars';
// import { BlobProvider } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf';
// let myPDf = pdfjs
// myPDf && myPDf.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { ContextMenu } from './ContextMenu';
import { ContextMenuTrigger } from './ContextMenuTrigger';
import { ContextMenuList } from './ContextMenuList';
// import { pdfjs } from 'react-pdf';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';

import {
  BsBookmark,
  BsFillBookmarkFill,
  BsSuitHeart,
  BsSuitHeartFill,
  BsStar,
  BsStarFill,
  BsHeart,
  BsHeartFill,
  BsMessenger,
} from 'react-icons/bs';
import YouTube from 'react-youtube';

import { MdMessage, MdOutlineMessage } from 'react-icons/md';
import RatingStarsAverage from './RatingStarsAverage';
// import thePdf from './sample.pdf';
const today = new Date();
const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

const BookingsContentVideoSingle = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const res_id = queryParameters.get('res_id');
  const fileExt = queryParameters.get('fileExt');

  // console.log('res_id: ', res_id);
  const [comment, setComment] = useState<string>('');

  const newRatingDefault = {
    fields: {
      Email: '',
      Rating: 0,
      accountID: '',
      commentOnRating: '',
      fileID: res_id,
    },
  };
  const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const [driveFile, setDriveFile] = useState<any>([]);
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [bookmark, setBookMark] = useState<boolean>(false);
  const [saved, setSaved] = useState<any>('');
  const [allSavedResources, setAllSavedResources] = useState<any>([]);
  const [avgRating, setAvgRating] = useState<any>(0);
  const [rate, setRate] = useState<boolean>(false);
  const [resourceViewsCount, setResourceViewsCount] = useState<any>(false);
  const [ratings, setRatings] = useState<any>([]);
  const [rating, setRating] = useState<any>(newRatingDefault);
  const [ratingScore, setRatingScore] = useState<number>(0);
  const [fileName, setFileName] = useState<string>('');
  const [loadingIcon, setLoadingIcon] = useState<any>(0);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tdIndex, setTdIndex] = useState<number>(0);
  const [theModal, setTheModal] = useState<number>(0);
  const [tomsRatings, setTomsRatings] = useState<TomsRating[] | []>([]);
  const [savedAccessToken, setSavedAccessToken] = useState<string>('');

  // const [newTr, setNewTr] = useState<TomsRating>(newTrDefault);
  const { onOpen, onToggle } = useDisclosure();
  const [student, setStudent] = useState({ fields: { 'Student/Patient Name': '' } });
  const [rows, setRows] = useState<any>([]);
  const [downloadedFile, setDownloadedFile] = useState<any>(null);

  const navigate = useNavigate();
  const reason = 'Not provided';
  const GoogleGetFileEP =
    'https://client-portal-serverless-functions.vercel.app/api/googleDriveGetResource';
  const GoogleTokenEP = 'https://client-portal-serverless-functions.vercel.app/api/googleDrive';
  const saveResourceEP = process.env.REACT_APP_UL_SAVED_RESOURCES;

  const deleteData = {
    fields: {
      saveStatus: 0,
    },
  };
  const saveResource = (e: any, saveStatus: number) => {
    try {
      ninoxTableAddRow(
        saveResourceEP,
        {
          fields: {
            resourceId: res_id,
            fileExt: fileExt,
            fileTitle: 'Res: ' + res_id,
            saveStatus: 1,
            userEmail: localStorage.getItem('accountEmail'),
            userId: localStorage.getItem('accountNumber'),
          },
        },
        bearerToken
      ).then(() => {
        toast({
          title: 'Bookmark saved!',
          description: 'The resource has been added to bookmarks.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        getAllRowsById(saveResourceEP).then((data) => {
          setSaved(data.filter((i: any) => i.fields['resourceId'] === res_id).length);
          setAllSavedResources(data.filter((i: any) => i.fields['resourceId'] === res_id));
        });
      });
    } catch {
      toast({
        title: 'Bookmark error',
        description:
          'Your bookmark was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  // const saveResourceUpdate = (e: any, id: number) => {
  //   try {
  //     ninoxTableUpdateRow(
  //       saveResourceEP!,
  //       Number(id),
  //       { fields: { saveStatus: 0 } },
  //       String(bearerToken)
  //     ).then(() => {
  //       toast({
  //         title: 'Resource removed.',
  //         description: 'Your resource has removed from your bookmarks.',
  //         status: 'success',
  //         duration: 6000,
  //         isClosable: true,
  //       });
  //       getAllRowsById(saveResourceEP).then((data) => {
  //         console.log('RES DATA', data);
  //         setSaved(data.filter((i: any) => i.fields['resourceId'] === res_id).length);
  //         setAllSavedResources(data.filter((i: any) => i.fields['resourceId'] === res_id));
  //       });
  //     });
  //   } catch {
  //     toast({
  //       title: 'Notes error',
  //       description: 'You note was not added to the system. Please email the systems administrator',
  //       status: 'error',
  //       duration: 6000,
  //       isClosable: true,
  //     });
  //   }
  // };

  const deleteSavedResource = (e: any, id: any) => {
    try {
      ninoxTableDeleteRow(saveResourceEP!, Number(id), deleteData, bearerToken!).then(() => {
        toast({
          title: 'Bookmark removed!',
          description: 'The resource bookmark has been removed.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        getAllRowsById(saveResourceEP).then((data) => {
          setAllSavedResources(data.filter((i: any) => i.fields['resourceId'] === res_id));
          setSaved(data.filter((i: any) => i.fields['resourceId'] === res_id).length);
        });
      });
    } catch {
      toast({
        title: 'Bookmark remove error',
        description:
          'Your bookmark was not removed from the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const IsolatedModal = ({ tr, id, modalState }: any) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    return (
      <Box as="section">
        <Modal
          onClose={() => {
            setTheModal(0);
            setOpenedModal(false);
          }}
          size={'3xl'}
          isOpen={theModal === id ? modalState : false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Toms Rating ID:{tr?.id}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* {JSON.stringify(tr)} */}
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>TOMS Rating ID</Text>
                <Text>{tr?.id}</Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Rating Date</Text>
                <Text>
                  {tr?.fields['Rating Date'] === undefined ? reason : tr?.fields['Rating Date']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Primary</Text>
                <Text>
                  {tr?.fields['Impairment Rating Primary'] === undefined
                    ? reason
                    : tr?.fields['Impairment Rating Primary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Secondary</Text>
                <Text>
                  {tr?.fields['Impairment Rating Secondary'] === undefined
                    ? reason
                    : tr?.fields['Impairment Rating Secondary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Primary</Text>
                <Text>
                  {tr?.fields['Activity Rating Primary'] === undefined
                    ? reason
                    : tr?.fields['Activity Rating Primary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Secondary</Text>
                <Text>
                  {tr?.fields['Activity Rating Secondary'] === undefined
                    ? reason
                    : tr?.fields['Activity Rating Secondary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Participation Rating</Text>
                <Text>
                  {tr?.fields['Participation Rating'] === undefined
                    ? reason
                    : tr?.fields['Participation Rating']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Wellbeing Rating</Text>
                <Text>
                  {tr?.fields['Wellbeing Rating'] === undefined
                    ? reason
                    : tr?.fields['Wellbeing Rating']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Carer Wellbeing Rating</Text>
                <Text>
                  {tr?.fields['Carer Wellbeing Rating'] === undefined
                    ? reason
                    : tr?.fields['Carer Wellbeing Rating']}
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  onClick={() => {
                    setTheModal(0);
                    setOpenedModal(false);
                  }}
                >
                  Close
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };
  // function handleModalOpen(rowId: any, rowSession: any) {
  //   // console.log('We tried to open ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
  //   setOpenedModal(true);
  //   setTheModal(rowId);
  //   // console.log(openedModal);
  // }
  // function handleModalClose(rowId: any, rowSession: any) {
  //   // console.log('We tried to close ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
  //   setOpenedModal(false);
  //   setTheModal(rowId);
  //   // console.log(openedModal);
  // }
  const [overlay, setOverlay] = useState<ReactNode>(<IsolatedModal session={0} />);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const userStats = userMeta && userMeta?.stats;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const resourceStats = process.env.REACT_APP_UL_RESOURCE_STATS;
  const resourcesRatings = process.env.REACT_APP_UL_RESOURCE_RATINGS;
  const toast = useToast();

  // const userNotes = tomsRatings?.filter((i: any) => i?.fields?.session_id == sessionId);
  const tomsRatingRegularSchoolsEP = process.env.REACT_APP_TOMS_RATING_QUERY_MS;
  const tomsRatinSENSchoolsEP = process.env.REACT_APP_TOMS_RATING_QUERY_SS;

  async function getDriveFile(endPoint: string, resource_id: string, fExt: string) {
    // const response = await fetch(endPoint);
    // const fileData = await response.json();
    // setDriveFile(fileData);
    // return fileData;

    const requestOptions = {
      method: 'POST',
      // headers: headers,
      body: JSON.stringify({ res_id: resource_id, fileExt: fExt }),
    };
    const response = await fetch(endPoint, requestOptions);
    const data = await response.json();
    return data;
  }
  const singleResourceEP = 'https://www.googleapis.com/drive/v3/files/';
  function downloadFile(at: any, fileURL: any) {
    // console.log('at: ', at);
    // console.log('fileURL: ', fileURL);
    const options = {
      headers: {
        Authorization: at,
      },
    };
    fetch(fileURL, options)
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        // console.log(file);
        setDownloadedFile(file);
        setLoadingIcon(1);
        // window.location.assign(file);
      });
  }
  function handleContextMenuIframe(e: any) {
    e.preventDefault(); // prevents the default right-click menu from appearing
  }
  function disableIframeDownload(e: any) {
    const protectedFrame = window.document.getElementById('protected-data-iframe')!;
    // protectedFrame.removeEventListener('contextmenu', handleContextMenuIframe);
  }
  const initialized = useRef(false);

  useEffect(() => {
    getDataFromEndPoint(GoogleTokenEP)
      .then((data: any) => {
        fileExt === 'mp4'
          ? getDriveFile(
              GoogleGetFileEP,
              fileExt === 'mp4' ? '1lIcATXD4Qi2ZYXqPHaqQNch8R03OML8Y' : res_id!,
              fileExt!
            ).then((mydata: any) => {
              setSavedAccessToken(mydata.fileUrl);
              downloadFile(data.config.headers.Authorization, mydata.fileUrl);
              console.log('fileMeta: ', mydata);
              // console.log('fileUrl: ', mydata.fileUrl);
              // console.log('fileName: ', mydata.fileMeta.data.name);
              setFileName(mydata.fileMeta.data.name);
              // console.log(mydata);
            })
          : setLoadingIcon(1);
      })
      .then(() => {
        getAllRowsByEncodedQuery(
          clientsQueryEP,
          `round(avg((select 'Resources Ratings')[fileID = "${String(res_id)}"].Rating), 2)`
        ).then((data: any) => {
          setAvgRating(data);
        });
        getAllRowsById(resourcesRatings).then((data: any) => {
          console.log('resourceStats:', data);
          setRatings(data);
        });
        getAllRowsById(saveResourceEP).then((data: any) => {
          console.log('savedResources:', data);
          setAllSavedResources(data.filter((i: any) => i.fields['resourceId'] === res_id));
          setSaved(
            data.filter((i: any) => i.fields['resourceId'] === res_id).length >= 1
              ? allSavedResources && allSavedResources[0]?.fields['saveStatus']
              : 0
          );
        });

        if (!initialized.current) {
          initialized.current = true;
          // getAllRowsById(resourcesRatings).then((data: any) => {
          //   console.log('resourcesRatings:', data);
          //   setRatings(data);
          // });
          const statDetails = {
            fileID: res_id,
            fileView: 1,
            'Email(Optional)': localStorage.getItem('accountEmail'),
          };
          ninoxTableAddRow(resourceStats, { fields: statDetails }, bearerToken).then(() => {
            getAllRowsByEncodedQuery(
              clientsQueryEP,
              `count((select 'Resources Stats')[fileID = "${String(res_id)}"])`
            ).then((data: any) => {
              setResourceViewsCount(data);
            });

            // getAllRowsById(resourceStats).then((data: any) => {
            //   // console.log('resourceStatsAdded - statDetails:', statDetails);
            //   setResourceViewsCount(data);
            // });
          });
        }
      });
    // function handleContextMenu(e: any) {
    //   e.preventDefault(); // prevents the default right-click menu from appearing
    // }
    // const rootElement = window.document.getElementById('protected-data')!;

    // const rootElementIframe = window.document.getElementById('protected-data-iframe')!;
    // // const protectedFrame = window.document.getElementById('protected-data-iframe')!;
    // // if (rootElement) {
    // //   rootElement.addEventListener('contextmenu', handleContextMenu);
    // //   return () => {
    // //     rootElement.addEventListener('contextmenu', handleContextMenu);
    // //     // rootElement.removeEventListener('contextmenu', handleContextMenu);
    // //     // protectedFrame.removeEventListener('contextmenu', handleContextMenu);
    // //     // protectedFrame.contentDocument.oncontextmenu = function () {
    // //     //   return false;
    // //     // };
    // //   };
    // // }
    // if (rootElementIframe) {
    //   rootElementIframe.addEventListener('contextmenu', handleContextMenu);
    //   return () => {
    //     rootElementIframe.addEventListener('contextmenu', handleContextMenu);
    //     // rootElement.removeEventListener('contextmenu', handleContextMenu);
    //     // protectedFrame.removeEventListener('contextmenu', handleContextMenu);
    //     // protectedFrame.contentDocument.oncontextmenu = function () {
    //     //   return false;
    //     // };
    //   };
    // }

    // setAvgRating(averageRating);
  }, []);

  useEffect(() => {
    // setTdIndex(0);
  }, [downloadedFile, loadingIcon, allSavedResources]);
  // const averageRating = (
  //   ratings
  //     .filter((i: any) => i.fields['fileID'] === res_id)
  //     .map((i: any) => i.fields['Rating'])
  //     .reduce((partialSum: any, a: any) => partialSum + a, 0) /
  //   ratings.filter((i: any) => i.fields['fileID'] === res_id).length
  // ).toFixed(2);
  // fields: {
  //   Email: "dsfds"
  //   Rating: 5
  //   accountID: "fsd"
  //   commentOnRating: "dfsf"
  //   fileID: "dsfgsg"
  // }

  const addRating = (e: any) => {
    try {
      ninoxTableAddRow(
        resourcesRatings,
        {
          fields: {
            Email: localStorage.getItem('accountEmail'),
            Rating: ratingScore,
            accountID: localStorage.getItem('accountNumber'),
            commentOnRating: comment,
            fileID: res_id,
          },
        },
        bearerToken
      ).then(() => {
        setTabIndex(1);
        setTdIndex(1);
        setRatingScore(0);
        getAllRowsByEncodedQuery(
          clientsQueryEP,
          `round(avg((select 'Resources Ratings')[fileID = "${String(res_id)}"].Rating), 2)`
        ).then((data: any) => {
          setAvgRating(data);
        });
        toast({
          title: 'Rating added.',
          description: `Your resource rating of has been added.`,
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        setRating(newRatingDefault);
        getAllRowsById(resourcesRatings).then((data) => {
          setRatings(data);
          console.log(data);
          setComment('');
        });
      });
    } catch {
      // toast({
      //   title: 'Notes error',
      //   description: 'You note was not added to the system. Please email the systems administrator',
      //   status: 'error',
      //   duration: 6000,
      //   isClosable: true,
      // });
    }
  };
  const accountType =
    localStorage.getItem('accountType') !== undefined && localStorage.getItem('accountType');
  const containerWidth = accountType === 'School' ? '100%' : '80%';
  const fileId = '1JA5ffjoRpdWZh1h6k2yyYsay0mlrDmyccS4fF1i_UFs';
  // const MyDoc = (
  //   <Document>
  //     <Page>// My document data</Page>
  //   </Document>
  // );
  const opts = {
    height: '390px',
    width: '640px',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white" id="protected-data">
          <Box p={2} mb={5}>
            <HStack spacing={5}>
              {/* <Button onClick={() => navigate('../dashboard-therapist')} variant={'defaultButton'}>
                Back
              </Button> */}
              <BackButton navigate={navigate} />

              <Heading pb={6} mt={6}>
                {fileName && fileName}
              </Heading>
            </HStack>
          </Box>
          <Box>
            <HStack alignItems={'flex-start'}>
              <Box width={'70%'}>
                <VStack>
                  {loadingIcon === 0 ? (
                    <Box
                      width={'100%'}
                      height={'30vh'}
                      alignContent={'center'}
                      display={'flex'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      pt={'15vh'}
                    >
                      <Spinner
                        thickness="8px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="brand.200"
                        size="xl"
                      />
                    </Box>
                  ) : (
                    <Box
                      width={'100%'}
                      height={'80vh'}
                      position={'relative'}
                      overflowY={'scroll'}
                      border={'3px solid gray.100'}
                    >
                      {downloadedFile !== null ? (
                        fileExt === 'pdf' || fileExt === 'undefined' || fileExt === 'pptx' ? (
                          <Worker
                            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                          >
                            <Document
                              file={downloadedFile}
                              onContextMenu={(e: any) => e.preventDefault()}
                            >
                              <Viewer fileUrl={downloadedFile} />
                              <Page pageNumber={1} />
                            </Document>
                          </Worker>
                        ) : fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' ? (
                          <Box
                            // data={downloadedFile + '#toolbar=0'}
                            backgroundColor={'gray.100'}
                            width={'100%'}
                            height={'100%'}
                            position={'relative'}
                            onContextMenu={(e: any) => e.preventDefault()}
                          >
                            <Box
                              backgroundImage={downloadedFile}
                              backgroundSize={'contain'}
                              backgroundRepeat={'no-repeat'}
                              backgroundPosition={'center'}
                              width={'100%'}
                              height={'100%'}
                            />
                            <Box
                              backgroundColor={'transparent'}
                              width={'100%'}
                              height={'100%'}
                              position={'absolute'}
                              top={0}
                              left={0}
                              id="protected-data-iframe"
                              onContextMenu={(e: any) => e.preventDefault()}
                            />
                          </Box>
                        ) : fileExt === 'mp4' ? (
                          <video
                            controls
                            width={'90%'}
                            controlsList="nodownload"
                            onContextMenu={(e: any) => e.preventDefault()}
                          >
                            <source src={downloadedFile} type="video/mp4" />
                          </video>
                        ) : fileExt === 'webm' ? (
                          <video
                            controls
                            width={'90%'}
                            controlsList="nodownload"
                            onContextMenu={(e: any) => e.preventDefault()}
                          >
                            <source src={downloadedFile} type="video/webm" />
                          </video>
                        ) : (
                          'No material'
                        )
                      ) : (
                        fileExt === 'youtube' && (
                          <YouTube videoId={res_id!} opts={opts} className={'youtubeIframe'} />
                        )
                      )}
                    </Box>
                  )}
                  <br></br>
                </VStack>
                {/* <Box>
                  Save: Onclick add row to api with resource id and email Onload retrieve rows from
                  saved table where email === loggedInUseremail and resource_id === current resource
                  Comment: List comments get all rows where resource_id === current resource. Rate:
                  Show stars, onclick insert star count into rating table with resource _id, user
                  email and selected rating Onload get all ratings by resource id
                </Box> */}
              </Box>
              <Box width={'30%'} pl={10} bg={'gray.100'} p={5} position={'relative'}>
                {/* <Text>
                  save id :{' '}
                  {JSON.stringify(
                    allSavedResources?.filter((i: any) => i.fields['resourceId'] === res_id)
                      .length <= 1 && allSavedResources[0]
                  )}
                </Text>
                <Text>
                  {JSON.stringify(
                    allSavedResources.filter((i: any) => i.fields['resourceId'] === res_id).length
                  )}
                </Text>
                <Text>
                  {JSON.stringify(
                    allSavedResources.filter((i: any) => i.fields['resourceId'] === res_id)
                  )}
                </Text> */}
                {/* <Text>SAVED: {saved}</Text> */}
                <Box
                  textAlign={'left'}
                  // bg={'brand.300'}
                  borderRadius={10}
                  color={'white'}
                  p={2}
                  cursor={'pointer'}
                  // _hover={{
                  //   background: 'brand.400',
                  // }}
                  w={'50px'}
                  height={'50px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  position={'absolute'}
                  top={4}
                  right={8}
                >
                  {saved === 0 ? (
                    <BsBookmark
                      fontSize={30}
                      onClick={(e) =>
                        allSavedResources?.length === 0
                          ? saveResource(e, saved)
                          : deleteSavedResource(e, allSavedResources[0].id)
                      }
                      fill={'brand.300'}
                    />
                  ) : (
                    <BsFillBookmarkFill
                      fontSize={30}
                      onClick={(e) =>
                        allSavedResources?.length === 0
                          ? saveResource(e, saved)
                          : deleteSavedResource(e, allSavedResources[0].id)
                      }
                      fill={'brand.300'}
                    />
                  )}
                </Box>
                <Tabs
                  variant="soft-rounded"
                  colorScheme="green"
                  onChange={(index) => setTabIndex(index)}
                  defaultIndex={tdIndex}
                >
                  <TabList>
                    <Tab>Video</Tab>
                    <Tab>Ratings & Reviews</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <HStack>
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'left'}
                          bg={'brand.200'}
                          borderRadius={10}
                          color={'white'}
                          p={5}
                          h={'160px'}
                          w={'50%'}
                        >
                          <Text fontWeight={'bold'}>Views</Text>
                          <Box justifyContent={'center'} alignItems={'center'}>
                            <Text fontSize={30} fontWeight={'bold'}>
                              {resourceViewsCount && resourceViewsCount}
                            </Text>
                          </Box>
                        </Box>
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'left'}
                          bg={'brand.300'}
                          borderRadius={10}
                          color={'white'}
                          p={5}
                          h={'160px'}
                          w={'50%'}
                        >
                          <Text fontWeight={'bold'}>Avg. rating</Text>
                          <Box justifyContent={'center'} alignItems={'center'}>
                            <VStack>
                              <Text fontSize={30} fontWeight={'bold'}>
                                {avgRating}
                              </Text>
                              <RatingStarsAverage
                                size={20}
                                icon="StarIcon"
                                scale={5}
                                fillColor="gold"
                                strokeColor="gold"
                                ratingScore={avgRating === 'NaN' ? avgRating : avgRating}
                              />
                            </VStack>
                          </Box>
                        </Box>
                      </HStack>
                      <Box w={'100%'} mt={5}>
                        <Heading size={'md'} fontWeight={'bold'}>
                          Rate this video
                        </Heading>
                        <RatingStars
                          size={48}
                          icon="StarIcon"
                          scale={5}
                          fillColor="gold"
                          strokeColor="grey"
                          ratingScore={ratingScore}
                          setRatingScore={setRatingScore}
                        />
                        <Textarea
                          // value={value}
                          // onChange={handleInputChange}
                          isRequired
                          placeholder="Enter your note details here"
                          size="3xl"
                          p={'5'}
                          borderRadius={10}
                          background={'white'}
                          mt={5}
                          onChange={(e) => setComment(e.target.value)}
                          value={comment}
                        />
                        <Box mt={2} textAlign={'right'} mb={5}>
                          <Button
                            variant={'defaultButton'}
                            onClick={(e) => {
                              addRating(e);
                            }}
                          >
                            Add Rating
                          </Button>
                        </Box>
                      </Box>
                    </TabPanel>
                    <TabPanel>
                      <Heading size={'md'} mb={5}>
                        Reviews & Ratings
                      </Heading>
                      <Box
                        h={'350px'}
                        // bg="gray.200"
                        borderRadius={5}
                        mt={10}
                        overflowY={'scroll'}
                        textAlign={'left'}
                      >
                        {ratings
                          .sort((a: any, b: any) => b.id - a.id)
                          .filter((i: any) => i.fields['fileID'] === res_id)
                          .map((i: any, k: any) => (
                            <Box bg={'white'} p={5} borderRadius={5} mb={5} textAlign={'left'}>
                              <VStack
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'left'}
                                alignItems={'left'}
                              >
                                <Box bg={'brand.300'} pl={5} pr={5} pt={1} borderRadius={22}>
                                  <RatingStarsAverage
                                    size={20}
                                    icon="StarIcon"
                                    scale={5}
                                    fillColor="gold"
                                    strokeColor="gold"
                                    ratingScore={
                                      avgRating === 'NaN' ? avgRating : i.fields['Rating']
                                    }
                                  />
                                </Box>

                                <Text fontWeight={'bold'} mt={1}>
                                  Rated: {i.fields['Rating']}/5
                                </Text>
                              </VStack>
                              <Divider pt={5} />
                              <Text pt={5}>{i.fields['commentOnRating']}</Text>
                            </Box>
                          ))}
                        {ratings.filter((i: any) => i.fields['fileID'] === res_id).length === 0 && (
                          <Box fontWeight={'bold'}>No reviews or ratings received</Box>
                        )}
                      </Box>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            </HStack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentVideoSingle;
