import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Select,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { DashboardVideoList } from './../components/DashboardVideoList';
import { BackButton } from './BackButton';
import postDataToEndPoint from '../utils/postDataToEndPoint';

const Help = ({ signedInUserId, signedInUserEmail, userMeta }: SignedInUserProps, props: any) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [data, setData] = useState<any>({
    accountType: localStorage.getItem('accountType'),
    accountEmail: localStorage.getItem('accountEmail'),
    issueSubject: '',
    issueType: '',
    issueDescription: '',
  });
  const helpEP = process.env.REACT_APP_HELP_EP;
  function sendDataToMake() {
    postDataToEndPoint(helpEP, data).then((data: any) => {
      data.data === 'test' &&
        setData({
          accountType: localStorage.getItem('accountType'),
          accountEmail: localStorage.getItem('accountEmail'),
          issueSubject: '',
          issueType: '',
          issueDescription: '',
        });
      data.data === 'test' &&
        toast({
          title: 'Message sent',
          description: 'Your issue has been sent to the administrator.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      data === 'Scenario failed to initialize.' &&
        toast({
          title: 'Message NOT sent',
          description: 'There was an error. Please email the systems administrator',
          status: 'error',
          duration: 6000,
          isClosable: true,
        });
    });
  }

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box w="100%" height={'auto'} mt={10} p={6} bg="white">
          <Box py={6} bg="white">
            <HStack spacing={5} mb={10}>
              <BackButton navigate={navigate} />
              <Heading>Help</Heading>
            </HStack>
            <Box display={'flex'} flexDirection={'row'}>
              <Box width={'50%'} bg={'gray.100'} p={10} borderRadius={10}>
                {' '}
                <Heading as={'h3'} size={'md'} mb={10} mt={0} textAlign={'left'}>
                  Create a support ticket
                </Heading>
                <FormControl mb={'5'}>
                  <FormLabel>Issue type: </FormLabel>
                  <Select
                    onChange={(e: any) => {
                      setData({ ...data, issueType: e.target.value });
                    }}
                    isRequired
                    bg={'white'}
                  >
                    <option value="">{'Select issue  type'}</option>
                    <option value="Login issue">Login issue</option>
                    <option value="Invoice issue">Invoice issue</option>
                    <option value="Resource issue">Resource issue</option>
                    <option value="Session issue">Session issue</option>
                  </Select>
                </FormControl>
                <FormControl mb={'5'}>
                  <FormLabel>Subject:</FormLabel>
                  <Input
                    value={data.issueSubject}
                    onChange={(e: any) => {
                      setData({ ...data, issueSubject: e.target.value });
                    }}
                    isRequired
                    placeholder="What do you require help with ? "
                    bg={'white'}
                  />
                </FormControl>
                <FormControl mb={'5'}>
                  <FormLabel>Describe the problem you are having:</FormLabel>
                  <Textarea
                    isRequired
                    placeholder="Describe your issue here"
                    size="3xl"
                    p={'5'}
                    borderRadius={10}
                    background={'white'}
                    mt={5}
                    onChange={(e: any) => {
                      setData({ ...data, issueDescription: e.target.value });
                    }}
                    value={data.issueDescription}
                  />
                  <Box textAlign={'left'} mb={5} mt={5}>
                    <Button
                      variant={'defaultButton'}
                      onClick={(e) => {
                        sendDataToMake();
                      }}
                      isDisabled={
                        data.issueType.length <= 0 ||
                        data.issueSubject.length <= 0 ||
                        data.issueDescription.length <= 0
                          ? true
                          : false
                      }
                      _hover={{ backgroundColor: 'brand.200' }}
                    >
                      Submit
                    </Button>
                  </Box>
                </FormControl>
              </Box>
              <Box width={'50%'}>
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'100%'}
                  height={540}
                  overflowY={'hidden'}
                  pt={10}
                >
                  {' '}
                  <Heading as={'h3'} size={'md'} mb={10} mt={0} textAlign={'center'}>
                    Helpful videos
                  </Heading>
                  <Box width={'100%'} overflowY={'scroll'} height={'580px'} px={10}>
                    <DashboardVideoList
                      accountType={localStorage.getItem('accountType')!}
                      accessLevel={localStorage.getItem('accessLevel')!}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Help;
