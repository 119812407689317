import { Flex, FlexProps, Icon, Link } from '@chakra-ui/react';
import React from 'react';
import { IconType } from 'react-icons';

interface NavItemProps extends FlexProps {
  icon: IconType;
  children?: any;
  path?: string;
  name?: string;
  menuItemTitles?: number;
}
export const NavItem = ({ icon, children, name, path, menuItemTitles, ...rest }: NavItemProps) => {
  return (
    <Link
      href={path}
      style={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
      // aria-aria-label={menuItemTitles}
      target={
        name === 'Add session' || name === 'Reviews' || name === 'My Subscription'
          ? '_blank'
          : '_self'
      }
    >
      <Flex
        align="center"
        p="4"
        mx="2.5"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'brand.400',
          color: 'white',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="24"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
            color={'brand.300'}
          />
        )}
        {menuItemTitles === 1 && children}
      </Flex>
    </Link>
  );
};
