import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useDisclosure,
  useToast,
  Select,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  Stack,
  Switch,
} from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { clientFields } from '../constants/clientFields';
// import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { addStudent } from '../interfaces/addStudent';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import getAllRowsById from '../utils/getAllRowsById';
import { editStudent } from '../interfaces/editStudent';

import { BackButton } from './BackButton';
import { useNavigate } from 'react-router-dom';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: number | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

interface Note {
  Date: Date | string;
  Time: string;
  'Type of Contact': string | number;
  Notes: string;
  'Name and Role': string;
  Caseload: number | undefined;
}
const today = new Date(Date.now());
// console.log(today.format('YYYY-MM-DD'));
// console.log(clientFields?.fields.map((i: any, k: any) => i.name));
// const check = new Date().getTimezoneOffset(+60);
// const dateAndTimeBeforeOffset = new Date();
// const myOffset = dateAndTimeBeforeOffset.getTimezoneOffset();
// const today = new Date(dateAndTimeBeforeOffset.getTime() + myOffset);
function join(date: Date, options: any, separator: string) {
  function format(option: any) {
    let formatter = new Intl.DateTimeFormat('en', option);
    return formatter.format(date);
  }
  return options.map(format).join(separator);
}
let options = [{ year: 'numeric' }, { month: 'numeric' }, { day: 'numeric' }];
let joined = join(new Date(), options, '-');

function getTime(date: Date) {
  let theDate = date;
  let day = theDate.getDay();
  // console.log(day);
}
getTime(today);
function getTheDate(date: Date) {
  return join(date, options, '-');
}
// console.log(joined);
// .formatDate('D M dd yy', new Date(end_date));

const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;
const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;

const BookingsContentEditClient = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  const navigate = useNavigate();
  // updateUser(signedInUserId);
  // date: getTheDate(today),
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');
  const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

  const schoolsEP = schoolType === 'RegularSchool' ? regularSchoolsEP : senSchoolsEP;
  const studentDefault = {
    firstName: '',
    lastName: '',
    currentTherapistAssigned: '',
    ifReassignedPreviousTherapist: '',
    status: '',
    clientType: '',
    therapyLocation: '',
    adultOrChild: '',
    sex: '',
    dateOfBirth: '',
    ageEnquiry: '',
    parentsFirstName: '',
    parentsLastName: '',
    schoolOrEmployer: '',
    phone: '',
    mobile1: '',
    mobile2: '',
    primaryEmailAddress: '',
    email1: '',
    email2: '',
    address: '',
    anyOtherInformationPartialAddress: '',
    additionalPartyInvolved: '',
    additionalPartyDetails: '',
    haveYouSeenASpeechTherapist: '',
    typeOfSpeechTherapistSeen: '',
    doYouHaveAWrittenReportDate: '',
    equiryConcerns: '',
    assessmentUndertaken: '',
    assessmentDate: '',
    reAssessmentIfApplicable: '',
    reAssessmentDate: '',
    reportProvided: '',
    reportPaidFor: '',
    diagnosis: '',
    dianosisNotes: '',
    dateDischarged: '',
    dischargeLetterSent: '',
  };
  const [formData, setFormData] = useState<any>(studentDefault);
  const [revertDischarge, setRevertDischarge] = useState<number>(0);
  const studentToUpdate = {
    'First Name': formData.firstName,
    'Last Name': formData.lastName,
    'Current Therapist Assigned': formData.currentTherapistAssigned,
    'If reassigned, Previous Therapist?': formData.ifReassignedPreviousTherapist,
    Status: formData.status,
    'Client type': formData.clientType,
    'Therapy Location': formData.therapyLocation,
    'Adult or Child?': formData.adultOrChild,
    Sex: formData.sex,
    'Date of Birth': formData.dateOfBirth,
    'Age (Enquiry)': formData.ageEnquiry,
    'Parents First Name': formData.parentsFirstName,
    'Parents Last Name': formData.parentsLastName,
    'School or Employer': formData.schoolOrEmployer,
    Phone: formData.phone,
    'Mobile 1': formData.mobile1,
    'Mobile 2': formData.mobile2,
    'Primary Email Address': formData.primaryEmailAddress,
    'Email 1': formData.email1,
    'Email 2': formData.email2,
    Address: formData.address,
    'Any other information (i.e. partial address)': formData.anyOtherInformationPartialAddress,
    'Additonal party involved: Solicitor, Court, School, Local Authority?':
      formData.additionalPartyInvolved,
    'Additional party details': formData.additionalPartyDetails,
    'Have you seen a Speech Therapist': formData.haveYouSeenASpeechTherapist,
    'Type of Speech Therapist seen?': formData.typeOfSpeechTherapistSeen,
    'Do you have a written report? Date?': formData.doYouHaveAWrittenReportDate,
    'Enquiry - Concerns': formData.equiryConcerns,
    'Assessment undertaken?': formData.assessmentUndertaken,
    'Assessment Date': formData.assessmentDate,
    'Re-assessment - If applicable?': formData.reAssessmentIfApplicable,
    'Re-assessment Date': formData.reAssessmentDate,
    'Report provided?': formData.reportProvided,
    'Report paid for?': formData.reportPaidFor,
    Diagnosis: formData.diagnosis,
    'Diagnosis (Notes)': formData.dianosisNotes,
    'Date discharged': formData.dateDischarged,
    'Discharge letter sent': formData.dischargeLetterSent,

    // 'School Name': form.schoolName,
    'Student/Patient Name': formData.studentPatientName,
    DOB: formData.DOB,
    'Class year': formData.classYear,
    Gender: formData.gender,
    Ethnicity: formData.ethnicity,
    'Date of Assessment': formData.dateOfAssessment,
    'Report date': formData.reportDate,
    'Report sent?': formData.reportSent,
    'Report Link': formData.reportLink,
    'Date of review': formData.dateOfReview,
    'Mainstream School': formData.mainstreamSchool,
    'Other Medical Diagnosis': formData.otherMedicalDiagnosis,
    'Class name': formData.className,
    'Other Communication/ Swallowing Disorder': formData.otherCommunicationSwallowingDisorder,
    'Name of Therapist': formData.nameOfTherapist,
    EHCP: formData.ehcp,
    Plan: formData.plan,
    Notes: formData.notes,
    'Referral Date': formData.referralDate,
    'Local Patient Identifier': formData.localPatientIdentifier,
    'TOMs rating': formData.tomsRating,
    'End of Episode/ Discharge Code/ Description': formData.endOfEpisodeDischargeCodeDescription,
    'Date of Discharge': formData.dateOfDischarge,
    'Sessions + Notes': formData.sessionsNotes,
    'Direct Hours': formData.directHours,
    'Indirect Hours': formData.indirectHours,
    'Hours completed': formData.hoursCompleted,
    'Hours remaining': formData.hoursRemaining,
    'Primary Medical Diagnosis': formData.primaryMedicalDiganosis,
    'Additional Medical Diagnosis': formData.additonalMedicalDiagnosis,
    'Primary Communication/ Swallowing Disorder': formData.primaryCommunicationsSwallowingDisorder,
    'Additional Communication/ Swallowing Disorder':
      formData.additionalCommunicationSwallowingDisorder,
    'Primary TOMs Scale': formData.primaryTomsScale,
    'Secondary TOMs Scale': formData.secondaryTomsScale,
    'Language 1': formData.language1,
    'Language 2': formData.language2,
    'Language 3': formData.language3,
    'Other Languages': formData.otherLanguages,
  };
  const defaultForm = {
    studentPatientName: '',
    dob: '01/02/03',
    classYear: 'none',
    mainstreamSchool: '',
    localPatientIdentifier: '',
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [dateAndTime, setDateAndTime] = useState<any>(today);
  const [notes, setNotes] = useState<any>([]);
  const [txs, setTxs] = useState<any>([]);
  const [newNote, setNewNote] = useState<any>();
  const { onOpen, onToggle } = useDisclosure();
  const [size, setSize] = useState('md');
  const [rows, setRows] = useState<any>([]);
  const [sessionNotes, setSessionNotes] = useState<any>([]);
  const [form, setForm] = useState<addStudent>(defaultForm);

  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allTherapistsEP = process.env.REACT_APP_ALL_THERAPISTS_EP;
  const userStats = userMeta && userMeta?.stats;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const toast = useToast();
  const userNotes = notes?.filter((i: any) => i?.fields?.session_id == sessionId);

  const updateStudent = (e: any) => {
    try {
      ninoxTableUpdateRow(
        clientsListEP!,
        Number(sessionId),
        { fields: studentToUpdate },
        String(bearerToken)
      ).then(() => {
        toast({
          title: 'Client udpated.',
          description: 'The client data has been successfully updated.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Update error',
        description:
          'The client was note updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    ninoxTableGetRow(clientsListEP!, Number(sessionId), bearerToken).then((data: any) => {
      console.log(data);
      const formLoadData = {
        firstName: data?.fields['First Name'],
        lastName: data?.fields['Last Name'],
        currentTherapistAssigned: data?.fields['Current Therapist Assigned'],
        ifReassignedPreviousTherapist: data?.fields['If reassigned, Previous Therapist?'],
        status: data?.fields['Status'],
        clientType: data?.fields['Client type'],
        therapyLocation: data?.fields['Therapy Location'],
        adultOrChild: data?.fields['Adult or Child?'],
        sex: data?.fields['Sex'],
        dateOfBirth: data?.fields['Date of Birth'],
        ageEnquiry: data?.fields['Age (Enquiry)'],
        parentsFirstName: data?.fields['Parents First Name'],
        parentsLastName: data?.fields['Parents Last Name'],
        schoolOrEmployer: data?.fields['School or Employer'],
        phone: data?.fields['Phone'],
        mobile1: data?.fields['Mobile 1'],
        mobile2: data?.fields['Mobile 2'],
        primaryEmailAddress: data?.fields['Primary Email Address'],
        email1: data?.fields['Email 1'],
        email2: data?.fields['Email 2'],
        address: data?.fields['Address'],
        anyOtherInformationPartialAddress:
          data?.fields['Any other information (i.e. partial address)'],
        additionalPartyInvolved:
          data?.fields['Additonal party involved: Solicitor, Court, School, Local Authority?'],
        additionalPartyDetails: data?.fields['Additional party details'],
        haveYouSeenASpeechTherapist: data?.fields['Have you seen a Speech Therapist'],
        typeOfSpeechTherapistSeen: data?.fields['Type of Speech Therapist seen?'],
        doYouHaveAWrittenReportDate: data?.fields['Do you have a written report? Date?'],
        equiryConcerns: data?.fields['Enquiry - Concerns'],
        assessmentUndertaken: data?.fields['Assessment undertaken?'],
        assessmentDate: data?.fields['Assessment Date'],
        reAssessmentIfApplicable: data?.fields['Re-assessment - If applicable?'],
        reAssessmentDate: data?.fields['Re-assessment Date'],
        reportProvided: data?.fields['Report provided?'],
        reportPaidFor: data?.fields['Report paid for?'],
        diagnosis: data?.fields['Diagnosis'],
        dianosisNotes: data?.fields['Diagnosis (Notes)'],
        dateDischarged: data?.fields['Date discharged'],
        dischargeLetterSent: data?.fields['Discharge letter sent'],
      };

      setFormData(formLoadData);
      // console.log(formLoadData);
      // console.log(schoolType);
    });
    getAllRowsById(allTherapistsEP!).then((data: any) => {
      const myData = data;
      setTxs(myData);
    });
    window.scrollTo(0, 0);
  }, []);

  const oldDate = '2023-09-02T15:00:00';

  const handleTypeOfContact = (e: any) => {
    setNewNote({ ...newNote, 'Type of Contact': e.target.value });
  };
  const studentClasses = ['N', 'R', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const roles = ['SLT', 'SLTA'];

  const studenFieldNames = [
    'Student/Patient Name',
    'DOB',
    'Class year',
    'Gender',
    'Ethnicity',
    'Date of Assessment',
    'Report date',
    'Report sent?',
    'Report Link',
    'Date of review',
    'Mainstream School',
    'Other Medical Diagnosis',
    'Class name',
    'Other Communication/ Swallowing Disorder',
    'Name of Therapist',
    'EHCP',
    'Plan',
    'Notes',
    'Referral Date',
    'Local Patient Identifier',
    'TOMs rating',
    'End of Episode/ Discharge Code/ Description',
    'Date of Discharge',
    'Sessions + Notes',
    'Direct Hours',
    'Indirect Hours',
    'Hours completed',
    'Hours remaining',
    'Primary Medical Diagnosis',
    'Additional Medical Diagnosis',
    'Primary Communication/ Swallowing Disorder',
    'Additional Communication/ Swallowing Disorder',
    'Primary TOMs Scale',
    'Secondary TOMs Scale',
    'Language 1',
    'Language 2',
    'Language 3',
    'Other Languages',
  ];

  const ifReassignedPreviousTherapistChoices = clientFields.fields.filter(
    (i: any) => i.name === 'If reassigned, Previous Therapist?'
  )[0].choices;
  const typeOfSpeechTherapistSeenChoices = clientFields.fields.filter(
    (i: any) => i.name === 'Type of Speech Therapist seen?'
  )[0].choices;
  const statusChoices = clientFields.fields.filter((i: any) => i.name === 'Status')[0].choices;
  const clientTypeChoices = clientFields.fields.filter((i: any) => i.name === 'Client type')[0]
    .choices;
  const tlChoices = clientFields.fields.filter((i: any) => i.name === 'Therapy Location')[0]
    .choices;
  const aocChoices = clientFields.fields.filter((i: any) => i.name === 'Adult or Child?')[0]
    .choices;
  const sexChoices = clientFields.fields.filter((i: any) => i.name === 'Sex')[0].choices;
  // const dateOfBirthChoices = clientFields.fields.filter((i: any) => i.name === 'Date of birth')[0]
  //   .choices;
  const additionalPartyInvolvedChoices = clientFields.fields.filter(
    (i: any) => i.name === 'Additonal party involved: Solicitor, Court, School, Local Authority?'
  )[0].choices;
  const haveYouSeenASpeechTherapistChoices = clientFields.fields.filter(
    (i: any) => i.name === 'Have you seen a Speech Therapist'
  )[0].choices;
  const assessmentUndertakenChoices = clientFields.fields.filter(
    (i: any) => i.name === 'Assessment undertaken?'
  )[0].choices;
  const reAssessmentIfApplicableChoices = clientFields.fields.filter(
    (i: any) => i.name === 'Re-assessment - If applicable?'
  )[0].choices;
  const diagnosisChoices = clientFields.fields.filter((i: any) => i.name === 'Diagnosis')[0]
    .choices;

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Box p={2} mb={5}>
            <HStack spacing={5}>
              <BackButton navigate={navigate} />
              <Heading pb={6} mt={6}>
                Edit Client: {formData?.firstName} {formData?.lastName}
              </Heading>
            </HStack>
          </Box>
          {/* {JSON.stringify(formData)} */}
          {/* {JSON.stringify(
            clientFields?.fields.filter((i: any) => i.name === 'Class year')[0].choices
          )} */}
          {/* {JSON.stringify(clientFields.fields.map((i: any, k: any) => i.name + ' : ' + i.type))} */}

          <Flex>
            <Box w={'100%'} p={'5'}>
              <Tabs variant="soft-rounded" colorScheme="green">
                <TabList>
                  <Tab>Client</Tab>
                  <Tab>Contact</Tab>
                  <Tab>Therapist</Tab>
                  <Tab>Session Data</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>First Name </FormLabel>
                      <Input
                        name="studentName"
                        value={formData?.firstName}
                        onChange={(e: any) =>
                          setFormData({ ...formData, firstName: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Last Name </FormLabel>
                      <Input
                        name="studentName"
                        value={formData?.lastName}
                        onChange={(e: any) =>
                          setFormData({ ...formData, lastName: e.target.value })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Date of birth </FormLabel>
                      <Input
                        name="dateOfBirth"
                        value={formData?.dateOfBirth}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({ ...formData, dateOfBirth: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Age (Enquiry): </FormLabel>
                      <Input
                        name="ageEnquiry"
                        value={formData?.ageEnquiry}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({ ...formData, ageEnquiry: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Parents First Name: </FormLabel>
                      <Input
                        name="parentsFirstName"
                        value={formData?.parentsFirstName}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({ ...formData, parentsFirstName: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Parents Last Name: </FormLabel>
                      <Input
                        name="parentsLastName"
                        value={formData?.parentsLastName}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({ ...formData, parentsLastName: e.target.value })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>School or Employer </FormLabel>
                      <Input
                        name="schoolOrEmployer"
                        value={formData?.schoolOrEmployer}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({ ...formData, schoolOrEmployer: e.target.value })
                        }
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>Phone </FormLabel>
                      <Input
                        name="phone"
                        value={formData?.phone}
                        type="text"
                        onChange={(e: any) => setFormData({ ...formData, phone: e.target.value })}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Mobile 1 </FormLabel>
                      <Input
                        name="mobile1"
                        value={formData?.mobile1}
                        type="text"
                        onChange={(e: any) => setFormData({ ...formData, mobile1: e.target.value })}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Mobile 2 </FormLabel>
                      <Input
                        name="mobile2"
                        value={formData?.mobile2}
                        type="text"
                        onChange={(e: any) => setFormData({ ...formData, mobile2: e.target.value })}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Primary Email Address</FormLabel>
                      <Input
                        name="primaryEmailAddress"
                        value={formData?.primaryEmailAddress}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({ ...formData, primaryEmailAddress: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Email 1</FormLabel>
                      <Input
                        name="email1"
                        value={formData?.email1}
                        type="text"
                        onChange={(e: any) => setFormData({ ...formData, email1: e.target.value })}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Email 2</FormLabel>
                      <Input
                        name="email2"
                        value={formData?.email2}
                        type="text"
                        onChange={(e: any) => setFormData({ ...formData, email2: e.target.value })}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Address</FormLabel>
                      <Input
                        name="address"
                        value={formData?.address}
                        type="text"
                        onChange={(e: any) => setFormData({ ...formData, address: e.target.value })}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Any other information (i.e. partial address)</FormLabel>
                      <Input
                        name="anyOtherInformationPartialAddress"
                        value={formData?.anyOtherInformationPartialAddress}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            anyOtherInformationPartialAddress: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>
                        Additonal party involved: Solicitor, Court, School, Local Authority?
                      </FormLabel>
                      <Select
                        isRequired
                        name="additionalPartyInvolved"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            additionalPartyInvolved: e.target.value,
                          })
                        }
                        value={formData?.additionalPartyInvolved}
                      >
                        {additionalPartyInvolvedChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Additional party details</FormLabel>
                      <Input
                        name="additionalPartyDetails"
                        value={formData?.additionalPartyDetails}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            additionalPartyDetails: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>Current Therapist Assigned: </FormLabel>
                      <Select
                        isRequired
                        name="currentTherapistAssigned"
                        onChange={(e: any) =>
                          setFormData({ ...formData, currentTherapistAssigned: e.target.value })
                        }
                        value={formData?.currentTherapistAssigned}
                      >
                        {txs &&
                          txs?.map((i: any, k: number) => (
                            <option value={i?.id} key={k}>
                              {i?.fields['First Name'] + ' ' + i?.fields['Surname']}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>If reassigned, Previous Therapist?</FormLabel>
                      <Select
                        isRequired
                        name="ifReassignedPreviousTherapist"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            ifReassignedPreviousTherapist: e.target.value,
                          })
                        }
                        value={formData?.ifReassignedPreviousTherapist}
                      >
                        {txs &&
                          txs?.map((i: any, k: number) => (
                            <option
                              value={i?.fields['First Name'] + ' ' + i?.fields['Surname']}
                              key={k}
                            >
                              {i?.fields['First Name'] + ' ' + i?.fields['Surname']}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Status </FormLabel>
                      <Select
                        isRequired
                        name="status"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            status: e.target.value,
                          })
                        }
                        value={formData?.status}
                      >
                        {statusChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Client type </FormLabel>
                      <Select
                        isRequired
                        name="clientType"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            clientType: e.target.value,
                          })
                        }
                        value={formData?.clientType}
                      >
                        {clientTypeChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Therapy location</FormLabel>
                      <Select
                        isRequired
                        name="therapyLocation"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            therapyLocation: e.target.value,
                          })
                        }
                        value={formData?.therapyLocation}
                      >
                        {tlChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Adult or Child: </FormLabel>
                      <Select
                        isRequired
                        name="adultOrChild"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            adultOrChild: e.target.value,
                          })
                        }
                        value={formData?.adultOrChild}
                      >
                        {aocChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Sex </FormLabel>
                      <Select
                        isRequired
                        name="adultOrChild"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            sex: e.target.value,
                          })
                        }
                        value={formData?.sex}
                      >
                        {sexChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Have you seen a Speech Therapist</FormLabel>
                      <Select
                        isRequired
                        name="haveYouSeenASpeechTherapist"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            haveYouSeenASpeechTherapist: e.target.value,
                          })
                        }
                        value={formData?.haveYouSeenASpeechTherapist}
                      >
                        {haveYouSeenASpeechTherapistChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>
                        Type of Speech Therapist seen? {formData?.typeOfSpeechTherapistSeen}
                      </FormLabel>

                      <Select
                        isRequired
                        name="typeOfSpeechTherapistSeen"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            typeOfSpeechTherapistSeen: e.target.value,
                          })
                        }
                        value={formData?.typeOfSpeechTherapistSeen}
                      >
                        {typeOfSpeechTherapistSeenChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Do you have a written report? Date?</FormLabel>
                      <Input
                        name="doYouHaveAWrittenReportDate"
                        value={formData?.doYouHaveAWrittenReportDate}
                        type="text"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            doYouHaveAWrittenReportDate: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Enquiry - Concerns</FormLabel>
                      <Textarea
                        name="equiryConcerns"
                        value={formData?.equiryConcerns}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            equiryConcerns: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Assessment undertaken?</FormLabel>
                      <Select
                        isRequired
                        name="assessmentUndertaken"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            assessmentUndertaken: e.target.value,
                          })
                        }
                        value={formData?.assessmentUndertaken}
                      >
                        {assessmentUndertakenChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>Assessment Date</FormLabel>
                      <Input
                        name="assessmentDate"
                        value={formData?.assessmentDate}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            assessmentDate: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Re-assessment - If applicable?</FormLabel>
                      <Select
                        isRequired
                        name="reAssessmentIfApplicable"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            reAssessmentIfApplicable: e.target.value,
                          })
                        }
                        value={formData?.reAssessmentIfApplicable}
                      >
                        {reAssessmentIfApplicableChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Re-assessment Date</FormLabel>
                      <Input
                        name="reAssessmentDate"
                        value={formData?.reAssessmentDate}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            reAssessmentDate: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>'Report provided?</FormLabel>
                      <Select
                        isRequired
                        name="reportProvided"
                        onChange={(e: any) =>
                          setFormData({ ...formData, reportProvided: e.target.value })
                        }
                        value={formData?.reportProvided}
                      >
                        <option value={''}>{'Select an option'}</option>
                        <option value={'true'}>{'True'}</option>
                        <option value={'false'}>{'False'}</option>
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Report paid for?</FormLabel>
                      <Select
                        isRequired
                        name="reportPaidFor"
                        onChange={(e: any) =>
                          setFormData({ ...formData, reportPaidFor: e.target.value })
                        }
                        value={formData?.reportPaidFor}
                      >
                        <option value={''}>{'Select an option'}</option>
                        <option value={'true'}>{'True'}</option>
                        <option value={'false'}>{'False'}</option>
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Diagnosis</FormLabel>
                      <Select
                        isRequired
                        name="diagnosis"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            diagnosis: e.target.value,
                          })
                        }
                        value={formData?.diagnosis}
                      >
                        {diagnosisChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Diagnosis (Notes)</FormLabel>
                      <Textarea
                        name="dianosisNotes"
                        value={formData?.dianosisNotes}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            dianosisNotes: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Date discharged</FormLabel>
                      <Input
                        name="dateDischarged"
                        value={formData?.dateDischarged}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            dateDischarged: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Discharge letter sent</FormLabel>
                      <Select
                        isRequired
                        name="dischargeLetterSent"
                        onChange={(e: any) =>
                          setFormData({ ...formData, dischargeLetterSent: e.target.value })
                        }
                        value={formData?.dischargeLetterSent}
                      >
                        <option value={''}>{'Select an option'}</option>
                        <option value={'true'}>{'True'}</option>
                        <option value={'false'}>{'False'}</option>
                      </Select>
                    </FormControl>
                  </TabPanel>
                </TabPanels>
              </Tabs>

              <Box textAlign={'right'}>
                <FormControl>
                  <Button
                    onClick={(e) => updateStudent(e)}
                    bg={'brand.300'}
                    _hover={{
                      bg: 'brand.200',
                    }}
                    color={'white'}
                    //   isDisabled={newNote.Notes.length === 0 ? true : false}
                    mt={'5'}
                  >
                    Update client
                  </Button>
                </FormControl>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentEditClient;
