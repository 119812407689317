import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  List,
  ListItem,
  Select,
  Stack,
  Text,
  Textarea,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { ContentState, EditorState } from 'draft-js';
import React, { ReactNode, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import './../constants/baseCssMessageCentreStyles.css';

import moment from 'moment';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
import UL_logo_large from './../logo/unlocking_language_logo_white.png';

import htmlToDraft from 'html-to-draftjs';
import { stateToHTML } from 'draft-js-export-html';

const MessagesContent = ({ signedInUserId, signedInUserEmail, userMeta }: SignedInUserProps) => {
  interface MessageFields {
    Data: string;
    Message: string;
    Number: string;
    'Data sent': string;
  }
  const toast = useToast();
  const [currentMsg, setCurrentMsg] = useState<any>('none');

  const accountTypesList = [
    { type: 'none', title: 'Select Account Type' },
    { type: 'Therapist Staff', title: 'Therapists' },
    { type: 'Mainstream', title: 'Mainstream Schools' },
    { type: 'SEN', title: 'SEN Schools' },
    { type: 'Private Client', title: 'Private Client' },
    { type: 'Subscriber', title: 'Subscribers' },
  ];
  const accountTypeSubList = [
    { type: 'none', title: 'Select Account Type Sub List' },
    { type: 'Private Client', title: 'Private Client' },
    { type: 'Intermediary', title: 'Intermediary' },
    { type: 'Tribunal', title: 'Tribunal' },
    { type: 'Local Authority', title: 'Local Authority' },
    { type: 'Focus Mental Health', title: 'Focus Mental Health' },
    { type: 'CYP', title: 'CYP' },
    { type: 'SEN Tutoring', title: 'SEN Tutoring' },
    { type: 'Barnet Local Authorities', title: 'Barnet Local Authorities' },
    { type: 'Havering Local Authorities', title: 'Havering Local Authorities' },
    { type: 'Hackney Local Authorities', title: 'Hackney Local Authorities' },
    { type: 'Enfield Local Authorities', title: 'Enfield Local Authorities' },
    { type: 'Tower Hamlets Local Authorities', title: 'Tower Hamlets Local Authorities' },
    { type: 'Newham Local Authorities', title: 'Newham Local Authorities' },
  ];
  const accessLevelList = [
    { type: 'none', title: 'Select Access Level' },
    { type: 'Free', title: 'Free' },
    { type: 'Bronze', title: 'Bronze' },
    { type: 'Silver', title: 'Silver' },
    { type: 'Gold', title: 'Gold' },
  ];
  const messageTypeList = [
    { type: 0, title: 'Draft' },
    { type: 1, title: 'Published' },
    { type: 2, title: 'Unpublish' },
  ];
  const messagesTableEP = process.env.REACT_APP_MESSAGES_TABLE;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;

  const [subject, setSubject] = useState<any>('');
  const [accountType, setAccountType] = useState<any>('none');
  const [accountTypeSub, setAccountTypeSub] = useState<any>('none');
  const [accessLevel, setAccessLevel] = useState<any>('none');
  const [editorStatus, setEditorStatus] = useState(EditorState.createEmpty());
  const [queryMsgData, setQueryMsgData] = useState<any>([]);
  const [editMsg, setEditMsg] = useState<any>(0);

  const deleteData = {
    fields: {
      saveStatus: 0,
    },
  };

  let newMessageDefault = {
    Subject: subject,
    Body: stateToHTML(editorStatus.getCurrentContent()),
    UsersThatReadMsg: [],
    UserNotifications: [],
    accountType: accountType,
    accountTypeSub: accountTypeSub,
    accessLevel: accessLevel,
    MessageStatus: 0,
  };
  const [newMessage, setNewMessage] = useState<any>(newMessageDefault);
  const handleAccountType = (e: any) => {
    setAccountType(e.target.value);
  };
  const handleAccountTypeSub = (e: any) => {
    setAccountTypeSub(e.target.value);
  };
  const handleAccessLevel = (e: any) => {
    setAccessLevel(e.target.value);
  };
  const today = new Date();
  const sendMessage = (e: any) => {
    try {
      console.log({
        fields: {
          Subject: subject,
          Body: stateToHTML(editorStatus.getCurrentContent()),
          UsersThatReadMsg: [],
          UserNotifications: [],
          accountType: accountType,
          accountTypeSubList: accountTypeSub,
          accessLevel: accessLevel,
          adminUser: localStorage.getItem('accountEmail'),
          'Date and Time': today,
          // MessageStatus: msgStatus,
        },
      });
      ninoxTableAddRow(
        messagesTableEP,
        {
          fields: {
            Subject: subject,
            Body: stateToHTML(editorStatus.getCurrentContent()),
            UsersThatReadMsg: [],
            UserNotifications: [],
            accountType: accountType,
            accountTypeSubList: accountTypeSub,
            accessLevel: accessLevel,
            adminUser: localStorage.getItem('accountEmail'),
            'Date and Time': today,
            // MessageStatus: msgStatus,
          },
        },
        bearerToken
      ).then((data) => {
        setNewMessage(newMessageDefault);
        setCurrentMsg('none');
        toast({
          title: 'Message successfully sent to usergroup.',
          description: 'Your message has been added to the system.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Message error',
        description:
          'You message was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const updateMessage = (e: any) => {
    try {
      ninoxTableUpdateRow(
        messagesTableEP! + '/',
        currentMsg.id,
        {
          fields: {
            Subject: subject,
            Body: stateToHTML(editorStatus.getCurrentContent()),
            UsersThatReadMsg: [],
            UserNotifications: [],
            accountType: accountType,
            accountTypeSubList: accountTypeSub,
            accessLevel: accessLevel,
            adminUser: localStorage.getItem('accountEmail'),
            'Date and Time': today,
            // MessageStatus: msgStatus,
          },
        },
        bearerToken!
      ).then((data) => {
        setCurrentMsg('none');
        toast({
          title: 'Message successfully updated.',
          description: 'Your message has been updated on the system.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Message error',
        description:
          'You message was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const GetMsg = (e: number) => {
    try {
      ninoxTableGetRow(messagesTableEP! + '/', e, bearerToken!).then((data: any) => {
        setEditMsg(false);
        setCurrentMsg(data);
        const contentBlock = htmlToDraft(data?.fields['Body']);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const newEditEditorState = EditorState.createWithContent(contentState);
        setEditorStatus(newEditEditorState);
        setSubject(data?.fields['Subject']);
        setAccessLevel(data?.fields['accessLevel']);
        setAccountType(data?.fields['accountType']);
        setAccountTypeSub(data?.fields['accountTypeSubList']);
      });
    } catch {
      toast({
        title: 'Message error',
        description:
          'You message was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const msgsAllClientsQuery = `concat((select 'Client App wide messaging'))`;

  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
  useEffect(() => {
    getAllRowsByEncodedQuery(clientsQueryEP, msgsAllClientsQuery).then((data: any) => {
      const msgArrayIds = data.split(', ');
      getMultipleApiData(messagesTableEP! + '/', bearerToken!, msgArrayIds).then((msgData: any) => {
        setQueryMsgData(msgData);
      });
    });
  }, []);

  useEffect(() => {
    getAllRowsByEncodedQuery(clientsQueryEP, msgsAllClientsQuery).then((data: any) => {
      const msgArrayIds = data.split(', ');
      getMultipleApiData(messagesTableEP! + '/', bearerToken!, msgArrayIds).then((msgData: any) => {
        setQueryMsgData(msgData);
      });
    });
  }, [currentMsg]);

  return (
    <>
      <Box ml={{ base: 0, md: 20 }}>
        {localStorage.getItem('accountType') === 'Admin' ? (
          <Box p={6} borderRadius={20} bg={'gray.100'}>
            <HStack>
              <Heading width="90%">Messages</Heading>
              <Box>
                <Button
                  variant={'defaultButton'}
                  onClick={() => {
                    setCurrentMsg('newMessage');
                    setNewMessage(newMessageDefault);
                    setEditorStatus(EditorState.createEmpty());
                    setAccountType('none');
                    setAccountTypeSub('none');
                    setAccessLevel('none');
                    setSubject('');
                  }}
                >
                  New Message
                </Button>
              </Box>
            </HStack>
            {/* <Box>subject:{JSON.stringify(subject)}</Box>
  <hr></hr>
  <Box>newMessage:{JSON.stringify(newMessage)}</Box>
  <hr></hr>
  <Box>currentMsg:{JSON.stringify(currentMsg)}</Box>
  <hr></hr>
  <Box>accountType:{JSON.stringify(accountType)}</Box>
  <hr></hr>
  <Box>accountTypeSub:{JSON.stringify(accountTypeSub)}</Box>
  <hr></hr>
  <Box>accessLevel:{JSON.stringify(accessLevel)}</Box>
  <hr></hr>
  <Box>{JSON.stringify(editorStatus)}</Box> */}

            <Box width={'100%'}>
              <Box borderRadius={20}>
                <HStack alignItems={'left'}>
                  <Box
                    width={'20%'}
                    height={'90vh'}
                    bg={'gray.100'}
                    borderRadius={20}
                    border={'2px solid #ddd'}
                    overflowY={'scroll'}
                    overflowX={'hidden'}
                    mt="5"
                  >
                    <VStack>
                      <List w="100%" m={0} borderRadius={20}>
                        {queryMsgData &&
                          queryMsgData
                            .sort((a: any, b: any) => b.id - a.id)
                            .map((i: any, k: any) => {
                              return (
                                <ListItem
                                  bg={currentMsg?.id === i.id ? 'gray.100' : 'white'}
                                  // borderBottom={'1px gray.300 solid'}
                                  borderBottom="2px solid #ccc"
                                  p={5}
                                  key={k}
                                  color={'black'}
                                  _hover={{ background: '#C6F6D5', cursor: 'pointer' }}
                                  listStyleType={'none'}
                                  onClick={() => {
                                    GetMsg(i.id);
                                  }}
                                >
                                  <Text m={0} fontWeight={'bold'}>
                                    {i?.fields['Subject']}
                                  </Text>
                                  <Text m={0} fontSize="xs">
                                    Message ID:{i?.id}
                                  </Text>
                                  <Text m={0} fontSize="xs">
                                    {i?.fields['accountType']}
                                  </Text>
                                  <Text m={0} fontSize="xs">
                                    {i?.fields['accessLevel']}
                                  </Text>
                                  <Text m={0} fontSize="xs">
                                    {moment(i?.createdAt).format('YYYY-MM-DD HH:MM')}
                                  </Text>
                                </ListItem>
                              );
                            })}
                      </List>
                    </VStack>
                  </Box>
                  <Box width={'80%'}>
                    {currentMsg === 'newMessage' ? (
                      <Box p={10}>
                        <Stack>
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel>Subject </FormLabel>
                            <Input
                              name="subject"
                              bg={'white'}
                              value={subject}
                              onChange={(e: any) => setSubject(e.target.value)}
                              width={'70%'}
                              isRequired
                            />
                          </FormControl>
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel>Account type: </FormLabel>
                            <Select
                              isRequired
                              name="accountType"
                              value={accountType}
                              bg={'white'}
                              mb={5}
                              onChange={(e: any) => handleAccountType(e)}
                              width={300}
                            >
                              {accountTypesList?.map((i: any, k: number) => (
                                <option value={i?.type} key={k}>
                                  {i.title}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          {accountType === 'Private Client' ? (
                            <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                              <FormLabel>Account type sub list: </FormLabel>
                              <Select
                                isRequired
                                name="acountTypeSubList"
                                value={accountTypeSub}
                                bg={'white'}
                                mb={5}
                                onChange={(e: any) => handleAccountTypeSub(e)}
                                width={300}
                              >
                                {accountTypeSubList?.map((i: any, k: number) => (
                                  <option value={i?.type} key={k}>
                                    {i.title}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ''
                          )}
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel>Access levels: </FormLabel>
                            <Select
                              isRequired
                              name="accessLevels"
                              value={accessLevel}
                              bg={'white'}
                              mb={5}
                              onChange={(e: any) => handleAccessLevel(e)}
                              width={300}
                            >
                              {accessLevelList?.map((i: any, k: number) => (
                                <option value={i?.type} key={k}>
                                  {i.title}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl mb={'5'} bg={'white'}>
                            <Box height={'50vh'} overflow={'scroll'}>
                              <Editor
                                editorState={editorStatus}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorStyle={{
                                  height: 300,
                                  margin: 12,
                                  borderWidth: 0.5,
                                  padding: 10,
                                  borderRadius: '2px',
                                }}
                                onEditorStateChange={(editorState: any) => {
                                  setEditorStatus(editorState);
                                }}
                              />
                              <Textarea
                                disabled
                                value={stateToHTML(editorStatus.getCurrentContent())}
                              />
                            </Box>
                          </FormControl>
                          <FormControl>
                            <Button
                              onClick={(e) => sendMessage(e)}
                              bg={'brand.300'}
                              _hover={{
                                bg: 'brand.200',
                              }}
                              color={'white'}
                              mt={'5'}
                              isDisabled={
                                subject === '' || accessLevel === 'none' || accountType === 'none'
                                  ? true
                                  : false
                              }
                            >
                              Publish message
                            </Button>
                          </FormControl>
                        </Stack>
                      </Box>
                    ) : currentMsg === 'none' ? (
                      <Box
                        height={'90vh'}
                        p={10}
                        width={'100%'}
                        bg={'white'}
                        borderRadius={20}
                        mt={5}
                      >
                        <Box>
                          <Flex flexDirection={'column'} alignItems={'end'}>
                            <Image
                              width="200px"
                              objectFit="cover"
                              src={UL_logo_large}
                              alt="Unlocking Language"
                            />
                          </Flex>

                          <Text fontWeight={'bold'} as={'h1'}>
                            Message Center
                          </Text>
                          <Text fontWeight={'bold'}>Here you can: </Text>
                          <List>
                            <ListItem>View the latest messages sent by the team</ListItem>
                            <ListItem>
                              Create new messages to publish to different user types on different
                              access levels
                            </ListItem>
                            <ListItem>Edit existing messages</ListItem>
                          </List>
                        </Box>
                      </Box>
                    ) : editMsg ? (
                      <Box p={10}>
                        <Stack>
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel>Subject </FormLabel>
                            <Input
                              name="subject"
                              bg={'white'}
                              value={subject}
                              onChange={(e: any) => setSubject(e.target.value)}
                              width={'70%'}
                              isRequired
                            />
                          </FormControl>
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel>Account type: </FormLabel>
                            <Select
                              isRequired
                              name="accountType"
                              value={accountType}
                              bg={'white'}
                              mb={5}
                              onChange={(e: any) => handleAccountType(e)}
                              width={300}
                            >
                              {accountTypesList?.map((i: any, k: number) => (
                                <option value={i?.type} key={k}>
                                  {i.title}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          {accountType === 'Private Client' ? (
                            <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                              <FormLabel>Account type sub list: </FormLabel>
                              <Select
                                isRequired
                                name="acountTypeSubList"
                                value={accountTypeSub}
                                bg={'white'}
                                mb={5}
                                onChange={(e: any) => handleAccountTypeSub(e)}
                                width={300}
                              >
                                {accountTypeSubList?.map((i: any, k: number) => (
                                  <option value={i?.type} key={k}>
                                    {i.title}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            ''
                          )}
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel>Access level: </FormLabel>
                            <Select
                              isRequired
                              name="accessLevel"
                              value={accessLevel}
                              bg={'white'}
                              mb={5}
                              onChange={(e: any) => handleAccessLevel(e)}
                              width={300}
                            >
                              {accessLevelList?.map((i: any, k: number) => (
                                <option value={i?.type} key={k}>
                                  {i.title}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl mb={'5'} bg={'white'}>
                            <Box height={'50vh'} overflow={'scroll'}>
                              <Editor
                                editorState={editorStatus}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                editorStyle={{
                                  height: 300,
                                  margin: 12,
                                  borderWidth: 0.5,
                                  padding: 10,
                                  borderRadius: '2px',
                                }}
                                onEditorStateChange={(editorState: any) => {
                                  setEditorStatus(editorState);
                                }}
                              />
                              <Textarea
                                disabled
                                value={stateToHTML(editorStatus.getCurrentContent())}
                              />
                            </Box>
                          </FormControl>
                          <FormControl width={300}>
                            <Text fontWeight={'normal'} mb={2}>
                              Posted by <strong>{currentMsg?.fields['adminUser']}</strong> on{' '}
                              <strong>{currentMsg?.fields['Date and Time']}</strong>
                            </Text>
                          </FormControl>
                          <FormControl>
                            <HStack>
                              <Button
                                onClick={(e) => updateMessage(e)}
                                bg={'brand.300'}
                                _hover={{
                                  bg: 'brand.200',
                                }}
                                color={'white'}
                                mt={'5'}
                              >
                                Update message
                              </Button>
                              <Button
                                onClick={(e) => setEditMsg(false)}
                                bg={'brand.300'}
                                _hover={{
                                  bg: 'brand.200',
                                }}
                                color={'white'}
                                mt={'5'}
                              >
                                Cancel
                              </Button>
                            </HStack>
                          </FormControl>
                        </Stack>
                      </Box>
                    ) : (
                      <Box p={10}>
                        <Stack>
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel fontWeight={'bold'}>Subject </FormLabel>
                            <Text>{subject}</Text>
                          </FormControl>
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel fontWeight={'bold'}>Account type: </FormLabel>
                            <Text>
                              {accountTypesList
                                ?.filter((z: any) => z.type === accountType)
                                .map((i: any, k: number) => i.title)}
                            </Text>
                          </FormControl>
                          {accountType === 'Private Client' ? (
                            <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                              <FormLabel fontWeight={'bold'}>Account type sub list: </FormLabel>
                              <Text>
                                {accountTypeSubList
                                  ?.filter((z: any) => z.type === accountTypeSub)
                                  .map((i: any, k: number) => i.title)}
                              </Text>
                            </FormControl>
                          ) : (
                            ''
                          )}
                          <FormControl mb={'5'} bg={'gray.100'} borderRadius={20}>
                            <FormLabel fontWeight={'bold'}>Access level: </FormLabel>
                            <Text>
                              {accessLevelList
                                ?.filter((z: any) => z.type === accessLevel)
                                .map((i: any, k: number) => i.title)}
                            </Text>
                          </FormControl>
                          <FormControl mb={'5'}>
                            <FormLabel fontWeight={'bold'}>
                              Preview of content seen by users:{' '}
                            </FormLabel>
                            <Box height={'50vh'} overflow={'scroll'} border={'dashed'}>
                              <Box
                                width={'100%'}
                                height={'90vh'}
                                p={10}
                                bg={'white'}
                                dangerouslySetInnerHTML={{
                                  __html:
                                    editorStatus && stateToHTML(editorStatus.getCurrentContent()),
                                }}
                                background={'white'}
                              />
                            </Box>
                          </FormControl>
                          <FormControl width={300} fontWeight={'bold'}>
                            <Text fontWeight={'normal'} mb={2}>
                              Posted by <strong>{currentMsg?.fields['adminUser']}</strong> on{' '}
                              <strong>{currentMsg?.fields['Date and Time']}</strong>
                            </Text>
                          </FormControl>
                          <FormControl>
                            <Button
                              onClick={(e) => setEditMsg(true)}
                              bg={'brand.300'}
                              _hover={{
                                bg: 'brand.200',
                              }}
                              color={'white'}
                              mt={'5'}
                            >
                              Edit message
                            </Button>
                          </FormControl>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </HStack>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box fontWeight={'bold'} p={10}>
            Page not found
          </Box>
        )}
      </Box>
    </>
  );
};

export default MessagesContent;
