export default async function uploadFileToClientUploadFolder(
  file: any,
  fileDescription: string,
  name: string,
  parentFolder: string,
  mimeType: string,
  fileSize: string
) {
  // const base64Response = await fetch(`${file}`);
  // const blob = await base64Response.blob();
  // const decodedData = window.atob(file);
  const requestBody = {
    file: file,
    mimeType: mimeType,
    description: fileDescription,
    name: name,
    parents: [parentFolder],
    size: fileSize,
  };
  // console.log('requestBody:', requestBody);

  // const requestOptions = {
  //   method: 'POST',
  //   body: JSON.stringify({ res_id: resource_id, fileExt: fExt }),
  // };

  const requestOptions = {
    method: 'POST',
    headers: {
      // Authorization: myToken,
      // 'Content-Type': mimeType,
      // 'Content-Type': 'application/json',
      // 'Content-Type': mimeType,
      'Content-Length': fileSize,
      // 'Content-Type': 'multipart/related; boundary="foo_bar_baz"'
      // 'Content-Transfer-Encoding': 'base64',
      // 'Content-Length': fileSize,
      // 'Content-Transfer-Encoding': 'base64',
    },
    body: JSON.stringify(requestBody),
    // body: JSON.stringify({
    //   file: file,
    //   mimeType: mimeType,
    //   description: fileDescription,
    //   parents: [parentFolder],
    // }),
  };
  const the_url = `https://www.googleapis.com/drive/v3/files?uploadType=media`;
  const the_serverless_url = `https://client-portal-serverless-functions.vercel.app/api/googleUploadClientFile`;

  const response = await fetch(
    // 'https://www.googleapis.com/upload/drive/v3/files?uploadType=media',
    the_serverless_url,
    requestOptions
  );
  const data = await response.json();
  // console.log('REQUEST BODY: ' + JSON.stringify(requestBody));
  // console.log('STATUS: ' + response.status);
  // console.log('DATA: ' + JSON.stringify(data));
  // console.log('THE FILE:', JSON.stringify(file));

  return data;
}
