import {
  Box,
  Container,
  Checkbox,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  Stack,
  Text,
  Textarea,
  useAccordion,
  HStack,
  List,
  ListItem,
  Image,
  Flex,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';
import UL_logo_large from './../logo/unlocking_language_logo_white.png';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { BackButton } from './BackButton';

// import htmlToDraft from 'html-to-draftjs';
// import draftToHtml from 'draftjs-to-html';
import { stateToHTML } from 'draft-js-export-html';
import { dropdownIndicatorCSS } from 'react-select/dist/declarations/src/components/indicators';
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

const MessagesContentUsers = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
}: SignedInUserProps) => {
  interface MessageFields {
    Data: string;
    Message: string;
    Number: string;
    'Data sent': string;
  }
  const toast = useToast();
  const navigate = useNavigate();

  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const messagesTableEP = process.env.REACT_APP_MESSAGES_TABLE;
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

  const [queryMsgData, setQueryMsgData] = useState<any>([]);
  const [readMsg, setReadMsg] = useState<any>([]);
  const [readMsgArray, setReadMsgArray] = useState<any>([]);
  const [emptyArray, setEmptyArray] = useState<any>(['empty']);

  const [currentMsg, setCurrentMsg] = useState<any>('none');

  // {useId: 1357, accountType: 'Private Client'}
  const test = [13, 1357];
  console.log(test.includes(13));
  const GetMsgAndMarkAsRead = (e: number, readOrNot: string) => {
    try {
      ninoxTableGetRow(messagesTableEP! + '/', e, bearerToken!).then((data: any) => {
        setCurrentMsg(data);
        setReadMsgArray([data?.fields['UsersThatReadMsg']]);
        console.log('Clicked msg:', data);
        const testidea =
          data?.fields['UsersThatReadMsg'] === undefined
            ? []
            : data?.fields['UsersThatReadMsg'].split(',');
        console.log('testidea:', testidea);
        let numArray = testidea.map(Number);
        console.log('numArray:', numArray);
        const currentUser = localStorage.getItem('accountNumber');
        data?.fields['UsersThatReadMsg'] === undefined
          ? ninoxTableUpdateRow(
              messagesTableEP! + '/',
              e,
              {
                fields: {
                  UsersThatReadMsg: [Number(currentUser)],
                },
              },
              bearerToken!
            )
          : !numArray.includes(Number(currentUser)) &&
            ninoxTableUpdateRow(
              messagesTableEP! + '/',
              e,
              {
                fields: {
                  UsersThatReadMsg: [...numArray, currentUser],
                },
              },
              bearerToken!
            );
      });
    } catch {
      toast({
        title: 'Message error',
        description:
          'You message was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const startDate = localStorage.getItem('messagesStart');
  let day = Number(moment(startDate).format('DD'));
  let month = Number(moment(startDate).format('MM'));
  let year = Number(moment(startDate).format('YYYY'));

  const msgsStdQuery = `concat((select 'Client App wide messaging')[contains(text(accountType), "${localStorage.getItem(
    'accountType'
  )}") and
contains(text(accessLevel), "${localStorage.getItem('accessLevel')}") and
'Date and Time' >= date(${year}, ${month}, ${day})])`;

  const msgsClientsQuery = `concat((select 'Client App wide messaging')[contains(text(accountType), "${localStorage.getItem(
    'accountType'
  )}") and
contains(text(accessLevel), "${localStorage.getItem('accessLevel')}") and
contains(text(accountTypeSubList), "${localStorage.getItem('accountTypeSubList')}") and
'Date and Time' >= date(${year}, ${month}, ${day})])`;

  const subListPresent = localStorage.getItem('accountTypeSubList');
  console.log('subListPresent:', subListPresent);
  useEffect(() => {
    getAllRowsByEncodedQuery(
      clientsQueryEP,
      localStorage.getItem('accountTypeSubList') === null ||
        localStorage.getItem('accountTypeSubList') === undefined
        ? msgsStdQuery
        : msgsClientsQuery
    ).then((data: any) => {
      console.log('QUERY DATA array:', JSON.stringify(data));
      let msgArrayIds = data === '' ? emptyArray : data?.split(', ');
      console.log('msgArrayIds length:', msgArrayIds.length);
      console.log('msgArrayIds:', msgArrayIds);
      console.log('emptyArray', emptyArray);

      msgArrayIds[0] !== 'empty'
        ? getMultipleApiData(messagesTableEP! + '/', bearerToken!, msgArrayIds).then(
            (msgData: any) => {
              // console.log('msgData', msgData);
              setQueryMsgData(msgData);
            }
          )
        : setQueryMsgData([]);
    });
  }, []);

  useEffect(() => {
    getAllRowsByEncodedQuery(
      clientsQueryEP,
      localStorage.getItem('accountTypeSubList') === null ||
        localStorage.getItem('accountTypeSubList') === undefined
        ? msgsStdQuery
        : msgsClientsQuery
    ).then((data: any) => {
      console.log('QUERY DATA  readMsgArray :', data);
      // let msgArrayIds = data.split(', ');
      let msgArrayIds = data === '' ? emptyArray : data?.split(', ');
      console.log('msgArrayIds:', msgArrayIds.length);
      msgArrayIds[0] !== 'empty' &&
        getMultipleApiData(messagesTableEP! + '/', bearerToken!, msgArrayIds).then(
          (msgData: any) => {
            console.log('msgData', msgData);
            setQueryMsgData(msgData);
          }
        );
    });
  }, [readMsgArray]);
  let value = 0;

  console.log('queryMsgData', queryMsgData);
  useEffect(() => {
    value =
      queryMsgData !== undefined &&
      queryMsgData.length > 0 &&
      queryMsgData?.filter(
        (i: any) =>
          //if UsersThatReadMsg is undefined
          i?.fields['UsersThatReadMsg'] === undefined ||
          !i?.fields['UsersThatReadMsg']
            .split(',')
            .map(Number)
            .includes(Number(localStorage.getItem('accountNumber')))
      ).length;
    localStorage.setItem('notificationsCount', String(value));
  }, [queryMsgData]);

  // Checkboxes to dropdowns
  // Sort latest sent
  // Admin panel all actions
  // Read receipts
  // Notifications
  // let notificationsCheck = [];

  // notificationsCheck =
  //   queryMsgData !== '' ||
  //   (queryMsgData?.fields['UsersThatReadMsg'] === undefined &&
  //     queryMsgData?.fields['UsersThatReadMsg'].split(','));
  // let itemArrayNotifications = notificationsCheck?.map(Number);
  return (
    <>
      <Container maxW="9xl">
        <Box ml={{ base: 0, md: 20 }}>
          <Box p={6} borderRadius={20} pt={10}>
            <HStack spacing={5} mb={5}>
              <BackButton navigate={navigate} />
              <Heading width="90%">Messages</Heading>
            </HStack>
            <HStack alignItems={'left'}>
              <Box width={'25%'} height={'90vh'} bg={'gray.100'} borderRadius={20}>
                <VStack>
                  <List w="100%" m={0} borderRadius={20}>
                    <ListItem
                      bg={'white'}
                      borderBottom={'1px gray.300 solid'}
                      p={5}
                      color={'black'}
                      _hover={{ background: 'gray.100', cursor: 'pointer' }}
                      listStyleType={'none'}
                      fontWeight={'bold'}
                      borderTopRadius={20}
                      onClick={() => {
                        setCurrentMsg('none');
                      }}
                    >
                      Total&nbsp; (
                      {queryMsgData !== undefined && queryMsgData.length > 0
                        ? queryMsgData.length
                        : 0}
                      ) / Unread &nbsp;(
                      {queryMsgData !== undefined && queryMsgData.length > 0
                        ? queryMsgData?.filter(
                            (i: any) =>
                              i?.fields['UsersThatReadMsg'] === undefined ||
                              !i?.fields['UsersThatReadMsg']
                                .split(',')
                                .map(Number)
                                .includes(Number(localStorage.getItem('accountNumber')))
                          ).length
                        : 0}
                      ){/* {JSON.stringify(queryMsgData.length)} */}
                    </ListItem>
                    <Box bg={'gray.100'} height={'80vh'} overflowY={'scroll'}>
                      {queryMsgData !== undefined && queryMsgData.length > 0 ? (
                        queryMsgData.map((i: any, k: any) => {
                          const testidea =
                            i?.fields['UsersThatReadMsg'] === undefined
                              ? []
                              : i?.fields['UsersThatReadMsg'].split(',');
                          console.log('testidea:', testidea);
                          let itemArray = testidea.map(Number);

                          return (
                            <ListItem
                              bg={currentMsg?.id === i.id ? 'gray.100' : 'white'}
                              borderBottom={'1px gray.300 solid'}
                              p={5}
                              key={k}
                              color={'black'}
                              _hover={{ background: 'gray.100', cursor: 'pointer' }}
                              listStyleType={'none'}
                              onClick={() => {
                                GetMsgAndMarkAsRead(
                                  i.id,
                                  itemArray.includes(Number(localStorage.getItem('accountNumber')))
                                    ? 'yes'
                                    : 'none'
                                );
                              }}
                              fontWeight={
                                itemArray.includes(Number(localStorage.getItem('accountNumber')))
                                  ? 'normal'
                                  : 'bold'
                              }
                              // fontWeight={
                              //   i?.fields['UsersThatReadMsg'] === undefined
                              //     ? ''
                              //     : [i?.fields['UsersThatReadMsg']]?.includes(i.id)
                              //     ? 'normal'
                              //     : 'bold'
                              // }
                            >
                              {i && i.id + ')' + i?.fields['Subject']}
                            </ListItem>
                          );
                        })
                      ) : (
                        <Box p={5}>No messages yet</Box>
                      )}
                    </Box>
                  </List>
                </VStack>
              </Box>
              <>
                {/* UsersThatReadMsg: [1357, 15, 34],
                      UserNotifications: [1357, 34], */}
                {currentMsg === 'none' ? (
                  <Box height={'90vh'} p={10} width={'100%'} bg={'white'} borderRadius={20}>
                    <Box>
                      <Flex flexDirection={'column'} alignItems={'end'}>
                        <Image
                          width="200px"
                          objectFit="cover"
                          src={UL_logo_large}
                          alt="Unlocking Language"
                        />
                      </Flex>

                      <Text fontWeight={'bold'} as={'h1'}>
                        Welcome to the your inbox - &nbsp;
                        {localStorage.getItem('firstName') !== undefined ||
                        localStorage.getItem('firstName') !== null
                          ? localStorage.getItem('firstName')
                          : '' + ' ' + localStorage.getItem('lastName') !== undefined ||
                            localStorage.getItem('lastName') !== null
                          ? localStorage.getItem('lastName')
                          : ''}
                      </Text>
                      <Text fontWeight={'bold'}>Here you can: </Text>
                      <List>
                        <ListItem>
                          View the latest messages from the Unlocking Language team
                        </ListItem>
                        <ListItem>Get access to discounts and vouchers</ListItem>
                        <ListItem>
                          Keep up with the latest new and updates for everything at Unlocking
                          Language
                        </ListItem>
                      </List>
                      {/* {JSON.stringify(
                          queryMsgData[0]?.fields['UsersThatReadMsg'] === undefined
                            ? 'Not yet read'
                            : 'Read'
                        )} */}
                    </Box>
                  </Box>
                ) : (
                  <Box
                    width={'100%'}
                    height={'90vh'}
                    p={10}
                    bg={'white'}
                    dangerouslySetInnerHTML={{
                      __html: currentMsg && currentMsg.fields['Body'],
                    }}
                    mb={10}
                    background={'white'}
                    borderRadius={20}
                  />
                )}
              </>
              {/* {queryMsgData?.fields['Body']} */}
              {/* {queryMsgData.map((i: any) => i.fields['Body'])} */}
              {/* {JSON.stringify(queryMsgData)} */}
            </HStack>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default MessagesContentUsers;
