import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

const StepNine = ({
  activeStep,
  setActiveStep,
  reportCompiler,
  setReportCompiler,
  reportCompilerOutput,
  setReportCompilerOutput,
  findStandardScoreWithinNestedObj,
  findStandardScorePlusMinusPointsWithinNestedObj,
  standardScoreToUseFinal,
  wcScaledScore,
  fdScaledScore,
  fsScaledScore,
  rsScaledScore,
  uspScaledScore,
  wdScaledScore,
  saScaledScore,
  srScaledScore,
  scScaledScore,
  lcScaledScore,
  wsScaledScore,
  clsSumOfScaledScore,
  rliSumOfScaledScore,
  eliSumOfScaledScore,
  lciSumOfScaledScore,
  lsiOrLmiSumOfScaledScore,
  buildCandleStickOne,
  setBuildCandleStickOne,
  setChartOne,
}: any) => {
  const [cs, setCs] = useState(0);
  const [nineOrUnderState, setNineOrUnderState] = useState<any>();
  const [overNineState, setOverNineState] = useState<any>();

  const TheRowCLSAIS = (props: {
    item: string;
    title: string;
    scaledScores: any;
    show: number;
    age: number;
    sumOfScaledScores: number;
    standardScore: any;
    stdScorePlusMinus: string;
    percentileRank: string;
    percentileRankFrom: string;
    percentileRankTo: string;
  }) => {
    const {
      item,
      title,
      scaledScores,
      show,
      age,
      sumOfScaledScores,
      standardScore,
      stdScorePlusMinus,
      percentileRank,
      percentileRankFrom,
      percentileRankTo,
    } = props;

    const ciFrom = Number(standardScore) - Number(stdScorePlusMinus);
    const ciTo = Number(standardScore) + Number(stdScorePlusMinus);
    console.log('standardScore:', standardScore);

    // localStorage.setItem()

    // WC FD  FS RS USP WD SA SR
    return Number(reportCompiler.ageYears) <= 8 ? (
      <Tr bg={'gray.100'}>
        <Td p={'1'}>{title}</Td>
        {scaledScores.map((i: any, k: any) => (
          <Td p={'1'} key={k}>
            {i.show === 1 ? (
              <Input bg={'white'} color={'black'} value={i.value} width={14} />
            ) : (
              <Input bg={'grey'} color={'black'} value={''} width={14} />
            )}
          </Td>
        ))}
        <Td p={'2'} textAlign={'center'}>
          <Divider orientation="vertical" />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={sumOfScaledScores} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={standardScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={stdScorePlusMinus} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={ciFrom} />
        </Td>
        <Td p={'2'}>to</Td>

        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={ciTo} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={percentileRank} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={percentileRankFrom} />
        </Td>
        <Td p={'2'}>to</Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={percentileRankTo} />
        </Td>
      </Tr>
    ) : (
      <Tr bg={'gray.100'}>
        <Td p={'1'}>{title}</Td>
        {scaledScores.map((i: any, k: any) => (
          <Td p={'1'} key={k}>
            {i.show === 1 ? (
              <Input bg={'white'} color={'black'} value={i.value} />
            ) : (
              <Input bg={'grey'} color={'black'} value={''} />
            )}
          </Td>
        ))}
        <Td p={'2'} textAlign={'center'}>
          <Divider orientation="vertical" />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={sumOfScaledScores} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={standardScore} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={stdScorePlusMinus} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={ciFrom} />
        </Td>
        <Td p={'2'}>to</Td>

        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={ciTo} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={percentileRank} />
        </Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={percentileRankFrom} />
        </Td>
        <Td p={'2'}>to</Td>
        <Td p={'2'}>
          <Input bg={'white'} color={'black'} value={percentileRankTo} />
        </Td>
      </Tr>
    );
  };

  // 13 to 21

  //   wc
  //   fd
  //   fs
  //   rs
  //   usp
  //   wd
  //   sa
  //   sr

  const defaulClsDataFiveToEight = [
    {
      item: 'sotssCLS',
      title: 'Core Language Score(CLS)',
      scaledScores: [
        { value: scScaledScore, show: 1 },
        { value: lcScaledScore, show: 0 },
        { value: wsScaledScore, show: 1 },

        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
      ],
      sumOfScaledScores: clsSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssRLI',
      title: 'Receptive Language Index(RLI)',
      scaledScores: [
        { value: scScaledScore, show: 1 },
        { value: lcScaledScore, show: 0 },
        { value: wsScaledScore, show: 1 },

        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 1 },
        { value: fsScaledScore, show: 0 },
        { value: rsScaledScore, show: 0 },
      ],
      sumOfScaledScores: rliSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssELI',
      title: 'Expressive Language Index(ELI)',
      scaledScores: [
        { value: scScaledScore, show: 0 },
        { value: lcScaledScore, show: 0 },
        { value: wsScaledScore, show: 1 },

        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
      ],
      sumOfScaledScores: eliSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssLCI',
      title: 'Language Content Index(LCI)',
      scaledScores: [
        { value: scScaledScore, show: 0 },
        { value: lcScaledScore, show: 1 },
        { value: wsScaledScore, show: 0 },

        { value: wcScaledScore, show: 1 },
        { value: fdScaledScore, show: 1 },
        { value: fsScaledScore, show: 0 },
        { value: rsScaledScore, show: 0 },
      ],
      sumOfScaledScores: lciSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssLSI',
      title: 'Language Structure Index(LSI)',
      scaledScores: [
        { value: scScaledScore, show: 1 },
        { value: lcScaledScore, show: 0 },
        { value: wsScaledScore, show: 1 },

        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
      ],
      sumOfScaledScores: lsiOrLmiSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
  ];

  const defaulClsDataNineToTwelve = [
    {
      item: 'sotssCLS',
      title: 'Core Language Score(CLS)',
      scaledScores: [
        { value: wcScaledScore, show: 1 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
        { value: uspScaledScore, show: 0 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 1 },
      ],
      sumOfScaledScores: clsSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssRLI',
      title: 'Receptive Language Index(RLI)',
      scaledScores: [
        { value: wcScaledScore, show: 1 },
        { value: fdScaledScore, show: 1 },
        { value: fsScaledScore, show: 0 },
        { value: rsScaledScore, show: 0 },
        { value: uspScaledScore, show: 0 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 1 },
      ],
      sumOfScaledScores: rliSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssELI',
      title: 'Expressive Language Index(ELI)',
      scaledScores: [
        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
        { value: uspScaledScore, show: 0 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 1 },
        { value: srScaledScore, show: 0 },
      ],
      sumOfScaledScores: eliSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssLCI',
      title: 'Language Content Index(LCI)',
      scaledScores: [
        { value: wcScaledScore, show: 1 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 0 },
        { value: rsScaledScore, show: 0 },
        { value: uspScaledScore, show: 1 },
        { value: wdScaledScore, show: 1 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 0 },
      ],
      sumOfScaledScores: lciSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssLMI',
      title: 'Language Memory Index(LMI)',
      scaledScores: [
        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 1 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
        { value: uspScaledScore, show: 0 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 0 },
      ],
      sumOfScaledScores: lsiOrLmiSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
  ];

  const defaulClsDataThirteenToTwentyOne = [
    {
      item: 'sotssCLS',
      title: 'Core Language Score(CLS)',
      scaledScores: [
        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
        { value: uspScaledScore, show: 1 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 1 },
      ],
      sumOfScaledScores: clsSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssRLI',
      title: 'Receptive Language Index(RLI)',
      scaledScores: [
        { value: wcScaledScore, show: 1 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 0 },
        { value: rsScaledScore, show: 0 },
        { value: uspScaledScore, show: 1 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 1 },
      ],
      sumOfScaledScores: rliSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssELI',
      title: 'Expressive Language Index(ELI)',
      scaledScores: [
        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
        { value: uspScaledScore, show: 0 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 1 },
        { value: srScaledScore, show: 0 },
      ],
      sumOfScaledScores: eliSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssLCI',
      title: 'Language Content Index(LCI)',
      scaledScores: [
        { value: wcScaledScore, show: 1 },
        { value: fdScaledScore, show: 0 },
        { value: fsScaledScore, show: 0 },
        { value: rsScaledScore, show: 0 },
        { value: uspScaledScore, show: 1 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 1 },
        { value: srScaledScore, show: 0 },
      ],
      sumOfScaledScores: lciSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
    {
      item: 'sotssLMI',
      title: 'Language Memory Index(LMI)',
      scaledScores: [
        { value: wcScaledScore, show: 0 },
        { value: fdScaledScore, show: 1 },
        { value: fsScaledScore, show: 1 },
        { value: rsScaledScore, show: 1 },
        { value: uspScaledScore, show: 0 },
        { value: wdScaledScore, show: 0 },
        { value: saScaledScore, show: 0 },
        { value: srScaledScore, show: 0 },
      ],
      sumOfScaledScores: lsiOrLmiSumOfScaledScore,
      age: reportCompiler.ageYears,
    },
  ];
  //   [wc, fd, fs, rs, usp, wd, sa, sr]
  const [coreLangugageScoreData, setCoreLangugageScoreData] = useState<any>(
    Number(reportCompiler.ageYears) <= 8
      ? defaulClsDataFiveToEight
      : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12
      ? defaulClsDataNineToTwelve
      : defaulClsDataThirteenToTwentyOne
  );
  console.log('coreLangugageScoreData: ', coreLangugageScoreData);
  function saveStdScoreToState() {
    return new Promise((resolve, reject) => {
      if (!coreLangugageScoreData) {
        reject(new Error('coreLangugageScoreData is not defined'));
        return;
      }

      try {
        coreLangugageScoreData.forEach((i: any, k: any) => {
          const scaledScoreCLS = findStandardScoreWithinNestedObj(
            i.sumOfScaledScores,
            standardScoreToUseFinal,
            i.item
          );

          localStorage.setItem(i.item, scaledScoreCLS);
        });

        resolve('Data saved successfully');
      } catch (error) {
        reject(error);
      }
    });
  }

  // const overNine = [
  //   ['day', 'a', 'b', 'c', 'd'],
  //   ['CLS : 72', 66, 72, 72, 78],
  //   ['RLI : 79', 73, 79, 79, 85],
  //   ['ELI : 71', 65, 71, 71, 77],
  //   ['LCI : 75', 70, 75, 75, 80],
  //   ['LMI : 76', 70, 76, 76, 82],
  // ];
  // localStorage.removeItem("image");

  // const nineOrUnder = [
  //   ['day', 'a', 'b', 'c', 'd'],
  //   ['CLS', 20, 28, 38, 45],
  //   ['RLI', 31, 38, 55, 66],
  //   ['ELI', 50, 55, 77, 80],
  //   ['LCI', 50, 77, 66, 77],
  //   ['LSI', 15, 66, 22, 68],
  // ];

  // setOptionsChartOne
  // setChartOne
  const dataToCandlestick = reportCompiler.ageYears >= 9 ? nineOrUnderState : overNineState;

  useEffect(() => {
    // Usage
    saveStdScoreToState()
      .then(() => {
        // setCs(1);

        let overNine: any = [['day', 'a', 'b', 'c', 'd']];
        let nineOrUnder: any = [['day', 'a', 'b', 'c', 'd']];

        const clsItem = [
          `CLS : ${localStorage.getItem('sotssCLS')}`,
          Number(localStorage.getItem('sotssCLSCIMIN')) < 40
            ? 40
            : Number(localStorage.getItem('sotssCLSCIMIN')),
          Number(localStorage.getItem('sotssCLS')),
          Number(localStorage.getItem('sotssCLS')),
          Number(localStorage.getItem('sotssCLSCIMAX')) > 160
            ? 160
            : Number(localStorage.getItem('sotssCLSCIMAX')),
        ];

        const rliItem = [
          `RLI : ${localStorage.getItem('sotssRLI')}`,
          Number(localStorage.getItem('sotssRLICIMIN')) < 40
            ? 40
            : Number(localStorage.getItem('sotssRLICIMIN')),
          Number(localStorage.getItem('sotssRLI')),
          Number(localStorage.getItem('sotssRLI')),
          Number(localStorage.getItem('sotssRLICIMAX')) > 160
            ? 160
            : Number(localStorage.getItem('sotssRLICIMAX')),
        ];

        const eliItem = [
          `ELI : ${localStorage.getItem('sotssELI')}`,
          Number(localStorage.getItem('sotssELICIMIN')) < 40
            ? 40
            : Number(localStorage.getItem('sotssELICIMIN')),
          Number(localStorage.getItem('sotssELI')),
          Number(localStorage.getItem('sotssELI')),
          Number(localStorage.getItem('sotssELICIMAX')) > 160
            ? 160
            : Number(localStorage.getItem('sotssELICIMAX')),
        ];

        const lciItem = [
          `LCI : ${localStorage.getItem('sotssLCI')}`,
          Number(localStorage.getItem('sotssLCICIMIN')) < 40
            ? 40
            : Number(localStorage.getItem('sotssLCICIMIN')),
          Number(localStorage.getItem('sotssLCI')),
          Number(localStorage.getItem('sotssLCI')),
          Number(localStorage.getItem('sotssLCICIMAX')) > 160
            ? 160
            : Number(localStorage.getItem('sotssLCICIMAX')),
        ];

        const lmiItem = [
          `LMI : ${localStorage.getItem('sotssLMI')}`,
          Number(localStorage.getItem('sotssLMICIMIN')) < 40
            ? 40
            : Number(localStorage.getItem('sotssLMICIMIN')),
          Number(localStorage.getItem('sotssLMI')),
          Number(localStorage.getItem('sotssLMI')),
          Number(localStorage.getItem('sotssLMICIMAX')) > 160
            ? 160
            : Number(localStorage.getItem('sotssLMICIMAX')),
        ];

        const lsiItem = [
          `LSI : ${localStorage.getItem('sotssLSI')}`,
          Number(localStorage.getItem('sotssLSICIMIN')) < 40
            ? 40
            : Number(localStorage.getItem('sotssLSICIMIN')),
          Number(localStorage.getItem('sotssLSI')),
          Number(localStorage.getItem('sotssLSI')),
          Number(localStorage.getItem('sotssLSICIMAX')) > 160
            ? 160
            : Number(localStorage.getItem('sotssLSICIMAX')),
        ];

        Number(localStorage.getItem('sotssCLS')) > 0 && overNine.push(clsItem);
        Number(localStorage.getItem('sotssRLI')) > 0 && overNine.push(rliItem);
        Number(localStorage.getItem('sotssELI')) > 0 && overNine.push(eliItem);
        Number(localStorage.getItem('sotssLCI')) > 0 && overNine.push(lciItem);
        Number(localStorage.getItem('sotssLMI')) > 0 && overNine.push(lmiItem);

        Number(localStorage.getItem('sotssCLS')) > 0 && nineOrUnder.push(clsItem);
        Number(localStorage.getItem('sotssRLI')) > 0 && nineOrUnder.push(rliItem);
        Number(localStorage.getItem('sotssELI')) > 0 && nineOrUnder.push(eliItem);
        Number(localStorage.getItem('sotssLCI')) > 0 && nineOrUnder.push(lciItem);
        Number(localStorage.getItem('sotssLSI')) > 0 && nineOrUnder.push(lsiItem);

        console.log('overNine', overNine);

        const dataToCandlestickChart = reportCompiler.ageYears >= 9 ? overNine : nineOrUnder;
        setOverNineState(overNine);
        setNineOrUnderState(nineOrUnder);
        setBuildCandleStickOne(1);
        setChartOne(dataToCandlestickChart);
        console.log('We got the new values'); // 'Data saved successfully'
      })
      .then(() => {})
      .catch((error) => {
        console.error('Error:', error);
      });
  }, []);

  // useEffect(() => {

  // }, [cs]);
  return (
    <Box bg={'gray.100'} p={10} width={'100%'} height={'auto'} borderRadius={20}>
      <Heading>Core Language Scores and Index Scores</Heading>
      <Table variant="simple" mt={10}>
        <Thead>
          {Number(reportCompiler.ageYears) <= 8 ? (
            <Tr>
              <Th p={'2'} textAlign={'center'}></Th>

              <Th p={'2'} textAlign={'center'}>
                SC
              </Th>
              <Th p={'2'} textAlign={'center'}>
                LC
              </Th>
              <Th p={'2'} textAlign={'center'}>
                WS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                WC
              </Th>
              <Th p={'2'} textAlign={'center'}>
                FD
              </Th>
              <Th p={'2'} textAlign={'center'}>
                FS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                RS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                <Divider orientation="vertical" />
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Sum of <br></br> Scaled Scores
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Standard<br></br> Score
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Standard Score<br></br> Points +/-
              </Th>
              <Th p={'2'} textAlign={'center'}>
                CI MIN%
              </Th>
              <Th p={'2'} textAlign={'center'}>
                to
              </Th>
              <Th p={'2'} textAlign={'center'}>
                CI MAX%
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Percentile rank
              </Th>
              <Th p={'2'} textAlign={'center'}>
                PR CI
              </Th>
              <Th p={'2'} textAlign={'center'}>
                to
              </Th>
              <Th p={'2'} textAlign={'center'}>
                PR CI
              </Th>
            </Tr>
          ) : Number(reportCompiler.ageYears) >= 9 && Number(reportCompiler.ageYears) <= 12 ? (
            <Tr>
              <Th p={'2'} textAlign={'center'}></Th>

              <Th p={'2'} textAlign={'center'}>
                WC
              </Th>
              <Th p={'2'} textAlign={'center'}>
                FD
              </Th>
              <Th p={'2'} textAlign={'center'}>
                FS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                RS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                USP
              </Th>
              <Th p={'2'} textAlign={'center'}>
                WD
              </Th>
              <Th p={'2'} textAlign={'center'}>
                SA
              </Th>
              <Th p={'2'} textAlign={'center'}>
                SR
              </Th>
              <Th p={'2'} textAlign={'center'}>
                <Divider orientation="vertical" />
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Sum of <br></br> Scaled Scores
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Standard<br></br> Score
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Standard Score<br></br> Points +/-
              </Th>
              <Th p={'2'} textAlign={'center'}>
                CI MIN%
              </Th>
              <Th p={'2'} textAlign={'center'}>
                to
              </Th>
              <Th p={'2'} textAlign={'center'}>
                CI MAX%
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Percentile rank
              </Th>
              <Th p={'2'} textAlign={'center'}>
                PR CI
              </Th>
              <Th p={'2'} textAlign={'center'}>
                to
              </Th>
              <Th p={'2'} textAlign={'center'}>
                PR CI
              </Th>
            </Tr>
          ) : (
            <Tr>
              <Th p={'2'} textAlign={'center'}></Th>

              <Th p={'2'} textAlign={'center'}>
                WC
              </Th>
              <Th p={'2'} textAlign={'center'}>
                FD
              </Th>
              <Th p={'2'} textAlign={'center'}>
                FS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                RS
              </Th>
              <Th p={'2'} textAlign={'center'}>
                USP
              </Th>
              <Th p={'2'} textAlign={'center'}>
                WD
              </Th>
              <Th p={'2'} textAlign={'center'}>
                SA
              </Th>
              <Th p={'2'} textAlign={'center'}>
                SR
              </Th>
              <Th p={'2'} textAlign={'center'}>
                <Divider orientation="vertical" />
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Sum of <br></br> Scaled Scores
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Standard<br></br> Score
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Standard Score<br></br> Points +/-
              </Th>
              <Th p={'2'} textAlign={'center'}>
                CI MIN%
              </Th>
              <Th p={'2'} textAlign={'center'}>
                to
              </Th>
              <Th p={'2'} textAlign={'center'}>
                CI MAX%
              </Th>
              <Th p={'2'} textAlign={'center'}>
                Percentile rank
              </Th>
              <Th p={'2'} textAlign={'center'}>
                PR CI
              </Th>
              <Th p={'2'} textAlign={'center'}>
                to
              </Th>
              <Th p={'2'} textAlign={'center'}>
                PR CI
              </Th>
            </Tr>
          )}
        </Thead>
        <Tbody>
          {coreLangugageScoreData.map((i: any, k: any) => {
            const scaledScoreCLS = findStandardScoreWithinNestedObj(
              i.sumOfScaledScores,
              standardScoreToUseFinal,
              i.item
            );

            const stdScorePlusMinusObject =
              findStandardScorePlusMinusPointsWithinNestedObj(standardScoreToUseFinal);

            console.log('stdScorePlusMinus: ', stdScorePlusMinusObject);

            function stdScorePlusMinusCheck(stdScorePlusMinusObject: any, clisType: string) {
              let stdScorePlusMinus;

              switch (true) {
                case clisType == 'sotssCLS':
                  stdScorePlusMinus = stdScorePlusMinusObject?.cls;
                  break;
                case clisType == 'sotssRLI':
                  stdScorePlusMinus = stdScorePlusMinusObject?.rli;
                  break;
                case clisType == 'sotssELI':
                  stdScorePlusMinus = stdScorePlusMinusObject?.eli;
                  break;
                case clisType == 'sotssLCI':
                  stdScorePlusMinus = stdScorePlusMinusObject?.lci;
                  break;
                case clisType == 'sotssLMI':
                  stdScorePlusMinus = stdScorePlusMinusObject?.lmi;
                  break;
                case clisType == 'sotssLSI':
                  stdScorePlusMinus = stdScorePlusMinusObject?.lsi;
                  break;
              }

              return stdScorePlusMinus;
            }

            const stdScorePlusMinus = stdScorePlusMinusCheck(stdScorePlusMinusObject, i.item);

            function findPercentileRankFromStdScoreWithinNestedObj(
              soss: number,
              ssObject: any,
              clisType: string
            ) {
              console.log('soss', soss);
              console.log('ssObject', ssObject);
              console.log('clisType', clisType);

              const theSsArray = ssObject[1];
              let clisTypeToUse;

              switch (true) {
                case clisType == 'sotssCLS':
                  clisTypeToUse = theSsArray?.filter((i: any) => String(i.clis) === String(soss));
                  break;
                case clisType == 'sotssRLI':
                  clisTypeToUse = theSsArray?.filter((i: any) => String(i.clis) === String(soss));
                  break;
                case clisType == 'sotssELI':
                  clisTypeToUse = theSsArray?.filter((i: any) => String(i.clis) === String(soss));
                  break;
                case clisType == 'sotssLCI':
                  clisTypeToUse = theSsArray?.filter((i: any) => String(i.clis) === String(soss));
                  break;
                case clisType == 'sotssLMI':
                  clisTypeToUse = theSsArray?.filter((i: any) => String(i.clis) === String(soss));
                  break;
                case clisType == 'sotssLSI':
                  clisTypeToUse = theSsArray?.filter((i: any) => String(i.clis) === String(soss));
                  break;
              }
              console.log('percentileRRRANK', clisTypeToUse);
              return clisTypeToUse[0]?.pr;
            }

            const percentileRank = findPercentileRankFromStdScoreWithinNestedObj(
              scaledScoreCLS,
              standardScoreToUseFinal,
              i.item
            );

            console.log('scaledScoreCLS CHECK', scaledScoreCLS);
            console.log('i.scaledScore', i.scaledScore);

            const ciFrom = Number(scaledScoreCLS) - Number(stdScorePlusMinus);
            const ciTo = Number(scaledScoreCLS) + Number(stdScorePlusMinus);
            console.log('ciFrom: ', ciFrom);
            console.log('ciTo : ', ciTo);

            const percentileRankFrom = findPercentileRankFromStdScoreWithinNestedObj(
              ciFrom,
              standardScoreToUseFinal,
              i.item
            );

            const percentileRankTo = findPercentileRankFromStdScoreWithinNestedObj(
              ciTo,
              standardScoreToUseFinal,
              i.item
            );

            localStorage.setItem(i.item + 'CIMAX', String(ciTo));
            localStorage.setItem(i.item + 'CIMIN', String(ciFrom));

            return (
              <TheRowCLSAIS
                item={i.item}
                title={i.title}
                scaledScores={i.scaledScores}
                show={i.show}
                age={i.age}
                sumOfScaledScores={i.sumOfScaledScores}
                standardScore={scaledScoreCLS}
                stdScorePlusMinus={stdScorePlusMinus}
                percentileRank={percentileRank}
                percentileRankFrom={percentileRankFrom}
                percentileRankTo={percentileRankTo}
              />
            );
          })}
        </Tbody>
      </Table>
    </Box>
  );
};
export default StepNine;
