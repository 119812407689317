import React from 'react';
import firebase from 'firebase/compat/app';

// export const AuthContext = React.createContext({
//   user: null,
//   //   setUser: (user: firebase.User) => {},
//   setUser: React.Dispatch<React.SetStateAction<string | null>>,
// });

// export const AuthContext = React.createContext<{
//   user: string | null;
//   setUser: () => void;
// }>({
//   user: null,
//   setUser: () => undefined,
// });
export const AuthContext = React.createContext<firebase.User | any>(null);
