import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Link,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useClipboard,
  Input,
  FormControl,
  FormLabel,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Select,
  Button,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getAllProfiles from '../utils/getAllProfiles';
import { BiCopy } from 'react-icons/bi';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { UserMeta } from '../interfaces/UserMeta';
import appendixA from '../constants/reporting/appendix_a.json';
const AddClientSession = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  const [rows, setRows] = useState<any>([]);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulClientsTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  // const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const [copied, setCopied] = useState<string>('');
  const [ciLevel, setCiLevel] = useState<number>(0);
  // Start by creating the report state.
  const [report, setReport] = useState<any>([]);
  function updateUserType(userType: string) {}
  // REACT_APP_GOOGLE_DRIVE_API_KEY_UL
  useEffect(() => {
    const allClientProfiles = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
    const allClientProfileTables = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES_TABLE;
    // const url = 'https://sheets.googleapis.com/v4/spreadsheets/' + spreadsheet_id + '/values/' + tab_name + '?alt=json&key=' + api_key;
    const spreadsheet_id = '1kX-poFOSJKmUrHIOZvZ2ruIb69xVKuGKDy5l2inq8-Q';
    const designppl_app_a_spreadsheet_id = '1m9zm_jiV9cObvsbymxDfmgH1wVKOrWYmTYmHBCG6lUc';
    const tab_name = '5:0-5:5';
    const api_key = 'AIzaSyAYoWt5owV4mad5jvyXuPsKMMAi-qUCxTM';
    const url =
      'https://sheets.googleapis.com/v4/spreadsheets/' +
      designppl_app_a_spreadsheet_id +
      '/values/' +
      tab_name +
      '?alt=json&key=' +
      api_key;
    console.log(url);
    console.log(appendixA);
    const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
    // rows &&
    //   getAllProfiles(EP, bearerToken!).then((values) => {
    //     console.log(values);
    //     setRows(values);
    //   });
  }, []);

  interface InvoiceFields {
    'Invoice Date': string;
    'Invoice Due Date': string;
    'Invoice Status': string;
    'Invoice Posted to Xero?': boolean;
    'Invoice No': string;
    Reference: string;
  }

  interface Invoices {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: InvoiceFields;
  }
  // console.log(rows);
  const siteUrl = 'https://sites.google.com/unlockinglanguage.co.uk/session-entry-form/home';

  function Iframe(props: any) {
    // return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }} />
    return <iframe src={siteUrl} title="description"></iframe>;
  }
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Heading pb={6}>Add Client Session</Heading>
          <Box mt={10}>
            <Link href={siteUrl} target="_blank">
              <Button variant={'defaultButton'} mb="10">
                Add Client Session
              </Button>
            </Link>

            <Iframe iframe={siteUrl} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddClientSession;
