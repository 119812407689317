import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  SimpleGrid,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  Text,
  VStack,
  useBoolean,
  useColorModeValue,
  Avatar,
  HStack,
  Image,
  StackDivider,
  Stack,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Link,
  Spinner,
} from '@chakra-ui/react';
import { AuthContext } from '../context/AuthContext';
import moment from 'moment';

import React, { ReactNode, useContext, useEffect, useState, useReducer } from 'react';
export default function BookingsContentUpcomingClientDates({ clientSessions }: any) {
  // console.log(combined);
  // .sort((a: any, b: any) => a?.fields['Start'].localeCompare(b?.fields['Start']))

  return (
    <Box overflow={'hidden'}>
      <Box>
        <Stack
          divider={<StackDivider />}
          spacing="2"
          height={'600px'}
          bg={'white'}
          overflowY={'scroll'}
        >
          {Number(clientSessions.length) > 0 &&
            clientSessions.map((i: any, k: any) => {
              let bookingDate = moment(new Date(i?.fields['Session Start'])).format(
                'DD/MM/YYYY HH:mm'
              );
              return (
                <Box key={k}>
                  <Heading size="xs" textTransform="uppercase">
                    {i?.fields['Brief Notes']}
                  </Heading>
                  <Text pt="2" fontSize="sm" pb={4}>
                    {bookingDate} - {i?.fields['Session Type']}
                  </Text>
                </Box>
              );
            })}
        </Stack>
      </Box>
    </Box>
  );
}
