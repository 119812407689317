import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  Textarea,
  HStack,
  Stack,
  Flex,
  Link,
  Spacer,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBoolean,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { addClient } from '../interfaces/addClient';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import getAllRowsById from '../utils/getAllRowsById';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getMultipleApiData from '../utils/getMultipleApiData';

import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import moment from 'moment';

const today = new Date();
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const ulDb = process.env.REACT_APP_UL_DB;
export const AddClientsForm = ({
  schoolFilter,
  setSchoolFilter,
  studentClasses,
  regularSchoolsEP,
  senSchoolsEP,
  therapistCaseNotesRegularSchoolsSEP,
  therapistCaseNotesSENSchoolsEP,
  tabIndex,
  setTheModal,
  setOpenedModal,
  setCaseNotes,
}: any) => {
  const today = new Date();
  const newDate = moment(today).format('DD/MM/YYYY');
  const defaultForm = {
    firstName: '',
    lastName: '',
    dob: newDate,
    classYear: 'none',
    mainstreamSchool: schoolFilter,
    localPatientIdentifier: '',
  };
  const filledFields = {
    firstName: 0,
    lastName: 0,
    dob: 0,
  };
  const [form, setForm] = useState<addClient>(defaultForm);
  const [dateDob, setDateDob] = useState('');
  const [schoolName, setSchoolName] = useState<string>();
  const [value, setValue] = useState(moment().format('DD/MM/YYYY'));
  const [sorter, setSorter] = useBoolean();
  const [activateButton, setActivateButton] = useState<any>(filledFields);

  const schoolToAddStudentTo =
    tabIndex === 0 ? therapistCaseNotesRegularSchoolsSEP : therapistCaseNotesSENSchoolsEP;
  const clientsList = process.env.REACT_APP_CLIENTS_LIST_EP;
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolID = queryParameters.get('schoolID');
  const schoolType = queryParameters.get('schoolType');
  const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const accountType = schoolType === 'RegularSchool' ? 'Mainstream' : 'SEN Schools';
  const schoolInView = Number(schoolID);
  const schoolsEP = tabIndex === 0 ? regularSchoolsEP : senSchoolsEP;
  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;
  // ${Number(schoolID)}
  const baseMsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['Mainstream School'.Id = school_id];
concat(students)`;

  const baseSsQuery = `let school_id := ${Number(schoolID)};
let students := (select Students)['SEN schools'.Id = school_id];
concat(students)`;

  const getAllStudentsEP = schoolType === 'RegularSchool' ? msQueryEP : ssQueryEP;
  const getAllStudentsQuery = schoolType === 'RegularSchool' ? baseMsQuery : baseSsQuery;
  const getCurrentSchoolsStudents =
    schoolType === 'RegularSchool' ? caseNotesMainstream : caseNotesSEN;

  // const handleClassSelection = (e: any) => {
  //   let inputValue = e.target.value;
  //   setForm({ ...form, classYear: inputValue });
  // };

  const handleClientsFirstName = (e: any) => {
    let inputValue = e.target.value;
    setForm({ ...form, firstName: inputValue });
    setActivateButton({ ...activateButton, firstName: 1 });
  };

  const handleClientsLastName = (e: any) => {
    let inputValue = e.target.value;
    setForm({ ...form, lastName: inputValue });
    setActivateButton({ ...activateButton, lastName: 1 });
  };

  const handleDOB = (e: any) => {
    const newDobDate = moment(new Date(e.target.value)).format('DD/MM/YYYY');
    const lpiDobDate = moment(new Date(e.target.value)).format('DDMMYYYY');
    setDateDob(e.target.value);
    handleLPI(lpiDobDate, newDobDate);
    setActivateButton({ ...activateButton, dob: 1 });
  };

  const handleLPI = (lpidate: string, newDobDate: string) => {
    const firstLetter = form.firstName.charAt(0);
    const lastLetter = form.lastName.charAt(form.lastName?.length - 1);
    const firstLetterUp = firstLetter;
    const lastLetterUp = lastLetter;

    let newLpi = firstLetterUp + lastLetterUp + lpidate + schoolFilter;
    setForm({
      ...form,
      localPatientIdentifier: newLpi.toUpperCase(),
      dob: newDobDate,
    });
  };

  function getSchoolName(schoolsEP: string, schoolID: number) {}

  function getStudentsAfterAdd() {
    // getAllRowsById(
    //   tabIndex === 0 ? therapistCaseNotesRegularSchoolsSEP : therapistCaseNotesSENSchoolsEP
    // ).then((data: any) => {
    //   const myData = data;
    //   setCaseNotes(
    //     tabIndex === 0
    //       ? myData.filter((i: any) => i?.fields['Mainstream School'] === schoolFilter)
    //       : myData.filter((i: any) => i?.fields['SEN Schools'] === schoolFilter)
    //   );
    // });

    // Clients query EP:
    const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

    // Clients query:
    const therapist_id = Number(localStorage.getItem('accountNumber'));
    const clientsQuery = `let therapist_id := ${therapist_id};
let students := (select 'Central Client Database - ALL STAFF USAGE')['Current Therapist Assigned'.id = therapist_id]
concat(students);
`;

    // Client query data EP:
    const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

    getAllRowsByEncodedQuery(clientsQueryEP, clientsQuery).then((data: any) => {
      const allStudents = data.split(',');
      // console.log(allStudents);
      // console.log(getAllStudentsEP);
      // console.log(getAllStudentsQuery);
      data?.length > 0 &&
        getMultipleApiData(clientsList!, bearerToken!, allStudents).then((data: any) => {
          setCaseNotes(
            data
              .filter(
                (i: any) =>
                  i.fields['Date of Discharge'] === undefined ||
                  i.fields['Date of Discharge'] === null ||
                  i.fields['Date of Discharge'] === ''
              )
              .sort((a: any, b: any) =>
                sorter === true
                  ? b?.fields['First Name'].localeCompare(a?.fields['First Name'])
                  : a?.fields['First Name'].localeCompare(b?.fields['First Name'])
              )
          );
        });
    });
  }
  const addStudent = async () => {
    const clientToAdd = {
      'First Name': form.firstName,
      'Last Name': form.lastName,
      'Current Therapist Assigned': Number(localStorage.getItem('accountNumber')),
      'Date of Birth': form.dob,
      // 'Local Patient Identifier': form.localPatientIdentifier,
    };

    console.log('Account creation triggered');
    try {
      ninoxTableAddRow(clientsList, { fields: clientToAdd }, bearerToken).then((data: any) => {
        getStudentsAfterAdd();
        return data;
      });
      console.log('Account created');
    } catch (error) {
      console.error(error);
      console.log('Account creation failed');
    }
  };

  // useEffect(() => {
  //   ninoxTableGetRow(schoolsEP, Number(schoolFilter), bearerToken).then((data: any) => {
  //     console.log(data);
  //     // setSchoolName(data?.id + ' - ' + data?.fields['School Name']);
  //   });
  // }, []);

  return (
    <ModalContent>
      <ModalHeader>Add a client</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Flex>
          <Box w={'100%'} p={'5'}>
            {/* <HStack>
              <FormControl mb={'5'}>
                <FormLabel>{tabIndex === 0 ? 'Mainstream School' : 'SEN School'}</FormLabel>
                <Input value={schoolName} isDisabled />
              </FormControl>
              <FormControl mb={'5'}>
                <FormLabel>Class: </FormLabel>
                <Select isRequired onChange={(e) => handleClassSelection(e)} value={form.classYear}>
                  {studentClasses?.map((i: any, k: number) => (
                    <option value={k + 1} key={k}>
                      {i}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </HStack> */}
            <FormControl mb={'5'}>
              <FormLabel>First Name </FormLabel>
              <Input
                value={form.firstName}
                onChange={handleClientsFirstName}
                isRequired
                placeholder="Enter first name"
              />
            </FormControl>
            <FormControl mb={'5'}>
              <FormLabel>Last Name </FormLabel>
              <Input
                value={form.lastName}
                onChange={handleClientsLastName}
                isRequired
                placeholder="Enter last name"
              />
            </FormControl>
            <FormControl mb={'5'}>
              <FormLabel>DOB </FormLabel>
              <HStack>
                <Input
                  // value={String(new Date(value))}
                  value={dateDob}
                  // value=""
                  onChange={handleDOB}
                  isRequired
                  width={'50%'}
                  placeholder="Select Date and Time"
                  type="date"
                />
                {/* <Box>Date:{JSON.stringify(value)}</Box> */}
              </HStack>
            </FormControl>
            <FormControl mb={'5'}>
              <FormLabel>Local Patient Identifier </FormLabel>
              <Input
                value={form.localPatientIdentifier}
                // onChange={handleLPI(form.dob)}
                // isRequired
                placeholder="Enter local patient identifier"
                isDisabled
              />
            </FormControl>
          </Box>
        </Flex>
      </ModalBody>
      <ModalFooter>
        <HStack>
          <Button
            onClick={() => {
              setTheModal(0);
              setOpenedModal(false);
            }}
          >
            Close
          </Button>
          <Button
            onClick={() => {
              addStudent();
              setTheModal(0);
              setOpenedModal(false);
            }}
            bg={'brand.300'}
            _hover={{
              bg: 'brand.200',
            }}
            color={'white'}
            isDisabled={
              form.firstName?.length <= 0 ||
              form.lastName?.length <= 0 ||
              form.dob?.length <= 0 ||
              activateButton.firstName === 0 ||
              activateButton.lastName === 0 ||
              activateButton.dob === 0
            }
          >
            Add Client
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  );
};
