import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Link,
} from '@chakra-ui/react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { TomsRatingClients } from '../interfaces/TomsRatingClients';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getAllRowsById from '../utils/getAllRowsById';
import getMultipleApiData from '../utils/getMultipleApiData';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import orderTomsRatings from '../utils/orderTomsRatings';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import { TomsRatingClientModal } from './TomsRatingClientModal';

import { BackButton } from './BackButton';
import { studentFields } from '../constants/studentFields';
import { CiViewTable } from 'react-icons/ci';

const queryParameters = new URLSearchParams(window.location.search);
const schoolType = queryParameters.get('schoolType');
const today = new Date();
const currentYear = Number(moment(today).format('YY'));
const currentMonth = Number(moment(today).format('MM'));
const currentSchoolYear =
  currentMonth >= 9
    ? {
        startYear: currentYear,
        endYear: currentYear + 1,
        yearRef: currentYear + '/' + (currentYear + 1),
      }
    : {
        startYear: currentYear - 1,
        endYear: currentYear,
        yearRef: currentYear - 1 + '/' + currentYear,
      };

const BookingsContentSingleTomsRatingClient = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  // const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  // const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  // const getCaseNotes = schoolType === 'RegularSchool' ? caseNotesMainstream! : caseNotesSEN!;
  // const EP = schoolType === 'RegularSchool' ? caseNotesMainstream : caseNotesSEN;
  const newTrDefault = {
    'Impairment Rating Primary': 0,
    'Impairment Rating Secondary': 0,
    'Activity Rating Primary': 0,
    'Activity Rating Secondary': 0,
    'Participation Rating': 0,
    'Wellbeing Rating': 0,
    'Carer Wellbeing Rating': 0,
    'Rating Date': today,
    'Central Client Database - ALL STAFF USAGE': Number(sessionId),
  };

  const newTrDefaultLoad = {
    'Impairment Rating Primary': '',
    'Impairment Rating Secondary': '',
    'Activity Rating Primary': '',
    'Activity Rating Secondary': '',
    'Participation Rating': '',
    'Wellbeing Rating': '',
    'Carer Wellbeing Rating': '',
    'Rating Date': today,
    Caseload: Number(sessionId),
  };

  interface TomsRatingUpdate {
    'Impairment Rating Primary': string;
    'Impairment Rating Secondary': string;
    'Activity Rating Primary': string;
    'Activity Rating Secondary': string;
    'Participation Rating': string;
    'Wellbeing Rating': string;
    'Carer Wellbeing Rating': string;
    'Rating Date': Date;
    Caseload: number;
  }

  const studentRatingsDefault = {
    primaryTomsScale: '',
    secondaryTomsScale: '',
  };
  const filterAreaRef = useRef<any>();
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [tomsRatings, setTomsRatings] = useState<TomsRatingClients[] | []>([]);
  const [tomsRatingsAllYears, setTomsRatingsAllYears] = useState<TomsRatingClients[] | []>([]);
  const [newTr, setNewTr] = useState<TomsRatingClients>(newTrDefault);
  const [yearFilter, setYearFilter] = useState(currentSchoolYear);
  const [student, setStudent] = useState<any>({ fields: { 'First Name': '', 'Last Name': '' } });
  const [studentData, setStudentData] = useState<any>();
  const [formData, setFormData] = useState<any>(studentRatingsDefault);
  const navigate = useNavigate();
  const reason = 'Not provided';
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const toast = useToast();
  const [tomsEdit, setTomsEdit] = useState<number>(0);
  const [loadTr, setLoadTr] = useState<TomsRatingUpdate>(newTrDefaultLoad);
  const [updateTr, setUpdateTr] = useState<any>();

  // const studentRatingToUpdate = {
  //   'Primary TOMs Scale': formData.primaryTomsScale,
  //   'Secondary TOMs Scale': formData.secondaryTomsScale,
  // };

  const tomsRatingChoicesRequired = [
    'Please select an option',
    '0',
    '0.5',
    '1',
    '1.5',
    '2',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
  ];

  const tomsRatingChoicesOptional = [
    'Please select an option or leave empty',
    '0',
    '0.5',
    '1',
    '1.5',
    '2',
    '2.5',
    '3',
    '3.5',
    '4',
    '4.5',
    '5',
  ];

  // Clients list data EP:
  const clientsListEP = process.env.REACT_APP_CLIENTS_LIST_EP;

  // Clients toms rating query EP:
  const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;

  // Clients toms rating query:
  const caseload_id = sessionId;
  const TrClientsQuery = `let caseload_id := ${caseload_id};
    let students := (select 'TOMs Rating')['Central Client Database - ALL STAFF USAGE'.Id = caseload_id];
    concat(students);
    `;

  // Client toms rating query data EP:
  const clientsTomsRatingsEP = process.env.REACT_APP_CLIENT_TOMS_RATINGS_EP;

  const IsolatedModal = ({ tr, id, modalState }: any) => {
    return (
      <Box as="section">
        <Modal
          onClose={() => {
            setTheModal(0);
            setOpenedModal(false);
          }}
          size={'3xl'}
          isOpen={theModal === id ? modalState : false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Toms Rating: {student?.fields['Student/Patient Name']}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* {JSON.stringify(tr)} */}
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>TOMS Rating ID</Text>
                <Text>{tr?.id}</Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Rating Date</Text>
                <Text>
                  {tr?.fields['Rating Date'] === undefined ? reason : tr?.fields['Rating Date']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Primary</Text>
                <Text>
                  {tr?.fields['Impairment Rating Primary'] === undefined
                    ? reason
                    : tr?.fields['Impairment Rating Primary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Secondary</Text>
                <Text>
                  {tr?.fields['Impairment Rating Secondary'] === undefined
                    ? reason
                    : tr?.fields['Impairment Rating Secondary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Primary</Text>
                <Text>
                  {tr?.fields['Activity Rating Primary'] === undefined
                    ? reason
                    : tr?.fields['Activity Rating Primary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Secondary</Text>
                <Text>
                  {tr?.fields['Activity Rating Secondary'] === undefined
                    ? reason
                    : tr?.fields['Activity Rating Secondary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Participation Rating</Text>
                <Text>
                  {tr?.fields['Participation Rating'] === undefined
                    ? reason
                    : tr?.fields['Participation Rating']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Wellbeing Rating</Text>
                <Text>
                  {tr?.fields['Wellbeing Rating'] === undefined
                    ? reason
                    : tr?.fields['Wellbeing Rating']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Carer Wellbeing Rating</Text>
                <Text>
                  {tr?.fields['Carer Wellbeing Rating'] === undefined
                    ? reason
                    : tr?.fields['Carer Wellbeing Rating']}
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  onClick={() => {
                    setTheModal(0);
                    setOpenedModal(false);
                  }}
                >
                  Close
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };

  function handleModalOpen(rowId: any) {
    setOpenedModal(true);
    setTheModal(rowId);
  }

  let tomsYearsSchoolYears = [
    tomsRatingsAllYears &&
      tomsRatingsAllYears
        ?.filter((i: any) => i.fields['Central Client Database - ALL STAFF USAGE'] === sessionId)
        .sort(orderTomsRatings)
        .map((i: any, k: any) => {
          let month = Number(moment(i?.fields['Rating Date']).format('MM'));
          let year = Number(moment(i?.fields['Rating Date']).format('YY'));
          return month < 9 ? year - 1 + '/' + year : year + '/' + (year + 1);
        }),
  ];
  let yearsForFilterListSchoolYears = tomsYearsSchoolYears[0];
  let uniqueYearsSchoolYears = yearsForFilterListSchoolYears.filter((element: any, index: any) => {
    return yearsForFilterListSchoolYears.indexOf(element) === index;
  });
  function applySelectedSchoolYear(selectedYear: any) {
    let sYear = Number(selectedYear?.slice(0, 2));
    let eYear = Number(selectedYear?.slice(-2));

    const currentYearFilter = {
      startYear: sYear,
      endYear: eYear,
      yearRef: sYear + '/' + eYear,
    };
    setYearFilter(currentYearFilter);
  }
  function getTomsRatingFiltered() {
    getAllRowsByEncodedQuery(clientsQueryEP, TrClientsQuery).then((data: any) => {
      // console.log(data);
      const allTomsRatings = data.split(',');
      data.length > 0 &&
        getMultipleApiData(clientsTomsRatingsEP!, bearerToken!, allTomsRatings).then(
          (data: any) => {
            console.log('TR:', data);
            setTomsRatings(
              data
                .sort(orderTomsRatings)
                .filter(
                  (i: any) =>
                    (Number(moment(i.fields['Rating Date']).format('YY')) ===
                      yearFilter.startYear &&
                      Number(moment(i.fields['Rating Date']).format('MM')) >= 9) ||
                    (Number(moment(i.fields['Rating Date']).format('YY')) === yearFilter.endYear &&
                      Number(moment(i.fields['Rating Date']).format('MM')) < 9)
                )
            );
            setTomsRatingsAllYears(data);
          }
        );
    });
  }
  function getTomsRatingsFilteredLatestYear() {
    getAllRowsByEncodedQuery(clientsQueryEP, TrClientsQuery).then((data: any) => {
      const allTomsRatings = data.split(',');
      data &&
        getMultipleApiData(clientsTomsRatingsEP!, bearerToken!, allTomsRatings).then(
          (data: any) => {
            setTomsRatings(
              data
                .sort(orderTomsRatings)
                .filter(
                  (i: any) =>
                    (Number(moment(i.fields['Rating Date']).format('YY')) ===
                      yearFilter.startYear &&
                      Number(moment(i.fields['Rating Date']).format('MM')) >= 9) ||
                    (Number(moment(i.fields['Rating Date']).format('YY')) === yearFilter.endYear &&
                      Number(moment(i.fields['Rating Date']).format('MM')) < 9)
                )
            );
            setTomsRatingsAllYears(data);

            let tomsYearsSchoolYears = [
              data &&
                data
                  ?.filter(
                    (i: any) => i.fields['Central Client Database - ALL STAFF USAGE'] === sessionId
                  )
                  .sort(orderTomsRatings)
                  .map((i: any, k: any) => {
                    let month = Number(moment(i?.fields['Rating Date']).format('MM'));
                    let year = Number(moment(i?.fields['Rating Date']).format('YY'));
                    return month < 9 ? year - 1 + '/' + year : year + '/' + (year + 1);
                  }),
            ];
            let yearsForFilterListSchoolYears = tomsYearsSchoolYears[0];
            let uniqueYearsSchoolYears = yearsForFilterListSchoolYears.filter(
              (element: any, index: any) => {
                return yearsForFilterListSchoolYears.indexOf(element) === index;
              }
            );
            applySelectedSchoolYear(uniqueYearsSchoolYears[0]);
          }
        );
    });
  }
  function getStudentData() {
    ninoxTableGetRow(String(clientsListEP), Number(sessionId), bearerToken).then((data: any) => {
      // console.log(data);
      setStudentData(data);
      setFormData({
        primaryTomsScale: data.fields['Primary TOMs Scale'],
        secondaryTomsScale: data.fields['Secondary TOMs Scale'],
      });
    });
  }

  // const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  // const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;

  const updateStudentRatings = (e: any, type: string) => {
    try {
      ninoxTableUpdateRow(
        clientsListEP!,
        Number(sessionId),
        {
          fields: {
            'Primary TOMs Scale': type === 'primary' ? e.target.value : formData.primaryTomsScale,
            'Secondary TOMs Scale':
              type === 'secondary' ? e.target.value : formData.secondaryTomsScale,
          },
        },
        String(bearerToken)
      ).then(() => {
        getStudentData();
        toast({
          title:
            type === 'primary' ? 'Primary TOMs Scale udpated.' : 'Secondary TOMs Scale udpated.',
          description: 'The student data has been successfully updated.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Update error',
        description:
          'The students TOMs scale was not updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const primaryTomsScale = studentFields.fields.filter(
    (i: any) => i.name === 'Primary TOMs Scale'
  )[0].choices;
  const secondaryTomsScale = studentFields.fields.filter(
    (i: any) => i.name === 'Secondary TOMs Scale'
  )[0].choices;
  useEffect(() => {
    getTomsRatingsFilteredLatestYear();
  }, []);

  // function getCurrentStudent() {
  //   ninoxTableGetRow(clientsListEP!, Number(sessionId), bearerToken).then((data: any) => {
  //     setStudent(data);
  //   });
  // }

  useEffect(() => {
    setNewTr(newTrDefault);
    ninoxTableGetRow(String(clientsListEP), Number(sessionId), bearerToken).then((data: any) => {
      // console.log(data);
      setStudent(data);
    });

    getStudentData();
    getTomsRatingFiltered();
    window.scrollTo(0, 0);
  }, [yearFilter, schoolType]);

  const ratingFields = [
    { value: 'irp', label: 'Impairment Rating Primary' },
    { value: 'irs', label: 'Impairment Rating Secondary' },
    { value: 'arp', label: 'Activity Rating Primary' },
    { value: 'ars', label: 'Activity Rating Secondary' },
    { value: 'pr', label: 'Participation Rating' },
    { value: 'wr', label: 'Wellbeing Rating' },
    { value: 'cwr', label: 'Carer Wellbeing Rating' },
  ];

  const handleClassSelection = (e: any, i: any, k: number) => {
    let inputValue = e.target.value;
    i.value === 'irp' && setNewTr({ ...newTr, 'Impairment Rating Primary': Number(inputValue) });
    i.value === 'irs' && setNewTr({ ...newTr, 'Impairment Rating Secondary': Number(inputValue) });
    i.value === 'arp' && setNewTr({ ...newTr, 'Activity Rating Primary': Number(inputValue) });
    i.value === 'ars' && setNewTr({ ...newTr, 'Activity Rating Secondary': Number(inputValue) });
    i.value === 'pr' && setNewTr({ ...newTr, 'Participation Rating': Number(inputValue) });
    i.value === 'wr' && setNewTr({ ...newTr, 'Wellbeing Rating': Number(inputValue) });
    i.value === 'cwr' && setNewTr({ ...newTr, 'Carer Wellbeing Rating': Number(inputValue) });
  };

  const handleClassSelectionUpdate = (e: any, i: any, k: number) => {
    console.log(i);
    console.log('key', e.target.selectedIndex);

    // let inputValue = e.target.selectedIndex;
    let inputValue = e.target.value;
    let inputKeyValue = e.target.selectedIndex;

    console.log('inputValue: ', inputValue);

    const tomsRatingChoicesOptionalRemap = [
      { id: 0, item: 'Please select an option or leave empty' },
      { id: 1, item: '0' },
      { id: 2, item: '0.5' },
      { id: 3, item: '1' },
      { id: 4, item: '1.5' },
      { id: 5, item: '2' },
      { id: 6, item: '2.5' },
      { id: 7, item: '3' },
      { id: 8, item: '3.5' },
      { id: 9, item: '4' },
      { id: 10, item: '4.5' },
      { id: 11, item: '5' },
    ];

    let nameToFindIRP = inputValue;
    let itemIRP = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindIRP)
    );
    let selectedIRP = itemIRP ? itemIRP.item : undefined;

    let nameToFindIRS = inputValue;
    let itemIRS = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindIRS)
    );
    let selectedIRS = itemIRS ? itemIRS.item : undefined;

    let nameToFindARP = inputValue;
    let itemARP = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindARP)
    );
    let selectedARP = itemARP ? itemARP.item : undefined;

    let nameToFindARS = inputValue;
    let itemARS = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindARS)
    );
    let selectedARS = itemARS ? itemARS.item : undefined;

    let nameToFindPR = inputValue;
    let itemPR = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindPR)
    );
    let selectedPR = itemPR ? itemPR.item : undefined;

    let nameToFindWR = inputValue;
    let itemWR = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindWR)
    );
    let selectedWR = itemWR ? itemWR.item : undefined;

    let nameToFindCWR = inputValue;
    let itemCWR = tomsRatingChoicesOptionalRemap.find(
      (choice: any) => String(choice.id) === String(nameToFindCWR)
    );
    let selectedCWR = itemCWR ? itemCWR.item : undefined;

    i === 'irp' && setLoadTr({ ...loadTr, 'Impairment Rating Primary': String(inputValue) });
    i === 'irs' && setLoadTr({ ...loadTr, 'Impairment Rating Secondary': String(inputValue) });
    i === 'arp' && setLoadTr({ ...loadTr, 'Activity Rating Primary': String(inputValue) });
    i === 'ars' && setLoadTr({ ...loadTr, 'Activity Rating Secondary': String(inputValue) });
    i === 'pr' && setLoadTr({ ...loadTr, 'Participation Rating': String(inputValue) });
    i === 'wr' && setLoadTr({ ...loadTr, 'Wellbeing Rating': String(inputValue) });
    i === 'cwr' && setLoadTr({ ...loadTr, 'Carer Wellbeing Rating': String(inputValue) });

    console.log('loadTr: ', loadTr);

    // i === 'irp' && setUpdateTr({ ...updateTr, 'Impairment Rating Primary': Number(inputKeyValue) });
    // i === 'irs' &&
    //   setUpdateTr({ ...updateTr, 'Impairment Rating Secondary': Number(inputKeyValue) });
    // i === 'arp' && setUpdateTr({ ...updateTr, 'Activity Rating Primary': Number(inputKeyValue) });
    // i === 'ars' && setUpdateTr({ ...updateTr, 'Activity Rating Secondary': Number(inputKeyValue) });
    // i === 'pr' && setUpdateTr({ ...updateTr, 'Participation Rating': Number(inputKeyValue) });
    // i === 'wr' && setUpdateTr({ ...updateTr, 'Wellbeing Rating': Number(inputKeyValue) });
    // i === 'cwr' && setUpdateTr({ ...updateTr, 'Carer Wellbeing Rating': Number(inputKeyValue) });

    // console.log('updateTr: ', updateTr);

    type UpdateTrType = {
      [key: string]: number;
    };
    const keyMapping: { [key: string]: keyof UpdateTrType } = {
      irp: 'Impairment Rating Primary',
      irs: 'Impairment Rating Secondary',
      arp: 'Activity Rating Primary',
      ars: 'Activity Rating Secondary',
      pr: 'Participation Rating',
      wr: 'Wellbeing Rating',
      cwr: 'Carer Wellbeing Rating',
    };

    if (keyMapping[i]) {
      setUpdateTr({ ...updateTr, [keyMapping[i]]: Number(inputKeyValue) });
    }
  };

  const addTomsRating = (e: any) => {
    try {
      ninoxTableAddRow(clientsTomsRatingsEP, { fields: newTr }, bearerToken).then(() => {
        toast({
          title: 'Toms ratings successfully added.',
          description: 'Your Toms rating has been added to the system.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setNewTr(newTrDefault);
        getAllRowsById(clientsTomsRatingsEP).then(() => {
          getTomsRatingsFilteredLatestYear();
        });
      });
    } catch {
      toast({
        title: 'Notes error',
        description:
          'You Toms rating was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const accountType =
    localStorage.getItem('accountType') !== undefined && localStorage.getItem('accountType');
  const containerWidth = accountType !== 'Therapist Staff' ? '100%' : '80%';

  const updateTomsRating = (trid: number) => {
    try {
      ninoxTableUpdateRow(
        clientsTomsRatingsEP!,
        trid,
        { fields: updateTr },
        // { fields: loadTr },
        String(bearerToken)
      ).then(() => {
        toast({
          title: 'Toms ratings successfully added.',
          description: 'Your Toms rating has been added to the system.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        setNewTr(newTrDefault);
        getAllRowsById(clientsTomsRatingsEP).then(() => {
          getTomsRatingsFilteredLatestYear();
        });
      });
    } catch {
      toast({
        title: 'Notes error',
        description:
          'You Toms rating was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Box p={2} mb={5}>
            <HStack spacing={5}>
              <BackButton navigate={navigate} />
              <Heading pb={6} mt={6} width={'80%'}>
                TOMS Rating: {student?.fields['First Name']} {student?.fields['Last Name']}
              </Heading>
              <Link
                href={
                  'https://docs.google.com/spreadsheets/d/e/2PACX-1vQcCfvQ3Cs3f9zjSPYuBGZjBeQcFKWOMpa-Qe5vFyEQbx3DYLvdipiPGLmtLb_65qd7rZq_v_kCXljy/pubhtml'
                }
                target="_blank"
              >
                <Button bg={'brand.300'} color={'white'} _hover={{ background: 'brand.200' }}>
                  TOMS Table Doc{' '}
                  <Box ml={2}>
                    <CiViewTable />
                  </Box>
                </Button>
              </Link>
            </HStack>
          </Box>
          <Flex>
            {accountType !== 'Therapist Staff' ? (
              ''
            ) : (
              <Box w={'20%'} p={'5'}>
                <HStack>
                  <FormControl mb={'5'}>
                    <FormLabel>Date</FormLabel>
                    <Input
                      name="ratingDate"
                      value={moment(newTr['Rating Date']).format('YYYY-MM-DD')}
                      type="date"
                      onChange={(e: any) => setNewTr({ ...newTr, 'Rating Date': e.target.value })}
                    />
                  </FormControl>
                </HStack>
                {ratingFields.map((i: any, k: any) => (
                  <FormControl mb={'5'} key={k}>
                    <FormLabel>{i.label}</FormLabel>
                    <Select isRequired onChange={(e) => handleClassSelection(e, i, k)}>
                      {k === 1 || k === 3 || k === 6
                        ? tomsRatingChoicesOptional?.map((i: any, k: number) => (
                            <option value={k} key={k}>
                              {i}
                            </option>
                          ))
                        : tomsRatingChoicesRequired?.map((i: any, k: number) => (
                            <option value={k} key={k}>
                              {i}
                            </option>
                          ))}
                    </Select>
                  </FormControl>
                ))}
                <FormControl>
                  <Button
                    onClick={(e) => addTomsRating(e)}
                    bg={'brand.300'}
                    _hover={{
                      bg: 'brand.200',
                    }}
                    color={'white'}
                    mt={'5'}
                  >
                    Add TOMS Rating
                  </Button>
                </FormControl>
              </Box>
            )}

            <Box w={containerWidth} h={'75vh'} p={'5'} borderColor={'#cccccc'} borderRadius={'5'}>
              <Flex
                width={'100%'}
                flexDirection={'row'}
                mb={5}
                bg={'transparent'}
                ref={filterAreaRef}
              >
                {studentData && (
                  <Box width={'33%'} p={2}>
                    <Text fontWeight={'bold'} mb={2}>
                      Primary TOMs Scale:
                    </Text>
                    <Select
                      isRequired
                      name="primaryTomsScale"
                      onChange={(e: any) => {
                        setFormData({ ...formData, primaryTomsScale: e.target.value });
                        updateStudentRatings(e, 'primary');
                      }}
                      value={formData.primaryTomsScale}
                      // bg={'brand.300'}
                      // color={'white'}
                      borderRadius={4}
                    >
                      {primaryTomsScale?.map((i: any, k: number) => (
                        <option value={i} key={k}>
                          {i}
                        </option>
                      ))}
                    </Select>
                  </Box>
                )}
                {studentData && (
                  <Box width={'33%'} p={2}>
                    <Text fontWeight={'bold'} mb={2}>
                      Secondary TOMs Scale:
                    </Text>
                    <Select
                      isRequired
                      name="secondaryTomsScale"
                      onChange={(e: any) => {
                        setFormData({ ...formData, secondaryTomsScale: e.target.value });
                        updateStudentRatings(e, 'secondary');
                      }}
                      value={formData.secondaryTomsScale}
                      // bg={'brand.200'}
                      // color={'white'}
                      borderRadius={4}
                    >
                      {secondaryTomsScale?.map((i: any, k: number) => (
                        <option value={i} key={k}>
                          {i}
                        </option>
                      ))}
                    </Select>
                  </Box>
                )}
                <Flex flexDirection={'column'} width={'33.3%'}>
                  <Box mt={0} p={2}>
                    <Text fontWeight={'bold'} mb={2}>
                      School year:{' '}
                    </Text>
                    <Select
                      name="yearFilter"
                      isRequired
                      onChange={(e: any) => applySelectedSchoolYear(e.target.value)}
                      value={yearFilter.yearRef}
                    >
                      {uniqueYearsSchoolYears.map((i: any, k: number) => (
                        <option value={i} key={k}>
                          {i}
                        </option>
                      ))}
                    </Select>
                  </Box>
                </Flex>
              </Flex>

              <TableContainer
                className="tomsRatingTableContainer"
                height={'60vh'}
                overflowY={'scroll'}
              >
                <Table variant="striped" colorScheme={'green'}>
                  <Thead
                    overflow={'scroll'}
                    //  position={'fixed'}
                    // top={0}
                    // left={0}
                    zIndex={10}
                  >
                    <Tr>
                      <Th>Rating Date</Th>
                      <Th>Impairment Rating Primary</Th>
                      <Th>Impairment Rating Secondary</Th>
                      <Th>Activity Rating Primary</Th>
                      <Th>Activity Rating Secondary</Th>
                      <Th>Participation Rating</Th>
                      <Th>Wellbeing Rating</Th>
                      <Th>Carer Wellbeing Rating</Th>
                    </Tr>
                  </Thead>
                  <Tbody className="tomsRatingTableContainer">
                    {tomsRatings &&
                      tomsRatings?.sort(orderTomsRatings).map((i: any, k: any) => {
                        return (
                          <Tr
                            key={k}
                            onClick={() => {
                              handleModalOpen(i.id);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <Td>{i?.fields['Rating Date']}</Td>
                            <Td>{i?.fields['Impairment Rating Primary']}</Td>
                            <Td>{i?.fields['Impairment Rating Secondary']}</Td>
                            <Td>{i?.fields['Activity Rating Primary']}</Td>
                            <Td>{i?.fields['Activity Rating Secondary']}</Td>
                            <Td>{i?.fields['Participation Rating']}</Td>
                            <Td>{i?.fields['Wellbeing Rating']}</Td>
                            <Td>{i?.fields['Carer Wellbeing Rating']}</Td>
                          </Tr>
                        );
                      })}
                  </Tbody>
                  {tomsRatings?.length <= 0 && (
                    <TableCaption>No TOMs Ratings provided found!</TableCaption>
                  )}
                </Table>
              </TableContainer>
              {tomsRatings &&
                tomsRatings
                  ?.filter(
                    (i: any) => i.fields['Central Client Database - ALL STAFF USAGE'] == sessionId
                  )
                  .map((i: any, k: any) => (
                    <TomsRatingClientModal
                      tr={i}
                      id={i.id}
                      createdAt={i['createdAt']}
                      modalState={openedModal}
                      key={k}
                      setTheModal={setTheModal}
                      setOpenedModal={setOpenedModal}
                      theModal={theModal}
                      student={student}
                      tomsEdit={tomsEdit}
                      reason={reason}
                      tomsRatingChoicesOptional={tomsRatingChoicesOptional}
                      handleClassSelectionUpdate={handleClassSelectionUpdate}
                      loadTr={loadTr}
                      setTomsEdit={setTomsEdit}
                      updateTomsRating={updateTomsRating}
                      sessionId={sessionId}
                      today={today}
                      setLoadTr={setLoadTr}
                      updateTr={updateTr}
                      setUpdateTr={setUpdateTr}
                    />
                  ))}
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentSingleTomsRatingClient;
