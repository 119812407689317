export default function sortDataByParameter(
  setCaseNotes: Function,
  caseNotes: any,
  schoolFilter: any,
  header: string,
  accountType: string,
  sorter: any
) {
  console.log('We are sorting ' + header);
  return setCaseNotes(
    caseNotes
      // .filter(
      //   (i: any) =>
      //     i?.fields[accountType === 'Mainstream' ? 'Mainstream School' : 'SEN Schools'] ===
      //     schoolFilter
      // )
      .sort((a: any, b: any) =>
        sorter === true
          ? a?.fields[header] === undefined
            ? null
            : a?.fields[header].localeCompare(
                b?.fields[header] === undefined ? null : b?.fields[header]
              )
          : b?.fields[header] === undefined
          ? null
          : b?.fields[header].localeCompare(
              a?.fields[header] === undefined ? null : a?.fields[header]
            )
      )
  );
}
