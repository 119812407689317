import {
  Box,
  Button,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableCaption,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { Base64 } from 'js-base64';
import React, { ReactNode, useEffect, useState } from 'react';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getMultipleApiData from './../utils/getMultipleApiData';
import getAllRowsById from '../utils/getAllRowsById';
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

const oldcid = '5AE98389CA9F454AAAAD2D115D365720';
const oldcs = '5YIx2__5VHpjcBNaXg2ZVBwE8urN5OGyeFJLY1D0qD-M-jkz';

const mainstreamSchoolsInvoicesEP = process.env.REACT_APP_INVOICES_MAINSTREAM_SCHOOLS;
const senSchoolsInvoicesEP = process.env.REACT_APP_INVOICES_SEN_SCHOOLS;
const invoicesEP =
  localStorage.getItem('accountType') === 'Mainstream'
    ? mainstreamSchoolsInvoicesEP
    : senSchoolsInvoicesEP;
// DESIGNPPL

const InvoicesContentSchools = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
}: SignedInUserProps) => {
  const [rows, setRows] = useState<any>([]);
  const [invoices, setInvoices] = useState<any>([]);
  const [unpaidInvoices, setUnpaidInvoices] = useState<any>([]);
  const [invoiceAmounts, setInvoiceAmounts] = useState<any>([]);
  const [invoicesLineItems, setInvoicesLineItems] = useState<any>([]);
  const [invoicesLineItemsData, setInvoicesLineItemsData] = useState<any>([]);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulInvoicesTable = process.env.REACT_APP_UL_INVOICES_TABLE;
  const mainstreamSchoolsInvoices = process.env.REACT_APP_INVOICES_MAINSTREAM_SCHOOLS;
  const senSchoolsInvoices = process.env.REACT_APP_INVOICES_SEN_SCHOOLS;
  const ulMsSchoolsInvoicesTable = process.env.REACT_APP_UL_MS_SCHOOLS_INVOICES_TABLE;
  const ulMsSchoolsInvoicesLineItemsTable =
    process.env.REACT_APP_UL_MS_SCHOOLS_INVOICES_LINE_ITEM_TABLE;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  // const loggedInUsersInvoices = localStorage.getItem('invoices')?.split(',').map(Number);
  // const loggedInUsersInvoices = [];

  const loggedInUsersInvoices = localStorage.getItem('invoices')?.split(',').map(Number);
  console.log('loggedInUsersInvoices', loggedInUsersInvoices);
  const schoolsNinoxEP =
    localStorage.getItem('accountType') === 'Mainstream'
      ? mainstreamSchoolsInvoices
      : senSchoolsInvoices;
  const userStats = userMeta && userMeta?.invoices;

  const invoicesStrings: any = localStorage.getItem('invoices');
  // useEffect(() => {
  //   console.log('useeffect test');

  //   // getDataFromEndPoint(xeroInvoicesTokenEP!).then((myToken) => {
  //   //   console.log('Token data:', myToken);
  //   //   setXeroToken(myToken);
  //   // });
  // }, []);
  useEffect(() => {
    getAllRowsById(schoolsNinoxEP).then((data) => {
      const invoices = data;
      console.log(
        'Invoices',
        invoices.filter(
          (i: any) =>
            i?.fields['Mainstream School'] === Number(localStorage.getItem('accountNumber'))
        )
      );
      setInvoices(
        invoices.filter(
          (i: any) =>
            i?.fields['Mainstream School'] === Number(localStorage.getItem('accountNumber'))
        )
      );

      getMultipleApiData(ulMsSchoolsInvoicesTable!, bearerToken!, loggedInUsersInvoices).then(
        async (data: any) => {
          console.log('first render');
          console.log('THE INVOICES', data);
          setInvoices(data);

          let values = data
            .filter((i: any) => i.fields['Invoice Line Item'] !== undefined)
            .map((z: any, k: any) => z.fields['Invoice Line Item']);
          console.log(values.toString());
          setInvoicesLineItemsData(values.toString()?.split(',').map(Number));

          const thisData = values.toString()?.split(',').map(Number);
          let items = await getMultipleApiData(
            ulMsSchoolsInvoicesLineItemsTable!,
            bearerToken!,
            thisData
          );
          setInvoicesLineItems(items);
          console.log('items', items);
        }
      );
    });
  }, []);

  interface InvoiceFields {
    'Invoice Date': string;
    'Invoice Due Date': string;
    'Invoice Status': string;
    'Invoice Posted to Xero?': boolean;
    'Invoice No': string;
    Reference: string;
  }

  interface Invoices {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: InvoiceFields;
  }
  console.log('invoicesLineItems:', invoicesLineItems);
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        {/* <Box>{JSON.stringify(unpaidInvoices)}</Box> */}
        {/* <Box>{JSON.stringify(invoiceAmounts)}</Box> */}
        {/* {getInvoiceAmount(i.fields['Invoice No'])} */}
        <Box p={6} bg="white">
          <Heading pb={6}>Invoices</Heading>
          <TableContainer>
            <Table variant="simple">
              <Tabs variant="soft-rounded" colorScheme="green">
                <TabList>
                  <Tab>Outstanding</Tab>
                  <Tab>Paid</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Thead>
                      <Tr>
                        <Th>Invoice number</Th>
                        <Th>Invoice Due Date</Th>
                        <Th>Invoice Amount</Th>
                        <Th>Pay / Enquiry</Th>
                        <Th>Payment link</Th>
                        <Th>Invoice reference</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {invoices &&
                        invoices
                          ?.filter(
                            (i: any) =>
                              i?.fields['Invoice Posted to Xero?'] === true &&
                              i?.fields['Invoice Status'] === 'Unpaid'
                          )
                          .map((i: any, k: any) => (
                            <Tr key={k}>
                              <Td>{i?.fields['Invoice No']}</Td>
                              <Td>{i?.fields['Invoice Date']}</Td>
                              <Td>
                                £
                                {invoicesLineItems.length > 0
                                  ? invoicesLineItems
                                      .filter((z: any) => z?.fields['Invoices'] === i.id)
                                      .map(
                                        (i: any, k: any) => i.fields['Quantity'] * i.fields['Price']
                                      )
                                      .reduce((sum: number, num: number) => sum + num)
                                  : 0}
                              </Td>
                              <Td>
                                {i?.fields['Invoice Status'] === 'Unpaid' ? (
                                  <Link
                                    to={{
                                      pathname: `/pay-invoice`,
                                      search: `id=${i.id}&invoice_number=${i.fields['Invoice No']}`,
                                    }}
                                    state={{
                                      lineItems:
                                        invoicesLineItems.length > 0
                                          ? invoicesLineItems
                                              .filter((z: any) => z?.fields['Invoices'] === i.id)
                                              .map(
                                                (i: any, k: any) =>
                                                  i.fields['Quantity'] * i.fields['Price']
                                              )
                                          : 0,
                                      invoiceValue:
                                        invoicesLineItems.length > 0
                                          ? invoicesLineItems
                                              .filter((z: any) => z?.fields['Invoices'] === i.id)
                                              .map(
                                                (i: any, k: any) =>
                                                  i.fields['Quantity'] * i.fields['Price']
                                              )
                                              .reduce((sum: number, num: number) => sum + num)
                                          : 0,
                                      invoiceNumber: i.fields['Invoice No'],
                                      quantity: i.fields['Quantity'],
                                    }}
                                  >
                                    <Button
                                      bg={'brand.300'}
                                      _hover={{
                                        bg: 'brand.200',
                                      }}
                                      color={'white'}
                                    >
                                      Manage
                                    </Button>
                                  </Link>
                                ) : (
                                  i?.fields['Invoice Status']
                                )}
                              </Td>
                              <Td>
                                {i?.fields['Xero Invoice URL'] === undefined ? (
                                  <Box textAlign={'center'}>Invoice not yet generated</Box>
                                ) : (
                                  <Link to={i?.fields['Xero Invoice URL']} target="_blank">
                                    {' '}
                                    <Button
                                      bg={'brand.300'}
                                      _hover={{
                                        bg: 'brand.200',
                                      }}
                                      color={'white'}
                                    >
                                      View invoice
                                    </Button>
                                  </Link>
                                )}
                              </Td>
                              <Td>{i?.fields['Reference']}</Td>
                            </Tr>
                          ))}
                      {rows === undefined && <TableCaption>No invoices found</TableCaption>}
                    </Tbody>
                  </TabPanel>
                  <TabPanel>
                    <Thead>
                      <Tr>
                        <Th>Invoice number</Th>
                        <Th>Invoice Due Date</Th>
                        <Th>Invoice Amount</Th>
                        <Th>Pay / Enquiry</Th>
                        <Th>Payment link</Th>
                        <Th>Invoice reference</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {invoices &&
                        invoices
                          ?.filter(
                            (i: any) =>
                              i?.fields['Invoice Posted to Xero?'] === true &&
                              i?.fields['Invoice Status'] === 'Paid'
                          )
                          .map((i: any, k: any) => (
                            <Tr key={k}>
                              <Td>{i?.fields['Invoice No']}</Td>
                              <Td>{i?.fields['Invoice Date']}</Td>
                              <Td>
                                £
                                {invoicesLineItems.length > 0
                                  ? invoicesLineItems
                                      .filter((z: any) => z?.fields['Invoices'] === i.id)
                                      .map(
                                        (i: any, k: any) => i.fields['Quantity'] * i.fields['Price']
                                      )
                                      .reduce((sum: number, num: number) => sum + num)
                                  : 0}
                              </Td>
                              <Td>
                                {i?.fields['Invoice Status'] === 'Unpaid' ? (
                                  <Link
                                    to={{
                                      pathname: `/pay-invoice`,
                                      search: `id=${i.id}&invoice_number=${i.fields['Invoice No']}`,
                                    }}
                                    state={{
                                      lineItems:
                                        invoicesLineItems.length > 0
                                          ? invoicesLineItems
                                              .filter((z: any) => z?.fields['Invoices'] === i.id)
                                              .map(
                                                (i: any, k: any) =>
                                                  i.fields['Quantity'] * i.fields['Price']
                                              )
                                          : 0,
                                      invoiceValue:
                                        invoicesLineItems.length > 0
                                          ? invoicesLineItems
                                              .filter((z: any) => z?.fields['Invoices'] === i.id)
                                              .map(
                                                (i: any, k: any) =>
                                                  i.fields['Quantity'] * i.fields['Price']
                                              )
                                              .reduce((sum: number, num: number) => sum + num)
                                          : 0,
                                      invoiceNumber: i.fields['Invoice No'],
                                      quantity: i.fields['Quantity'],
                                    }}
                                  >
                                    <Button
                                      bg={'brand.300'}
                                      _hover={{
                                        bg: 'brand.200',
                                      }}
                                      color={'white'}
                                    >
                                      Manage
                                    </Button>
                                  </Link>
                                ) : (
                                  i?.fields['Invoice Status']
                                )}
                              </Td>
                              <Td>
                                {i?.fields['Xero Invoice URL'] === undefined ? (
                                  <Box textAlign={'center'}>Invoice not yet generated</Box>
                                ) : (
                                  <Link to={i?.fields['Xero Invoice URL']} target="_blank">
                                    {' '}
                                    <Button
                                      bg={'brand.300'}
                                      _hover={{
                                        bg: 'brand.200',
                                      }}
                                      color={'white'}
                                    >
                                      View invoice
                                    </Button>
                                  </Link>
                                )}
                              </Td>
                              <Td>{i?.fields['Reference']}</Td>
                            </Tr>
                          ))}
                      {rows === undefined && <TableCaption>No invoices found</TableCaption>}
                    </Tbody>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default InvoicesContentSchools;
