import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  Button,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
import postDataToEndPoint from '../utils/postDataToEndPoint';

const ProfileClientInfo = ({ profileDataType }: any) => {
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);
  const userAccountNumber = localStorage.getItem('accountNumber');

  // console.log('userAccountNumber: ', userAccountNumber);

  // Parents First Name
  // Parents Last Name
  // Phone
  // Mobile 1
  // Email 1
  // Email 2
  // Address
  // Additonal party involved: Solicitor, Court, School, Local Authority?
  // Additional party details
  // Access level

  const defaultForm = {
    parentsFirstName: '',
    parentsLastName: '',
    phone: '',
    mobile1: '',

    email1: '',
    email2: '',
    address: '',

    additionalPartyInvolved: '',
    additionalPartyDetails: '',
    accessLevel: '',
  };
  const [form, setForm] = useState<any>(defaultForm);

  function getUserData() {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUser(data);
      setForm({
        ...form,
        parentsFirstName: data?.fields['Parents First Name'],
        parentsLastName: data?.fields['Parents Last Name'],
        phone: data?.fields['Phone'],
        mobile1: data?.fields['Mobile 1'],
        email1: data?.fields['Email 1'],
        email2: data?.fields['Email 2'],
        address: data?.fields['Address'],
        additionalPartyInvolved:
          data?.fields['Additonal party involved: Solicitor, Court, School, Local Authority?'] !==
          true
            ? 'No'
            : 'Yes',
        additionalPartyDetails: data?.fields['Additional party details'],
        accessLevel: data?.fields['Access level'],
        webInfoReqChangesPending: ['Website info change request pending'],
        clientInfoReqChangesPending: ['Client info change request pending'],
        invoiceInfoReqChangesPending: ['Invoice info change request pending'],
      });
    });
  }

  const clientToUpdate = {
    'Parents First Name': form.parentsFirstName,
    'Parents Last Name': form.parentsLastName,
    Phone: form.phone,
    'Mobile 1': form.mobile1,
    'Email 1': form.email1,
    'Email 2': form.email2,
    Address: form.address,
    'Additonal party involved: Solicitor, Court, School, Local Authority?':
      form.additionalPartyInvolved,
    'Additional party details':
      form.additionalPartyDetails !== null ? form.additionalPartyDetails : 'empty',
    'Access level': form.accessLevel,
  };

  const updateClientInfoReqChangesPending = {
    'Client info change request pending': true,
  };

  const requestProfileChangeViaMakeEP = process.env.REACT_APP_REQUEST_PROFILE_CHANGES_VIA_MAKE_EP;
  const requestBody = {
    data: {
      type: [profileDataType],
      id: [Number(userAccountNumber)],
      parentsFirstName: [form.parentsFirstName],
      parentsLastName: [form.parentsLastName],
      phone: [form.phone],
      email1: [form.email1],
      email2: [form.email2],
      address: [form.address],
      additionalPartyInvolved: [form.additionalPartyInvolved],
      additionalPartyDetails: [
        form.additionalPartyDetails.length <= 0 ? 'empty' : form.additionalPartyDetails,
      ],
      accessLevel: [form.accessLevel],
    },
  };
  function sendDataToMake() {
    setEdit(1);
    postDataToEndPoint(requestProfileChangeViaMakeEP, requestBody).then(() => setEdit(0));
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      { fields: updateClientInfoReqChangesPending },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      getUserData();
      setEdit(0);
    });
  }

  // function updateClientInfo() {
  //   ninoxTableUpdateRow(
  //     process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
  //     Number(userAccountNumber),
  //     { fields: clientToUpdate },
  //     String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
  //   ).then((data: any) => {
  //     console.log('saved:' + data);
  //     getUserData();
  //     setEdit(0);
  //   });
  // }

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Box w="100%">
      <Heading as={'h3'} size={'md'} my={5}>
        Client Information
      </Heading>
      <Box display={'flex'} flexDirection={'row'}>
        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="parents-first-name" fontWeight={'bold'}>
              Parents First Name
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Parents First Name']
            ) : (
              <Input
                id="parents-first-name"
                placeholder="Parents First Name"
                value={form.parentsFirstName}
                onChange={(e) => setForm({ ...form, parentsFirstName: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="parents-last-name" fontWeight={'bold'}>
              Parents Last Name
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Parents Last Name']
            ) : (
              <Input
                id="parents-last-name"
                placeholder="Parents Last Name"
                value={form.parentsLastName}
                onChange={(e) => setForm({ ...form, parentsLastName: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="phone" fontWeight={'bold'}>
              Phone
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Phone']
            ) : (
              <Input
                id="phone"
                placeholder="Phone"
                value={form.phone}
                onChange={(e) => setForm({ ...form, phone: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="mobile-1" fontWeight={'bold'}>
              Mobile 1
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Mobile 1']
            ) : (
              <Input
                id="mobile-1"
                placeholder="Mobile 1"
                value={form.mobile1}
                onChange={(e) => setForm({ ...form, mobile1: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>
        </Box>

        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="email-1" fontWeight={'bold'}>
              Email 1{' '}
              <Tooltip
                label={'Please email info@unlockinglangauge.co.uk to change this field'}
                style={{ cursor: 'pointer' }}
              >
                <Icon mt={0} />
              </Tooltip>
            </FormLabel>
            {/* {edit === 0 ? (
              user?.fields['Email 1']
            ) : (
              <Input
                id="email-1"
                placeholder="Email 1"
                value={form.email1}
                onChange={(e) => setForm({ ...form, email1: e.target.value })}
              />
            )} */}
            {user?.fields['Email 1']}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="email-2" fontWeight={'bold'}>
              Email 2{' '}
              <Tooltip
                label={'Please email info@unlockinglangauge.co.uk to change this field'}
                style={{ cursor: 'pointer' }}
              >
                <Icon mt={0} />
              </Tooltip>
            </FormLabel>
            {/* {edit === 0 ? (
              user?.fields['Email 2']
            ) : (
              <Input
                id="email-2"
                placeholder="Email 2"
                value={form.email2}
                onChange={(e) => setForm({ ...form, email2: e.target.value })}
              />
            )} */}
            {user?.fields['Email 2']}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="address" fontWeight={'bold'}>
              Address
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Address']
            ) : (
              <Input
                id="address"
                placeholder="Address"
                value={form.address}
                onChange={(e) => setForm({ ...form, address: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="access-level" fontWeight={'bold'}>
              Current Subscription{' '}
              {/* <Tooltip
                label={'Please email info@unlockinglangauge.co.uk to change your subscription'}
                style={{ cursor: 'pointer' }}
              >
                <Icon mt={0} />
              </Tooltip> */}
            </FormLabel>
            {/* {edit === 0 ? ( */}
            {user?.fields['Access level']}
            {/* ) : (
              <Input
                id="address"
                placeholder="Access level"
                value={form.accessLevel}
                onChange={(e) => setForm({ ...form, accessLevel: e.target.value })}
              />
            )} */}
          </FormControl>
        </Box>

        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="additional-party-involved" fontWeight={'bold'}>
              Additonal party involved: Solicitor, Court, School, Local Authority?{' '}
            </FormLabel>
            {edit === 0 ? (
              user?.fields[
                'Additonal party involved: Solicitor, Court, School, Local Authority?'
              ] !== true ? (
                'No'
              ) : (
                'Yes'
              )
            ) : (
              <Select
                id="additional-party-involved"
                // placeholder="Additonal party involved: Solicitor, Court, School, Local Authority?"
                onChange={(e) => setForm({ ...form, additionalPartyInvolved: e.target.value })}
                value={form.additionalPartyInvolved}
                bg={'white'}
              >
                {}
                <option value={'Yes'}>Yes</option>
                <option value={'No'}>No</option>
              </Select>
            )}
          </FormControl>
          {form.additionalPartyInvolved === 'Yes' && (
            <FormControl mb="5%">
              <FormLabel htmlFor="additional-party-details" fontWeight={'bold'}>
                Who is funding the session?
              </FormLabel>
              {edit === 0 ? (
                user?.fields['Additional party details']
              ) : (
                <Input
                  id="additional-party-details"
                  placeholder="Additional party details"
                  value={form.additionalPartyDetails}
                  onChange={(e) => setForm({ ...form, additionalPartyDetails: e.target.value })}
                  bg={'white'}
                />
              )}
            </FormControl>
          )}
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} alignItems={'right'} justifyContent={'right'}>
        <FormControl>
          <FormLabel htmlFor="" fontWeight={'bold'}></FormLabel>
          {edit === 0 ? (
            user?.fields['Client info change request pending'] !== true ? (
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
              >
                Request changes
              </Button>
            ) : (
              <Button isDisabled>Request changes awaiting approval</Button>
            )
          ) : (
            <HStack>
              <Button variant={'defaultButton'} onClick={() => sendDataToMake()}>
                Save
              </Button>
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
              >
                Cancel
              </Button>
            </HStack>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};
export default ProfileClientInfo;
