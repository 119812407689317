import {
  Box,
  Button,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  IconButton,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  useClipboard,
  Input,
  FormControl,
  FormLabel,
  Checkbox,
  CheckboxGroup,
  Divider,
  HStack,
  Select,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import React, { ReactNode, useEffect, useState } from 'react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getAllProfiles from '../utils/getAllProfiles';
import { BiCopy } from 'react-icons/bi';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { UserMeta } from '../interfaces/UserMeta';
import appendixA from '../constants/reporting/appendix_a.json';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import getNinoxData from '../utils/getNinoxData';
import getAllRowsByRowId from '../utils/getAllRowsByRowId';
import getAllRowsById from '../utils/getAllRowsById';
import getMultipleApiData from '../utils/getMultipleApiData';
import getDataFromEndPoint from '../utils/getDataFromEndPoint';
import getAllInvoicesData from '../utils/getAllInvoicesData';
// import getInvoice from './../utils/getInvoice';

interface ClientsInvoicesDashboard {
  invoices: any;
  setInvoices: Function;
  invoicesLineItems: any;
  setInvoiceLineItems: Function;
  invoicesLineItemsData: any;
  setInvoicesLineItemsData: Function;
}
const ClientInvoicesDashboard = ({
  invoices,
  setInvoices,
  invoicesLineItems,
  setInvoiceLineItems,
  invoicesLineItemsData,
  setInvoicesLineItemsData,
}: ClientsInvoicesDashboard) => {
  // const [invoices, setInvoices] = useState<any>([]);
  // const [invoicesLineItems, setInvoiceLineItems] = useState<any>([]);
  // const [invoicesLineItemsData, setInvoicesLineItemsData] = useState<any>([]);
  // const [xeroToken, setXeroToken] = useState<any>();

  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const ulClientsInvoicesTable = process.env.REACT_APP_UL_INVOICES_TABLE;
  const ulClientsInvoicesLineItemsTable = process.env.REACT_APP_UL_INVOICES_LINE_ITEM_TABLE;
  const invoicesServerlessEP = process.env.REACT_APP_SERVERLESS_INVOICES_EP;
  const xeroInvoicesTokenEP = process.env.REACT_APP_SERVERLESS_TOKEN_EP;
  const loggedInUserId = localStorage.getItem('accountNumber');
  const loggedInUsersInvoices = localStorage.getItem('invoices')?.split(',').map(Number);
  // console.log('loggedInUserId', loggedInUserId);
  // console.log('loggedInUsersInvoices', loggedInUsersInvoices);
  const xeroInvoicesEP = 'https://api.xero.com/api.xro/2.0/Invoices/';

  // async function getInvoice(xeroInvoicesEP: string, invoiceId: number, xeroAccessToken: string) {
  //   // const apiUrl = `${xeroInvoicesEP}${invoiceId}`;
  //   const apiUrl = `https://api.xero.com/api.xro/2.0/Invoices/${invoiceId}`;
  //   const requestOptions = {
  //     method: 'GET',
  //     headers: {
  //       Authorization: `Bearer ${xeroAccessToken}`,
  //       'Content-Type': 'application/json',
  //     },
  //   };

  //   fetch(apiUrl, requestOptions)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       // 3. Extract the URL of the PDF from the invoice data
  //       const pdfUrl = data.Invoices[0].OnlineInvoiceUrl;
  //       console.log('PDF URL:', pdfUrl);
  //       return pdfUrl;
  //     })
  //     .catch((error) => console.error('Error:', error));
  // }
  const getPdfInvoiceServerlessEP =
    'https://client-portal-serverless-functions.vercel.app/api/getXeroPdfInvoiceUrl';

  async function getInvoiceFromXeroServerlessFunction(
    xeroInvoicesEP: string,
    invoiceId: number,
    xeroAccessToken: any
  ) {
    const requestBody = {
      xeroInvoicesEP: xeroInvoicesEP,
      invoiceId: invoiceId,
      xeroAccessToken: xeroAccessToken.token_type + ' ' + xeroAccessToken.access_token,
    };
    // console.log('requestBody: ', requestBody);

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(getPdfInvoiceServerlessEP, requestOptions);
    const data = await response.text;

    return data;
  }

  useEffect(() => {
    // getMultipleApiData(ulClientsInvoicesTable!, bearerToken!, loggedInUsersInvoices).then(
    //   async (data: any) => {
    //     let values = data
    //       .filter((i: any) => i.fields['Invoice Line Item'] !== undefined)
    //       .map((z: any, k: any) => z.fields['Invoice Line Item']);
    //     // console.log(values.toString());
    //     setInvoicesLineItemsData(values.toString()?.split(',').map(Number));
    //     const thisData = values.toString()?.split(',').map(Number);
    //     setInvoiceLineItems(
    //       await getMultipleApiData(ulClientsInvoicesLineItemsTable!, bearerToken!, thisData)
    //     );
    //     setInvoices(data);
    //   }
    // );
    // getDataFromEndPoint(xeroInvoicesTokenEP!).then((myToken) => {
    //   // console.log('Token data:', myToken);
    //   setXeroToken(myToken);
    // });
  }, []);

  // getAllInvoicesData(xeroInvoicesEP, invoicesServerlessEP!, thisInvoice, xeroToken)
  //   .then((data) => {
  //     console.log(data);
  //     // setInvoiceAmounts(data);
  //   })
  //   .catch((error) => error);

  return (
    <>
      <Box>
        <Box bg="white">
          <Heading pb={6}>Invoices</Heading>
          {/* <Box>{JSON.stringify(invoicesLineItemsData)}</Box>
          <hr></hr>
          <Box>{JSON.stringify(invoicesLineItems)}</Box> */}
          <Table variant="simple">
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab>Outstanding</Tab>
                <Tab>Paid</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Thead>
                    <Tr>
                      {/* <Th>ID</Th> */}
                      <Th>Invoice number</Th>
                      <Th>Invoice Date</Th>
                      {/* <Th>Invoice Due Date</Th>
                      <Th>Invoice reference</Th> */}
                      <Th>Invoice value</Th>
                      <Th>
                        Manage{' '}
                        <Tooltip
                          label={'Pay invoices or send an invoice enquiry'}
                          style={{ cursor: 'pointer' }}
                        >
                          <Icon mt={0} />
                        </Tooltip>
                      </Th>
                      <Th>View invoice</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invoices &&
                      invoices
                        ?.filter(
                          (i: any) =>
                            i?.fields['Invoice Posted to Xero?'] === true &&
                            i?.fields['Invoice Status'] === 'Unpaid'
                        )
                        .map((i: any, k: any) => (
                          <Tr key={k}>
                            {/* <Td>{i?.id}</Td> */}
                            <Td>{i?.fields['Invoice No']}</Td>
                            <Td>{i?.fields['Invoice Date']}</Td>
                            {/* <Td>{i?.fields['Invoice Due Date']}</Td>
                            <Td>{i?.fields['Reference']}</Td> */}
                            <Td>
                              {/* {i?.fields['Invoice Line Item']} === {i.id} ||| */}
                              {i?.fields['Invoice Line Item'] !== undefined
                                ? '£' +
                                  invoicesLineItems
                                    .filter((z: any) => z?.fields['Invoices'] === i.id)
                                    .map(
                                      (i: any, k: any) => i.fields['Quantity'] * i.fields['Price']
                                    )
                                    .reduce((sum: number, num: number) => sum + num)
                                : '0'}
                            </Td>
                            <Td>
                              {i?.fields['Invoice Status'] === 'Unpaid' ? (
                                <Link
                                  to={{
                                    pathname: `/pay-invoice`,
                                    search: `id=${i.id}&invoice_number=${i.fields['Invoice No']}`,
                                  }}
                                  state={{
                                    lineItems:
                                      i?.fields['Invoice Line Item'] !== undefined
                                        ? invoicesLineItems
                                            ?.filter((z: any) => z?.fields['Invoices'] === i.id)
                                            .map(
                                              (i: any, k: any) =>
                                                i.fields['Quantity'] * i.fields['Price']
                                            )
                                        : '0',
                                    invoiceValue:
                                      i?.fields['Invoice Line Item'] !== undefined
                                        ? invoicesLineItems
                                            ?.filter((z: any) => z?.fields['Invoices'] === i.id)
                                            .map(
                                              (i: any, k: any) =>
                                                i.fields['Quantity'] * i.fields['Price']
                                            )
                                            .reduce((sum: number, num: number) => sum + num)
                                        : '',
                                    invoiceNumber: i.fields['Invoice No'],
                                    quantity: i.fields['Quantity'],
                                  }}
                                >
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                  >
                                    Manage
                                  </Button>
                                </Link>
                              ) : (
                                i?.fields['Invoice Status']
                              )}
                            </Td>
                            <Td>
                              {i?.fields['Xero Invoice URL'] === undefined ? (
                                ''
                              ) : (
                                <Link to={i?.fields['Xero Invoice URL']} target="_blank">
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                    // onClick={(e) => {
                                    //   getDataFromEndPoint(xeroInvoicesTokenEP!).then((myToken: any) => {
                                    //     getInvoiceFromXeroServerlessFunction(
                                    //       getPdfInvoiceServerlessEP,
                                    //       i?.fields['Invoice No'],
                                    //       myToken
                                    //     ).then((data) => {
                                    //       console.log(data);
                                    //       return data;
                                    //     });
                                    //   });
                                    // }}
                                    // "https://in.xero.com/4UmJFXqwkjd9bYpsOgwdvOIp2UqTW8QRZJhPE1B4"
                                  >
                                    View Invoice
                                  </Button>
                                </Link>
                              )}
                            </Td>
                          </Tr>
                        ))}
                    {invoices === undefined && <TableCaption>No invoices found</TableCaption>}
                  </Tbody>
                </TabPanel>
                <TabPanel>
                  <Thead>
                    <Tr>
                      {/* <Th>ID</Th> */}
                      <Th>Invoice number</Th>
                      <Th>Invoice Date</Th>
                      <Th>Invoice Due Date</Th>
                      <Th>Invoice reference</Th>
                      <Th>Invoice value</Th>
                      <Th>Invoice status</Th>
                      <Th>View invoice</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {invoices &&
                      invoices
                        ?.filter(
                          (i: any) =>
                            i?.fields['Invoice Posted to Xero?'] === true &&
                            i?.fields['Invoice Status'] === 'Paid'
                        )
                        .map((i: any, k: any) => (
                          <Tr key={k}>
                            {/* <Td>{i?.id}</Td> */}
                            <Td>{i?.fields['Invoice No']}</Td>
                            <Td>{i?.fields['Invoice Date']}</Td>
                            <Td>{i?.fields['Invoice Due Date']}</Td>
                            <Td>{i?.fields['Reference']}</Td>
                            <Td>
                              {/* {i?.fields['Invoice Line Item']} === {i.id} ||| */}
                              {i?.fields['Invoice Line Item'] !== undefined
                                ? '£' +
                                  invoicesLineItems
                                    .filter((z: any) => z?.fields['Invoices'] === i.id)
                                    .map(
                                      (i: any, k: any) => i.fields['Quantity'] * i.fields['Price']
                                    )
                                    .reduce((sum: number, num: number) => sum + num)
                                : '0'}
                            </Td>
                            <Td>
                              {i?.fields['Invoice Status'] === 'Unpaid' ? (
                                <Link
                                  to={{
                                    pathname: '/other',
                                  }}
                                  state={{
                                    lineItems:
                                      i?.fields['Invoice Line Item'] !== undefined
                                        ? invoicesLineItems
                                            ?.filter((z: any) => z?.fields['Invoices'] === i.id)
                                            .map(
                                              (i: any, k: any) =>
                                                i.fields['Quantity'] * i.fields['Price']
                                            )
                                        : '0',
                                    invoiceValue:
                                      i?.fields['Invoice Line Item'] !== undefined
                                        ? invoicesLineItems
                                            ?.filter((z: any) => z?.fields['Invoices'] === i.id)
                                            .map(
                                              (i: any, k: any) =>
                                                i.fields['Quantity'] * i.fields['Price']
                                            )
                                            .reduce((sum: number, num: number) => sum + num)
                                        : '',
                                    invoiceNumber: i.fields['Invoice No'],
                                    description: i.fields['Description'],
                                    quantity: i.fields['Quantity'],
                                  }}
                                >
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                  >
                                    View Invoice
                                  </Button>
                                </Link>
                              ) : (
                                i?.fields['Invoice Status']
                              )}
                            </Td>
                            <Td>
                              {/* <Button
                                bg={'brand.300'}
                                _hover={{
                                  bg: 'brand.200',
                                }}
                                color={'white'}
                                onClick={(e) => {
                                  getInvoiceFromXeroServerlessFunction(
                                    xeroInvoicesEP,
                                    i?.fields['Invoice No'],
                                    xeroToken
                                  ).then((data) => {
                                    return data;
                                  });
                                }}
                              >
                                View invoice ===
                              </Button> */}
                              {i?.fields['Xero Invoice URL'] === undefined ? (
                                ''
                              ) : (
                                <Link to={i?.fields['Xero Invoice URL']} target="_blank">
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                    // onClick={(e) => {
                                    //   getDataFromEndPoint(xeroInvoicesTokenEP!).then((myToken: any) => {
                                    //     getInvoiceFromXeroServerlessFunction(
                                    //       getPdfInvoiceServerlessEP,
                                    //       i?.fields['Invoice No'],
                                    //       myToken
                                    //     ).then((data) => {
                                    //       console.log(data);
                                    //       return data;
                                    //     });
                                    //   });
                                    // }}
                                    // "https://in.xero.com/4UmJFXqwkjd9bYpsOgwdvOIp2UqTW8QRZJhPE1B4"
                                  >
                                    View Invoice
                                  </Button>
                                </Link>
                              )}
                            </Td>
                          </Tr>
                        ))}

                    {invoices === undefined && <TableCaption>No invoices found</TableCaption>}
                  </Tbody>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default ClientInvoicesDashboard;
