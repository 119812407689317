import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  Link,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

export const TomsRatingModal = ({
  tr,
  id,
  modalState,
  setTheModal,
  setOpenedModal,
  theModal,
  student,
  tomsEdit,
  reason,
  tomsRatingChoicesOptional,
  handleClassSelectionUpdate,
  loadTr,
  setTomsEdit,
  updateTomsRating,
  sessionId,
  today,
  setLoadTr,
  updateTr,
  setUpdateTr,
  createdAt,
}: any) => {
  const [changeDate, setChangeDate] = useState(0);
  // useEffect(() => {
  //   // setLoadTr()
  //   setLoadTr({
  //     'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
  //     'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
  //     'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
  //     'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
  //     'Participation Rating': tr?.fields['Participation Rating'],
  //     'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
  //     'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
  //     'Rating Date': today,
  //     Caseload: Number(sessionId),
  //   });
  //   setUpdateTr({
  //     'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
  //     'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
  //     'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
  //     'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
  //     'Participation Rating': tr?.fields['Participation Rating'],
  //     'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
  //     'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
  //     'Rating Date': today,
  //     Caseload: Number(sessionId),
  //   });
  // }, []);

  // useEffect(() => {
  //   // setLoadTr()
  //   setUpdateTr({
  //     'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
  //     'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
  //     'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
  //     'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
  //     'Participation Rating': tr?.fields['Participation Rating'],
  //     'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
  //     'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
  //     'Rating Date': today,
  //     Caseload: Number(sessionId),
  //   });
  // }, [tr]);

  // useEffect(() => {
  //   // setLoadTr()
  //   setLoadTr({
  //     'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
  //     'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
  //     'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
  //     'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
  //     'Participation Rating': tr?.fields['Participation Rating'],
  //     'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
  //     'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
  //     'Rating Date': today,
  //     Caseload: Number(sessionId),
  //   });
  // }, [updateTr]);
  const tomsRatingChoicesOptionalRemap = [
    { id: 0, item: 'Please select an option or leave empty' },
    { id: 1, item: '0' },
    { id: 2, item: '0.5' },
    { id: 3, item: '1' },
    { id: 4, item: '1.5' },
    { id: 5, item: '2' },
    { id: 6, item: '2.5' },
    { id: 7, item: '3' },
    { id: 8, item: '3.5' },
    { id: 9, item: '4' },
    { id: 10, item: '4.5' },
    { id: 11, item: '5' },
  ];

  const ratingFields = [
    { value: 'irp', label: 'Impairment Rating Primary' },
    { value: 'irs', label: 'Impairment Rating Secondary' },
    { value: 'arp', label: 'Activity Rating Primary' },
    { value: 'ars', label: 'Activity Rating Secondary' },
    { value: 'pr', label: 'Participation Rating' },
    { value: 'wr', label: 'Wellbeing Rating' },
    { value: 'cwr', label: 'Carer Wellbeing Rating' },
  ];

  let nameToFindIRP = loadTr['Impairment Rating Primary'];
  let itemIRP = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindIRP)
  );
  let selectedIRP = itemIRP ? itemIRP.id : undefined;

  let nameToFindIRS = loadTr['Impairment Rating Secondary'];
  let itemIRS = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindIRS)
  );
  let selectedIRS = itemIRS ? itemIRS.id : undefined;

  let nameToFindARP = loadTr['Activity Rating Primary'];
  let itemARP = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindARP)
  );
  let selectedARP = itemARP ? itemARP.id : undefined;

  let nameToFindARS = loadTr['Activity Rating Secondary'];
  let itemARS = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindARS)
  );
  let selectedARS = itemARS ? itemARS.id : undefined;

  let nameToFindPR = loadTr['Participation Rating'];
  let itemPR = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindPR)
  );
  let selectedPR = itemPR ? itemPR.id : undefined;

  let nameToFindWR = loadTr['Wellbeing Rating'];
  let itemWR = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindWR)
  );
  let selectedWR = itemWR ? itemWR.id : undefined;

  let nameToFindCWR = loadTr['Carer Wellbeing Rating'];
  let itemCWR = tomsRatingChoicesOptionalRemap.find(
    (choice: any) => String(choice.item) === String(nameToFindCWR)
  );
  let selectedCWR = itemCWR ? itemCWR.id : undefined;
  const now = moment(new Date()); //todays date
  const end = moment(createdAt); // another date
  const duration = moment.duration(now.diff(end));
  const hours = duration.asHours().toFixed(2);
  console.log('hours:', hours);
  return (
    <Box as="section">
      <Modal
        onClose={() => {
          setTheModal(0);
          setOpenedModal(false);
        }}
        size={'3xl'}
        isOpen={theModal === id ? modalState : false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Toms Rating: {student?.fields['Student/Patient Name']}</ModalHeader>
          {/* <FormControl mb={'5'} key={k}>
                    <FormLabel>{i.label}</FormLabel>
                    <Select isRequired onChange={(e) => handleClassSelection(e, i, k)}>
                      {k === 1 || k === 3 || k === 6
                        ? tomsRatingChoicesOptional?.map((i: any, k: number) => (
                            <option value={k} key={k}>
                              {i}
                            </option>
                          ))
                        : tomsRatingChoicesRequired?.map((i: any, k: number) => (
                            <option value={k} key={k}>
                              {i}
                            </option>
                          ))}
                    </Select>
                  </FormControl> */}
          <ModalCloseButton />
          {tomsEdit === 1 ? (
            <ModalBody>
              {/* <Box bg={'pink'}>loadTr:{JSON.stringify(loadTr)}</Box>
              <Box bg={'yellow'}>updateTr:{JSON.stringify(updateTr)}</Box> */}

              <Box mb={'5'}>
                <Text fontWeight={'bold'}>TOMS Rating ID</Text>
                <Text>{tr?.id}</Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Rating Date</Text>
                <HStack>
                  <Text>
                    {tr?.fields['Rating Date'] === undefined ? reason : tr?.fields['Rating Date']}
                  </Text>
                  <DatePicker
                    dateFormat="Pp"
                    customInput={<Button>Change date</Button>}
                    required={true}
                    selected={loadTr['Rating Date']}
                    onChange={(date: any) => {
                      setLoadTr({ ...loadTr, 'Rating Date': date });
                      setUpdateTr({ ...updateTr, 'Rating Date': date });
                      setChangeDate(1);
                    }}
                  />
                </HStack>

                {changeDate === 1 && (
                  <Box>
                    <Text fontWeight={'bold'}>Updated date: </Text>
                    <Text mb={5}>{moment(loadTr['Rating Date']).format('YYYY-MM-DD')}</Text>
                  </Box>
                )}
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Primary</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'irp', tr?.id)}
                  value={String(loadTr['Impairment Rating Primary'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Secondary</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'irs', tr?.id)}
                  value={String(loadTr['Impairment Rating Secondary'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Primary</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'arp', tr?.id)}
                  value={String(loadTr['Activity Rating Primary'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Secondary</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'ars', tr?.id)}
                  value={String(loadTr['Activity Rating Secondary'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Participation Rating</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'pr', tr?.id)}
                  value={String(loadTr['Participation Rating'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Wellbeing Rating</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'wr', tr?.id)}
                  value={String(loadTr['Wellbeing Rating'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Carer Wellbeing Rating</Text>
                <Select
                  isRequired
                  onChange={(e) => handleClassSelectionUpdate(e, 'cwr', tr?.id)}
                  // value={selectedCWR}
                  value={String(loadTr['Carer Wellbeing Rating'])}
                >
                  {tomsRatingChoicesOptional?.map((i: any, k: number) => {
                    return (
                      <option value={i} key={k}>
                        {i}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </ModalBody>
          ) : (
            <ModalBody>
              {/* {JSON.stringify(tr)} */}
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>TOMS Rating ID</Text>
                <Text>{tr?.id}</Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Rating Date</Text>
                <Text>
                  {tr?.fields['Rating Date'] === undefined ? reason : tr?.fields['Rating Date']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Primary</Text>
                <Text>
                  {tr?.fields['Impairment Rating Primary'] === undefined
                    ? reason
                    : tr?.fields['Impairment Rating Primary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Impairment Rating Secondary</Text>
                <Text>
                  {tr?.fields['Impairment Rating Secondary'] === undefined
                    ? reason
                    : tr?.fields['Impairment Rating Secondary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Primary</Text>
                <Text>
                  {tr?.fields['Activity Rating Primary'] === undefined
                    ? reason
                    : tr?.fields['Activity Rating Primary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Activity Rating Secondary</Text>
                <Text>
                  {tr?.fields['Activity Rating Secondary'] === undefined
                    ? reason
                    : tr?.fields['Activity Rating Secondary']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Participation Rating</Text>
                <Text>
                  {tr?.fields['Participation Rating'] === undefined
                    ? reason
                    : tr?.fields['Participation Rating']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Wellbeing Rating</Text>
                <Text>
                  {tr?.fields['Wellbeing Rating'] === undefined
                    ? reason
                    : tr?.fields['Wellbeing Rating']}
                </Text>
              </Box>
              <Box mb={'5'}>
                <Text fontWeight={'bold'}>Carer Wellbeing Rating</Text>
                <Text>
                  {tr?.fields['Carer Wellbeing Rating'] === undefined
                    ? reason
                    : tr?.fields['Carer Wellbeing Rating']}
                </Text>
              </Box>
            </ModalBody>
          )}
          <ModalFooter>
            <HStack>
              {/* <Box>{Number(hours) <= Number(24) ? 'less than 24hrs' : 'more than 24hrs'}</Box> */}
              {tomsEdit === 1 ? (
                <Button
                  onClick={() => {
                    // setTheModal(0);
                    // setOpenedModal(false);
                    setTomsEdit(0);
                    updateTomsRating(tr.id);
                    setTheModal(0);
                    setOpenedModal(false);
                    setChangeDate(0);
                  }}
                >
                  Save changes
                </Button>
              ) : Number(hours) <= Number(6) ? (
                <Button
                  onClick={() => {
                    // setTheModal(0);
                    // setOpenedModal(false);

                    setTomsEdit(1);
                    // console.log({
                    //   'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
                    //   'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
                    //   'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
                    //   'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
                    //   'Participation Rating': tr?.fields['Participation Rating'],
                    //   'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
                    //   'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
                    //   'Rating Date': today,
                    //   Caseload: Number(sessionId),
                    // });
                    setLoadTr({
                      'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
                      'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
                      'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
                      'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
                      'Participation Rating': tr?.fields['Participation Rating'],
                      'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
                      'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
                      'Rating Date': today,
                      Caseload: Number(sessionId),
                    });
                    setUpdateTr({
                      // 'Impairment Rating Primary': tr?.fields['Impairment Rating Primary'],
                      // 'Impairment Rating Secondary': tr?.fields['Impairment Rating Secondary'],
                      // 'Activity Rating Primary': tr?.fields['Activity Rating Primary'],
                      // 'Activity Rating Secondary': tr?.fields['Activity Rating Secondary'],
                      // 'Participation Rating': tr?.fields['Participation Rating'],
                      // 'Wellbeing Rating': tr?.fields['Wellbeing Rating'],
                      // 'Carer Wellbeing Rating': tr?.fields['Carer Wellbeing Rating'],
                      // 'Rating Date': today,
                      Caseload: Number(sessionId),
                    });
                  }}
                >
                  Edit
                </Button>
              ) : (
                <small>Unable to edit note as 24hrs have passed</small>
              )}
              <Button
                onClick={() => {
                  setTheModal(0);
                  setOpenedModal(false);
                  setTomsEdit(0);
                }}
              >
                Close
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
