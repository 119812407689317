import { extendTheme, defineStyleConfig, defineStyle } from '@chakra-ui/react';
/* theme.ts */
const baseStyle = {
  // select the indicator part

  indicator: {
    // change the default border radius to 0
    // color: 'red',
    // background: 'pink',
    // borderColor: 'orange',
    borderRadius: 0,
    // variants: {
    //   'data-status=complete': {
    //     border: '2px solid',
    //     borderColor: 'green.500',
    //   },
    //   solid: {
    //     bg: 'green.500',
    //     color: 'white',
    //   },
    // },
  },
};

const defaultButton = {
  backgroundColor: 'brand.300',
  color: 'white',
  _hover: {
    backgroundColor: 'brand.200',
    color: 'white',
  },
};
const stepperTheme = {
  baseStyle,
};

// const outline = defineStyle({
//   border: '2px dashed', // change the appearance of the border
//   borderRadius: 0, // remove the border radius
//   fontWeight: 'semibold', // change the font weight
// })

export const buttonTheme = defineStyleConfig({
  variants: { defaultButton },
});

export const theme = extendTheme({
  colors: {
    brand: {
      25: '#ffffff',
      50: '#FF0000',
      100: '#D6D944',
      200: '#40A83D',
      300: '#0C7348',
      400: '#2D444A',
      500: '#2D444A',

      'stepper-accent-color': 'red',
    },
  },
  fonts: {
    heading: 'var(--font-rubik)',
    body: 'var(--font-rubik)',
  },
  components: {
    Stepper: stepperTheme,
    Button: buttonTheme,
  },
});
