import ninoxTableGetRow from './ninoxTableGetRow';

export default function getMultipleApiDataNonAsync(
  endPoint: string,
  bearerToken: string,
  items: number[] | any
) {
  let endPointsFetch: object[] = [];

  items.forEach((item: any) => {
    endPointsFetch.push(
      ninoxTableGetRow(endPoint, item, bearerToken).then((response) => {
        return response;
      })
    );
  });

  const results = Promise.all(endPointsFetch);

  return results;
}
