import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Flex,
  Stack,
  Heading,
  Text,
  Link,
  Button,
  Box,
  ButtonGroup,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  FormHelperText,
  Image,
} from '@chakra-ui/react';
import {} from '@chakra-ui/react';
import StepOne from '../components/subscriberSignUpSteps/StepOne';
import StepTwo from '../components/subscriberSignUpSteps/StepTwo';
import StepThree from '../components/subscriberSignUpSteps/StepThree';
import StepFour from '../components/subscriberSignUpSteps/StepFour';
import StepFive from '../components/subscriberSignUpSteps/StepFive';
import UL_logo from './../logo/unlocking_language_logo_white.png';
import { privateForm } from '../interfaces/privateForm';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import { useNavigate } from 'react-router-dom';

// import { SmallCloseIcon } from '@chakra-ui/icons';
// const steps = [
//   { title: 'Details', description: 'Fill in your details', content: 'Content 1' },
//   {
//     title: 'Contract',
//     description: 'View & Sign Contract',
//     content: 'Content 3',
//   },
//   { title: 'Subscription type', description: 'Select your subscription', content: 'Content 4' },
//   { title: 'Payment', description: 'Make the first payment', content: 'Content 5' },
// ];
const steps = [
  { title: 'Subscription', description: 'Select a subscription', content: 'Content 1' },
  {
    title: 'Setup your account',
    description: 'Finalise your account',
    content: 'Content 4',
  },
  { title: 'Get started', description: 'Access portal', content: 'Content 5' },
];
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const ulDb = process.env.REACT_APP_UL_DB;
const ulTherapistsTable = process.env.REACT_APP_UL_ALL_THERAPIST_PROFILES;
const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
const ulSubscriberTable = process.env.REACT_APP_SUBSCRIBER_TABLE;
export default function SubscriberSignUp(): JSX.Element {
  const queryParameters = new URLSearchParams(window.location.search);
  const subscribeStep = queryParameters.get('step');
  const sid = queryParameters.get('id');
  const navigate = useNavigate();

  const defaultForm = {
    firstname: '',
    lastname: '',
    address: '',
    email: '',
    contactNumber: '',
    password: '',
    cpassword: '',
    contract: 0,
  };
  const [form, setForm] = useState<privateForm>(defaultForm);
  const [signContract, setSignContract] = useState(0);
  const [packageSelected, setPackageSelected] = useState('none');
  const [stepFromUrl, setStepFromUrl] = useState(subscribeStep);
  const { activeStep, setActiveStep } = useSteps(
    subscribeStep === 'subscribe'
      ? {
          index: 0,
          count: steps.length,
        }
      : {
          index: 2,
          count: steps.length,
        }
  );

  function goBackOneStep(activeStep: number) {
    setActiveStep(activeStep - 1);
  }
  function getTheContractField() {}
  function signTheContract() {
    // insert client
    // get contract field
    // run conditional
    // go to ninox
    // update contract field
    // get contract field
    // run conditional
  }
  const createAccount = async () => {
    const userToAdd = {
      Active: signContract,
      'Email address': form.email,
      'First name': form.firstname,
      'Last name': form.lastname,
      // Address: form.address,
      // contactNumber: form.contactNumber,
    };
    console.log('Account creation triggered');
    try {
      await auth.createUserWithEmailAndPassword(form.email, form.password);
      ninoxTableAddRow(ulSubscriberTable, { fields: userToAdd }, bearerToken).then(
        (data: any) => data
      );
      console.log('Account created');
    } catch (error) {
      console.error(error);
      console.log('Account creation failed');
    }
  };

  function collectDataAndSignup() {
    setActiveStep(activeStep + 1);
    activeStep === 1 && createAccount();
  }
  const stepSelect = steps[activeStep].title;
  function createAccountAndRedirect() {
    createAccount();
    navigate('/dashboard-subscriber');
  }
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'6xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        {/* <FormControl id="userName" isRequired>
          <FormLabel>User name</FormLabel>
          <Input placeholder="UserName" _placeholder={{ color: 'gray.500' }} type="text" />
        </FormControl> */}

        <Stack align={'left'} mb={'50px'}>
          <HStack width={'100%'}>
            <Box width={'50%'}>
              <Heading as={'h4'}>Subscriber sign up</Heading>
            </Box>
            <Box display={'flex'} alignItems={'right'} textAlign={'right'} width={'50%'}>
              <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
          </HStack>
          <Text fontSize={'lg'} color={'gray.600'}>
            Get started today
          </Text>
        </Stack>
        <Stepper index={activeStep} colorScheme="brand">
          {steps.map(
            (step, index) =>
              activeStep <= 4 && (
                <>
                  <Step key={index}>
                    <StepIndicator
                    // color={'white'}
                    // bg={'red'}
                    // border={'1px solid red'}
                    // borderColor={'red'}
                    // background={'red'}
                    >
                      <StepStatus
                        complete={
                          <StepIcon
                          // border={'1px solid red'}
                          // borderColor={'red'}
                          // background={'red'}
                          />
                        }
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                        // color={'red'}
                        // border={'1px solid red'}>
                      />
                    </StepIndicator>

                    <Box flexShrink="0">
                      <StepTitle>{step.title}</StepTitle>
                      <StepDescription>{step.description}</StepDescription>
                    </Box>

                    <StepSeparator />
                  </Step>
                </>
              )
          )}
        </Stepper>
        {stepFromUrl == 'subscribe' ? (
          <Stack spacing={6} direction={['column', 'row']}>
            {/* <StepOne title={steps[activeStep].title} form={form} setForm={setForm} />
             */}
            <StepThree
              title={steps[activeStep].title}
              form={form}
              setForm={setForm}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              packageSelected={packageSelected}
              setPackageSelected={setPackageSelected}
            ></StepThree>
          </Stack>
        ) : (
          ''
        )}

        {stepFromUrl == 'account' && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepOne
              title={'Setup your account'}
              subCheckoutId={sid && sid}
              form={form}
              setForm={setForm}
              createAccountAndRedirect={createAccountAndRedirect}
            />
          </Stack>
        )}

        {/* {activeStep === 1 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepTwo
              title={steps[activeStep].title}
              form={form}
              setForm={setForm}
              signContract={signContract}
              setSignContract={setSignContract}
            />
          </Stack>
        )}

        {activeStep === 2 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepThree
              title={steps[activeStep].title}
              form={form}
              setForm={setForm}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              packageSelected={packageSelected}
              setPackageSelected={setPackageSelected}
            ></StepThree>
          </Stack>
        )}
        {activeStep === 3 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepFour
              title={steps[activeStep].title}
              setActiveStep={setActiveStep}
              activeStep={setActiveStep}
              packageSelected={packageSelected}
              setPackageSelected={setPackageSelected}
            />
          </Stack>
        )}
        {activeStep === 4 && (
          <Stack spacing={6} direction={['column', 'row']}>
            <StepFive index={activeStep}>Thank you for completing all steps</StepFive>
          </Stack>
        )} */}
        <Stack spacing={6} direction={['column', 'row']}>
          {/* {activeStep === 0 && (
            <>
              <Button
                bg={'brand.300'}
                color={'white'}
                w="full"
                _hover={{
                  bg: 'brand.200',
                }}
                onClick={() => goBackOneStep(activeStep)}
              >
                Back
              </Button>
              <Button
                bg={'brand.200'}
                color={'white'}
                w="full"
                _hover={{
                  bg: 'brand.300',
                }}
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Next
              </Button>
            </>
          )} */}
          {/* <>
            <Button
              bg={'brand.300'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'brand.200',
              }}
              onClick={() => goBackOneStep(activeStep)}
            >
              Back
            </Button>

            <Button
              bg={'brand.200'}
              color={'white'}
              w="full"
              _hover={{
                bg: 'brand.300',
              }}
              onClick={() => {
                collectDataAndSignup();
              }}
              isDisabled={
                activeStep === 0
                  ? form.password !== form.cpassword
                    ? true
                    : false
                  : activeStep === 1
                  ? signContract === 0
                    ? true
                    : false
                  : false
              }
            >
              Next
            </Button>
          </> */}
          {activeStep === 0 || (activeStep === 1 && <></>)}
          {activeStep >= 4 && (
            <>
              <Link href={'/'}>
                <Button
                  bg={'brand.300'}
                  color={'white'}
                  w="full"
                  _hover={{
                    bg: 'brand.200',
                  }}
                >
                  Back to Login select
                </Button>
              </Link>
            </>
          )}
        </Stack>
        <Outlet />
      </Stack>
    </Flex>
  );
}
