export default async function ninoxTableDeleteRow(
  endpoint: string,
  rowId: number,
  requestBody: object,
  bearerToken: string
) {
  // Ensure Authorization header is properly formatted
  const headers = {
    Authorization: `${bearerToken}`,
    'Content-Type': 'application/json',
  };

  const requestOptions = {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(requestBody),
  };

  try {
    // Use template literals for URL construction
    const response = await fetch(`${endpoint}/${rowId}`, requestOptions);

    // Check if the response status is 204 (No Content)
    if (response.status === 204) {
      return null; // Return null or an appropriate value for no content
    }

    // Check if the response is ok (status is in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    // Check for non-JSON response
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      return data;
    } else {
      // If the content type is not JSON, return the text or handle it accordingly
      const textData = await response.text();
      return textData;
    }
  } catch (error) {
    // Handle any errors
    console.error('Error deleting row:', error);
    throw error;
  }
}
