import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Grid,
  Text,
  Button,
  Spinner,
  Avatar,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
// import { privateForm } from '../interfaces/privateForm';
import UserProfilePic from './UserProfilePic';

const ProfileClientTxBioProfilePic = ({ headshotid }: any) => {
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);
  const userAccountNumber = localStorage.getItem('accountNumber');
  const [txProfilePic, setTxProfilePic] = useState<any>('');
  const [loadingIcon, setLoadingIcon] = useState<any>(0);
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;

  console.log('headshotid: ', headshotid);

  function downloadFile(at: any, fileURL: any) {
    // console.log('at: ', at);
    // console.log('fileURL: ', fileURL);
    const options = {
      headers: {
        Authorization: at,
      },
    };
    fetch(fileURL, options)
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        // console.log(file);
        setTxProfilePic(file);
        // setDownloadedFile(file);
        setLoadingIcon(1);
        // window.location.assign(file);
      });
  }
  // const [form, setForm] = useState<any>(defaultForm);

  // function getUserData() {
  //   ninoxTableGetRow(
  //     process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
  //     Number(userAccountNumber),
  //     process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
  //   ).then((data: any) => {
  //     setUser(data);
  //     // setForm({
  //     //   ...form,
  //     //   firstName: data?.fields['First Name'],
  //     //   lastName: data?.fields['Last Name'],
  //     //   email: data?.fields['Primary Email Address'],
  //     //   contactNumber: data?.fields['Phone'],
  //     //   addressLineOne: data?.fields['Address'],
  //     //   addressLineTwo: data?.fields['Xero Contact Street Address'],
  //     //   city: data?.fields['Xero Contact Area Address'],
  //     //   postCode: data?.fields['Xero Contact Area Address'],
  //     //   password: '',
  //     //   cpassword: '',
  //     // });
  //   });
  // }

  useEffect(() => {
    // getUserData();
    downloadFile(bearerToken, headshotid);
  }, []);

  console.log('HERE:', headshotid?.split('/')[0]);

  // Split the URL by "/"
  const parts = headshotid ? headshotid.split('/') : null;

  // Get the last item (handling possible trailing slashes)
  const lastItem = headshotid === null ? null : parts.filter((part: any) => part !== '').pop();

  return (
    <Box w="100%" pb={'10'}>
      <Box display={'flex'} flexDirection={'column'} alignItems={'right'} justifyContent={'right'}>
        <HStack>
          <Box w="220px" mr={10}>
            {String(lastItem) === 'undefined' || String(lastItem) === null ? (
              <Avatar src={headshotid} size="2xl" />
            ) : (
              <UserProfilePic profilePic={txProfilePic!} imageWidth="160" />
            )}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
export default ProfileClientTxBioProfilePic;
