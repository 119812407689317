import {
  Box,
  Button,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  HStack,
  Divider,
  useBoolean,
  Input,
  InputRightAddon,
  InputGroup,
  InputLeftElement,
  Spinner,
} from '@chakra-ui/react';
import { Search2Icon, CloseIcon } from '@chakra-ui/icons';

import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '..';
import { Note } from '../interfaces/Note';
import { PortalProps } from '../interfaces/PortalProps';
import getAllRowsById from '../utils/getAllRowsById';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import { BackButton } from './BackButton';
import sortDataByParameter from '../utils/sortDataByParameter';
import sorterIcon from '../utils/sorterIcon';
import getMultipleApiData from '../utils/getMultipleApiData';

const today = new Date();
const BookingsContentStudents = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
}: PortalProps) => {
  const newNoteDefault = {
    nid: 0,
    session_id: 0,
    note_type: 'brief_note',
    note_owner: signedInUserId,
    note_details: '',
    posted_at: today,
    modified_at: today,
  };
  const [sorter, setSorter] = useBoolean();

  const [newNote, setNewNote] = useState<Note>(newNoteDefault);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchValueLowerCase, setSearchValueLowerCase] = useState<string>('');
  const [caseNotes, setCaseNotes] = useState<any>([]);
  const [schoolFilter, setSchoolFilter] = useState(0);
  const [activeSort, setActiveSort] = useState<string>('None');
  const [activeSearch, setActiveSearch] = useState(0);
  const [showDischarged, setShowDischarged] = useState<number>(0);
  const [loading, setLoading] = useState(0);

  const handleSearch = (e: any) => {
    let searchInput = e.target.value;
    let lowerSearchInput = searchInput.toLowerCase();
    setSearchValue(searchInput);
    setSearchValueLowerCase(lowerSearchInput);
    setActiveSearch(1);
  };
  const navigate = useNavigate();
  const userAccount = useContext(MyContext);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allProfilesFullUrl = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const userStats = userMeta && userMeta?.stats;
  const therapistsTable = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const caseNotesSEN = process.env.REACT_APP_UL_STUDENTS_SEN;
  const accountType = localStorage.getItem('accountType');
  const getCaseNotes = accountType === 'Mainstream' ? caseNotesMainstream : caseNotesSEN;
  function clearSearch() {
    setSearchValue('');
    getAllRowsById(getCaseNotes).then((data) => {
      const myData = data;
      setCaseNotes(
        myData
          .filter(
            (i: any) =>
              i?.fields[accountType === 'Mainstream' ? 'Mainstream School' : 'SEN Schools'] ===
              schoolFilter
          )
          .filter(
            (i: any) =>
              i.fields['End of Episode/ Discharge Code/ Description'] === undefined ||
              i.fields['End of Episode/ Discharge Code/ Description'] === null ||
              i.fields['End of Episode/ Discharge Code/ Description'] === ''
          )
          .sort((a: any, b: any) =>
            sorter === true
              ? b?.fields['Student/Patient Name'].localeCompare(a?.fields['Student/Patient Name'])
              : a?.fields['Student/Patient Name'].localeCompare(b?.fields['Student/Patient Name'])
          )
      );
    });
  }
  const accountNumber = localStorage.getItem('accountNumber');

  const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
  const ssQueryEP = process.env.REACT_APP_SS_QUERY_EP;

  const baseMsAllStudentsQuery = `let school_id := ${Number(accountNumber)};
  let students := (select Students)['Mainstream School'.Id = school_id];
  concat(students)`;

  const baseSsAllStudentsQuery = `let school_id := ${Number(accountNumber)};
  let students := (select Students)['SEN schools'.Id = school_id];
  concat(students)`;

  const msSchoolStudents = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
  const ssSchoolStudents = process.env.REACT_APP_UL_STUDENTS_SEN;

  useEffect(() => {
    const therapistCaseNotesEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
    setNewNote(newNoteDefault);
    setSchoolFilter(Number(accountNumber));
    // getAllRowsById(getCaseNotes).then((data) => {
    //   const myData = data;
    //   setCaseNotes(
    //     myData
    //       .filter(
    //         (i: any) =>
    //           i?.fields[accountType === 'Mainstream' ? 'Mainstream School' : 'SEN Schools'] ===
    //           schoolFilter
    //       )
    //       .filter(
    //         (i: any) =>
    //           i.fields['End of Episode/ Discharge Code/ Description'] === undefined ||
    //           i.fields['End of Episode/ Discharge Code/ Description'] === null ||
    //           i.fields['End of Episode/ Discharge Code/ Description'] === ''
    //       )
    //       .sort((a: any, b: any) =>
    //         sorter === true
    //           ? b?.fields['Student/Patient Name'].localeCompare(a?.fields['Student/Patient Name'])
    //           : a?.fields['Student/Patient Name'].localeCompare(b?.fields['Student/Patient Name'])
    //       )
    //   );
    // });

    const delayDebounceFn = setTimeout(() => {
      searchValueLowerCase.length >= 1 &&
        setCaseNotes(
          caseNotes.filter((caseNote: any) => {
            return caseNote?.fields['Student/Patient Name']
              .toLocaleLowerCase()
              .includes(searchValueLowerCase);
            // ||
            // caseNote?.fields['DOB'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Class year'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Class name'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Gender'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Ethnicity'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Referral Date']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Language 1'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Language 2'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Language 3'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Other Languages']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Local Patient Identifier']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Date of Assessment']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Report'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Report sent'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Report Link'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Notes'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Date of review']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Name of Therapist']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Primary Medical Diagnosis']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Additional Medical Diagnosis']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Other Medical Diagnosis']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Primary Communication/ Swallowing Disorder']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Additional Communication/ Swallowing Disorder']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Other Communication/ Swallowing Disorder']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['EHCP'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Direct Hours'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Indirect Hours']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Hours completed']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Hours remaining']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Plan'].toLocaleLowerCase().includes(searchValueLowerCase) ||
            // caseNote?.fields['Primary TOMs Scale']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Secondary TOMs Scale']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['End of Episode/ Discharge Code/ Description']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase) ||
            // caseNote?.fields['Date of Discharge']
            //   .toLocaleLowerCase()
            //   .includes(searchValueLowerCase)
          })
        );
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [userStats, schoolFilter, searchValueLowerCase]);

  useEffect(() => {}, [searchValue]);

  useEffect(() => {
    accountType === 'Mainstream'
      ? getAllRowsByEncodedQuery(msQueryEP, baseMsAllStudentsQuery).then((data: any) => {
          const allStudents = data.split(',');
          data?.length > 0 &&
            getMultipleApiData(msSchoolStudents!, bearerToken!, allStudents).then((myData: any) => {
              setCaseNotes(
                myData
                  .sort((a: any, b: any) =>
                    sorter === true
                      ? b?.fields['Student/Patient Name'].localeCompare(
                          a?.fields['Student/Patient Name']
                        )
                      : a?.fields['Student/Patient Name'].localeCompare(
                          b?.fields['Student/Patient Name']
                        )
                  )
                  .filter(
                    (i: any) =>
                      i.fields['End of Episode/ Discharge Code/ Description'] === undefined ||
                      i.fields['End of Episode/ Discharge Code/ Description'] === null ||
                      i.fields['End of Episode/ Discharge Code/ Description'] === ''
                  )
              );
            });
        })
      : getAllRowsByEncodedQuery(ssQueryEP, baseSsAllStudentsQuery).then((data: any) => {
          const allStudents = data.split(',');
          data?.length > 0 &&
            getMultipleApiData(ssSchoolStudents!, bearerToken!, allStudents).then((myData: any) => {
              setCaseNotes(
                myData
                  .sort((a: any, b: any) =>
                    sorter === true
                      ? b?.fields['Student/Patient Name'].localeCompare(
                          a?.fields['Student/Patient Name']
                        )
                      : a?.fields['Student/Patient Name'].localeCompare(
                          b?.fields['Student/Patient Name']
                        )
                  )
                  .filter(
                    (i: any) =>
                      i.fields['End of Episode/ Discharge Code/ Description'] === undefined ||
                      i.fields['End of Episode/ Discharge Code/ Description'] === null ||
                      i.fields['End of Episode/ Discharge Code/ Description'] === ''
                  )
              );
            });
        });
  }, []);

  const headings = [
    'Student/Patient Name',
    'View Session notes',
    // 'Edit Student',
    'Add TOMS Rating',
    'DOB',
    'Class year',
    'Class name',
    'Gender',
    'Ethnicity',
    'Referral Date',
    'Language 1',
    'Language 2',
    'Language 3',
    'Other Languages',
    'Local Patient Identifier',
    'Date of Assessment',
    'Report',
    'Report sent',
    'Report Link',
    'Notes',
    'Date of review',
    'Name of Therapist',
    'Primary Medical Diagnosis',
    'Additional Medical Diagnosis',
    'Other Medical Diagnosis',
    'Primary Communication/ Swallowing Disorder',
    'Additional Communication/ Swallowing Disorder',
    'Other Communication/ Swallowing Disorder',
    'EHCP',
    'Direct Hours',
    'Indirect Hours',
    'Hours completed',
    'Hours remaining',
    'Plan',
    'Primary TOMs Scale',
    'Secondary TOMs Scale',
  ];

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Box p={2} mb={5}>
            <HStack spacing={5}>
              <Box width={'40%'}>
                <HStack spacing={5}>
                  <BackButton navigate={navigate} />
                  <Heading pb={6} mt={6} size={'md'}>
                    Students ({caseNotes.length})
                  </Heading>
                </HStack>
              </Box>
              <Box width={'60%'}>
                <HStack>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={<Search2Icon color="gray.600" />}
                    />
                    <Input
                      pr="4.5rem"
                      placeholder="Search for a student"
                      onChange={(e) => handleSearch(e)}
                      type="text"
                      value={searchValue}
                      size="xl"
                      borderRadius={5}
                    />
                    {activeSearch === 1 ? (
                      <InputRightAddon
                        children={<CloseIcon color="gray.600" />}
                        onClick={() => {
                          clearSearch();
                        }}
                        cursor={'pointer'}
                      />
                    ) : (
                      <InputRightAddon children="Search" cursor={'pointer'} />
                    )}
                  </InputGroup>
                  <Button
                    variant={'defaultButton'}
                    onClick={() => {
                      showDischarged === 0 ? setShowDischarged(1) : setShowDischarged(0);
                    }}
                    px={10}
                  >
                    {showDischarged === 0 ? 'Show discharged students' : 'Hide discharged students'}
                  </Button>
                </HStack>
              </Box>
            </HStack>
          </Box>
          <TableContainer className="studentTableContainer">
            <Table variant="striped">
              <Thead>
                <Tr style={{ backgroundColor: 'gray.300' }}>
                  {headings.map((i: any, k: number) => (
                    <Th
                      key={k}
                      style={
                        k === 0
                          ? {
                              position: 'sticky',
                              left: '0',
                              width: 'auto',
                              zIndex: 999,
                              cursor: 'pointer',
                            }
                          : {}
                      }
                      onClick={() => {
                        sortDataByParameter(
                          setCaseNotes,
                          caseNotes,
                          schoolFilter,
                          i,
                          accountType!,
                          sorter
                        );
                        setActiveSort(i);
                        setSorter.toggle();
                      }}
                    >
                      {i} {sorterIcon(sorter, activeSort, i)}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {caseNotes.length === 0 ? (
                  <Box
                    width={'100%'}
                    alignContent={'center'}
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    pt={5}
                  >
                    <Spinner
                      thickness="8px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="brand.200"
                      size="xl"
                    />
                  </Box>
                ) : caseNotes && showDischarged === 1 ? (
                  caseNotes
                    .filter((z: any) => z.fields['Date of Discharge'])
                    .map((i: any, k: any) => {
                      return (
                        <Tr key={k} style={{ cursor: 'pointer' }}>
                          <Td
                            style={
                              k % 2 == 0
                                ? {
                                    position: 'sticky',
                                    left: '0',
                                    width: 'auto',
                                    zIndex: 9,
                                    fontWeight: 'bold',
                                    background: 'gray.100',
                                  }
                                : {
                                    position: 'sticky',
                                    left: '0',
                                    width: 'auto',
                                    zIndex: 9,
                                    fontWeight: 'bold',
                                    background: 'white',
                                  }
                            }
                          >
                            {i?.fields['Student/Patient Name']}
                          </Td>
                          <Td>
                            <Button
                              bg={'brand.300'}
                              color={'white'}
                              _hover={{
                                background: 'brand.200',
                              }}
                              onClick={() => {
                                localStorage.getItem('accountType') === 'Mainstream'
                                  ? navigate(
                                      '/student-session/' + i.id + '?schoolType=RegularSchool'
                                    )
                                  : navigate('/student-session/' + i.id + '?schoolType=SENSchool');
                              }}
                            >
                              View session notes
                            </Button>
                          </Td>
                          <Td>
                            <Button
                              bg={'brand.300'}
                              color={'white'}
                              _hover={{
                                background: 'brand.200',
                              }}
                              onClick={() => {
                                localStorage.getItem('accountType') === 'Mainstream'
                                  ? navigate('/toms-rating/' + i.id + '?schoolType=RegularSchool')
                                  : navigate('/toms-rating/' + i.id + '?schoolType=SENSchool');
                              }}
                            >
                              TOMS Rating
                            </Button>
                          </Td>
                          <Td>{i?.fields['DOB'] === undefined ? null : i?.fields['DOB']}</Td>
                          <Td>{i?.fields['Class year']}</Td>
                          <Td>{i?.fields['Class name']}</Td>
                          <Td>{i?.fields['Gender'] === undefined ? null : i?.fields['Gender']}</Td>
                          <Td>{i?.fields['Ethnicity']}</Td>
                          <Td>{i?.fields['Referral Date']}</Td>
                          <Td>{i?.fields['Language 1']}</Td>
                          <Td>{i?.fields['Language 2']}</Td>
                          <Td>{i?.fields['Language 3']}</Td>
                          <Td>{i?.fields['Other Languages']}</Td>
                          <Td>{i?.fields['Local Patient Identifier']}</Td>
                          <Td>{i?.fields['Date of Assessment']}</Td>
                          <Td>{i?.fields['Report date']}</Td>
                          <Td>{i?.fields['Report sent'] === true ? 'Yes' : 'No'}</Td>
                          <Td>{i?.fields['Report Link']}</Td>
                          <Td>{i?.fields['Notes']}</Td>
                          <Td>{i?.fields['Date of review']}</Td>
                          <Td>{i?.fields['Name of Therapist']}</Td>
                          <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                          <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                          <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                          <Td>{i?.fields['Primary Communication/ Swallowing Disorder']}</Td>
                          <Td>{i?.fields['Additional Communication/ Swallowing Disorder']}</Td>
                          <Td>{i?.fields['Other Communication/ Swallowing Disorder']}</Td>
                          <Td>{i?.fields['EHCP']}</Td>
                          <Td>{i?.fields['Direct Hours']}</Td>
                          <Td>{i?.fields['Indirect Hours']}</Td>
                          <Td>{i?.fields['Hours completed']}</Td>
                          <Td>{i?.fields['Hours remaining']}</Td>
                          <Td>{i?.fields['Plan']}</Td>
                          <Td>{i?.fields['Primary TOMs Scale']}</Td>
                          <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                        </Tr>
                      );
                    })
                ) : (
                  caseNotes
                    .filter(
                      (z: any) =>
                        z.fields['Date of Discharge'] === undefined ||
                        z.fields['Date of Discharge'] === null ||
                        z.fields['Date of Discharge'] === ''
                    )
                    .map((i: any, k: any) => {
                      return (
                        <Tr key={k} style={{ cursor: 'pointer' }}>
                          <Td
                            style={
                              k % 2 == 0
                                ? {
                                    position: 'sticky',
                                    left: '0',
                                    width: 'auto',
                                    zIndex: 9,
                                    fontWeight: 'bold',
                                    background: 'gray.100',
                                  }
                                : {
                                    position: 'sticky',
                                    left: '0',
                                    width: 'auto',
                                    zIndex: 9,
                                    fontWeight: 'bold',
                                    background: 'white',
                                  }
                            }
                          >
                            {i?.fields['Student/Patient Name']}
                          </Td>
                          <Td>
                            <Button
                              bg={'brand.300'}
                              color={'white'}
                              _hover={{
                                background: 'brand.200',
                              }}
                              onClick={() => {
                                localStorage.getItem('accountType') === 'Mainstream'
                                  ? navigate(
                                      '/student-session/' + i.id + '?schoolType=RegularSchool'
                                    )
                                  : navigate('/student-session/' + i.id + '?schoolType=SENSchool');
                              }}
                            >
                              View session notes
                            </Button>
                          </Td>
                          <Td>
                            <Button
                              bg={'brand.300'}
                              color={'white'}
                              _hover={{
                                background: 'brand.200',
                              }}
                              onClick={() => {
                                localStorage.getItem('accountType') === 'Mainstream'
                                  ? navigate('/toms-rating/' + i.id + '?schoolType=RegularSchool')
                                  : navigate('/toms-rating/' + i.id + '?schoolType=SENSchool');
                              }}
                            >
                              TOMS Rating
                            </Button>
                          </Td>
                          <Td>{i?.fields['DOB'] === undefined ? null : i?.fields['DOB']}</Td>
                          <Td>{i?.fields['Class year']}</Td>
                          <Td>{i?.fields['Class name']}</Td>
                          <Td>{i?.fields['Gender'] === undefined ? null : i?.fields['Gender']}</Td>
                          <Td>{i?.fields['Ethnicity']}</Td>
                          <Td>{i?.fields['Referral Date']}</Td>
                          <Td>{i?.fields['Language 1']}</Td>
                          <Td>{i?.fields['Language 2']}</Td>
                          <Td>{i?.fields['Language 3']}</Td>
                          <Td>{i?.fields['Other Languages']}</Td>
                          <Td>{i?.fields['Local Patient Identifier']}</Td>
                          <Td>{i?.fields['Date of Assessment']}</Td>
                          <Td>{i?.fields['Report date']}</Td>
                          <Td>{i?.fields['Report sent'] === true ? 'Yes' : 'No'}</Td>
                          <Td>{i?.fields['Report Link']}</Td>
                          <Td>{i?.fields['Notes']}</Td>
                          <Td>{i?.fields['Date of review']}</Td>
                          <Td>{i?.fields['Name of Therapist']}</Td>
                          <Td>{i?.fields['Primary Medical Diagnosis']}</Td>
                          <Td>{i?.fields['Additional Medical Diagnosis']}</Td>
                          <Td>{i?.fields['Other Medical Diagnosis']}</Td>
                          <Td>{i?.fields['Primary Communication/ Swallowing Disorder']}</Td>
                          <Td>{i?.fields['Additional Communication/ Swallowing Disorder']}</Td>
                          <Td>{i?.fields['Other Communication/ Swallowing Disorder']}</Td>
                          <Td>{i?.fields['EHCP']}</Td>
                          <Td>{i?.fields['Direct Hours']}</Td>
                          <Td>{i?.fields['Indirect Hours']}</Td>
                          <Td>{i?.fields['Hours completed']}</Td>
                          <Td>{i?.fields['Hours remaining']}</Td>
                          <Td>{i?.fields['Plan']}</Td>
                          <Td>{i?.fields['Primary TOMs Scale']}</Td>
                          <Td>{i?.fields['Secondary TOMs Scale']}</Td>
                        </Tr>
                      );
                    })
                )}
                {/* {caseNotes === undefined && <TableCaption>No students yet!</TableCaption>} */}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentStudents;
