import React, { useCallback, useEffect, useState } from 'react';
import { Elements, PaymentElement, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import getDataFromEndPoint from './../../utils/getDataFromEndPoint';
import postDataToEndPoint from './../../utils/postDataToEndPoint';
import ninoxTableGetRow from './../../utils/ninoxTableGetRow';
import {
  Box,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Image,
  Text,
  Link,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  SimpleGrid,
} from '@chakra-ui/react';
// import UL_logo from './../../../logo/unlocking_language_logo_white.png';

const StepFive = ({ title }: any) => {
  const [cs, setCs] = useState('');
  const [userDetails, setUserDetails] = useState<any>();
  const [amount, setAmount] = useState<number>(0);

  const [amountToCharge, setAmountToCharge] = useState<number>(0);
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');
  const invoiceID = queryParameters.get('invoiceID');

  const pkstripe = process.env.REACT_APP_UL_STRIPE_PK;
  const stripePromise = loadStripe(String(pkstripe!));

  const stripePaymentEP =
    'https://client-portal-serverless-functions.vercel.app/api/stripePaymentIntent';

  useEffect(() => {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(ul_cl_id),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUserDetails(data);
      const requestBody = {
        amount:
          data?.fields['Xero Invoice Line Item Amount'] === undefined ||
          data?.fields['Xero Invoice Line Item Amount'] === null ||
          data?.fields['Xero Invoice Line Item Amount'] === ''
            ? 0
            : Number(data?.fields['Xero Invoice Line Item Amount'] * 100),
        description: invoiceID,
        customerID: data?.fields['Stripe Customer id'],
        // customerID: Number(ul_cl_id), // We changed it to ['Stripe Customer id'], but might need to do another live test
        receipt_email: data?.fields['Email 1'],
        name: data?.fields['First Name'] + ' ' + data?.fields['Last Name'],
        email: data?.fields['Email 1'],
        phone: data?.fields['Mobile 1'],
        metadata: {
          customer_first_name: data?.fields['First Name'],
          customer_last_name: data?.fields['Last Name'],
          customer_contact_number: data?.fields['Phone'],
          customer_mobile_number: data?.fields['Mobile 1'],
          customer_account_number: Number(ul_cl_id),
          payment_type: 'client_invoice_payment',
        },
        currency: 'gbp',
      };

      // const requestBody = {
      //   amount: invoice?.state?.invoiceValue * 100,
      //   customerID: Number(localStorage?.getItem('accountNumber')),
      //   receipt_email: localStorage?.getItem('accountEmail'),
      //   name: localStorage.getItem('firstName') + ' ' + localStorage?.getItem('lastName'),
      //   email: localStorage.getItem('accountEmail'),
      //   phone: localStorage.getItem('mobileNumber'),
      //   metadata: {
      //     customer_first_name: localStorage.getItem('firstName'),
      //     customer_last_name: localStorage.getItem('lastName'),
      //     customer_phone_number: localStorage.getItem('phoneNumber'),
      //     customer_mobile_number: localStorage.getItem('mobileNumber'),
      //     customer_account_number: Number(localStorage?.getItem('accountNumber')),
      //     payment_type: 'client_invoice_payment',
      //   },
      //   currency: 'gbp',
      // };

      setAmount(Number(data?.fields['Xero Invoice Line Item Amount']) * 100);

      postDataToEndPoint(stripePaymentEP, requestBody).then((stripeData) => {
        // console.log(stripeData.clientSecret);
        setCs(stripeData.clientSecret);
      });
    });
  }, []);

  const options = {
    // passing the client secret obtained from the server
    clientSecret: cs,
  };
  return (
    <Box w="100%" mt={'5'}>
      <Text mb={'5'}>
        We will only be taking the first payment today and will pre-notify you before any direct
        debit charges.
      </Text>
      <Box>
        {/* here {JSON.stringify(cs)} */}
        {cs && (
          <Box display={'flex'} flexDirection={'row'}>
            <Box width={'60%'}>
              {options && (
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm invoiceID={invoiceID && invoiceID} />
                  {/* <CardElement  /> */}
                </Elements>
              )}
            </Box>
            <Box mt={-1} mb={10} ml={20} width={'30%'} p={5} bg={'gray.100'} borderRadius={10}>
              <Heading as="h4" size="lg" mb={'10'} mt={5}>
                {title}
              </Heading>
              <Stat>
                <StatLabel>Invoice number:</StatLabel>
                <StatNumber fontSize={30}>{invoiceID}</StatNumber>
              </Stat>
              <Stat>
                <StatLabel>First payment amount due today</StatLabel>
                <StatNumber fontSize={30}>
                  £{(amount / 100).toFixed(2)}
                  {/* {JSON.stringify(userDetails?.fields['Xero Invoice Line Item Amount'])} */}
                </StatNumber>
                <StatHelpText>
                  We will only be taking the first payment today of £{(amount / 100).toFixed(2)} and
                  will pre-notify you before any direct debit charges.
                </StatHelpText>
              </Stat>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default StepFive;
