import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// Designppl
// const firebaseConfig = {
//   apiKey: 'AIzaSyC5mHZ_-_WCk68fZFmkWY4xebQbDb_Iqos',
//   authDomain: 'designppl.firebaseapp.com',
//   projectId: 'designppl',
//   storageBucket: 'designppl.appspot.com',
//   messagingSenderId: '734843127756',
//   appId: '1:734843127756:web:97ba7e4e884fffc780ff69',
// }; //this is where your firebase app values you copied will go

// Unlocking language
const firebaseConfig = {
  apiKey: 'AIzaSyBruU0wIwdXvsKw_4i5Vdiyj77Ck8I_Q8s',
  authDomain: 'client-portal-69658.firebaseapp.com',
  projectId: 'client-portal-69658',
  storageBucket: 'client-portal-69658.appspot.com',
  messagingSenderId: '857890901984',
  appId: '1:857890901984:web:c69b0067cc06f7a3dee585',
  measurementId: 'G-3YDM1ZFF3T',
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
