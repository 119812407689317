import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Grid,
  Text,
  Icon,
  Input,
  Button,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from './../../src/utils/ninoxTableGetRow';
import ninoxTableUpdateRow from './../../src/utils/ninoxTableUpdateRow';
import postDataToEndPoint from '../utils/postDataToEndPoint';

const ProfileClientDashboard = ({ profileDataType }: any) => {
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);
  const userAccountNumber = localStorage.getItem('accountNumber');
  // console.log('userAccountNumber: ', userAccountNumber);
  const defaultForm = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    addressLineOne: '',
    addressLineTwo: '',
    city: '',
    postCode: '',
    password: '',
    cpassword: '',
  };
  const [form, setForm] = useState<any>(defaultForm);

  function getUserData() {
    ninoxTableGetRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    ).then((data: any) => {
      setUser(data);
      setForm({
        ...form,
        firstName: data?.fields['First Name'],
        lastName: data?.fields['Last Name'],
        email: data?.fields['Primary Email Address'],
        contactNumber: data?.fields['Phone'],
        addressLineOne: data?.fields['Address'],
        addressLineTwo: data?.fields['Xero Contact Street Address'],
        city: data?.fields['Xero Contact Area Address'],
        postCode: data?.fields['Xero Contact Area Address'],
        webInfoReqChangesPending: ['Website info change request pending'],
        clientInfoReqChangesPending: ['Client info change request pending'],
        invoiceInfoReqChangesPending: ['Invoice info change request pending'],
        password: '',
        cpassword: '',
      });
    });
  }

  const clientToUpdate = {
    'First Name': form.firstName,
    'Last Name': form.lastName,
    'Primary Email Address': form.email,
    Phone: form.contactNumber,
    Address: form.addressLineOne,
    'Xero Contact Street Address': form.addressLineTwo,
    // 'Xero Contact Street Address': form.city,
    // 'Xero Contact Street Address': form.postCode,
  };

  function updateClientInfo() {
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      { fields: clientToUpdate },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      console.log('saved:' + data);
      getUserData();
      setEdit(0);
    });
  }

  const requestProfileChangeViaMakeEP = process.env.REACT_APP_REQUEST_PROFILE_CHANGES_VIA_MAKE_EP;
  const requestBody = {
    data: {
      type: [profileDataType],
      id: [Number(userAccountNumber)],
      firstName: [form.firstName],
      lastName: [form.lastName],
      email: [form.email],
      contactNumber: [form.contactNumber],
      addressLineOne: [form.addressLineOne],
      addressLineTwo: [form.addressLineTwo],
    },
  };
  // function sendDataToMake() {
  //   postDataToEndPoint(requestProfileChangeViaMakeEP, requestBody).then(() => setEdit(0));
  // }
  const updateWebsiteInfoReqChangesPending = {
    'Website info change request pending': true,
  };

  function sendDataToMake() {
    postDataToEndPoint(requestProfileChangeViaMakeEP, requestBody).then(() => setEdit(0));
    ninoxTableUpdateRow(
      process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
      Number(userAccountNumber),
      { fields: updateWebsiteInfoReqChangesPending },
      String(process.env.REACT_APP_NINOX_API_BEARER_TOKEN!)
    ).then((data: any) => {
      getUserData();
      setEdit(0);
    });
  }

  // {
  //   signUpError && (
  //     <Box color={'red.500'} mb={10} fontWeight={'bold'}>
  //       {signUpError?.code === 'auth/email-already-in-use'
  //         ? 'You already have an account with us. Have you tried sigining in?'
  //         : signUpError?.code === 'auth/missing-password'
  //         ? 'Missing password! Password is a mandatory field. Please enter a password'
  //         : signUpError?.code === 'auth/weak-password'
  //         ? 'Weak password! Please enter a stronger password'
  //         : signUpError?.code}
  //     </Box>
  //   );
  // }

  useEffect(() => {
    getUserData();
  }, []);
  return (
    <Box w="100%">
      <Heading as={'h3'} size={'md'} my={5}>
        My Profile
      </Heading>
      <Box display={'flex'} flexDirection={'row'} mb={5}>
        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="first-name" fontWeight={'bold'}>
              First name
            </FormLabel>
            {edit === 0 ? (
              user?.fields['First Name']
            ) : (
              <Input
                id="first-name"
                placeholder="First name"
                value={form.firstName}
                onChange={(e) => setForm({ ...form, firstName: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="last-name" fontWeight={'bold'}>
              Last name
            </FormLabel>
            {edit === 0 ? (
              user?.fields['Last Name']
            ) : (
              <Input
                id="last-name"
                placeholder="Last name"
                value={form.lastName}
                onChange={(e) => setForm({ ...form, lastName: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>
        </Box>

        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="contact-number" fontWeight={'bold'}>
              Contact number
            </FormLabel>
            {edit === 0 ? (
              user?.fields.Phone
            ) : (
              <Input
                id="contact-number"
                placeholder="Contact number"
                value={form.contactNumber}
                onChange={(e) => setForm({ ...form, contactNumber: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>

          <FormControl mb="5%">
            <FormLabel htmlFor="address-line-1" fontWeight={'bold'}>
              Full address &amp; Postcode
            </FormLabel>
            {edit === 0 ? (
              user?.fields.Address === undefined ? (
                'Not provided'
              ) : (
                user?.fields.Address
              )
            ) : (
              <Input
                id="address-line-1"
                placeholder="Address line 1"
                value={form.addressLineOne}
                onChange={(e) => setForm({ ...form, addressLineOne: e.target.value })}
                bg={'white'}
              />
            )}
          </FormControl>
        </Box>
        <Box w={'30%'} mr={5} bg={'gray.100'} borderRadius={10} p={5}>
          <FormControl mb="5%">
            <FormLabel htmlFor="email" fontWeight={'bold'}>
              Email{' '}
              <Tooltip
                label={'Please email info@unlockinglangauge.co.uk to change this field'}
                style={{ cursor: 'pointer' }}
              >
                <Icon mt={0} />
              </Tooltip>
            </FormLabel>
            {user?.fields['Primary Email Address']}

            {/* {edit === 0 ? (
              user?.fields['Primary Email Address']
            ) : (
              <Input
                id="email"
                placeholder="Email address"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            )} */}
          </FormControl>
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'right'} justifyContent={'right'}>
        <FormControl>
          <FormLabel htmlFor="" fontWeight={'bold'}></FormLabel>
          {edit === 0 ? (
            user?.fields['Website info change request pending'] !== true ? (
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
              >
                Request changes
              </Button>
            ) : (
              <Button isDisabled>Request changes awaiting approval</Button>
            )
          ) : (
            <HStack>
              <Button variant={'defaultButton'} onClick={() => sendDataToMake()}>
                Save
              </Button>
              <Button
                variant={'defaultButton'}
                onClick={() => (edit === 0 ? setEdit(1) : setEdit(0))}
              >
                Cancel
              </Button>
            </HStack>
          )}
        </FormControl>
      </Box>
    </Box>
  );
};
export default ProfileClientDashboard;
