export default async function createFolder(
  folderId: string,
  parentFolder: string,
  myToken: string
) {
  const requestBody = {
    name: folderId,
    parents: [parentFolder],
    mimeType: 'application/vnd.google-apps.folder',
  };
  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: myToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };
  const response = await fetch('https://www.googleapis.com/drive/v3/files/', requestOptions);
  const data = await response.json();

  return data;
}
