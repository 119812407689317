import { Avatar, Box, Button, Flex, HStack, Heading, Link, Text } from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { MyContext } from '..';
import { AuthContext } from '../context/AuthContext';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';

const DashboardDoctifyReviews = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
}: SignedInUserPropsTherapist) => {
  const user = useContext(AuthContext);
  const userAccount = useContext(MyContext);
  const sessionAccountType = localStorage.getItem('accountType');

  return (
    <>
      <Box p={10} ml={20}>
        <Box py={5}>
          <Heading textAlign={'left'} as="h1" size="lg" mb={10}>
            Doctify Reviews
          </Heading>
          <Link href="https://www.doctify.com/uk/review/1zCCYi/single" target="_blank">
            <Button
              bg={'brand.200'}
              color="white"
              _hover={{ bg: 'brand.300', color: 'white' }}
              mb={5}
            >
              Leave us a review on Doctify
            </Button>
          </Link>
        </Box>
        <Box>
          <Heading textAlign={'left'} as="h1" size="lg" mb={10}>
            Google Reviews
          </Heading>
          <Link href="https://g.page/r/Cb1qGpO4ofIHEB0/review" target="_blank">
            <Button
              bg={'brand.200'}
              color="white"
              _hover={{ bg: 'brand.300', color: 'white' }}
              mb={5}
            >
              Leave us a review on Google Reviews
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default DashboardDoctifyReviews;
