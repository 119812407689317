import React, { createContext, useState, useCallback } from 'react';
export const userTypeContext = createContext<any>(null);

type ContextProviderProps = {
  children: React.ReactNode;
};

export const UserTypeProvider = ({ children }: ContextProviderProps) => {
  const [userType, setUserType] = useState('none');

  const updateUserType = useCallback(
    (userType: any) => {
      setUserType(userType);
    },
    [setUserType]
  );

  const value = { userType, updateUserType };
  return <userTypeContext.Provider value={value}>{children}</userTypeContext.Provider>;
};
