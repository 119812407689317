import {
  Box,
  Heading,
  Button,
  HStack,
  Tag,
  Image,
  Text,
  VStack,
  AspectRatio,
  Link,
  Input,
  Icon,
  IconButton,
  InputGroup,
  InputRightElement,
  Spinner,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  Spacer,
  ButtonGroup,
  SimpleGrid,
  Tooltip,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import { SearchIcon, CloseIcon, UnlockIcon } from '@chakra-ui/icons';
import React, { useEffect, useState } from 'react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
// import useDrivePicker from 'react-google-drive-picker';
import { gapi, loadAuth2, loadAuth2WithProps, loadClientAuth2 } from 'gapi-script';
import { GoogleAuth } from 'google-auth-library';
import { google } from 'googleapis';
import { drive } from '@googleapis/drive';
import { SignedInUserProps } from './../interfaces/SignedInUserProps';
import { UserMeta } from './../interfaces/UserMeta';
import { loadGapiInsideDOM } from 'gapi-script';
import getDataFromEndPoint from './../utils/getDataFromEndPoint';
import getAllRowsByEncodedQuery from './../utils/getAllRowsByEncodedQuery';
import { BsBookmark, BsFillBookmarkFill } from 'react-icons/bs';
import { useNavigate, useMatch, Navigate } from 'react-router-dom';
import { BackButton } from './BackButton';
import getAllRowsById from '../utils/getAllRowsById';

type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

const apiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
const clientId = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;

const apiKeyUl = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY_UL;
const clientIdUl = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID_UL;

const folderIconsEP = process.env.REACT_APP_RESOURCE_ICONS;

const CLIENT_ID = clientId;
const API_KEY = apiKey; // Optional, only required if you need to use API key-based requests
const SCOPE = 'https://www.googleapis.com/auth/drive'; // The scope for Google Drive API
const userLevel = 'BE7C971279';

// const initClient = () => {
//   gapi.client.init({
//     apiKey: API_KEY,
//     clientId: CLIENT_ID,
//     scope: SCOPE,
//   });
// };
const the_url = `https://www.googleapis.com/drive/v3/files?`;

const ResourcesContentTherapist = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
}: SignedInUserProps) => {
  const navigate = useNavigate();

  const match = useMatch('/:firstRoute/:secondRoute');
  const firstRoute = match?.params['firstRoute'];
  const secondRoute = match?.params['secondRoute'];

  // console.log('firstRoute: ', firstRoute);
  // console.log('secondRoute: ', secondRoute);

  const [driveFiles, setDriveFiles] = useState<any>([]);
  const [driveFolders, setDriveFolders] = useState<any>([]);
  const [driveShortcutFolders, setDriveShortcutFolders] = useState<any>([]);
  const [driveFilesAndFolders, setDriveFilesAndFolders] = useState<any>([]);
  const [folderIconsList, setFolderIconsList] = useState<any>([]);
  const [loadingIcon, setLoadingIcon] = useState<any>(0);
  const [parentFolder, setParentFolder] = useState<string>('');
  const [folderToCreate, setFolderToCreate] = useState<string>('');
  const [fileToUpload, setFileToUpload] = useState<string>('');
  const [fileToUploadDescription, setFileToUploadDescription] = useState<string>('');
  const [savedAccessToken, setSavedAccessToken] = useState<string>('');
  const [currentItem, setCurrentItem] = useState<string>('none');
  const [searchValue, setSearchValue] = useState<string>('');
  const [modalContent, setModalContent] = useState<any>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const finalRef = React.useRef(null);
  const queryParameters = new URLSearchParams(window.location.search);
  const folderID = queryParameters.get('res_id');
  function getLevel(level: any, levelTypes: any) {
    return levelTypes
      .filter((i: any) => i.driveLabelId === level)
      .map((i: any, k: number) => (
        <Tag bg={String(i.accessLevelColor)} key={k}>
          {i.levelTextLabel}
        </Tag>
      ));
  }
  async function connectToGoogle(bt: any) {
    let scopes =
      'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/drive.admin.labels https://www.googleapis.com/auth/drive.admin.labels.readonly';
    let auth2 = await loadAuth2(gapi, clientId!, scopes);
    return bt;
  }
  const labelsRefHardcoded = [
    { driveLabelId: 'BE7C971279', levelTextLabel: 'Bronze', accessLevelColor: 'orange.400' },
    { driveLabelId: 'D6DBBD7807', levelTextLabel: 'Silver', accessLevelColor: 'lightgrey' },
    { driveLabelId: 'E5E4E161AF', levelTextLabel: 'Gold', accessLevelColor: 'gold' },
  ];
  async function filesList() {
    let headers = new Headers();

    // headers.append('Content-Type', 'application/json');

    // headers.append('Authorization', 'Basic ' + base64.encode(username + ':' + password));

    const response = await fetch(
      `https://europe-west2-bubbly-lore-387110.cloudfunctions.net/getFiles`,
      {
        // mode: 'cors',
        method: 'GET',
        headers: headers,
        // headers: {
        //   Authorization: myToken,
        //   'Content-Type': 'application/json',
        // },
      }
    ).then((response) => response.json());
    // const fileData = await response.json();
    // return fileData;
    return response;
  }
  async function getFileMeta(fep: any, myToken: string) {
    const response = await fetch(fep + '1z6-myp5MGM8ef55MLVSztU01Mir24zcB', {
      headers: {
        Authorization: myToken,
        'Content-Type': 'application/json',
      },
    });
    const fileData = await response.json();
    return fileData;
  }
  async function getAllLabels(myToken: string) {
    const response = await fetch(
      `https://drivelabels.googleapis.com/v2/labels/oTewCNdxOCjbHM4mzd9bY21tnm3ZrB7mOmbSNNEbbFcb@published`,
      {
        headers: {
          Authorization: myToken,
          'Content-Type': 'application/json',
        },
      }
    );
    const fileData = await response.json();
    return fileData;
  }
  async function getFilesLabels(fileId: string, myToken: string) {
    //www.googleapis.com/drive/v3/files/{fileId}/listLabels
    const response = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/listLabels`, {
      headers: {
        Authorization: myToken,
        'Content-Type': 'application/json',
      },
    });
    const fileData = await response.json();

    // return fileData.labels[0].fields['55CC217458'].selection;

    return fileData.labels[0];
  }
  async function createFolder(folderId: string, parentFolder: string, myToken: string) {
    const requestBody = {
      name: folderId,
      parents: [parentFolder],
      mimeType: 'application/vnd.google-apps.folder',
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: myToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch('https://www.googleapis.com/drive/v3/files/', requestOptions);
    const data = await response.json();

    return data;
  }
  async function UploadFileActions(
    fileId: string,
    fileDescription: string,
    parentFolder: string,
    myToken: string
  ) {
    const requestBody = {
      title: fileId,
      mimeType: 'mime/type',
      description: 'Stuff about the file',
      parents: [parentFolder],
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        Authorization: myToken,
        'Content-Type': 'text/csv',
        // 'Content-Length': 20,
      },
      body: JSON.stringify(requestBody),
    };
    const response = await fetch(
      'https://www.googleapis.com/upload/drive/v3/files?uploadType=media',
      requestOptions
    );
    const data = await response.json();
  }

  async function getFilesOfParentDriveFolder(parentFolder: string) {
    // console.log('getFilesOfParentDriveFolder', ' was triggered');
    // console.log('savedAccessToken: ', savedAccessToken);
    // console.log('parentFolder: ', parentFolder);
    setParentFolder(parentFolder);
    getAllDriveFiles(parentFolder, the_url, savedAccessToken);
    getAllDriveFolders(parentFolder, the_url, savedAccessToken);
    getAllDriveShortcutFolders(parentFolder, the_url, savedAccessToken);
    combineDriveFilesAndFolders();
    // const currentUrl = window.location.href;
    // console.log(currentUrl);
    // const addParams = new URLSearchParams(currentUrl);
    // console.log(addParams);
    // addParams.append('res_id', parentFolder);
    navigate(`/resources/${parentFolder}`);
  }
  async function combineDriveFilesAndFolders() {
    const allFilesAndFolders = [...driveFiles, ...driveFolders, ...driveShortcutFolders];
    setDriveFilesAndFolders(allFilesAndFolders);
    // console.log(
    //   `We now have a total of ${allFilesAndFolders?.length} files and folders from Google Drive`
    // );
    setLoadingIcon(1);
  }
  async function getAllDriveFiles(parentFolder: string, endPoint: string | any, myToken: string) {
    const data = new URLSearchParams();
    data.append(
      'q',
      `'${parentFolder}' in parents  and 'labels/oTewCNdxOCjbHM4mzd9bY21tnm3ZrB7mOmbSNNEbbFcb' in labels`
    );
    data.append('includeLabels', 'labels/oTewCNdxOCjbHM4mzd9bY21tnm3ZrB7mOmbSNNEbbFcb');
    data.append('mimeType', 'application/vnd.google-apps.files');
    data.append(
      'fields',
      'files(labelInfo, id, name, description, fileExtension, webContentLink, webViewLink, mimeType, thumbnailLink, exportLinks)'
      // '*'
    );
    const response = await fetch(endPoint + data, {
      headers: {
        Authorization: myToken,
        'Content-Type': 'application/json',
      },
    });
    const allFiles = await response.json();
    // console.log(allFiles.files);
    setDriveFiles(allFiles.files);
    allFiles?.files?.forEach((element: any) => {
      getFilesLabels(element.id, myToken).then((data) => {});
    });
    // console.log(`We got ${allFiles?.files.length} files from Google Drive`);
    return allFiles.files;
  }
  async function getAllDriveFolders(parentFolder: string, endPoint: string | any, myToken: string) {
    const data = new URLSearchParams();
    data.append('q', `'${parentFolder}' in parents`);
    data.append('fields', '*');
    data.append('mimeType', 'application/vnd.google-apps.folders');
    const response = await fetch(endPoint + data, {
      headers: {
        Authorization: myToken,
        'Content-Type': 'application/json',
      },
    });
    const allFolders = await response.json();
    const theFolders = allFolders.files;
    // console.log(
    //   `We got ${
    //     theFolders.filter((i: any) => i.mimeType === 'application/vnd.google-apps.folder')?.length
    //   } folders from Google Drive`
    // );
    let foldersToAdd = theFolders.filter(
      (i: any) => i.mimeType === 'application/vnd.google-apps.folder'
    );
    setDriveFolders(foldersToAdd);
    return foldersToAdd;
  }
  async function getAllDriveShortcutFolders(
    parentFolder: string,
    endPoint: string | any,
    myToken: string
  ) {
    const data = new URLSearchParams();
    data.append('q', `'${parentFolder}' in parents`);
    data.append('fields', '*');
    data.append('mimeType', 'application/vnd.google-apps.shortcut');
    const response = await fetch(endPoint + data, {
      headers: {
        Authorization: myToken,
        'Content-Type': 'application/json',
      },
    });
    const allFolders = await response.json();
    const theFolders = allFolders.files;
    // console.log(
    //   `We got ${
    //     theFolders.filter((i: any) => i.mimeType === 'application/vnd.google-apps.shortcut')?.length
    //   } shortcut folders from Google Drive`
    // );
    let shortcutFoldersToAdd = theFolders.filter(
      (i: any) => i.mimeType === 'application/vnd.google-apps.shortcut'
    );
    // console.log(shortcutFoldersToAdd);
    setDriveShortcutFolders(shortcutFoldersToAdd);
    return shortcutFoldersToAdd;
  }
  async function getDriveFilesAndFoldersOnStartUp(accessToken: string) {
    // console.log(accessToken);
    //Start up at Client therapy resources level
    const startingParentFolder = '14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5';
    // Parent folder
    // const startingParentFolder = '196cfg-FMl7d22W354JXazxEPz6vTkrSO';
    // Client therapy resources folder is below but files need to be tagged else they wont show
    // const startingParentFolder = '14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5';
    setParentFolder(secondRoute!);
    getAllDriveFiles(secondRoute!, the_url, accessToken);
    getAllDriveFolders(secondRoute!, the_url, accessToken);
    getAllDriveShortcutFolders(secondRoute!, the_url, accessToken);
    combineDriveFilesAndFolders();
  }

  // const [openPicker, authResponse] = useDrivePicker();
  // const handleOpenPicker = () => {
  //   openPicker({
  //     clientId: clientIdUl!,
  //     developerKey: apiKeyUl!,
  //     // viewId: 'DOCS_IMAGES_AND_VIDEOS | FOLDERS',
  //     // token: token, // pass oauth token in case you already have one
  //     showUploadView: true,
  //     showUploadFolders: true,
  //     supportDrives: true,
  //     multiselect: true,
  //     // customViews: customViewsArray, // custom view
  //     callbackFunction: (data) => {
  //       if (data.action === 'cancel') {
  //       }
  //     },
  //   });
  // };

  // async function createFolder() {
  //   // Get credentials and build service
  //   // TODO (developer) - Use appropriate auth mechanism for your app

  //   const auth = new GoogleAuth({
  //     scopes: 'https://www.googleapis.com/auth/drive',
  //   });

  //   const service = google.drive({ version: 'v3', auth });
  //   const fileMetadata = {
  //     name: 'Invoices',
  //     mimeType: 'application/vnd.google-apps.folder',
  //   };
  //   try {
  //     const file = await service.files.create({
  //       resource: fileMetadata,
  //       fields: 'id',
  //     });
  //     return file.data.id;
  //   } catch (err) {
  //     // TODO(developer) - Handle error
  //     throw err;
  //   }
  // }
  interface modalProps {
    modalTitle: string;
    modalContent: string;
  }
  function folderCreateActions() {
    createFolder(folderToCreate, parentFolder, savedAccessToken);
    getAllDriveFiles(parentFolder, the_url, savedAccessToken);
    getAllDriveFolders(parentFolder, the_url, savedAccessToken);
    getAllDriveShortcutFolders(parentFolder, the_url, savedAccessToken);
    onClose();
  }

  const CustomModal = ({ modalTitle, modalContent }: modalProps) => (
    <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{modalTitle}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {modalContent === 'folder' ? (
            <Input
              name="folder"
              type="text"
              value={folderToCreate}
              onChange={(e) => setFolderToCreate(e.target.value)}
            />
          ) : (
            <Stack>
              <Input
                name="File description"
                type="text"
                value={fileToUploadDescription}
                // onChange={(e) => setFileToUploadDescription(e.target.value)}
              />
              <Input
                name="File to upload"
                type="file"
                // value={folderToCreate}
                onChange={(e) => setFileToUpload(e.target.value)}
                variant="unstyled"
              />
            </Stack>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            bg={'brand.200'}
            _hover={{ backgroundColor: 'brand.300' }}
            color={'white'}
            mr={3}
            onClick={() => {
              modalContent === 'folder'
                ? folderCreateActions()
                : UploadFileActions(
                    fileToUpload,
                    fileToUploadDescription,
                    parentFolder,
                    savedAccessToken
                  );
            }}
          >
            {modalTitle}
          </Button>
          <Button bg={'gray.100'} onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
  // const handleSearch = (e: any) => {
  //   setSearchValue(e.target.value);
  //   const results = driveFilesAndFolders?.filter((row: any) => {
  //     if (e.target.value === '') return driveFilesAndFolders;
  //     return row['name'].toLowerCase().includes(e.target.value.toLowerCase());
  //   });
  //   setDriveFilesAndFolders(results);
  // };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase(); // Convert input to lowercase for case-insensitive comparison
    setSearchValue(e.target.value); // Update search input value state

    // Check if driveFilesAndFolders exists and filter based on search input
    const results = driveFiles?.filter((row: { name: string }) => {
      // If the search term is empty, return true to include all items
      if (searchTerm === '') return true;
      // Otherwise, check if the row's name includes the search term
      return row.name.toLowerCase().includes(searchTerm);
    });

    setDriveFiles(results); // Update the filtered results state
  };

  const clearSearch = (e: any) => {
    setSearchValue('');
    getDriveFilesAndFoldersOnStartUp(savedAccessToken);
  };
  // const userAccessLevelLabelFromLogin = userMeta?.accessLevel.toLowerCase();
  const userAccessLevelLabelFromLogin = localStorage.getItem('accessLevel');
  const userAccessLevelID = labelsRefHardcoded.filter(
    (i: any) => i.levelTextLabel === userAccessLevelLabelFromLogin
  );
  const GoogleTokenEP = 'https://client-portal-serverless-functions.vercel.app/api/googleDrive';
  useEffect(() => {
    getDataFromEndPoint(GoogleTokenEP).then((data: any) => {
      // console.log(data);
      setSavedAccessToken(data.config.headers.Authorization);
      getDriveFilesAndFoldersOnStartUp(data.config.headers.Authorization);
      const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
      const folderIconsQuery = `let item := (select 'Icons Resources');
        let array := [""];
        for i in item do
          let id := number(i.Id);
          let folder_id := i.'Folder ID';
          let icon_id := i.'Icon ID';
          array := array(array, ["{'id': " + id + ",'fields':{'Folder ID':'" + folder_id + "','Icon ID':'" + icon_id +
              "'}}"])
        end;
        "" + join(slice(array, 1, length(array)), ",") + ""`;

      getAllRowsByEncodedQuery(clientsQueryEP, folderIconsQuery).then((clientData: any) => {
        const validJsonString = `[${clientData.replace(/'/g, '"')}]`;
        const jsonArray = JSON.parse(validJsonString);
        console.log(jsonArray);
        setFolderIconsList(jsonArray);
      });

      // connectToGoogle(data.config.headers.Authorization).then((data: any) => {
      //   // console.log('userAccessLevelID: ', userAccessLevelID[0]?.driveLabelId);
      //   // filesList();
      //   // console.log('parentFolder:' + parentFolder);
      //   // console.log('savedAccessToken:' + savedAccessToken);
      // });
    });

    // filesList();
    // initClient();
    // handleSignIn();
  }, [loadingIcon, secondRoute]);

  // useEffect(()=> {

  // }, [navigate])
  function truncate(input: string) {
    if (input.length > 30) {
      return input.substring(0, 30) + '...';
    }
    return input;
  }
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          {modalContent}
          <CustomModal
            modalTitle={modalContent === 'file' ? 'Upload a file' : 'Create a folder'}
            modalContent={modalContent}
          />
          <Flex minWidth="max-content" alignItems="center" gap="2">
            <HStack pb={6}>
              <BackButton navigate={navigate} />
              <Box p={2}>
                {/* <Heading>Therapist Resources</Heading> */}
                <Heading>Resources</Heading>
              </Box>
            </HStack>
            <Spacer />
            {driveFiles.length >= 1 && (
              <HStack gap="2">
                {/* <Text>{'Matching label ide from hardcoded label ids':userAccessLevelID[0]?.driveLabelId}</Text> */}

                {/* <Text>Access level: </Text>
                <Text textTransform={'uppercase'} fontWeight={'bold'}>
                  {localStorage.getItem('accessLevel') !== null &&
                    localStorage.getItem('accessLevel')}
                </Text> */}
                {/* <Button
                  ml={'5'}
                  bg={'brand.200'}
                  color={'white'}
                  style={{ cursor: 'pointer' }}
                  _hover={{ bg: 'brand.300', color: 'white' }}
                >
                  Upgrade account
                </Button> */}
              </HStack>
            )}
          </Flex>
          {localStorage.getItem('accessLevel') === 'Free' ? (
            <Box>
              <Box
                background={'white'}
                borderRadius={10}
                padding={5}
                textAlign={'left'}
                display={'flex'}
                flexDirection={'column'}
              >
                <Text fontWeight={'bold'} mb={5} textAlign={'left'}>
                  <UnlockIcon mr={5} /> Upgrade subscription
                </Text>
                <Box
                  width={'100%'}
                  dangerouslySetInnerHTML={{ __html: localStorage.getItem('ShortBio')! }}
                  overflow={'scroll'}
                  mt={0}
                  pr={5}
                />
                <Box>
                  <Text mb={5} textAlign={'left'}>
                    You need to upgrade your membership to access resources
                  </Text>
                  <UnorderedList mb={5} textAlign={'left'}>
                    <ListItem>
                      <Text fontWeight={'bold'}>Free: £0.00 per month</Text>
                    </ListItem>
                    <ListItem>
                      <Text fontWeight={'bold'}>Bronze: £2.99 per month</Text>
                    </ListItem>
                    <ListItem>
                      <Text fontWeight={'bold'}>Silver: £3.99 per month</Text>
                    </ListItem>
                    <ListItem>
                      <Text fontWeight={'bold'}>Gold: £4.99 per month</Text>
                    </ListItem>
                  </UnorderedList>
                  <Link href={localStorage.getItem('stripeCpLogin')!} target="_blank">
                    <Button variant={'defaultButton'}>Upgrade</Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          ) : (
            <Flex minWidth="max-content" alignItems="center" gap="2">
              <Box w={'md'} mb={10}>
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    placeholder="Search for a file"
                    onChange={(e) => handleSearch(e)}
                    type="search"
                    value={searchValue}
                    // value={searchValue}
                  />
                  <InputRightElement>
                    <IconButton
                      colorScheme="blue"
                      aria-label="Search database"
                      icon={
                        searchValue !== '' ? (
                          <CloseIcon onClick={(e) => clearSearch(e)} />
                        ) : (
                          <IconButton
                            bg={'brand.200'}
                            aria-label="search"
                            size="md"
                            icon={<SearchIcon />}
                          />
                        )
                      }
                    />
                  </InputRightElement>
                </InputGroup>{' '}
                {driveFilesAndFolders.length >= 1 && searchValue.length >= 1
                  ? driveFilesAndFolders.length + ' results'
                  : ''}
              </Box>
              <Spacer />
              {localStorage.getItem('accessLevel') === null &&
              localStorage.getItem('accessLevel') === 'admin' ? (
                <ButtonGroup gap="2">
                  <Button
                    onClick={() => {
                      onOpen();
                      setModalContent('folder');
                    }}
                  >
                    Create folder
                  </Button>
                  <Button
                    onClick={() => {
                      onOpen();
                      setModalContent('file');
                    }}
                    ml={5}
                  >
                    Upload file
                  </Button>
                </ButtonGroup>
              ) : (
                ''
              )}
            </Flex>
          )}

          {localStorage.getItem('accessLevel') === 'Free' ? (
            ''
          ) : (
            <Box display={'flex'} flexWrap={'wrap'} flexDirection={'row'}>
              {driveFilesAndFolders && loadingIcon === 0 ? (
                <Box
                  width={'100%'}
                  height={'30vh'}
                  alignContent={'center'}
                  display={'flex'}
                  flexDirection={'column'}
                  alignItems={'center'}
                  pt={'15vh'}
                >
                  <Spinner
                    thickness="8px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="brand.200"
                    size="xl"
                  />
                </Box>
              ) : (
                <>
                  <SimpleGrid columns={[1, 2, 3, 3]} spacing={5}>
                    {driveFolders.map((i: any, k: any) => (
                      <Box
                        key={k}
                        onClick={() => getFilesOfParentDriveFolder(i.id)}
                        w={'100%'}
                        h={'84px'}
                        textAlign={'left'}
                        alignContent={'center'}
                        alignItems={'center'}
                        cursor={'pointer'}
                        _hover={{
                          background: 'gray.200',
                          color: 'teal.500',
                          textDecoration: 'none',
                        }}
                        border={'1px solid eaeaea'}
                        bg={'gray.100'}
                        borderRadius={5}
                        px={5}
                        mb={5}
                        mr={5}
                        textDecoration={'none'}
                      >
                        <HStack mt={0}>
                          <Box
                            w={'64px'}
                            height={'64px'}
                            display={'flex'}
                            flexDirection={'row'}
                            alignContent={'center'}
                            alignItems={'center'}
                          >
                            {folderIconsList?.filter((x: any) => x.fields['Folder ID'] === i.id)
                              .length === 1 ? (
                              <Image
                                // src={`https://drive.google.com/thumbnail?id=${i.fields?.ad_image_url}&sz=w1000`}
                                src={`https://drive.google.com/thumbnail?id=${
                                  folderIconsList?.find((f: any) => f.fields['Folder ID'] === i.id)
                                    ?.fields['Icon ID']
                                }`}
                                width="56px"
                              />
                            ) : (
                              <Image
                                src={
                                  'https://drive-thirdparty.googleusercontent.com/' +
                                  256 +
                                  '/type/' +
                                  i['mimeType']
                                }
                              />
                            )}
                            {/* <Image
                            src={
                              'https://drive.google.com/uc?export=view&id=1BL0viHBjalbeuFD2E1FbDX0wIPh4yHGf'
                            }
                            width="56px"
                          /> */}
                          </Box>
                          <Text
                            fontSize="md"
                            textAlign={'left'}
                            textDecoration={'none'}
                            _hover={{ textDecoration: 'none' }}
                            color={'black'}
                            width={'100%'}
                            height={'24px'}
                            overflow={'hidden'}
                            pl={5}
                            // mt={-5}
                          >
                            {truncate(i['name'])}{' '}
                          </Text>
                          <Box>
                            <Tooltip
                              label={i['description']}
                              style={{ cursor: 'pointer' }}
                              p={5}
                              pb={-5}
                            >
                              <Icon mt={0} />
                            </Tooltip>
                          </Box>
                          {/* <Image src={i['thumbnailLink']} boxSize="200px" /> */}
                        </HStack>
                      </Box>
                    ))}
                    {driveFiles
                      ?.filter((i: any) =>
                        i['labelInfo'].labels[0].fields['55CC217458'].selection.includes(
                          userAccessLevelID[0]?.driveLabelId
                        )
                      )
                      .map((i: any, k: any) =>
                        // <Link key={k} href={i['webViewLink']} mr={5} mt={5} target={'_blank'}>
                        i['mimeType'] === 'application/vnd.google-apps.folder' ? (
                          <Box
                            key={k}
                            onClick={() => setCurrentItem(i['webViewLink'])}
                            w={'100%'}
                            h={'84px'}
                            textAlign={'left'}
                            alignContent={'center'}
                            alignItems={'center'}
                            cursor={'pointer'}
                            _hover={{
                              background: 'gray.200',
                              color: 'teal.500',
                              textDecoration: 'none',
                            }}
                            border={'1px solid eaeaea'}
                            bg={'gray.100'}
                            borderRadius={5}
                            p={'5'}
                            mb={5}
                            mr={5}
                            textDecoration={'none'}
                          >
                            <HStack>
                              <Box
                                w={'64px'}
                                height={'64px'}
                                display={'flex'}
                                flexDirection={'row'}
                                alignContent={'center'}
                                alignItems={'center'}
                              >
                                <Image
                                  src={
                                    'https://drive-thirdparty.googleusercontent.com/' +
                                    256 +
                                    '/type/' +
                                    i['mimeType']
                                  }
                                />
                              </Box>

                              <Text fontSize="sm">{truncate(i['name'])} </Text>
                              <Tooltip
                                label={i['description']}
                                style={{ cursor: 'pointer' }}
                                p={5}
                                pb={-5}
                              >
                                <Icon mt={-1} />
                              </Tooltip>
                            </HStack>
                          </Box>
                        ) : (
                          <Link
                            key={k}
                            href={'/resource/?res_id=' + i['id'] + '&fileExt=' + i['fileExtension']}
                            // target={'_blank'}
                            textAlign={'center'}
                            alignContent={'center'}
                            textDecoration={'none'}
                          >
                            <Box
                              key={k}
                              onClick={() => setCurrentItem(i['webViewLink'])}
                              w={'100%'}
                              h={'84px'}
                              textAlign={'left'}
                              alignContent={'center'}
                              alignItems={'center'}
                              cursor={'pointer'}
                              _hover={{
                                background: 'gray.200',
                                color: 'teal.500',
                                textDecoration: 'none',
                              }}
                              border={'1px solid eaeaea'}
                              bg={'gray.100'}
                              borderRadius={5}
                              px={5}
                              mb={5}
                              mr={5}
                              textDecoration={'none'}
                            >
                              <HStack>
                                <Box
                                  w={'64px'}
                                  height={'64px'}
                                  display={'flex'}
                                  flexDirection={'row'}
                                  alignContent={'center'}
                                  alignItems={'center'}
                                >
                                  <Image
                                    src={
                                      'https://drive-thirdparty.googleusercontent.com/' +
                                      256 +
                                      '/type/' +
                                      i['mimeType']
                                    }
                                  />
                                </Box>

                                <Text
                                  fontSize="md"
                                  textAlign={'left'}
                                  textDecoration={'none'}
                                  _hover={{ textDecoration: 'none' }}
                                  color={'black'}
                                  width={'100%'}
                                  height={'24px'}
                                  overflow={'hidden'}
                                  ml={5}
                                >
                                  {truncate(i['name'])}{' '}
                                </Text>
                                <Box>
                                  <Tooltip
                                    label={i['description']}
                                    style={{ cursor: 'pointer' }}
                                    p={5}
                                    pb={-5}
                                  >
                                    <Icon mt={-1} />
                                  </Tooltip>
                                </Box>
                                {/* <Image src={i['thumbnailLink']} boxSize="200px" /> */}
                              </HStack>
                            </Box>
                          </Link>
                        )
                      )}
                  </SimpleGrid>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ResourcesContentTherapist;
