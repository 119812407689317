export default function getUserNinoxDataByEmail(endPointPassed: string, bearerToken: any) {
  const singleUserData = fetch(endPointPassed, {
    headers: {
      Authorization: bearerToken,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((data) => data[0]);

  return singleUserData;
}

// export default async function getUserNinoxDataByEmail(endPointPassed: string, bearerToken: any) {
//   const response = await fetch(endPointPassed, {
//     headers: {
//       Authorization: bearerToken,
//       'Content-Type': 'application/json',
//     },
//   });
//   const userData = await response.json();
//   const singleUserData = userData[0];

//   return singleUserData;
// }
