import getAllRowsByEncodedQueryTxDays from './../utils/getAllRowsByEncodedQueryTxDays';

export default async function getRadialChartData(
  schoolsDays: any,
  queryType: any,
  schoolType: any
) {
  let endPointsFetch: object[] = [];

  schoolsDays.forEach((item: any) => {
    let remDaysQueryMs = `let school_id := ${item.school};let today_date := today();let today_year := year(today_date);let today_month := month(today_date);let school_years_array := if today_month >= 9 then [today_year, number(today_year + 1)] else [number(today_year - 1), today_year] end; school_years_array; let full_dates := (select 'School Dates')['Mainstream School'.Id = school_id and Start >= date(number(item(school_years_array, 0)), 9, 1) and Start <= date(number(item(school_years_array, 1)), 8, 1) and number('Duration (hrs)') >= number(4 * 60 * 60 * 1000)]; let half_dates := (select 'School Dates')['Mainstream School'.Id = school_id and Start >= date(number(item(school_years_array, 0)), 9, 1) and Start <= date(number(item(school_years_array, 1)), 8, 1) and number('Duration (hrs)') < number(4 * 60 * 60 * 1000)]; let contracted_days := item((select 'Mainstream School')[Id = school_id].'Annual (full) days', 0); contracted_days - count(full_dates) - count(half_dates) * 0.5`;
    let remDaysQuerySs = `let school_id := ${item.school};
    let today_date := today();
    let today_year := year(today_date);
    let today_month := month(today_date);
    let school_years_array := if today_month >= 9 then
            [today_year, number(today_year + 1)]
        else
            [number(today_year - 1), today_year]
        end;
    school_years_array;
    let full_dates := (select 'School Dates')['Mainstream School'.Id = school_id and
                    Start >= date(number(item(school_years_array, 0)), 9, 1) and
                Start <= date(number(item(school_years_array, 1)), 8, 1) and
            number('Duration (hrs)') > number(4 * 60 * 60 * 1000)];
    let half_dates := (select 'School Dates')['Mainstream School'.Id = school_id and
                    Start >= date(number(item(school_years_array, 0)), 9, 1) and
                Start <= date(number(item(school_years_array, 1)), 8, 1) and
            number('Duration (hrs)') <= number(4 * 60 * 60 * 1000)];
    let contracted_days := item((select 'SEN Schools')[Id = school_id].'Annual (full) days', 0);
    contracted_days - count(full_dates) - count(half_dates) * 0.5`;

    const dataQuery = schoolType === 'Ms' ? remDaysQueryMs : remDaysQuerySs;
    endPointsFetch.push(
      getAllRowsByEncodedQueryTxDays(queryType, dataQuery).then((data: any) => {
        return {
          school: item.school,
          remDays: data,
          annualDays: item.annualFullDays,
          completedDays: Number(item.annualFullDays) - Number(data),
          schoolName: item.schoolName,
          schoolType: schoolType,
        };
      })
    );
  });

  const results = await Promise.all(endPointsFetch);

  return results;
}
