import {
  AbsoluteCenter,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  HStack,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import { LoginProps } from '../interfaces/LoginProps';
import { MyContext } from '..';
import { signInWithGoogle } from '../context/GoogleAuthProvider';
import { FcGoogle } from 'react-icons/fc';
import UL_pattern_bg from './../logo/UL_pattern_bg.png';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';
const loginImage =
  'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80';

export default function AdminLogin({ signedInUserType, setSignedInUserType }: LoginProps) {
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  let userType = 'admin';
  useEffect(() => {
    setSignedInUserType(userType);
    // console.log(userType);
  }, []);
  const signIn = async () => {
    try {
      await auth.signInWithEmailAndPassword(emailRef.current!.value, passwordRef.current!.value);
      navigate('/admin-dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  const signOut = async () => {
    await auth.signOut();
    localStorage.clear();
    navigate('/admin-login');
  };

  const LeftData = () => (
    <Stack spacing={10} width={'80%'}>
      <Text fontSize={50} fontWeight={'black'} color={'brand.300'} lineHeight={1} mt={50}>
        Admin Login
      </Text>
      {user && (
        <VStack>
          <Button onClick={() => navigate('/admin-dashboard')}>
            You're already logged in, Go to Admin Dashboard
          </Button>
          <Text>or</Text>
          <Button onClick={() => signOut()}>Sign Out</Button>
        </VStack>
      )}
      {!user && (
        <Box rounded={'lg'}>
          <Stack align={'left'}>
            <Text fontSize={'lg'} color={'gray.600'} pb={'5'} textAlign={'left'}>
              Login to get started
            </Text>
          </Stack>
          <Stack spacing={4}>
            <Stack>
              <Button
                colorScheme={'gray'}
                leftIcon={<FcGoogle />}
                variant={'solid'}
                onClick={() => {
                  signInWithGoogle().then(() => navigate('/admin-dashboard'));
                }}
              >
                Sign in with Google
              </Button>
              <Box textAlign={'left'} mt={3}>
                <Link href="/">← Back home</Link>
              </Box>
            </Stack>
          </Stack>
        </Box>
      )}
    </Stack>
  );
  return (
    <Box
      position="relative"
      background={'white'}
      height={'100vh'}
      backgroundImage={UL_pattern_bg}
      backgroundRepeat={'repeat'}
      backgroundSize={'cover'}
    >
      <Box
        bg={'white'}
        position={'absolute'}
        left={0}
        bottom={'0'}
        minH={'90vh'}
        borderTopRightRadius="40"
        width={'94%'}
      >
        <Flex align={'center'} justify={'center'}>
          <HStack spacing={8} mx={'auto'} width={'90%'} py={6} px={6}>
            <Box rounded={'lg'} p={8} mt={30} background={'white'} width={'50%'}>
              <LeftData />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'50%'}
            >
              <Image width="600px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
}
