import { FiCalendar, FiFile, FiHome, FiMessageSquare, FiMessageCircle, FiUsers, FiUser, FiHelpCircle } from 'react-icons/fi';
import { FaRegCalendarPlus } from 'react-icons/fa6';
import { MdOutlineReceiptLong, MdAssessment } from 'react-icons/md';
import { RiLogoutBoxLine, RiAdminLine } from 'react-icons/ri';
import {AiFillFund} from 'react-icons/ai';
import {TbReportAnalytics} from 'react-icons/tb';

import { AiOutlineDashboard } from "react-icons/ai";
import { LuSchool2 } from "react-icons/lu";
import { IoPeopleOutline } from "react-icons/io5";
import { GoPencil } from "react-icons/go";

export const LinkItems = [
    { name: 'Dashboard', icon: AiOutlineDashboard, path: '/admin-dashboard', accountType: ['Super Admin',  'Admin'] },
    { name: 'Dashboard', icon: AiOutlineDashboard, path: '/dashboard-client', accountType: ['Super Admin',  'Private Client',] },
    { name: 'Dashboard', icon: AiOutlineDashboard, path: '/dashboard-therapist', accountType: ['Therapist Staff'] },
    { name: 'Dashboard', icon: AiOutlineDashboard, path: '/dashboard-subscriber', accountType: ['Subscriber'] },
    { name: 'Dashboard', icon: AiOutlineDashboard, path: '/dashboard-mainstream-school', accountType: ['Mainstream'] },
    { name: 'Dashboard', icon: AiOutlineDashboard, path: '/dashboard-sen-school', accountType: ['SEN'] },
    // { name: 'Resources', icon: FiFile, path: '/resources', accountType: ['Private Client', 'Subscriber'] },
    { name: 'Resources', icon: FiFile, path: '/resources/14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5', accountType: ['Therapist Staff'] },
    { name: 'Resources', icon: FiFile, path: '/resources/14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5', accountType: ['Private Client'] },
    { name: 'Resources', icon: FiFile, path: '/resources/14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5', accountType: ['Subscriber'] },
    { name: 'Resources', icon: FiFile, path: '/resources/14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5', accountType: ['Mainstream'] },
    { name: 'Resources', icon: FiFile, path: '/resources/14IT6abHPA8w_M8zvnmZVjp6w_WvNcBx5', accountType: ['SEN'] },

    // { name: 'Resources', icon: FiFile, path: '/resources', accountType: ['Schools'] },
    { name: 'Admin', icon: RiAdminLine, path: '/admin', accountType: ['Super Admin', 'Admin'] },
    { name: 'Sessions', icon: FiCalendar, path: '/private-client-sessions', accountType: ['Private Client', ] },
    { name: 'Schools', icon: LuSchool2, path: '/schools?schoolID=0&schoolType=RegularSchool', accountType: ['Therapist Staff'] },
    { name: 'Clients', icon: IoPeopleOutline, path: '/clients', accountType: ['Admin', 'Therapist Staff'] },
    { name: 'Students', icon: FiUsers, path: '/students', accountType: ['Mainstream', 'SEN'] },
    { name: 'Invoices', icon: MdOutlineReceiptLong, path: '/school-invoices', accountType: ['Mainstream', 'SEN'] },
    { name: 'Messages', icon: FiMessageSquare, path: '/messages', accountType: ['Therapist Staff', 'SEN', 'Mainstream','Subscriber', 'Private Client'] },
    { name: 'Message Center', icon: FiMessageCircle, path: '/message-center', accountType: ['Super Admin', 'Admin'] },
    { name: 'Invoices', icon: MdOutlineReceiptLong, path: '/client-invoices', accountType: ['Private Client'] }, // only Xero invoices
    // { name: 'Invoices', icon: MdOutlineReceiptLong, path: '/subscriber-profile', accountType: ['Subscriber'] }, // only Stripe subscriber invoices
    // { name: 'Reviews', icon: MdAssessment, path: '/dashboard-reviews', accountType: ['Super Admin', 'Mainstream', 'SEN', 'Private Client', 'Subscriber'] },
    { name: 'Reporting', icon: GoPencil, path: '/reportingapp', accountType: ['Admin','Therapist Staff'] },
    { name: 'Profile', icon: FiUser, path: '/profile?profileData=0', accountType: ['Private Client'] },
    { name: 'Profile', icon: FiUser, path: '/ul-academy-profile?profileData=5', accountType: ['Subscriber'] },
    // { name: 'My Subscription', icon: FiUser, path: localStorage.getItem('stripeCpLogin'), accountType: ['Subscriber'] },
    { name: 'Add session', icon: FaRegCalendarPlus, path: 'https://sites.google.com/unlockinglanguage.co.uk/session-entry-form/home', accountType: ['Therapist Staff'] },
    { name: 'Reviews', icon: MdAssessment, path: 'https://www.google.com/maps/place//data=!4m3!3m2!1s0x47d8a80df91934e9:0x7f2a1b8931a6abd!12e1?source=g.page.m.dd._&laa=lu-desktop-reviews-dialog-review-solicitation', accountType: ['Super Admin', 'Mainstream', 'SEN', 'Private Client', 'Subscriber'] },
    { name: 'Help', icon: FiHelpCircle, path: '/help', accountType: ['Therapist Staff', 'SEN', 'Mainstream','Subscriber', 'Private Client', 'Super Admin',  'Admin'] },

    // { name: 'Profile', icon: FiUser, path: '/profile', accountType: ['Super Admin', 'Admin', 'Therapist Staff', 'Mainstream', 'SEN', 'Private Client', 'Subscriber'] },

  ];
  