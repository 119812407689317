export default async function ninoxTableUpdateRow(
  endpoint: string,
  rowId: number,
  requestBody: object,
  bearerToken: string
) {
  const headers = {
    Authorization: bearerToken,
    'Content-Type': 'application/json',
  };

  const requestOptions = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(endpoint + rowId, requestOptions);
  const data = await response.json();
}
