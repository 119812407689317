import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Flex,
  Stack,
  Heading,
  Text,
  Link,
  List,
  ListIcon,
  ListItem,
  Button,
  Box,
  ButtonGroup,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  FormHelperText,
  Image,
} from '@chakra-ui/react';
import {} from '@chakra-ui/react';
import StepOne from '../components/subscriberSignUpSteps/StepOne';
import StepTwo from '../components/subscriberSignUpSteps/StepTwo';
import StepThree from '../components/subscriberSignUpSteps/StepThree';
import StepFour from '../components/subscriberSignUpSteps/StepFour';
import StepFive from '../components/subscriberSignUpSteps/StepFive';
import UL_logo from './../logo/unlocking_language_logo_white.png';
import { privateForm } from '../interfaces/privateForm';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import postDataToEndPoint from '../utils/postDataToEndPoint';
import { MdCheckCircle, MdFacebook, MdTwoWheeler } from 'react-icons/md';
import { BsFacebook, BsInstagram, BsTwitterX } from 'react-icons/bs';
// import { SmallCloseIcon } from '@chakra-ui/icons';
const steps = [
  { title: 'Details', description: 'Fill in your details', content: 'Content 1' },
  {
    title: 'Contract',
    description: 'View & Sign Contract',
    content: 'Content 3',
  },
  { title: 'Subscription type', description: 'Select your subscription', content: 'Content 4' },
  // { title: 'Payment', description: 'Make the first payment', content: 'Content 5' },
];

export default function ClinicalSignUpThankYou(): JSX.Element {
  const queryParameters = new URLSearchParams(window.location.search);
  const paymentConfirmation = queryParameters.get('payment_intent');
  const invoiceID = queryParameters.get('invoiceID');

  const defaultForm = {
    firstname: '',
    lastname: '',
    address: '',
    email: '',
    contactNumber: '',
    password: '',
    cpassword: '',
    contract: 0,
  };
  const [form, setForm] = useState<privateForm>(defaultForm);
  const [signContract, setSignContract] = useState(0);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  function goBackOneStep(activeStep: number) {
    setActiveStep(activeStep - 1);
  }
  function getTheContractField() {}
  function signTheContract() {
    // insert client
    // get contract field
    // run conditional
    // go to ninox
    // update contract field
    // get contract field
    // run conditional
  }
  const createAccount = async () => {
    try {
      await auth.createUserWithEmailAndPassword(form.email, form.password);
    } catch (error) {
      console.error(error);
    }
  };

  function collectDataAndSignup() {
    setActiveStep(activeStep + 1);
    activeStep === 1 && createAccount();
  }
  const stepSelect = steps[activeStep].title;

  const passInvoiceIDToMakeEP = process.env.REACT_APP_PASS_INVOICEID_TO_MAKE_EP;
  const requestBody = {
    data: {
      id: [String(invoiceID && invoiceID)],
      stripePaymentIntent: [String(paymentConfirmation && paymentConfirmation)],
      // clientid: ''
    },
  };
  useEffect(() => {
    postDataToEndPoint(passInvoiceIDToMakeEP, requestBody);
  }, []);
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Stack align={'left'} mb={'50px'}>
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'} width={'100%'}>
            <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
          </Box>
          <Box width={'100%'} alignItems={'center'} p={10}>
            <Heading as={'h4'} mb={'5'} fontSize={30} color={'brand.200'}>
              Thank you for completing our onboarding process!
            </Heading>
            <Text>Your transaction has been successfully received and is now being processed.</Text>
            <Box my={10}>
              <Box mb={5}>
                <Text fontWeight={'bold'}>Payment Confirmation ID:</Text>
                <Text>
                  {paymentConfirmation
                    ? paymentConfirmation
                    : 'No payment has been made yet - contact support'}
                </Text>
              </Box>
              <Box mb={5}>
                <Text fontWeight={'bold'}>Invoice ID:</Text>
                <Text>
                  {invoiceID ? invoiceID : 'No invoice has been made yet - contact support'}
                </Text>
              </Box>
              <Box>
                <Text fontWeight={'bold'}>Processing times:</Text>
                <Text>Payment processing may take up to 48 hours to complete.</Text>
              </Box>
            </Box>
            <Box my={10}>
              <Text fontWeight={'bold'}>While you wait, why not check out our:</Text>
              <List spacing={3}>
                <ListItem>
                  <Link href="#">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    &nbsp;Latest Products
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="#">
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    &nbsp;Helpful Articles
                  </Link>
                </ListItem>
              </List>
            </Box>
            <Box my={10}>
              <Text fontWeight={'bold'}>
                Follow us on social media for updates, offers, and more:
              </Text>
              <List spacing={3}>
                <ListItem>
                  <Link href="https://www.facebook.com/UnlockingLanguage" target="_blank">
                    <HStack>
                      <ListIcon as={BsFacebook} color="green.500" />
                      <Text>&nbsp;Facebook</Text>
                    </HStack>
                  </Link>
                </ListItem>
                <ListItem>
                  <Link href="https://www.instagram.com/unlockinglanguage/" target="_blank">
                    <HStack>
                      <ListIcon as={BsInstagram} color="green.500" />
                      <Text>&nbsp;Instagram</Text>
                    </HStack>
                  </Link>
                </ListItem>
              </List>
            </Box>
            <Box mb={10}>
              <Text>
                If you have any questions, please email us at{' '}
                <Link href="info@unlockinglanguage.com">info@unlockinglanguage.com</Link> or call us
                at{' '}
                <Link href={'tel:02075369299'} fontWeight={'bold'}>
                  020 7536 9299
                </Link>
              </Text>
              <Text>
                Check our <a href="/help">FAQ</a> for quick answers to common questions.
              </Text>
            </Box>

            <Link href={'/dashboard-client'} textAlign={'center'} mt={10}>
              <Button
                textAlign={'center'}
                bg={'brand.300'}
                _hover={{
                  bg: 'brand.200',
                }}
                color={'white'}
              >
                Go to Dashboard
              </Button>
            </Link>
          </Box>
        </Stack>
        <Outlet />
      </Stack>
    </Flex>
  );
}
