import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  useDisclosure,
  useToast,
  Select,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  Stack,
  Switch,
} from '@chakra-ui/react';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { studentFields } from '../constants/studentFields';
// import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { addStudent } from '../interfaces/addStudent';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import ninoxTableGetRow from './../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from './../utils/ninoxTableUpdateRow';
import getAllRowsById from './../utils/getAllRowsById';
import { editStudent } from '../interfaces/editStudent';
import { BackButton } from './BackButton';
import { useNavigate } from 'react-router-dom';
import orderSchools from '../utils/orderSchools';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: number | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

interface Note {
  Date: Date | string;
  Time: string;
  'Type of Contact': string | number;
  Notes: string;
  'Name and Role': string;
  Caseload: number | undefined;
}
const today = new Date(Date.now());
// console.log(today.format('YYYY-MM-DD'));
// console.log(studentFields?.fields.map((i: any, k: any) => i.name));
// const check = new Date().getTimezoneOffset(+60);
// const dateAndTimeBeforeOffset = new Date();
// const myOffset = dateAndTimeBeforeOffset.getTimezoneOffset();
// const today = new Date(dateAndTimeBeforeOffset.getTime() + myOffset);
function join(date: Date, options: any, separator: string) {
  function format(option: any) {
    let formatter = new Intl.DateTimeFormat('en', option);
    return formatter.format(date);
  }
  return options.map(format).join(separator);
}
let options = [{ year: 'numeric' }, { month: 'numeric' }, { day: 'numeric' }];
let joined = join(new Date(), options, '-');

function getTime(date: Date) {
  let theDate = date;
  let day = theDate.getDay();
  // console.log(day);
}
getTime(today);
function getTheDate(date: Date) {
  return join(date, options, '-');
}
// console.log(joined);
// .formatDate('D M dd yy', new Date(end_date));

// const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
// const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
// const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
// const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;
// const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
// const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;

const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const senSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_SEN;
const therapistSessionNotesRegularSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_MAINSTREAM;
const therapistSessionNotesSENSchoolsEP = process.env.REACT_APP_UL_SESSION_NOTES_SEN;
const therapistCaseNotesRegularSchoolsSEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const therapistCaseNotesSENSchoolsEP = process.env.REACT_APP_UL_STUDENTS_SEN;

const BookingsContentEditStudent = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  sessionId,
}: SignedInUserProps) => {
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  const navigate = useNavigate();
  // updateUser(signedInUserId);
  // date: getTheDate(today),
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');
  const EP =
    schoolType === 'RegularSchool'
      ? therapistCaseNotesRegularSchoolsSEP
      : therapistCaseNotesSENSchoolsEP;
  const schoolsEP = schoolType === 'RegularSchool' ? regularSchoolsEP : senSchoolsEP;
  const studentDefault = {
    studentPatientName: '',
    DOB: '',
    classYear: '',
    gender: '',
    ethnicity: '',
    dateOfAssessment: '',
    reportDate: '',
    reportSent: '',
    reportLink: '',
    dateOfReview: '',
    school: '',
    otherMedicalDiagnosis: '',
    className: '',
    otherCommunicationSwallowingDisorder: '',
    nameOfTherapist: '',
    ehcp: '',
    plan: '',
    notes: '',
    referralDate: '',
    localPatientIdentifier: '',
    tomsRating: '',
    endOfEpisodeDischargeCodeDescription: '',
    dateOfDischarge: '',
    revertDischarge: '',
    sessionsNotes: '',
    directHours: '',
    indirectHours: '',
    indirectHoursCompleted: '',
    indirectHoursRemaining: '',
    hoursCompleted: '',
    hoursRemaining: '',
    primaryMedicalDiganosis: '',
    additionalMedicalDiganosis: '',
    primaryCommunicationsSwallowingDisorder: '',
    additionalCommunicationSwallowingDisorder: '',
    primaryTomsScale: '',
    secondaryTomsScale: '',
    language1: '',
    language2: '',
    lanaguge3: '',
    otherLanguages: '',
  };
  const [formData, setFormData] = useState<any>(studentDefault);
  const [revertDischarge, setRevertDischarge] = useState<number>(0);
  const [dischargeDate, setDischargeDate] = useState<any>(null);

  const studentToUpdateMainstream = {
    // 'School Name': form.schoolName,
    'Student/Patient Name': formData.studentPatientName,
    DOB: formData.DOB,
    'Class year': formData.classYear,
    Gender: formData.gender,
    Ethnicity: formData.ethnicity,
    'Date of Assessment': formData.dateOfAssessment,
    'Report date': formData.reportDate,
    'Report sent?': formData.reportSent,
    'Report Link': formData.reportLink,
    'Date of review': formData.dateOfReview,
    'Mainstream School': formData.school,
    'Other Medical Diagnosis': formData.otherMedicalDiagnosis,
    'Class name': formData.className,
    'Other Communication/ Swallowing Disorder': formData.otherCommunicationSwallowingDisorder,
    'Name of Therapist': formData.nameOfTherapist,
    ehcp: formData.ehcp,
    Plan: formData.plan,
    Notes: formData.notes,
    'Referral Date': formData.referralDate,
    'Local Patient Identifier': formData.localPatientIdentifier,
    'TOMs rating': formData.tomsRating,
    'End of Episode/ Discharge Code/ Description': formData.endOfEpisodeDischargeCodeDescription,
    'Date of Discharge': formData.dateOfDischarge,
    'Sessions + Notes': formData.sessionsNotes,
    'Direct Hours': formData.directHours,
    'Indirect Hours': formData.indirectHours,
    'Indirect hours remaining': formData.indirectHoursRemaining,
    'Indirect hours completed': formData.indirectHoursCompleted,
    'Hours completed': formData.hoursCompleted,
    'Hours remaining': formData.hoursRemaining,
    'Primary Medical Diagnosis': formData.primaryMedicalDiganosis,
    'Additional Medical Diagnosis': formData.additionalMedicalDiagnosis,
    'Primary Communication/ Swallowing Disorder': formData.primaryCommunicationsSwallowingDisorder,
    'Additional Communication/ Swallowing Disorder':
      formData.additionalCommunicationSwallowingDisorder,
    'Primary TOMs Scale': formData.primaryTomsScale,
    'Secondary TOMs Scale': formData.secondaryTomsScale,
    'Language 1': formData.language1,
    'Language 2': formData.language2,
    'Language 3': formData.language3,
    'Other Languages': formData.otherLanguages,
  };
  const studentToUpdateSEN = {
    // 'School Name': form.schoolName,
    'Student/Patient Name': formData.studentPatientName,
    DOB: formData.DOB,
    'Class year': formData.classYear,
    Gender: formData.gender,
    Ethnicity: formData.ethnicity,
    'Date of Assessment': formData.dateOfAssessment,
    'Report date': formData.reportDate,
    'Report sent?': formData.reportSent,
    'Report Link': formData.reportLink,
    'Date of review': formData.dateOfReview,
    'SEN Schools': formData.school,
    'Other Medical Diagnosis': formData.otherMedicalDiagnosis,
    'Class name': formData.className,
    'Other Communication/ Swallowing Disorder': formData.otherCommunicationSwallowingDisorder,
    'Name of Therapist': formData.nameOfTherapist,
    ehcp: formData.ehcp,
    Plan: formData.plan,
    Notes: formData.notes,
    'Referral Date': formData.referralDate,
    'Local Patient Identifier': formData.localPatientIdentifier,
    'TOMs rating': formData.tomsRating,
    'End of Episode/ Discharge Code/ Description': formData.endOfEpisodeDischargeCodeDescription,
    'Date of Discharge': formData.dateOfDischarge,
    'Sessions + Notes': formData.sessionsNotes,
    'Direct Hours': formData.directHours,
    'Indirect Hours': formData.indirectHours,
    'Indirect hours remaining': formData.indirectHoursRemaining,
    'Indirect hours completed': formData.indirectHoursCompleted,
    'Hours completed': formData.hoursCompleted,
    'Hours remaining': formData.hoursRemaining,
    'Primary Medical Diagnosis': formData.primaryMedicalDiganosis,
    'Additional Medical Diagnosis': formData.additionalMedicalDiagnosis,
    'Primary Communication/ Swallowing Disorder': formData.primaryCommunicationsSwallowingDisorder,
    'Additional Communication/ Swallowing Disorder':
      formData.additionalCommunicationSwallowingDisorder,
    'Primary TOMs Scale': formData.primaryTomsScale,
    'Secondary TOMs Scale': formData.secondaryTomsScale,
    'Language 1': formData.language1,
    'Language 2': formData.language2,
    'Language 3': formData.language3,
    'Other Languages': formData.otherLanguages,
  };

  const cancelDischargeMainstream = {
    'Date of Discharge': null,
  };
  const cancelDischargeSEN = {
    'Date of Discharge': null,
  };

  const defaultForm = {
    studentPatientName: '',
    dob: '01/02/03',
    classYear: 'none',
    mainstreamSchool: '',
    localPatientIdentifier: '',
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [dateAndTime, setDateAndTime] = useState<any>(today);
  const [notes, setNotes] = useState<any>([]);
  const [schools, setSchools] = useState<any>([]);
  const [newNote, setNewNote] = useState<any>();
  const { onOpen, onToggle } = useDisclosure();
  const [size, setSize] = useState('md');
  const [rows, setRows] = useState<any>([]);
  const [sessionNotes, setSessionNotes] = useState<any>([]);
  const [form, setForm] = useState<addStudent>(defaultForm);

  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const userStats = userMeta && userMeta?.stats;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  const toast = useToast();
  const userNotes = notes?.filter((i: any) => i?.fields?.session_id == sessionId);

  const updateStudent = (e: any) => {
    try {
      ninoxTableUpdateRow(
        EP!,
        Number(sessionId),
        { fields: schoolType === 'RegularSchool' ? studentToUpdateMainstream : studentToUpdateSEN },
        String(bearerToken)
      ).then(() => {
        toast({
          title: 'Student udpated.',
          description: 'The student data has been successfully updated.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Update error',
        description:
          'The student was note updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  const cancelDischarge = (e: any) => {
    try {
      ninoxTableUpdateRow(
        EP!,
        Number(sessionId),
        { fields: schoolType === 'RegularSchool' ? cancelDischargeMainstream : cancelDischargeSEN },
        String(bearerToken)
      ).then((data: any) => {
        // setFormData({ ...formData, dateOfDischarge: data?.fields['Date of Discharge'] });
        setFormData({ ...formData, dateOfDischarge: null });
        setRevertDischarge(1);
        setDischargeDate(null);
        console.log('dod', data?.fields['Date of Discharge']);
        navigate(`/schools?schoolID=${formData.school}&schoolType=${schoolType}`);
        toast({
          title: 'Student discharge cancelled.',
          description: 'The student data has been successfully updated.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Update error',
        description:
          'The student was note updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    ninoxTableGetRow(EP!, Number(sessionId), bearerToken).then((data: any) => {
      const formLoadData = {
        studentPatientName: data?.fields['Student/Patient Name'],
        DOB: data?.fields['DOB'],
        classYear: data?.fields['Class year'],
        gender: data?.fields['Gender'],
        ethnicity: data?.fields['Ethnicity'],
        dateOfAssessment: data?.fields['Date of Assessment'],
        reportDate: data?.fields['Report date'],
        reportSent: data?.fields['Report sent?'],
        reportLink: data?.fields['Report Link'],
        dateOfReview: data?.fields['Date of review'],
        school:
          schoolType === 'RegularSchool'
            ? data?.fields['Mainstream School']
            : data?.fields['SEN Schools'],
        otherMedicalDiagnosis: data?.fields['Other Medical Diagnosis'],
        className: data?.fields['Class name'],
        otherCommunicationSwallowingDisorder:
          data?.fields['Other Communication/ Swallowing Disorder'],
        nameOfTherapist: data?.fields['Name of Therapist'],
        ehcp: data?.fields['EHCP'],
        plan: data?.fields['Plan'],
        notes: data?.fields['Notes'],
        referralDate: data?.fields['Referral Date'],
        localPatientIdentifier: data?.fields['Local Patient Identifier'],
        tomsRating: data?.fields['TOMs rating'],
        endOfEpisodeDischargeCodeDescription:
          data?.fields['End of Episode/ Discharge Code/ Description'],
        dateOfDischarge: data?.fields['Date of Discharge'],
        sessionsNotes: data?.fields['Sessions + Notes'],
        directHours: data?.fields['Direct Hours'],
        indirectHours: data?.fields['Indirect Hours'],
        indirectHoursRemaining: data?.fields['Indirect hours remaining'],
        indirectHoursCompleted: data?.fields['Indirect hours completed'],
        hoursCompleted: data?.fields['Hours completed'],
        hoursRemaining: data?.fields['Hours remaining'],
        primaryMedicalDiganosis: data?.fields['Primary Medical Diagnosis'],
        additionalMedicalDiagnosis: data?.fields['Additional Medical Diagnosis'],
        primaryCommunicationsSwallowingDisorder:
          data?.fields['Primary Communication/ Swallowing Disorder'],
        additionalCommunicationSwallowingDisorder:
          data?.fields['Additional Communication/ Swallowing Disorder'],
        primaryTomsScale: data?.fields['Primary TOMs Scale'],
        secondaryTomsScale: data?.fields['Secondary TOMs Scale'],
        language1: data?.fields['Language 1'],
        language2: data?.fields['Language 2'],
        lanaguge3: data?.fields['Language 3'],
        otherLanguages: data?.fields['Other Languages'],
      };
      setFormData(formLoadData);
      setDischargeDate(data?.fields['Date of Discharge']);
      // console.log(formLoadData);
      // console.log(schoolType);
    });
    getAllRowsById(schoolsEP).then((data: any) => {
      const myData = data;
      // console.log('Regular Schools: ', JSON.stringify(myData), 'color:blue;');
      setSchools(myData);
    });
    window.scrollTo(0, 0);
  }, []);

  const oldDate = '2023-09-02T15:00:00';

  const handleTypeOfContact = (e: any) => {
    setNewNote({ ...newNote, 'Type of Contact': e.target.value });
  };
  const studentClasses = ['N', 'R', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

  const roles = ['SLT', 'SLTA'];

  const studenFieldNames = [
    'Student/Patient Name',
    'DOB',
    'Class year',
    'Gender',
    'Ethnicity',
    'Date of Assessment',
    'Report date',
    'Report sent?',
    'Report Link',
    'Date of review',
    'SEN Schools',
    'Other Medical Diagnosis',
    'Class name',
    'Other Communication/ Swallowing Disorder',
    'Name of Therapist',
    'EHCP',
    'Plan',
    'Notes',
    'Referral Date',
    'Local Patient Identifier',
    'TOMs rating',
    'End of Episode/ Discharge Code/ Description',
    'Date of Discharge',
    'Sessions + Notes',
    'Direct Hours',
    'Indirect Hours',
    'Indirect hours remaining',
    'Indirect hours completed',
    'Hours completed',
    'Hours remaining',
    'Primary Medical Diagnosis',
    'Additional Medical Diagnosis',
    'Primary Communication/ Swallowing Disorder',
    'Additional Communication/ Swallowing Disorder',
    'Primary TOMs Scale',
    'Secondary TOMs Scale',
    'Language 1',
    'Language 2',
    'Language 3',
    'Other Languages',
  ];

  const classYearChoices = studentFields.fields.filter((i: any) => i.name === 'Class year')[0]
    .choices;
  const primaryMedicalDiganosis = studentFields.fields.filter(
    (i: any) => i.name === 'Primary Medical Diagnosis'
  )[0].choices;
  const additionalMedicalDiagnosis = studentFields.fields.filter(
    (i: any) => i.name === 'Additional Medical Diagnosis'
  )[0].choices;
  const primaryCommunicationsSwallowingDisorder = studentFields.fields.filter(
    (i: any) => i.name === 'Primary Communication/ Swallowing Disorder'
  )[0].choices;
  const additionalCommunicationSwallowingDisorder = studentFields.fields.filter(
    (i: any) => i.name === 'Additional Communication/ Swallowing Disorder'
  )[0].choices;
  const primaryTomsScale = studentFields.fields.filter(
    (i: any) => i.name === 'Primary TOMs Scale'
  )[0].choices;
  const secondaryTomsScale = studentFields.fields.filter(
    (i: any) => i.name === 'Secondary TOMs Scale'
  )[0].choices;
  const language1 = studentFields.fields.filter((i: any) => i.name === 'Language 1')[0].choices;
  const language2 = studentFields.fields.filter((i: any) => i.name === 'Language 2')[0].choices;
  const language3 = studentFields.fields.filter((i: any) => i.name === 'Language 3')[0].choices;
  const therapistName = localStorage.getItem('firstName') + ' ' + localStorage.getItem('lastName');
  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <Box p={2} mb={5}>
            <HStack spacing={5}>
              <BackButton navigate={navigate} />
              <Heading pb={6} mt={6}>
                Edit Student: {formData?.studentPatientName}
              </Heading>
            </HStack>
          </Box>
          {/* {JSON.stringify(formData)} */}
          {/* {JSON.stringify(
            studentFields?.fields.filter((i: any) => i.name === 'Class year')[0].choices
          )} */}
          {/* {JSON.stringify(studentFields.fields.map((i: any, k: any) => i.name + ' : ' + i.type))} */}

          <Flex>
            <Box w={'100%'} p={'5'}>
              <Tabs variant="soft-rounded" colorScheme="green">
                <TabList>
                  <Tab>Student Info</Tab>
                  <Tab>Clinical Info</Tab>
                  <Tab>EHCP</Tab>
                  <Tab>Ratings</Tab>
                  <Tab>Discharge</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>Student name </FormLabel>
                      <Input
                        name="studentName"
                        value={formData?.studentPatientName}
                        onChange={(e: any) =>
                          setFormData({ ...formData, studentPatientName: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>DOB </FormLabel>
                      <Input
                        name="dob"
                        value={formData?.DOB}
                        onChange={(e: any) => setFormData({ ...formData, DOB: e.target.value })}
                        type="date"
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>
                        {schoolType === 'RegularSchool' ? 'Mainstream School' : 'SEN School'}{' '}
                      </FormLabel>
                      <Select
                        isRequired
                        name="school"
                        onChange={(e: any) => setFormData({ ...formData, school: e.target.value })}
                        value={formData?.school}
                      >
                        {schools &&
                          schools?.sort(orderSchools).map((i: any, k: number) => (
                            <option value={i?.id} key={k}>
                              {i?.fields['School Name']}
                            </option>
                          ))}
                      </Select>
                    </FormControl>
                    {/* <FormControl mb={'5'}>
                      <FormLabel>Class: </FormLabel>
                      <Select
                        isRequired
                        onChange={(e: any) =>
                          setFormData({ ...formData, classYear: e.target.value })
                        }
                        value={formData?.classYear}
                      >
                        {classYearChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl> */}
                    <FormControl mb={'5'}>
                      <FormLabel>Local Patient Identifier </FormLabel>
                      <Input
                        name="referralDate"
                        value={formData?.localPatientIdentifier}
                        type="string"
                        isDisabled={true}
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Class: </FormLabel>
                      <Select
                        isRequired
                        name="class"
                        onChange={(e: any) =>
                          setFormData({ ...formData, classYear: e.target.value })
                        }
                        value={formData?.classYear}
                      >
                        {classYearChoices?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Class name</FormLabel>
                      <Input
                        name="className"
                        value={formData?.className}
                        onChange={(e: any) =>
                          setFormData({ ...formData, className: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Gender: </FormLabel>
                      <Select
                        isRequired
                        name="gender"
                        onChange={(e: any) => setFormData({ ...formData, gender: e.target.value })}
                        value={formData?.gender}
                      >
                        <option value={''}>{'Select an option'}</option>
                        <option value={'Male'}>{'Male'}</option>
                        <option value={'Female'}>{'Female'}</option>
                      </Select>
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Ethnicity </FormLabel>
                      <Input
                        name="ethnicity"
                        value={formData?.ethnicity}
                        onChange={(e: any) =>
                          setFormData({ ...formData, ethnicity: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Referral date </FormLabel>
                      <Input
                        name="referralDate"
                        value={formData?.referralDate}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({ ...formData, referralDate: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Language 1: </FormLabel>
                      <Select
                        isRequired
                        name="language1"
                        onChange={(e: any) =>
                          setFormData({ ...formData, language1: e.target.value })
                        }
                        value={formData?.language1}
                      >
                        {language1?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Language 2: </FormLabel>
                      <Select
                        isRequired
                        name="language2"
                        onChange={(e: any) =>
                          setFormData({ ...formData, language2: e.target.value })
                        }
                        value={formData?.language2}
                      >
                        {language2?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Language 3: </FormLabel>
                      <Select
                        isRequired
                        name="language3"
                        onChange={(e: any) =>
                          setFormData({ ...formData, language3: e.target.value })
                        }
                        value={formData?.language3}
                      >
                        {language3?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Other Languages</FormLabel>
                      <Input
                        name="otherLanguages"
                        value={formData?.otherLanguages}
                        type="string"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            otherLanguages: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>Date of Assessment </FormLabel>
                      <Input
                        name="dateOfAssessment"
                        value={formData?.dateOfAssessment}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({ ...formData, dateOfAssessment: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Date of review </FormLabel>
                      <Input
                        name="dateOfReview"
                        value={formData?.dateOfReview}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({ ...formData, dateOfReview: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Report date </FormLabel>
                      <Input
                        name="reportDate"
                        value={formData?.reportDate}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({ ...formData, reportDate: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Report sent?: </FormLabel>
                      <Select
                        isRequired
                        onChange={(e: any) =>
                          setFormData({ ...formData, reportSent: e.target.value })
                        }
                        value={formData?.reportSent}
                      >
                        <option value={''}>{'Select'}</option>
                        <option value={'true'}>{'Yes'}</option>
                        <option value={'false'}>{'No'}</option>
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Report link </FormLabel>
                      <Input
                        name="reportLink"
                        value={formData?.reportLink}
                        onChange={(e: any) =>
                          setFormData({ ...formData, reportLink: e.target.value })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Notes</FormLabel>
                      <Textarea
                        name="notes"
                        value={formData?.notes}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            notes: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Name of Therapist</FormLabel>
                      <Input
                        name="nameOfTherapist"
                        value={formData?.nameOfTherapist}
                        // isDisabled={true}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            nameOfTherapist: e.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Primary Medical Diagnosis: </FormLabel>
                      <Select
                        isRequired
                        name="primaryMedicalDiganosis"
                        onChange={(e: any) =>
                          setFormData({ ...formData, primaryMedicalDiganosis: e.target.value })
                        }
                        value={formData?.primaryMedicalDiganosis}
                      >
                        {primaryMedicalDiganosis?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Additional Medical Diagnosis: </FormLabel>
                      <Select
                        isRequired
                        name="additionalMedicalDiagnosis"
                        onChange={(e: any) =>
                          setFormData({ ...formData, additionalMedicalDiagnosis: e.target.value })
                        }
                        value={formData?.additionalMedicalDiagnosis}
                      >
                        {additionalMedicalDiagnosis?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Other Medical Diagnosis</FormLabel>
                      <Input
                        name="reportLink"
                        value={formData?.otherMedicalDiagnosis}
                        onChange={(e: any) =>
                          setFormData({ ...formData, otherMedicalDiagnosis: e.target.value })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Primary Communication/ Swallowing Disorder: </FormLabel>
                      <Select
                        isRequired
                        name="primaryCommunicationsSwallowingDisorder"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            primaryCommunicationsSwallowingDisorder: e.target.value,
                          })
                        }
                        value={formData?.primaryCommunicationsSwallowingDisorder}
                      >
                        {primaryCommunicationsSwallowingDisorder?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Additional Communication/ Swallowing Disorder: </FormLabel>
                      <Select
                        isRequired
                        name="additionalCommunicationSwallowingDisorder"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            additionalCommunicationSwallowingDisorder: e.target.value,
                          })
                        }
                        value={formData?.additionalCommunicationSwallowingDisorder}
                      >
                        {additionalCommunicationSwallowingDisorder?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Other Communication/ Swallowing Disorder</FormLabel>
                      <Input
                        name="otherCommunicationSwallowingDisorder"
                        value={formData?.otherCommunicationSwallowingDisorder}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            otherCommunicationSwallowingDisorder: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>EHCP?: </FormLabel>
                      <Select
                        name="ehcp"
                        isRequired
                        onChange={(e: any) => setFormData({ ...formData, ehcp: e.target.value })}
                        value={formData?.ehcp}
                      >
                        <option value={''}>{'Select'}</option>
                        <option value={'true'}>{'Yes'}</option>
                        <option value={'false'}>{'No'}</option>
                      </Select>
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Plan</FormLabel>
                      <Textarea
                        name="plan"
                        value={formData?.plan}
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            plan: e.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Direct hours </FormLabel>
                      <Input
                        name="directHours"
                        value={formData?.directHours}
                        type="number"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            directHours: e.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Indirect Hours</FormLabel>
                      <Input
                        name="indirectHours"
                        value={formData?.indirectHours}
                        type="number"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            indirectHours: e.target.value,
                          })
                        }
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Direct hours completed</FormLabel>
                      <Input
                        name="hoursCompleted"
                        value={formData?.hoursCompleted}
                        type="number"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            hoursCompleted: e.target.value,
                          })
                        }
                        isDisabled={true}
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Indirect hours completed</FormLabel>
                      <Input
                        name="indirectHoursCompleted"
                        value={formData?.indirectHoursCompleted}
                        type="number"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            indirectHoursCompleted: e.target.value,
                          })
                        }
                        isDisabled={true}
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Direct hours remaining</FormLabel>
                      <Input
                        name="hoursRemaining"
                        value={formData?.hoursRemaining}
                        type="number"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            hoursRemaining: e.target.value,
                          })
                        }
                        isDisabled={true}
                      />
                    </FormControl>

                    <FormControl mb={'5'}>
                      <FormLabel>Indirect hours remaining</FormLabel>
                      <Input
                        name="indirectHoursRemaining"
                        value={formData?.indirectHoursRemaining}
                        type="number"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            indirectHoursRemaining: e.target.value,
                          })
                        }
                        isDisabled={true}
                      />
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>Primary TOMs Scale: </FormLabel>
                      <Select
                        isRequired
                        name="primaryTomsScale"
                        onChange={(e: any) =>
                          setFormData({ ...formData, primaryTomsScale: e.target.value })
                        }
                        value={formData?.primaryTomsScale}
                      >
                        {primaryTomsScale?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Secondary TOMs Scale: </FormLabel>
                      <Select
                        isRequired
                        name="secondaryTomsScale"
                        onChange={(e: any) =>
                          setFormData({ ...formData, secondaryTomsScale: e.target.value })
                        }
                        value={formData?.secondaryTomsScale}
                      >
                        {secondaryTomsScale?.map((i: any, k: number) => (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </TabPanel>
                  <TabPanel>
                    <FormControl mb={'5'}>
                      <FormLabel>End of Episode/ Discharge Code/ Description </FormLabel>
                      <Input
                        name="endOfEpisodeDischargeCodeDescription"
                        value={formData?.endOfEpisodeDischargeCodeDescription}
                        type="string"
                        onChange={(e: any) =>
                          setFormData({
                            ...formData,
                            endOfEpisodeDischargeCodeDescription: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Date of discharge </FormLabel>
                      <Input
                        name="dateOfDischarge"
                        value={formData?.dateOfDischarge}
                        type="date"
                        onChange={(e: any) =>
                          setFormData({ ...formData, dateOfDischarge: e.target.value })
                        }
                      />{' '}
                      {/* {dischargeDate} and {formData.dateOfDischarge} */}
                    </FormControl>
                  </TabPanel>
                </TabPanels>
              </Tabs>
              {/* <FormControl mb={'5'}>
                <FormLabel>TOMS rating: </FormLabel>
                <Select
                  name="tomsRating"
                  isRequired
                  onChange={(e: any) => setFormData({ ...formData, tomsRating: e.target.value })}
                  value={formData?.tomsRating}
                >
                  <option value={1}>1</option>
                </Select>
              </FormControl> */}
              {/* <FormControl mb={'5'}>
                <FormLabel>Sessions + Notes: </FormLabel>
                <Select
                  name="sessionsNotes"
                  isRequired
                  onChange={(e: any) => setFormData({ ...formData, sessionsNotes: e.target.value })}
                  value={formData?.sessionsNotes}
                >
                  <option value={1}>TBC</option>
                </Select>
              </FormControl> */}
              <Box textAlign={'right'}>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'right'}
                  justifyContent={'flex-end'}
                >
                  {dischargeDate?.length > 6 && (
                    <Button
                      onClick={(e) => cancelDischarge(e)}
                      bg={'brand.300'}
                      _hover={{
                        bg: 'brand.200',
                      }}
                      color={'white'}
                      //   isDisabled={newNote.Notes.length === 0 ? true : false}
                      m={'2.5'}
                    >
                      Cancel Discharge
                    </Button>
                  )}
                  <Button
                    onClick={(e) => updateStudent(e)}
                    bg={'brand.300'}
                    _hover={{
                      bg: 'brand.200',
                    }}
                    color={'white'}
                    //   isDisabled={newNote.Notes.length === 0 ? true : false}
                    m={'2.5'}
                  >
                    Update student
                  </Button>
                </Box>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentEditStudent;
