import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Grid,
  Text,
  Button,
  Stack,
  Link,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
// import { privateForm } from '../interfaces/privateForm';
import { InlineWidget } from 'react-calendly';

const ProfileClientDiscoveryCall = ({ title }: any) => {
  const [user, setUser]: any = useState();
  useEffect(() => {}, []);
  return (
    <Box w="100%">
      {/* <Heading as={'h3'} size={'md'} my={5}>
        Book a Discovery Call
      </Heading> */}
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'left'}
        justifyContent={'left'}
        alignContent={'left'}
      >
        <InlineWidget url="https://calendly.com/unlockinglanguage/15min?primary_color=40a838" />
      </Box>
    </Box>
  );
};
export default ProfileClientDiscoveryCall;
