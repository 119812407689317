import React, { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
  useColorModeValue,
  Stack,
  VStack,
  List,
  ListItem,
  ListIcon,
  Link,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { privateForm } from '../../interfaces/privateForm';

import { Lorem10Paragraphs } from '../../utils/Lorem10Paragraphs';
interface Form {
  title: string;
  form: privateForm;
  setForm: Function;
  activeStep: number;
  setActiveStep: Function;
  packageSelected: string;
  setPackageSelected: Function;
}
const StepThree = ({
  title,
  form,
  setForm,
  activeStep,
  setActiveStep,
  packageSelected,
  setPackageSelected,
}: Form) => {
  const [scrollBehavior, setScrollBehavior] = React.useState('inside');

  // 'subData?.fields["Unlocking Language - Resources - Bronze"]';
  // 'subData?.fields["Unlocking Language - Resources - Silver"]';
  // 'subData?.fields["Unlocking Language - Resources - Gold"]';
  //https:buy.stripe.com/test_00g295b3N4uUfD2289
  const subscriptionPackages = [
    {
      level: 'Bronze',
      stripeUrl: 'https://buy.stripe.com/8wM6rQfbIeZjeDSdQR',
      stripeSubscriptionID: '8wM6rQfbIeZjeDSdQR',
    },
    {
      level: 'Silver',
      stripeUrl: 'https://buy.stripe.com/4gw17w2oW04pbrG002',
      stripeSubscriptionID: '4gw17w2oW04pbrG002',
    },
    {
      level: 'Gold',
      stripeUrl: 'https://buy.stripe.com/cN2aI6fbIcRb9jy9AD',
      stripeSubscriptionID: 'cN2aI6fbIcRb9jy9AD',
    },
  ];

  interface Props {
    children: React.ReactNode;
  }

  function PriceWrapper(props: Props) {
    const { children } = props;

    return (
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: 'center', lg: 'flex-start' }}
        borderColor={useColorModeValue('gray.200', 'gray.500')}
        borderRadius={'xl'}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box w="100%" height={'auto'} p={4}>
      {/* <Heading as="h3" size="lg" mb={'10'}>
        {title}
      </Heading> */}
      <Grid templateColumns="repeat(1, 1fr)" gap={6}>
        {/* {JSON.stringify(form)} */}
        <Box>
          <Box py={12}>
            <VStack spacing={2} textAlign="center">
              <Heading as="h1" fontSize="4xl">
                Plans that fit your need
              </Heading>
              <Text fontSize="lg" color={'gray.500'}>
                {/* Start with 14-day free trial. No credit card needed. Cancel at anytime. */}
                Get started today with an Unlocking Language Subscripton Package.
              </Text>
            </VStack>

            <Stack
              direction={{ base: 'column', md: 'row' }}
              textAlign="center"
              justify="center"
              spacing={{ base: 4, lg: 10 }}
              py={10}
            >
              <PriceWrapper>
                <Box py={4} px={6}>
                  <Text fontSize="2xl" fontWeight={'bold'}>
                    Bronze Tier
                  </Text>
                  <Text fontWeight="500" fontSize="xl">
                    Basic Support Package
                  </Text>
                  <HStack justifyContent="center">
                    <Text fontSize="3xl" fontWeight="600">
                      £
                    </Text>
                    <Text fontSize="5xl" fontWeight="900">
                      2.99
                    </Text>
                    <Text fontSize="3xl" color="gray.500">
                      /month
                    </Text>
                  </HStack>
                </Box>
                <Box>
                  <Text color="gray.500" px={6} pb={6}>
                    Unlock the basics with our Bronze Tier package. Designed to provide essential
                    support, this tier offers limited resources perfect for clients starting their
                    speech and language therapy journey. Enjoy access to our free video library,
                    featuring a range of introductory exercises and tips from our expert therapists.
                    Best of all, if you are a client, you get free access to these valuable tools,
                    making it easier than ever to begin your path to improved communication skills.
                  </Text>
                </Box>
                <VStack
                  bg={useColorModeValue('gray.50', 'gray.700')}
                  py={4}
                  borderBottomRadius={'xl'}
                >
                  <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Access to a selection of free videos
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Basic exercises and introductory resources
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Completely free of charge if you are a client.
                    </ListItem>
                  </List>
                  <Box w="80%" pt={7}>
                    <Link href={subscriptionPackages[0]?.stripeUrl} target={'_blank'}>
                      <Button
                        w="full"
                        colorScheme="green"
                        variant="outline"
                        // onClick={() => {
                        //   setActiveStep(activeStep + 1);
                        //   setPackageSelected(subscriptionPackages[0]);
                        // }}
                      >
                        Get started
                      </Button>
                    </Link>
                  </Box>
                </VStack>
              </PriceWrapper>
              <PriceWrapper>
                <Box position="relative">
                  <Box
                    position="absolute"
                    top="-16px"
                    left="50%"
                    style={{ transform: 'translate(-50%)' }}
                  >
                    <Text
                      textTransform="uppercase"
                      bg={useColorModeValue('brand.200', 'brand.200')}
                      px={3}
                      py={1}
                      color={useColorModeValue('white', 'white')}
                      fontSize="sm"
                      fontWeight="600"
                      rounded="xl"
                    >
                      Most Popular
                    </Text>
                  </Box>
                  <Box py={4} px={6}>
                    <Text fontSize="2xl" fontWeight={'bold'}>
                      Silver Tier
                    </Text>
                    <Text fontWeight="500" fontSize="xl">
                      Enhanced Support Package
                    </Text>
                    <HStack justifyContent="center">
                      <Text fontSize="3xl" fontWeight="600">
                        £
                      </Text>
                      <Text fontSize="5xl" fontWeight="900">
                        3.99
                      </Text>
                      <Text fontSize="3xl" color="gray.500">
                        /month
                      </Text>
                    </HStack>
                  </Box>
                  <Box>
                    <Text color="gray.500" px={6} pb={6}>
                      Elevate your therapy experience with the Silver Tier package. This tier offers
                      a broader selection of resources, including more technical and specialised
                      materials tailored to meet diverse therapy needs. Whether you're looking to
                      deepen your understanding or need targeted exercises, our enhanced support
                      package provides the tools necessary for more effective therapy sessions.
                    </Text>
                  </Box>
                  <VStack
                    bg={useColorModeValue('gray.50', 'gray.700')}
                    py={4}
                    borderBottomRadius={'xl'}
                  >
                    <List spacing={3} textAlign="start" px={12}>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Access to a wider range of resources
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Technical and specialised therapy materials
                      </ListItem>
                      <ListItem>
                        <ListIcon as={FaCheckCircle} color="green.500" />
                        Ideal for clients seeking a more comprehensive support system
                      </ListItem>
                    </List>
                    <Box w="80%" pt={7}>
                      {/* <Link href={subData?.fields['Unlocking Language - Resources - Silver']}> */}
                      <Link href={subscriptionPackages[1]?.stripeUrl} target={'_blank'}>
                        <Button
                          w="full"
                          colorScheme="green"
                          variant="outline"
                          // onClick={() => {
                          //   setActiveStep(activeStep + 1);
                          //   setPackageSelected(subscriptionPackages[1]);
                          // }}
                        >
                          Get started
                        </Button>
                      </Link>
                    </Box>
                  </VStack>
                </Box>
              </PriceWrapper>
              <PriceWrapper>
                <Box py={4} px={6}>
                  <Text fontSize="2xl" fontWeight={'bold'}>
                    Gold Tier
                  </Text>
                  <Text fontWeight="500" fontSize="xl">
                    Premium Support Package
                  </Text>
                  <HStack justifyContent="center">
                    <Text fontSize="3xl" fontWeight="600">
                      £
                    </Text>
                    <Text fontSize="5xl" fontWeight="900">
                      4.99
                    </Text>
                    <Text fontSize="3xl" color="gray.500">
                      /month
                    </Text>
                  </HStack>
                </Box>
                <Box>
                  <Text color="gray.500" px={6} pb={6}>
                    Experience the full spectrum of our speech and language therapy resources with
                    the Gold Tier package. Gain unrestricted access to all our materials, including
                    the most advanced and comprehensive resources available. This premium package is
                    designed for clients who are committed to achieving the best possible outcomes
                    in their therapy journey. With the Gold Tier, you have everything you need to
                    succeed.
                  </Text>
                </Box>
                <VStack
                  bg={useColorModeValue('gray.50', 'gray.700')}
                  py={4}
                  borderBottomRadius={'xl'}
                >
                  <List spacing={3} textAlign="start" px={12}>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Unlimited access to all resources
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Advanced and comprehensive therapy materials
                    </ListItem>
                    <ListItem>
                      <ListIcon as={FaCheckCircle} color="green.500" />
                      Best value for clients seeking maximum support
                    </ListItem>
                  </List>
                  <Box w="80%" pt={7}>
                    <Link href={subscriptionPackages[2]?.stripeUrl} target={'_blank'}>
                      <Button
                        w="full"
                        colorScheme="green"
                        variant="outline"
                        // onClick={() => {
                        //   setActiveStep(activeStep + 1);
                        //   setPackageSelected(subscriptionPackages[0]);
                        // }}
                      >
                        Get started
                      </Button>
                    </Link>
                  </Box>
                </VStack>
              </PriceWrapper>
            </Stack>
          </Box>
        </Box>
      </Grid>
    </Box>
  );
};
export default StepThree;
