export default async function postDataToEndPointCreateReport(
  endpoint: string | any,
  requestBody: any
) {
  // const headers = {
  //   'Content-Type': 'application/json',
  // };

  const requestOptions = {
    method: 'POST',
    // headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(endpoint, requestOptions);
  console.log('response', response);
  const data = await response.json();
  return data;
}
