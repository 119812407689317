export default function getFileTypeFromBase64(base64String: any) {
  // Check if the base64 string contains a data URL prefix
  const dataUrlPattern = /^data:([a-zA-Z0-9-\/]+);base64,/;
  const matches = base64String.match(dataUrlPattern);

  // If there is a match, return the MIME type, otherwise return null
  if (matches && matches.length > 1) {
    return matches[1];
  } else {
    return null;
  }
}
