import { Box, BoxProps, CloseButton, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState, useContext } from 'react';
import { LinkItems } from './../constants/sidebarMenuItems';
import UL_logo from './../logo/unlocking_language_logo_white.png';
import { NavItem } from './NavItem';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiFillFund } from 'react-icons/ai';

import { useNavigate } from 'react-router-dom';
import { gapi } from 'gapi-script';
import { signOut } from './../utils/signOut';
import UL_logo_small from './../logo/ul_nav_closed.png';
import UL_logo_large from './../logo/unlocking_language_logo_white.png';
import { UserMeta } from '../interfaces/UserMeta';
import { MyContext } from '..';
interface SidebarProps extends BoxProps {
  onClose: () => void;
  navWidth: number;
  setNavWidth: Function;
  userMeta: UserMeta;
}
export const SidebarContent = ({
  onClose,
  navWidth,
  setNavWidth,
  userMeta,
  ...rest
}: SidebarProps) => {
  const navigate = useNavigate();
  const [menuItemTitles, setMenuItemTitles] = useState(0);
  const userAccount = useContext(MyContext);
  let accountTypeStored = localStorage.getItem('accountType');

  function toggleMenuLook() {
    if (menuItemTitles === 1) {
      setNavWidth(20);
      setMenuItemTitles(0);
    } else {
      setNavWidth(60);
      setMenuItemTitles(1);
    }
  }

  useEffect(() => {
    setNavWidth(20);
    setMenuItemTitles(0);
  }, []);

  function theRouteToLogoutTo() {
    let value;

    console.log('locationToLogoutTo', value);

    // if (userAccount.userAccountType === 'therapist') {
    //   value = '/therapist-login';
    // } else if (userAccount.userAccountType === 'subscriber') {
    //   value = '/subscriber-login';
    // } else if (userAccount.userAccountType === 'Mainstream') {
    //   value = '/school-login?schoolType=RegularSchool';
    // } else if (userAccount.userAccountType === 'SEN') {
    //   value = '/school-login?schoolType=SENSchool';
    // } else if (userAccount.userAccountType === 'admin') {
    //   value = '/admin-login';
    // } else if (userAccount.userAccountType === 'client') {
    //   value = '/private-client-clinical-login';
    // } else {
    //   value = '/';
    // }

    if (userAccount.userAccountType === 'therapist') {
      value = '/';
    } else if (userAccount.userAccountType === 'subscriber') {
      value = '/';
    } else if (userAccount.userAccountType === 'Mainstream') {
      value = '/';
    } else if (userAccount.userAccountType === 'SEN') {
      value = '/';
    } else if (userAccount.userAccountType === 'admin') {
      value = '/';
    } else if (userAccount.userAccountType === 'client') {
      value = '/';
    } else {
      value = '/';
    }

    return value;
  }
  return (
    <Box
      // transition="1s ease"
      bg={useColorModeValue('white', 'gray.900')}
      // bg={'red'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: navWidth }}
      // w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      zIndex={99}
      {...rest}
    >
      <Flex
        h="80px"
        alignItems="left"
        p="4"
        justifyContent="space-between"
        flexDirection={'column'}
        mt={2}
      >
        {navWidth === 20 ? (
          <Image width="50px" objectFit="cover" src={UL_logo_small} alt="Unlocking Language" />
        ) : (
          <Image width="200px" objectFit="cover" src={UL_logo_large} alt="Unlocking Language" />
        )}
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <NavItem
        icon={menuItemTitles === 0 ? AiOutlineDoubleRight : AiOutlineDoubleLeft}
        // path={link.path}
        // {...(link.name === 'Logout' && handleSignOutClick)}
        onClick={() => {
          toggleMenuLook();
        }}
        menuItemTitles={menuItemTitles}
      ></NavItem>
      {LinkItems.filter((i: any) => i.accountType.includes(accountTypeStored)).map((link: any) => (
        <NavItem
          key={link.name}
          icon={link.icon}
          path={link.path}
          name={link.name}
          menuItemTitles={menuItemTitles}
          // {...(link.name === 'Logout' && handleSignOutClick)}
          // action={handleSignOutClick}
        >
          {/* {link.accountType.includes(userMeta?.accountType) ? 'Yes' : 'No'} */}
          {menuItemTitles === 0 ? '' : link.name}
        </NavItem>
      ))}
      <NavItem
        icon={RiLogoutBoxLine}
        // path={link.path}
        // {...(link.name === 'Logout' && handleSignOutClick)}
        onClick={() => {
          // navigateToSignPost();
          const logoutPath = theRouteToLogoutTo();
          signOut();
          navigate(String(theRouteToLogoutTo()));
        }}
        menuItemTitles={menuItemTitles}
      >
        Logout
      </NavItem>
    </Box>
  );
};
// https://www.doctify.com/uk/review/1zCCYi/single
