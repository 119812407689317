import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  HStack,
  VStack,
  useDisclosure,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import UL_pattern_bg from './../logo/UL_pattern_bg.png';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../auth/fireBaseSetup';
import { sendPasswordResetEmail } from 'firebase/auth';

import { AuthContext } from '../context/AuthContext';
import { LoginProps } from '../interfaces/LoginProps';
import { signOut } from './../utils/signOut';

import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { ForgotPasswordModal } from './../components/ForgotPasswordModal';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const loginImage =
  'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80';

export default function PrivateClinicalClientLogin({
  signedInUserType,
  setSignedInUserType,
}: LoginProps) {
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<any>('');
  const [message, setMessage] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  // TypeScript type for Firebase Auth error
  interface FirebaseAuthError extends Error {
    code?: string; // Optional because not all errors will have a code
  }
  let userType = 'clinical';
  useEffect(() => {
    setSignedInUserType(userType);
    console.log(userType);
  }, []);

  const forgotPassword = async (email: string): Promise<void> => {
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully!');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      throw error;
    }
  };

  const handleForgotPassword = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      await forgotPassword(emailRef.current!.value);
      setMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setMessage('Failed to send password reset email. Please try again.');
    }
  };
  const signIn = async () => {
    try {
      await auth.signInWithEmailAndPassword(emailRef.current!.value, passwordRef.current!.value);
      navigate('/dashboard-client');
    } catch (error) {
      // console.error(err);
      const err = error as FirebaseAuthError;

      // Handle different Firebase login error codes
      switch (err.code) {
        case 'auth/user-not-found':
          setError('No user found with this email.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password. Please try again.');
          break;
        case 'auth/invalid-email':
          setError('Invalid email format.');
          break;
        case 'auth/user-disabled':
          setError('This user has been disabled.');
          break;
        case 'auth/too-many-requests':
          setError('Too many unsuccessful login attempts. Please try again later.');
          break;
        default:
          setError('An unexpected error occurred. Please try again.');
      }
    }
  };

  const signOut = async () => {
    await auth.signOut();
    localStorage.clear();
    navigate('/private-client-clinical-login');
  };

  const LeftData = () => (
    <Stack spacing={10} width={'80%'}>
      <Text fontSize={50} fontWeight={'black'} color={'brand.300'} lineHeight={1}>
        Clinical Client Login
      </Text>
      {user && (
        <VStack>
          <Button onClick={() => navigate('/dashboard-client')}>
            You're already logged in, Go to Client Dashboard
          </Button>
          <Text>or</Text>
          <Button onClick={() => signOut()}>Sign Out</Button>
        </VStack>
      )}
      {!user ? (
        <Box
          rounded={'lg'}
          //   bg={useColorModeValue('white', 'gray.700')}
        >
          <Stack align={'left'}>
            <Text fontSize={'lg'} color={'gray.600'} pb={'5'}>
              Login to get started
            </Text>
          </Stack>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                Email address
              </FormLabel>
              <Input type="email" ref={emailRef} />
            </FormControl>
            <FormControl id="password">
              <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                Password
              </FormLabel>
              {/* <Input type="password" ref={passwordRef} />
               */}

              <InputGroup size="md">
                <Input
                  pr="4.5rem"
                  type={show ? 'text' : 'password'}
                  ref={passwordRef}
                  placeholder="Enter password"
                />
                <InputRightElement width="4.5rem">
                  <Button h="1.75rem" size="sm" onClick={handleClick}>
                    {show ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Stack>
              <Stack
                direction={{ base: 'column', sm: 'row' }}
                align={'start'}
                justify={'space-between'}
              >
                {/* <Checkbox>Remember me</Checkbox> */}
                {/* <Link color={'blue.400'}>Forgot password?</Link> */}
              </Stack>

              {/* <Button
                            bg={'blue.400'}
                            color={'white'}
                            _hover={{
                              bg: 'blue.500',
                            }}
                            onClick={createAccount}
                          >
                            Sign up
                          </Button> */}
              <Button
                bg={'brand.300'}
                color={'white'}
                _hover={{
                  bg: 'brand.200',
                }}
                onClick={signIn}
                // isDisabled={true}
              >
                Sign in
              </Button>
              {error && <Box style={{ color: 'red' }}>{error}</Box>}
              <HStack>
                <Box textAlign={'left'}>
                  <Link href="/">← Back home</Link>
                </Box>
                <Box alignItems={'right'}>
                  <Link onClick={onOpen}>
                    <QuestionOutlineIcon /> Forgot password
                  </Link>
                  <ForgotPasswordModal isOpen={isOpen} onClose={onClose} />
                </Box>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      ) : (
        <>
          {/* <h2 className="mt-4 text-center">Welcome {user.displayName}</h2>
                  {user.email}
                  {user.uid}
                  {user.photoURL}
                  {user.phoneNumber} */}
          {/* <Avatar name="Dan Abrahmov" src="https://bit.ly/dan-abramov" /> */}
        </>
      )}
    </Stack>
  );

  return (
    <Box
      position="relative"
      background={'white'}
      height={'100vh'}
      backgroundImage={UL_pattern_bg}
      backgroundRepeat={'repeat'}
      backgroundSize={'cover'}
    >
      <Box
        bg={'white'}
        position={'absolute'}
        left={0}
        bottom={'0'}
        minH={'90vh'}
        borderTopRightRadius="40"
        width={'94%'}
      >
        <Flex align={'center'} justify={'center'}>
          <HStack spacing={8} mx={'auto'} width={'90%'} py={6} px={6}>
            <Box rounded={'lg'} p={8} background={'white'} width={'50%'}>
              <LeftData />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'50%'}
            >
              <Image width="600px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
}
