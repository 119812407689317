import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Flex,
  Stack,
  Heading,
  Text,
  Link,
  List,
  ListIcon,
  ListItem,
  Button,
  Box,
  ButtonGroup,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  FormHelperText,
  Image,
  background,
} from '@chakra-ui/react';
import {} from '@chakra-ui/react';
import StepOne from '../components/subscriberSignUpSteps/StepOne';
import StepTwo from '../components/subscriberSignUpSteps/StepTwo';
import StepThree from '../components/subscriberSignUpSteps/StepThree';
import StepFour from '../components/subscriberSignUpSteps/StepFour';
import StepFive from '../components/subscriberSignUpSteps/StepFive';
import UL_logo from './../logo/unlocking_language_logo_white.png';
import { privateForm } from '../interfaces/privateForm';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import { MdCheckCircle, MdFacebook, MdTwoWheeler } from 'react-icons/md';
import { BsFacebook, BsInstagram, BsTwitterX } from 'react-icons/bs';

// import { SmallCloseIcon } from '@chakra-ui/icons';
const steps = [
  { title: 'Details', description: 'Fill in your details', content: 'Content 1' },
  {
    title: 'Contract',
    description: 'View & Sign Contract',
    content: 'Content 3',
  },
  { title: 'Subscription type', description: 'Select your subscription', content: 'Content 4' },
  // { title: 'Payment', description: 'Make the first payment', content: 'Content 5' },
];

export default function ClientOnboardingEntry(): JSX.Element {
  const queryParameters = new URLSearchParams(window.location.search);
  const ul_cl_id = queryParameters.get('ul_cl');

  const defaultForm = {
    firstname: '',
    lastname: '',
    address: '',
    email: '',
    contactNumber: '',
    password: '',
    cpassword: '',
    contract: 0,
  };
  const [form, setForm] = useState<privateForm>(defaultForm);
  const [signContract, setSignContract] = useState(0);

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  function goBackOneStep(activeStep: number) {
    setActiveStep(activeStep - 1);
  }
  function getTheContractField() {}
  function signTheContract() {
    // insert client
    // get contract field
    // run conditional
    // go to ninox
    // update contract field
    // get contract field
    // run conditional
  }
  const createAccount = async () => {
    try {
      await auth.createUserWithEmailAndPassword(form.email, form.password);
    } catch (error) {
      console.error(error);
    }
  };

  function collectDataAndSignup() {
    setActiveStep(activeStep + 1);
    activeStep === 1 && createAccount();
  }
  const stepSelect = steps[activeStep].title;
  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'4xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Stack align={'left'} mb={'50px'}>
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'} width={'100%'}>
            <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
          </Box>
          <Box width={'100%'} alignItems={'center'} p={10}>
            <Heading as={'h4'} mb={'5'} fontSize={30} color={'brand.200'}>
              Welcome to Unlocking Language!
            </Heading>
            <Text>
              This landing page provides a brief overview of our services and guides you on how to
              fill in the following forms to get started. Our service is designed to help you
              achieve your goals in a seamless and efficient manner. Let's get started!
            </Text>
            <Box my={10}>
              <Heading as={'h6'} mb={'5'} fontSize={30} color={'brand.200'}>
                Getting started!
              </Heading>
            </Box>
            <Box my={10}>
              <Text fontWeight={'bold'} mb="5">
                The process contains 5 steps:
              </Text>
              <List spacing={5}>
                <ListItem>
                  <Text fontWeight={'bold'}>Personal details:</Text>
                  <Text>
                    Please provide your personal information, including your name, address, and
                    contact number. Subsequently, you will also create your login credentials.
                  </Text>
                </ListItem>
                <ListItem>
                  <HStack>
                    <Text fontWeight={'bold'}>Uploads:</Text>
                  </HStack>
                  <Text>
                    Upload any files that may help the therapist in therapy such as an EHCP.
                  </Text>
                </ListItem>
                <ListItem>
                  <HStack>
                    <Text fontWeight={'bold'}>Contract:</Text>
                  </HStack>
                  <Text>
                    Proceed to review and sign the contract, finalising the terms of engagement for
                    our services.
                  </Text>
                </ListItem>
                <ListItem>
                  <HStack>
                    <Text fontWeight={'bold'}>Gocardless:</Text>
                  </HStack>
                  <Text>
                    Your Direct Debit will be set up now, but we'll confirm the amount and let you
                    know before future payments are taken.
                  </Text>
                </ListItem>
                <ListItem>
                  <HStack>
                    <Text fontWeight={'bold'}>First payment:</Text>
                  </HStack>
                  <Text>
                    We will only be taking the first payment today and will pre-notify you before
                    any direct debit charges.
                  </Text>
                </ListItem>
              </List>
            </Box>
            <Box mb={10}>
              <Text>
                If you have any questions, please email us at&nbsp;
                <Link href="mailto:info@unlockinglanguage.com" fontWeight={'bold'}>
                  info@unlockinglanguage.com
                </Link>{' '}
                or call us at{' '}
                <Link href={'tel:02075369299'} fontWeight={'bold'}>
                  020 7536 9299
                </Link>
              </Text>
              <Text>
                Check our{' '}
                <Link href="#" fontWeight={'bold'}>
                  FAQ
                </Link>{' '}
                for quick answers to common questions.
              </Text>
            </Box>

            <Link
              href={'/private-client-clinical-sign-up?ul_cl=' + ul_cl_id}
              textAlign={'center'}
              mt={10}
            >
              <Button
                textAlign={'center'}
                bg={'brand.300'}
                color={'white'}
                _hover={{ background: 'brand.400' }}
              >
                Get started
              </Button>
            </Link>
          </Box>
        </Stack>
        <Outlet />
      </Stack>
    </Flex>
  );
}
