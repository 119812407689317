import React from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Tooltip,
} from '@chakra-ui/react';
export const totalStudentsHint = (
  <Box p={5}>
    <Heading size={'md'}>Total Students</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>Total students at the school.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>Count of current students, excluding those discharged.</Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>Current academic year.</Text>
  </Box>
);
export const ehcpHint = (
  <Box p={5}>
    <Heading size={'md'}>EHCP</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>Total students at the school.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>Count of current students that have EHCP, excluding those discharged.</Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>Current academic year.</Text>
  </Box>
);
export const avgInteractionsHint = (
  <Box p={5}>
    <Heading size={'md'}>Avg. interactions</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>Average number of interactions recorded for each student.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>
      Sum of interactions for all students, excluding those discharged, divided by the number of
      current students.
    </Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>Current academic year.</Text>
  </Box>
);
export const avgAgeHint = (
  <Box p={5}>
    <Heading size={'md'}>Avg. interactions</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>Average age of students.</Text>
    <Text fontWeight={'bold'}>How is it calculated?</Text>
    <Text mb={2}>Sum of all students' ages divided by the total number of students.</Text>
    <Text fontWeight={'bold'}>Timeframe of calculation?</Text>
    <Text mb={2}>Current academic year.</Text>
  </Box>
);
export const genderHint = (
  <Box p={5}>
    <Heading size={'md'}>Genders</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>
      Pie chart showcasing the distribution of genders among current students, excluding those
      discharged.
    </Text>
    <Text fontWeight={'bold'}>How is it represented?</Text>
    <Text mb={2}>Each gender category is depicted as a proportionate slice of the pie.</Text>
    <Text fontWeight={'bold'}>Timeframe of representation?</Text>
    <Text mb={2}>Current academic year.</Text>
  </Box>
);
export const classYearsHint = (
  <Box p={5}>
    <Heading size={'md'}>Class years</Heading>
    <Text fontWeight={'bold'}>What is it?</Text>
    <Text mb={2}>
      Bar chart illustrating the distribution of students across different class years.
    </Text>
    <Text fontWeight={'bold'}>How is it represented?</Text>
    <Text mb={2}>
      Each class year is depicted as a separate bar, with its height indicating the number of
      students.
    </Text>
    <Text fontWeight={'bold'}>Timeframe of representation?</Text>
    <Text mb={2}>Current academic year.</Text>
  </Box>
);
