import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  HStack,
  Text,
  Button,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { privateForm } from '../../interfaces/privateForm';
import { auth } from './../../auth/fireBaseSetup';
import { useNavigate } from 'react-router-dom';

interface StepOne {
  title: string;
  form: privateForm;
  setForm: Function;
}
const StepOne = ({ title, form, setForm, subCheckoutId }: any) => {
  // https://portal.unlockinglanguage.co.uk/subscriber-sign-up?step=account
  const [user, setUser] = useState<any>(null);
  const [signUpError, setSignUpError] = useState<any>('');
  const navigate = useNavigate();

  const createAccount = async () => {
    console.log('Subscriber account creation triggered');
    try {
      // await auth.createUserWithEmailAndPassword(
      //   'subscriber@unlockinglanguage.co.uk',
      //   form.password
      // );
      await auth.createUserWithEmailAndPassword(user?.customer_details?.email, form.password);
      console.log('Subscriber account created!');
      navigate('/subscriber-login');
    } catch (error) {
      console.error(error);
      setSignUpError(error);
      console.log('Account creation failed');
    }
  };
  function createAccountAndRedirect() {
    form?.cpassword === form?.password && createAccount();
  }
  useEffect(() => {
    const stripe = require('stripe')(process.env.REACT_APP_UL_STRIPE_SK);

    const session =
      subCheckoutId &&
      stripe.checkout.sessions.retrieve(String(subCheckoutId)).then((data: any) => {
        console.log(data);
        setUser(data);
        // console.log(data);
      });
    console.log('subCheckoutId:', subCheckoutId);
    // ninoxTableGetRowBySubId(
    //   process.env.REACT_APP_SUBSCRIBER_TABLE!,
    //   subscription,
    //   process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
    // ).then((data: any) => {
    //   setUser(data);
    // });
  }, []);

  return (
    <Box w="100%" height={'auto'} p={4}>
      <Heading as="h4" size="lg">
        {title}
      </Heading>
      {/* {JSON.stringify(user)} */}
      <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={10}>
        <Box>
          {/* <FormControl mb="5%">
            <FormLabel htmlFor="first-name" fontWeight={'bold'}>
              First name
            </FormLabel>
            <Text>{user?.fields['First name']}</Text>
       
          </FormControl> */}

          {/* <FormControl mb="5%">
            <FormLabel htmlFor="address" fontWeight={'normal'}>
              Address
            </FormLabel>
            <Input
              id="address"
              placeholder="Address"
              onChange={(e) => setForm({ ...form, address: e.target.value })}
              value={form?.address}
            />
          </FormControl> */}
          <FormControl mb="5%">
            <FormLabel htmlFor="email" fontWeight={'bold'}>
              Email
            </FormLabel>
            <Text>{user?.customer_details?.email}</Text>
            {/* <FormLabel htmlFor="email" fontWeight={'normal'}>
              Email
            </FormLabel>
            <Input
              id="email"
              placeholder="Email"
              onChange={(e) => setForm({ ...form, email: e.target.value })}
              value={form?.email}
              disabled={true}
            /> */}
          </FormControl>
        </Box>
        <Box>
          {/* <FormControl mb="5%">
            <FormLabel htmlFor="last-name" fontWeight={'bold'}>
              Last name
            </FormLabel>
            <Text>{user?.fields['Last name']}</Text>
          </FormControl> */}

          {/* <FormControl mb="5%">
            <FormLabel htmlFor="contact-number" fontWeight={'bold'}>
              Contact number
            </FormLabel>
            <Text>{user?.fields['Contact number']}</Text>
          </FormControl> */}
        </Box>
        <Box>
          <FormControl mb="5%">
            <FormLabel htmlFor="password" fontWeight={'normal'}>
              Password
            </FormLabel>
            <Input
              id="password"
              placeholder="Enter password"
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              value={form?.password}
              type="password"
            />
          </FormControl>
          {signUpError && (
            <Box color={'red.500'} mb={10} fontWeight={'bold'} mt={5}>
              {signUpError?.code === 'auth/email-already-in-use'
                ? 'It seems like you already have an account with us.'
                : signUpError?.code === 'auth/missing-password'
                ? 'Missing password! Password is a mandatory field. Please enter a password'
                : signUpError?.code === 'auth/weak-password'
                ? 'Weak password! Please enter a stronger password'
                : signUpError?.code}
            </Box>
          )}
        </Box>
        <Box>
          <FormControl mb="5%">
            <FormLabel htmlFor="password" fontWeight={'normal'}>
              Confirm Password
            </FormLabel>
            <Input
              id="cpassword"
              placeholder="Enter password again"
              onChange={(e) => setForm({ ...form, cpassword: e.target.value })}
              value={form?.cpassword}
              type="password"
            />
          </FormControl>
          {signUpError && (
            <Box color={'red.500'} mb={10} fontWeight={'bold'} mt={5}>
              {signUpError?.code === 'auth/email-already-in-use'
                ? 'It seems like you already have an account with us.'
                : signUpError?.code === 'auth/missing-password'
                ? 'Missing password! Password is a mandatory field. Please enter a password'
                : signUpError?.code === 'auth/weak-password'
                ? 'Weak password! Please enter a stronger password'
                : signUpError?.code}
            </Box>
          )}
        </Box>
        <Box>
          <Button
            bg={'brand.300'}
            color={'white'}
            w="full"
            _hover={{
              bg: 'brand.200',
            }}
            onClick={() => createAccountAndRedirect()}
          >
            Set password
          </Button>
        </Box>
        <Box>
          {form?.password.length > Number(0) && form?.cpassword !== form?.password && (
            <Box color={'red.500'} mb={10} fontWeight={'bold'} mt={5}>
              {'Passwords do not match'}
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
};
export default StepOne;
