import React, { createContext, useState, useCallback } from 'react';

// const userDetailsDefaultValue = {
//   firstname: 'Bobby',
//   lastname: 'Mo',
//   email: 'mo@giveusfeedback.io',
//   accessLevel: 'bronze',
//   avatar: 'none',
//   accountType: '',
//   userStatus: 0,
//   loggedIn: false,
// };

const userDetailsDefaultValue = 0;

type StateContextType = {
  userDetails: number | null;
};

// export const StateContext = createContext<StateContextType | null>(null);

export const userDetailsContext = createContext<any>(null);

// export const StateContext = createContext<StateContextType | undefined>(undefined);
type ContextProviderProps = {
  children: React.ReactNode;
};

// export const UserDetailsProvider = ({ children }: ContextProviderProps) => {
//   const [userDetails, setUserDetails] = useState(null);

//   const updateUser = useCallback(
//     (userDetails: any) => {
//       setUserDetails(userDetails);
//     },
//     [setUserDetails]
//   );

//   // const removePage = useCallback(
//   //   page => { setPages(pages => pages.filter(p => p.name !== page.name)); },
//   //   [setPages]
//   // );
//   const value = {
//     userDetails,
//     updateUser,
//   };

//   return <userDetailsContext.Provider value={value}>{children}</userDetailsContext.Provider>;
// };

export const UserDetailsProvider = ({ children }: ContextProviderProps) => {
  const [userDetails, setUserDetails] = useState(null);

  const updateUser = useCallback(
    (userDetails: any) => {
      setUserDetails(userDetails);
    },
    [setUserDetails]
  );

  const value = { userDetails, updateUser };
  return <userDetailsContext.Provider value={value}>{children}</userDetailsContext.Provider>;
};
