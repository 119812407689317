import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { FaHospital, FaSchool, FaUserNurse } from 'react-icons/fa';
import { IoPeople } from 'react-icons/io5';
import { RiAdminFill } from 'react-icons/ri';

import { Box, Button, Flex, HStack, Image, Link, Stack, Text, VStack } from '@chakra-ui/react';
import UL_pattern_bg from './../logo/UL_pattern_bg.png';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';
const Home = () => {
  const LeftContent = () => (
    <Stack spacing={10} width={'80%'}>
      <Text fontSize={50} fontWeight={'black'} color={'brand.300'} lineHeight={1}>
        Access your service
      </Text>
      <Link
        href={'/school-login?schoolType=RegularSchool'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <HStack>
          {' '}
          <FaSchool size={70} color="#0c7348" />
          <Button
            bg={'brand.200'}
            color={'white'}
            _hover={{
              bg: 'brand.300',
              textDecoration: 'none',
            }}
            width={'100%'}
            p={7}
            borderRadius={30}
            textTransform={'uppercase'}
            fontWeight={'bold'}
            ml={2}
          >
            School
          </Button>
        </HStack>
      </Link>
      <Link
        href={'/therapist-login'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <HStack>
          <FaUserNurse size={70} color="#0c7348" />
          <Button
            bg={'brand.200'}
            color={'white'}
            _hover={{
              bg: 'brand.300',
            }}
            width={'100%'}
            p={7}
            borderRadius={30}
            textTransform={'uppercase'}
            fontWeight={'bold'}
            ml={2}
          >
            Speech Therapist
          </Button>
        </HStack>
      </Link>
      <Link
        href={'/private-client-clinical-login'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <HStack>
          <FaHospital size={70} color="#0c7348" />
          <Button
            bg={'brand.200'}
            color={'white'}
            _hover={{
              bg: 'brand.300',
            }}
            width={'100%'}
            p={7}
            borderRadius={30}
            textTransform={'uppercase'}
            fontWeight={'bold'}
            ml={2}
          >
            Clinical Client
          </Button>
        </HStack>
      </Link>
      <Link
        href={'/subscriber-login'}
        _hover={{
          textDecoration: 'none',
        }}
      >
        <HStack>
          <IoPeople size={70} color="#0c7348" />
          <Button
            bg={'brand.200'}
            color={'white'}
            _hover={{
              bg: 'brand.300',
            }}
            width={'100%'}
            p={7}
            borderRadius={30}
            textTransform={'uppercase'}
            fontWeight={'bold'}
            ml={2}
          >
            Ul academy
          </Button>
        </HStack>
      </Link>
    </Stack>
  );
  return (
    <>
      <Box
        position="relative"
        background={'white'}
        height={'100vh'}
        backgroundImage={UL_pattern_bg}
        backgroundRepeat={'repeat'}
        backgroundSize={'cover'}
      >
        <Box
          bg={'white'}
          position={'absolute'}
          left={0}
          bottom={'0'}
          minH={'90vh'}
          borderTopRightRadius="40"
          width={'94%'}
        >
          <Flex align={'center'} justify={'center'}>
            <Box position={'absolute'} top={10} right={10}>
              <Link
                href={'/admin-login'}
                _hover={{
                  textDecoration: 'none',
                }}
              >
                <VStack>
                  <RiAdminFill size={50} color="#0c7348" />
                  <Text textTransform={'uppercase'} color={'brand.300'} fontWeight={'bold'}>
                    {' '}
                    Admin
                  </Text>
                </VStack>
              </Link>
            </Box>
            <HStack spacing={8} mx={'auto'} width={'90%'} py={6} px={6}>
              <Box rounded={'lg'} p={8} background={'white'} width={'50%'}>
                <LeftContent />
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                width={'50%'}
              >
                <Image width="600px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
              </Box>
            </HStack>
          </Flex>
        </Box>
      </Box>
      <Outlet />
    </>
  );
};

export default Home;
