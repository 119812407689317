import {
  Box,
  Button,
  HStack,
  Heading,
  Stat,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { NinoxContext } from '../context/NinoxContext';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { NinoxData } from '../interfaces/NinoxData';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import { BackButton } from './BackButton';
import ProfileClientTxBioProfilePic from './../components/ProfileClientTxBioProfilePic';
type StateContextType = {
  userDetails: number | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};

const ProfileTxSchools = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  const user = useContext(AuthContext);
  const userData = useContext(NinoxContext);
  const navigate = useNavigate();

  const [ninoxData, setNinoxData] = useState<NinoxData | null>(null);
  const [newNinoxData, setNewNinoxData] = useState<any | null>(null);
  const { userDetails } = useContext<StateContextType>(userDetailsContext);
  const [tabIndex, setTabIndex] = useState(0);
  const queryParameters = new URLSearchParams(window.location.search);
  const activeTx = queryParameters.get('tx');

  const endPoint = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  console.log('stripeCustomerId', localStorage.getItem('stripeCustomerId')!);
  const stripeCustomerId = localStorage.getItem('stripeCustomerId')!;
  const DiscoveryCall = () => {
    return <Heading></Heading>;
  };

  useEffect(() => {}, []);
  return (
    <Box ml={{ base: 0, md: 20 }} p="6">
      <Box p={6} bg="white">
        <HStack spacing={5} mb={10}>
          <BackButton navigate={navigate} />
          <Heading>Profile</Heading>
        </HStack>
        <Box>
          <Box width={'100%'}>
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                {/* <Tab
                  bg={'gray.100'}
                  mr={5}
                  onClick={() => {
                    setTabIndex(3);
                    navigate('/profile');
                  }}
                >
                  {activeTx}
                </Tab> */}

                {localStorage.getItem('Therapist 1') !== 'undefined' && (
                  <Tab>{localStorage.getItem('Therapist 1')}</Tab>
                )}
                {localStorage.getItem('Therapist 2') !== 'undefined' && (
                  <Tab>{localStorage.getItem('Therapist 2')}</Tab>
                )}
                {localStorage.getItem('Therapist 3') !== 'undefined' && (
                  <Tab>{localStorage.getItem('Therapist 3')}</Tab>
                )}
              </TabList>
              <TabPanels>
                {localStorage.getItem('Therapist 1') !== 'undefined' && (
                  <TabPanel bg={'white'} mt={5} borderRadius={5}>
                    {localStorage.getItem('Headshot1') !== undefined && (
                      <ProfileClientTxBioProfilePic
                        headshotid={localStorage.getItem('Headshot1')}
                      />
                    )}
                    <Stat textAlign={'left'} mx={-10}>
                      {/* <StatLabel>Therapist 1:</StatLabel> */}
                      <StatNumber fontSize={'md'}>{localStorage.getItem('Therapist 1')}</StatNumber>
                      <Box
                        width={'100%'}
                        dangerouslySetInnerHTML={{
                          __html: localStorage.getItem('TherapistBio1')!,
                        }}
                        overflow={'scroll'}
                        mt={5}
                        pr={5}
                      />
                    </Stat>
                  </TabPanel>
                )}
                {localStorage.getItem('Therapist 2') !== 'undefined' && (
                  <TabPanel bg={'white'} mt={5} borderRadius={5}>
                    {localStorage.getItem('Headshot1') !== undefined && (
                      <ProfileClientTxBioProfilePic
                        headshotid={localStorage.getItem('Headshot2')}
                      />
                    )}
                    <Stat textAlign={'left'} mx={-10}>
                      {/* <StatLabel>Therapist 1:</StatLabel> */}
                      <StatNumber fontSize={'md'}>{localStorage.getItem('Therapist 2')}</StatNumber>
                      <Box
                        width={'100%'}
                        dangerouslySetInnerHTML={{
                          __html: localStorage.getItem('TherapistBio2')!,
                        }}
                        overflow={'scroll'}
                        mt={5}
                        pr={5}
                      />
                    </Stat>
                  </TabPanel>
                )}
                {localStorage.getItem('Therapist 3') !== 'undefined' && (
                  <TabPanel bg={'white'} mt={5} borderRadius={5}>
                    {localStorage.getItem('Headshot1') !== undefined && (
                      <ProfileClientTxBioProfilePic
                        headshotid={localStorage.getItem('Headshot3')}
                      />
                    )}
                    <Stat textAlign={'left'} mx={-10}>
                      {/* <StatLabel>Therapist 1:</StatLabel> */}
                      <StatNumber fontSize={'md'}>{localStorage.getItem('Therapist 3')}</StatNumber>
                      <Box
                        width={'100%'}
                        dangerouslySetInnerHTML={{
                          __html: localStorage.getItem('TherapistBio3')!,
                        }}
                        overflow={'scroll'}
                        mt={5}
                        pr={5}
                      />
                    </Stat>
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileTxSchools;
