import {
  AbsoluteCenter,
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Stack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  HStack,
  VStack,
  useDisclosure,
  InputRightElement,
  InputGroup,
} from '@chakra-ui/react';
import React, { useContext, useRef, useEffect, useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

import { useNavigate } from 'react-router-dom';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
import { sendPasswordResetEmail } from 'firebase/auth';
import { LoginProps } from '../interfaces/LoginProps';
import { MyContext } from '..';
import UL_pattern_bg from './../logo/UL_pattern_bg.png';
import UL_logo from './../logo/unlocking_language_logo_transparent.png';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { ForgotPasswordModal } from './../components/ForgotPasswordModal';
const loginImage =
  'https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80';

export default function SchoolLogin({ signedInUserType, setSignedInUserType }: LoginProps) {
  const user = useContext(AuthContext);
  const navigate = useNavigate();
  const emailRefMs = useRef<HTMLInputElement>(null);
  const passwordRefMs = useRef<HTMLInputElement>(null);
  const emailRefSs = useRef<HTMLInputElement>(null);
  const passwordRefSs = useRef<HTMLInputElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  let userType = 'school';
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolTypeFromUrl = queryParameters.get('schoolType');
  const schoolType = localStorage.getItem('accountType');
  const [error, setError] = useState<any>('');
  const [message, setMessage] = useState<string>('');

  const [show, setShow] = useState(false);

  const changeURLParam = (newValue: any) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('schoolType', newValue);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
  };

  const handleClick = () => {
    setShow(!show);
    // changeURLParam(schoolTypeFromUrl === 'RegularSchool' ? 'RegularSchool' : 'SENSchool');
  };
  // TypeScript type for Firebase Auth error
  interface FirebaseAuthError extends Error {
    code?: string; // Optional because not all errors will have a code
  }

  useEffect(() => {
    setSignedInUserType(userType);
    console.log(userType);
  }, []);
  // const forgotPassword = async (email: string): Promise<void> => {
  //   try {
  //     await sendPasswordResetEmail(auth, email);
  //     console.log('Password reset email sent successfully!');
  //   } catch (error) {
  //     console.error('Error sending password reset email:', error);
  //     throw error;
  //   }
  // };

  // const handleForgotPassword = async (event: React.FormEvent) => {
  //   event.preventDefault();
  //   try {
  //     await forgotPassword(emailRef.current!.value);
  //     setMessage('Password reset email sent! Check your inbox.');
  //   } catch (error) {
  //     setMessage('Failed to send password reset email. Please try again.');
  //   }
  // };
  const signInMainstream = async () => {
    try {
      await auth.signInWithEmailAndPassword(
        emailRefMs.current!.value,
        passwordRefMs.current!.value
      );
      navigate('/dashboard-mainstream-school');
    } catch (error) {
      // console.error(err);
      const err = error as FirebaseAuthError;

      // Handle different Firebase login error codes
      switch (err.code) {
        case 'auth/user-not-found':
          setError('No user found with this email.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password. Please try again.');
          break;
        case 'auth/invalid-email':
          setError('Invalid email format.');
          break;
        case 'auth/user-disabled':
          setError('This user has been disabled.');
          break;
        case 'auth/too-many-requests':
          setError('Too many unsuccessful login attempts. Please try again later.');
          break;
        default:
          setError('An unexpected error occurred. Please try again.');
      }
    }
  };
  const signInSENCO = async () => {
    try {
      await auth.signInWithEmailAndPassword(
        emailRefSs.current!.value,
        passwordRefSs.current!.value
      );
      navigate('/dashboard-sen-school');
    } catch (error) {
      const err = error as FirebaseAuthError;

      // Handle different Firebase login error codes
      switch (err.code) {
        case 'auth/user-not-found':
          setError('No user found with this email.');
          break;
        case 'auth/wrong-password':
          setError('Incorrect password. Please try again.');
          break;
        case 'auth/invalid-email':
          setError('Invalid email format.');
          break;
        case 'auth/user-disabled':
          setError('This user has been disabled.');
          break;
        case 'auth/too-many-requests':
          setError('Too many unsuccessful login attempts. Please try again later.');
          break;
        default:
          setError('An unexpected error occurred. Please try again.');
      }
    }
  };
  const signOut = async () => {
    await auth.signOut();
    localStorage.clear();
    navigate('/school-login');
  };

  const LeftData = () => (
    <Stack spacing={10} width={'100%'}>
      {user &&
        (schoolTypeFromUrl === 'Mainstream'
          ? navigate('/dashboard-mainstream-school')
          : navigate('/dashboard-sen-school'))}
      {!user && (
        <Box rounded={'lg'} p={8}>
          <Text fontSize={50} fontWeight={'black'} color={'brand.300'} lineHeight={1} pb={10}>
            School Login
          </Text>
          <Tabs
            variant="soft-rounded"
            colorScheme="green"
            defaultIndex={schoolTypeFromUrl === 'RegularSchool' ? 0 : 1}
          >
            <TabList>
              <Tab
                onClick={() => {
                  setError('');
                  changeURLParam('RegularSchool');
                }}
              >
                Mainstream Schools
              </Tab>
              <Tab
                onClick={() => {
                  setError('');
                  changeURLParam('SENSchool');
                }}
              >
                SEN Schools
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack align={'left'}>
                  {/* <Heading fontSize={'2xl'}>Mainstream School Login</Heading> */}
                  <Text fontSize={'lg'} color={'gray.600'} pb={'5'}>
                    Login to get started
                  </Text>
                </Stack>
                <Stack spacing={4}>
                  <FormControl id="email-mainstream-school">
                    <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                      Email address
                    </FormLabel>
                    <Input type="email" ref={emailRefMs} />
                  </FormControl>
                  <FormControl id="password-mainstream-school">
                    <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                      Password
                    </FormLabel>
                    {/* <Input type="password" ref={passwordRefMs} /> */}
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        ref={passwordRefMs}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? <ViewOffIcon /> : <ViewIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Stack>
                    <Button
                      bg={'brand.300'}
                      color={'white'}
                      _hover={{
                        bg: 'brand.200',
                      }}
                      width={'100%'}
                      onClick={signInMainstream}
                    >
                      Sign in
                    </Button>
                    {error && <Box style={{ color: 'red' }}>{error}</Box>}

                    {/* <HStack textAlign={'center'}>
                      <Text>Not registered?</Text>
                      <Link
                        href={'/school-sign-up?schoolID=51&schoolType=RegularSchool'}
                        _hover={{ textDecoration: 'none' }}
                      >
                        <Text color={'brand.200'}>Register now!</Text>
                      </Link>
                    </HStack> */}
                    <HStack>
                      <Box textAlign={'left'}>
                        <Link href="/">← Back home</Link>
                      </Box>
                      <Box alignItems={'right'}>
                        <Link onClick={onOpen}>
                          <QuestionOutlineIcon /> Forgot password
                        </Link>
                        <ForgotPasswordModal isOpen={isOpen} onClose={onClose} />
                      </Box>
                    </HStack>
                  </Stack>
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack align={'left'}>
                  {/* <Heading fontSize={'2xl'}>SEN School Login</Heading> */}
                  <Text fontSize={'lg'} color={'gray.600'} pb={'5'}>
                    Login to get started
                  </Text>
                </Stack>
                <Stack spacing={4}>
                  <FormControl id="email-sen-school">
                    <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                      Email address
                    </FormLabel>
                    <Input type="email" ref={emailRefSs} />
                  </FormControl>
                  <FormControl id="password-sen-school">
                    <FormLabel textTransform={'uppercase'} color={'brand.200'}>
                      Password
                    </FormLabel>
                    {/* <Input type="password" ref={passwordRefSs} /> */}
                    <InputGroup size="md">
                      <Input
                        pr="4.5rem"
                        type={show ? 'text' : 'password'}
                        ref={passwordRefSs}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? <ViewOffIcon /> : <ViewIcon />}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <Stack>
                    <Button
                      bg={'brand.300'}
                      color={'white'}
                      _hover={{
                        bg: 'brand.200',
                      }}
                      width={'100%'}
                      onClick={signInSENCO}
                    >
                      Sign in
                      {error && <Box style={{ color: 'red' }}>{error}</Box>}
                    </Button>
                    {/* <HStack textAlign={'center'}>
                      <Text>Not registered?</Text>
                      <Link
                        href={'/school-sign-up?schoolID=51&schoolType=RegularSchool'}
                        _hover={{ textDecoration: 'none' }}
                      >
                        <Text color={'brand.200'}>Register now!</Text>
                      </Link>
                    </HStack> */}
                    <HStack>
                      <Box textAlign={'left'}>
                        <Link href="/">← Back home</Link>
                      </Box>
                      <Box alignItems={'right'}>
                        <Link onClick={onOpen}>
                          <QuestionOutlineIcon /> Forgot password
                        </Link>
                        <ForgotPasswordModal isOpen={isOpen} onClose={onClose} />
                      </Box>
                    </HStack>
                  </Stack>
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      )}
    </Stack>
  );

  return (
    <Box
      position="relative"
      background={'white'}
      height={'100vh'}
      backgroundImage={UL_pattern_bg}
      backgroundRepeat={'repeat'}
      backgroundSize={'cover'}
    >
      <Box
        bg={'white'}
        position={'absolute'}
        left={0}
        bottom={'0'}
        minH={'90vh'}
        borderTopRightRadius="40"
        width={'94%'}
      >
        <Flex align={'center'} justify={'center'}>
          <HStack spacing={8} mx={'auto'} width={'90%'}>
            <Box rounded={'lg'} p={8} background={'white'} width={'50%'}>
              <LeftData />
            </Box>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              width={'50%'}
            >
              <Image width="600px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
            </Box>
          </HStack>
        </Flex>
      </Box>
    </Box>
  );
}
