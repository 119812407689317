export const clientFields = {
  id: 'H',
  name: 'Central Client Database - ALL STAFF USAGE',
  fields: [
    { id: 'S', name: 'Date of next therapy session', type: 'date' },
    {
      id: 'X1',
      name: 'Have you seen a Speech Therapist',
      type: 'choice',
      choices: ['Please select an option', 'Yes', 'No'],
    },
    { id: 'T', name: 'Date discharged', type: 'date' },
    { id: 'V', name: 'Diagnosis (Notes)', type: 'string' },
    { id: 'G3', name: 'Parents Last Name', type: 'string' },
    {
      id: 'P2',
      name: 'Therapy Length',
      type: 'multi',
      choices: ['Please select an option', '30 mins', '45 mins', '60 mins'],
    },
    {
      id: 'Y1',
      name: 'Type of Speech Therapist seen?',
      type: 'choice',
      choices: ['Please select an option', 'NHS', 'Private'],
    },
    { id: 'H2', name: 'Date / Time of Enquiry', type: 'timestamp' },
    { id: 'Z', name: 'Assessment Rate', type: 'number' },
    {
      id: 'Q1',
      name: 'If reassigned, Previous Therapist?',
      type: 'choice',
      choices: [
        'Please select an option',
        'Shermeena Rabbi',
        'Madeleine Swan',
        'Emma Corrigan',
        'Sana Mahmood',
        'Megan Armstrong',
        'Sarita Austin',
        'Desiree Boatswain',
        'Janice Ko',
        'Mehreen Masoud',
        'Meera Mehta',
        'Molly Mulvaney',
        'Sarah Olulode',
        'Layla Parsons',
        'Zahra Sheikh',
        'Alanna Wagher',
        'Sarah Watkins-Baker',
        'Priscilla Bailey',
        'Priyanka Bouri',
        'Lydia Chong',
        'Abeer Essa',
        'Saoirse Lane',
        'Fatima Mawji',
        'Jaya Simpson',
        'Anette Szulc',
        'Laura Thorn',
        'Jonathan Turgel',
        'Kendra Tye-Page',
        'Lena Sakure',
        'Shamera Sathiaruban',
        'Emma Louise Sinnott',
        'Louise Evans',
        'Ahlem Birem',
        'Marium Sattar',
        'Emily Atkinson',
        'Salwa Shafiq',
        'Zain Alabbasi',
        'Gemma McCallum',
        'Naimah Mohchin',
        'Rachel Siew',
        'Samia Rockson',
        'Katie Hughes',
        'Abbey Flesner',
        'Abirami Ramalingam',
        'Adesola Amusan',
        'Bethany Marsden',
        'Chrystle Gajadhar',
        'Clarice Lau',
        'Disha Depala',
        'Faika Khan',
        'Farzana Khatun',
        'Fiona Vokshi',
        'Gizem Yildaz',
        'Hafsa Sham',
        'Jusveen Khurana',
        'Katrina Bradford',
        'Kishwar Khan',
        'Ladina Yeboah',
        'Laura Eccles',
        'Leah Palmer',
        'Shaleema Miah',
        'Masuma Shuba',
        'Michaela Balie',
        'Nabeel Imdad',
        'Tamsin Duggie',
        'Valentina Di Genova',
        'Warda Samawe',
        'William Howarth',
        'Siobahn Ally',
        'Shilpa Khanom',
        'Rachel Thomas',
        'Renae Hesse',
        'Chloe Jackson',
      ],
    },
    { id: 'H3', name: 'Xero Contact First Name', type: 'string' },
    {
      id: 'Z1',
      name: 'Sex',
      type: 'choice',
      choices: ['Please select an option', 'Male', 'Female'],
    },
    { id: 'A1', name: 'Report Rate', type: 'number' },
    { id: 'I3', name: 'Xero Contact Surname', type: 'string' },
    {
      id: 'J1',
      name: 'Diagnosis',
      type: 'choice',
      choices: [
        'Please select an option',
        'Language Disorder',
        'Language Disorder associated with ASD/ADHD/GDD',
        'Developmental Language Disorder - receptive',
        'Developmental Language Disorder - expressive',
        'Developmental Language Disorder - receptive and expressive',
        'Dysfluency (stammering)',
        'Social Communication Difficulties',
        'Selective Mutism',
        'Delayed Phonological Development',
        'Consistent Phonological Disorder',
        'Inconsistent Phonological Disorder',
        'Articulation Disorder',
        'Developmental Verbal Dyspraxia',
        'Aphasia - receptive',
        'Aphasia - expressive',
        'Aphasia - global',
        'Developmental Stammer',
        'Psychogenic Stammer',
        'Social Communication Difficulties associated with ASD',
        'Muscle Tension Dysphonia',
        'Functional Dysphonia',
        'Vocal Nodules',
        'Dysarthria',
        'Verbal Dyspraxia',
      ],
    },
    { id: 'R3', name: 'Xero Invoice Line Item Amount', type: 'number' },
    {
      id: 'J2',
      name: 'Stats',
      type: 'rev',
      referenceFromTable: 'I',
      referenceFromField: 'F',
    },
    { id: 'B1', name: 'Therapy Rate', type: 'number' },
    { id: 'S1', name: 'Age (Enquiry)', type: 'number' },
    {
      id: 'A4',
      name: 'Therapy Frequency',
      type: 'multi',
      choices: [
        'Please select an option',
        'Weekly',
        'Fortnightly',
        'Monthly',
        'One off',
        'Other - Specify in notes',
      ],
    },
    { id: 'B2', name: 'Do you have a written report? Date?', type: 'string' },
    {
      id: 'K1',
      name: 'Status',
      type: 'choice',
      choices: ['Please select an option', 'Current', 'Discharged', 'Paused', 'Enquiry'],
    },
    { id: 'C1', name: 'Mobile 1', type: 'phone' },
    {
      id: 'K3',
      name: 'Invoices',
      type: 'rev',
      referenceFromTable: 'L',
      referenceFromField: 'J',
    },
    { id: 'T2', name: 'Record iD Field for Zapier', type: 'number' },
    { id: 'A', name: 'First Name', type: 'string' },
    {
      id: 'L1',
      name: 'Client type',
      type: 'choice',
      choices: [
        'Please select an option',
        'Private Client',
        'Intermediary',
        'Tribunal',
        'Local Authority ',
        'Focus Mental Health',
        'CYP',
        'SEN Tutoring',
      ],
    },
    { id: 'B', name: 'Last Name', type: 'string' },
    { id: 'D2', name: 'Enquiry - Concerns', type: 'string' },
    {
      id: 'U2',
      name: 'SLA Template',
      type: 'choice',
      choices: [
        'Please select an option',
        'Adult',
        'Child',
        'Intermediary Services',
        'Tribunal',
        'Local Authority',
        'Intermediary Assessment',
        'Solicitor/Case Manager',
        'Adult - 2.5',
        'Adult - 1.75',
        'Adult - 1.5',
        'Adult - 1',
        'Child - 2.5',
        'Child - 1.5',
        'Child - 1.25',
        'Child - 1',
      ],
    },
    { id: 'F', name: 'Date of Birth', type: 'date' },
    { id: 'D3', name: 'Xero Contact Street Address', type: 'string' },
    { id: 'H', name: 'Parents First Name', type: 'string' },
    { id: 'M2', name: 'Email 2', type: 'email' },
    {
      id: 'I',
      name: 'Adult or Child?',
      type: 'choice',
      choices: ['Please select an option', 'Adult', 'Child'],
    },
    {
      id: 'E2',
      name: 'Any other information (i.e. partial address)',
      type: 'string',
    },
    {
      id: 'V2',
      name: 'Docusign Template Name for Integration',
      type: 'string',
    },
    { id: 'K', name: 'Phone', type: 'phone' },
    {
      id: 'N1',
      name: 'Additonal party involved: Solicitor, Court, School, Local Authority?',
      type: 'choice',
      choices: ['Please select an option', 'Yes', 'No'],
    },
    { id: 'L', name: 'Email 1', type: 'email' },
    { id: 'E3', name: 'Xero Contact Area Address', type: 'string' },
    {
      id: 'V3',
      name: 'Current Therapist Assigned',
      type: 'ref',
      referenceToTable: 'O',
      reverseField: 'I',
    },
    { id: 'M', name: 'Address', type: 'location' },
    { id: 'N2', name: 'Docusign', type: 'boolean' },
    {
      id: 'F1',
      name: 'Invoice Frequency',
      type: 'multi',
      choices: [
        'Please select an option',
        'Pay as you go',
        'Blocks of 8',
        'Pay as you go - GoCardless',
        'Blocks of 8 -  GoCardless',
        'Blocks of 12',
        'Blocks of 12 - GoCardless',
        'Blocks of 6',
        'Blocks of 6 - GoCardless',
        'Monthly',
      ],
    },
    {
      id: 'O',
      name: 'Assessment undertaken?',
      type: 'multi',
      choices: [
        'Please select an option',
        'No',
        'Adult Assessment',
        'Toddler Assessment',
        'Child Assessment',
        'Tribunal Assessment',
        'Intermediary Assessment',
        'EHCP Assessment',
        'Online Screening',
      ],
    },
    { id: 'P', name: 'Report provided?', type: 'boolean' },
    { id: 'O1', name: 'Additional party details', type: 'string' },
    { id: 'F3', name: 'Xero Contact City/County Address', type: 'string' },
    { id: 'B4', name: 'Assessment Date', type: 'date' },
    { id: 'C4', name: 'Email Template - Child', type: 'email' },
    { id: 'F4', name: 'Email Template - Adult', type: 'email' },
    {
      id: 'G4',
      name: 'Therapy Location',
      type: 'choice',
      choices: [
        'Please select an option',
        'Online',
        'Clinic - CW',
        'Home',
        'School',
        'Clinic - GP',
      ],
    },
    { id: 'J4', name: 'Session Period Start', type: 'date' },
    { id: 'K4', name: 'Session Period End', type: 'date' },
    { id: 'L4', name: 'Block of 8 Invoices Paid For?', type: 'boolean' },
    {
      id: 'M4',
      name: 'Re-assessment - If applicable?',
      type: 'multi',
      choices: ['Please select an option', 'Yes', 'No'],
    },
    { id: 'N4', name: 'Re-assessment Date', type: 'date' },
    { id: 'O4', name: 'Docusign Returned Full Name', type: 'string' },
    { id: 'R4', name: 'Docusign Returned Email Address', type: 'string' },
    { id: 'T4', name: 'Docusign Returned Gender', type: 'string' },
    { id: 'U4', name: 'Docusign Returned Telephone', type: 'phone' },
    { id: 'V4', name: 'Docusign Returned Address', type: 'string' },
    { id: 'W4', name: 'Docusign Returned Other', type: 'string' },
    { id: 'X4', name: 'Docusign Returned Mobile', type: 'phone' },
    { id: 'Y4', name: 'Docusign Returned D.O.B (Text)', type: 'string' },
    { id: 'Z4', name: 'Report paid for?', type: 'boolean' },
    {
      id: 'G5',
      name: 'Choose your Docusign Template',
      type: 'ref',
      referenceToTable: 'K',
      reverseField: 'J',
    },
    { id: 'J5', name: 'Reason if none scheduled?', type: 'string' },
    { id: 'L5', name: 'Discharge letter sent', type: 'boolean' },
    { id: 'M5', name: 'Pricing notes', type: 'string' },
    { id: 'N5', name: 'Sessions in Block', type: 'number' },
    {
      id: 'O5',
      name: 'Xero Overview',
      type: 'rev',
      referenceFromTable: 'R',
      referenceFromField: 'J',
    },
    { id: 'T5', name: 'Date of Last Sync', type: 'timestamp' },
    { id: 'V5', name: 'School or Employer', type: 'string' },
    { id: 'W5', name: 'Session Block Start Date', type: 'date' },
    { id: 'Z5', name: 'Copied Client To Tribunal DB', type: 'boolean' },
    {
      id: 'C6',
      name: 'Who is the Payee for the Invoice in Xero?',
      type: 'choice',
      choices: ['Please select an option', 'Client', 'LA'],
    },
    { id: 'E6', name: 'LA Contact Name', type: 'string' },
    { id: 'G6', name: 'LA Contact Email', type: 'email' },
    { id: 'J6', name: 'Xero Contact Email', type: 'email' },
    { id: 'N6', name: 'Session Block End Date', type: 'date' },
    { id: 'P6', name: 'Start Date - Link Sessions To Invoice', type: 'date' },
    { id: 'Q6', name: 'End Date - Link Sessions To Invoice', type: 'date' },
    {
      id: 'T6',
      name: 'Xero Invoice',
      type: 'ref',
      referenceToTable: 'R',
      reverseField: 'S',
    },
    { id: 'Y6', name: 'Mobile 2', type: 'phone' },
    { id: 'Z6', name: 'PO Number', type: 'string' },
    {
      id: 'B7',
      name: 'Rate?',
      type: 'choice',
      choices: ['Please select an option', 'Current rate', 'Special', 'Local authority'],
    },
    { id: 'I7', name: 'Notes - rate', type: 'string' },
    { id: 'R7', name: 'Xero Contact ID', type: 'string' },
    {
      id: 'T7',
      name: 'Month',
      type: 'choice',
      choices: [
        'Please select an option',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
    },
    { id: 'W7', name: 'Invoice instructions', type: 'html' },
    {
      id: 'B8',
      name: 'Type',
      type: 'choice',
      choices: ['Please select an option', 'Pay and Go', 'Session Blocks'],
    },
    { id: 'C8', name: 'Primary Email Address', type: 'string' },
    {
      id: 'D8',
      name: 'Select Primary Email Address',
      type: 'choice',
      choices: ['Please select an option', 'Email 1', 'Email 2'],
    },
    { id: 'Q8', name: 'Override', type: 'boolean' },
    {
      id: 'C9',
      name: 'Copied to Unlocking Language Tribunal DB',
      type: 'boolean',
    },
    {
      id: 'J9',
      name: 'Select number',
      type: 'choice',
      choices: [
        'Please select an option',
        'Phone',
        'Mobile 1',
        'Mobile 2',
        'DocuSign mobile',
        'DocuSign telephone',
      ],
    },
    { id: 'K9', name: 'Message (plain-text)', type: 'string' },
    {
      id: 'L9',
      name: 'Templates',
      type: 'choice',
      choices: [
        'Please select an option',
        'Unpaid Invoice (All)',
        'Appointment reminder',
        'Custom',
        'Sign up to GoCardless',
        'Unpaid Invoice (Single)',
      ],
    },
    { id: 'Q9', name: 'Select Invoice', type: 'dchoice' },
    {
      id: 'V9',
      name: 'Messages',
      type: 'rev',
      referenceFromTable: 'HB',
      referenceFromField: 'C',
    },
    {
      id: 'AA',
      name: 'Access level',
      type: 'choice',
      choices: ['Please select an option', 'Silver', 'Bronze', 'Gold'],
    },
    { id: 'BA', name: 'Agreed to T&Cs - website', type: 'boolean' },
    { id: 'CA', name: 'Docusign sent', type: 'boolean' },
    { id: 'EA', name: 'Gocardless active', type: 'boolean' },
    { id: 'FA', name: 'Auto message trigger', type: 'boolean' },
    { id: 'GA', name: 'Stripe first payment', type: 'boolean' },
    {
      id: 'IA',
      name: 'Has this person unsubscribed to Marketing SMS',
      type: 'boolean',
    },
    { id: 'KA', name: 'Uploads folder', type: 'link' },
    { id: 'LA', name: 'HTML Contract', type: 'html' },
    {
      id: 'MA',
      name: 'Type of NEW invoice',
      type: 'choice',
      choices: ['Please select an option', 'Assessment', 'Invoice'],
    },
    { id: 'PA', name: 'Account status', type: 'boolean' },
    { id: 'RA', name: 'Car reg', type: 'string' },
    { id: 'TA', name: 'Car reg 2', type: 'string' },
    {
      id: 'UA',
      name: 'Mode of transport',
      type: 'choice',
      choices: ['Please select an option', 'Car', 'Public Transport'],
    },
    {
      id: 'VA',
      name: 'TOMs rating',
      type: 'rev',
      referenceFromTable: 'WB',
      referenceFromField: 'I',
    },
    {
      id: 'WA',
      name: 'Primary TOMs Scale',
      type: 'choice',
      choices: [
        'Select primary scale',
        '10- Cognition',
        '11- Complex and Multiple Difficulty',
        '2- Augmentative and Alternative Communication',
        '22- Dysarthria',
        '23- Dysfluency',
        '24- Dysphagia',
        '25- Dysphasia/Aphasia',
        '26- Dysphonia',
        '27- Dyspraxia',
        '29- Head Injury',
        '3- Autistic Spectrum Disorder',
        '30- Hearing Impairment',
        '33- Learning Disability: Communication',
        '36- Multifactorial Conditions',
        '38- Neurological Disorders',
        '40- Phonological Disorder',
        '5- Cerebral Palsy',
        '50- Paediatric Dysphagia',
        '51- Selective Mutism',
        '6- Developmental Language Disorder',
        '63- Attention Deficit Hyperactivity Disorder',
        '64- Auditory Impairment',
        '66- Social Communication Difficulty',
        '67- Pragmatic Communiction Disorder',
        '68- Articulation Disorder',
        '7- Challenging Behaviour nd Forensic Mental Health',
        '9- Cleft Lip and Palate',
      ],
    },
    {
      id: 'XA',
      name: 'Secondary TOMs Scale',
      type: 'choice',
      choices: [
        'Select secondary scale',
        '10- Cognition',
        '11- Complex and Multiple Difficulty',
        '2- Augmentative and Alternative Communication',
        '22- Dysarthria',
        '23- Dysfluency',
        '24- Dysphagia',
        '25- Dysphasia/Aphasia',
        '26- Dysphonia',
        '27- Dyspraxia',
        '29- Head Injury',
        '3- Autistic Spectrum Disorder',
        '30- Hearing Impairment',
        '33- Learning Disability: Communication',
        '36- Multifactorial Conditions',
        '38- Neurological Disorders',
        '40- Phonological Disorder',
        '5- Cerebral Palsy',
        '50- Paediatric Dysphagia',
        '51- Selective Mutism',
        '6- Developmental Language Disorder',
        '63- Attention Deficit Hyperactivity Disorder',
        '64- Auditory Impairment',
        '66- Social Communication Difficulty',
        '67- Pragmatic Communiction Disorder',
        '68- Articulation Disorder',
        '7- Challenging Behaviour nd Forensic Mental Health',
        '9- Cleft Lip and Palate',
      ],
    },
  ],
};
