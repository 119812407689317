import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Flex,
  Stack,
  Heading,
  Text,
  Link,
  Button,
  Box,
  ButtonGroup,
  Spacer,
  useColorModeValue,
  FormControl,
  FormLabel,
  Input,
  HStack,
  Avatar,
  AvatarBadge,
  IconButton,
  Center,
  FormHelperText,
  Image,
} from '@chakra-ui/react';
import {} from '@chakra-ui/react';
import StepOne from '../components/subscriberSignUpSteps/StepOne';
import StepTwo from '../components/subscriberSignUpSteps/StepTwo';
import StepThree from '../components/subscriberSignUpSteps/StepThree';
import StepFour from '../components/subscriberSignUpSteps/StepFour';
import StepFive from '../components/subscriberSignUpSteps/StepFive';
import UL_logo from './../logo/unlocking_language_logo_white.png';
import { privateForm } from '../interfaces/privateForm';
import { auth } from '../auth/fireBaseSetup';
import { AuthContext } from '../context/AuthContext';
// import { SmallCloseIcon } from '@chakra-ui/icons';
const steps = [
  { title: 'Details', description: 'Fill in your details', content: 'Content 1' },
  {
    title: 'Contract',
    description: 'View & Sign Contract',
    content: 'Content 3',
  },
  { title: 'Subscription type', description: 'Select your subscription', content: 'Content 4' },
  // { title: 'Payment', description: 'Make the first payment', content: 'Content 5' },
];

export default function NoPage(): JSX.Element {
  const defaultForm = {
    firstname: '',
    lastname: '',
    address: '',
    email: '',
    contactNumber: '',
    password: '',
    cpassword: '',
    contract: 0,
  };

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack
        spacing={4}
        w={'full'}
        maxW={'xl'}
        bg={useColorModeValue('white', 'gray.700')}
        rounded={'xl'}
        boxShadow={'lg'}
        p={6}
        my={12}
      >
        <Stack align={'left'} mb={'50px'}>
          <Box display={'flex'} alignItems={'center'} flexDirection={'column'} width={'100%'}>
            <Image width="200px" objectFit="cover" src={UL_logo} alt="Unlocking Language" />
          </Box>
          <Box width={'100%'} alignItems={'center'}>
            <Heading as={'h3'} mb={'5'} textAlign={'center'} color={'brand.200'}>
              Oops! Page not not found!
            </Heading>
            <Text textAlign={'center'}>Let's get you back on track</Text>
            <Link href={'/'} textAlign={'center'}>
              <Text textAlign={'center'} color={'brand.300'}>
                Go to the login page
              </Text>
            </Link>
          </Box>
        </Stack>
        <Outlet />
      </Stack>
    </Flex>
  );
}
