import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Text,
  Textarea,
  Tooltip,
  HStack,
  VStack,
  Stack,
  Flex,
  Link,
} from '@chakra-ui/react';

import React, { ReactNode, useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getMultipleApiData from '../utils/getMultipleApiData';
import getAllRowsById from '../utils/getAllRowsById';
import getAllProfiles from '../utils/getAllProfiles';
import ninoxTableAddRow from '../utils/ninoxTableAddRow';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { userDetailsContext } from '../context/UserDetailsProvider';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { json } from 'stream/consumers';
import { color } from 'framer-motion';
import { BackButton } from './BackButton';
import moment from 'moment';
import momentBusiness from 'moment-business-days';
import Moment from 'react-moment';
import momentTimezone from 'moment-timezone';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import RatingStarsViewNoClick from './RatingStarsViewNoClick';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
// type StateContextType = {
//   userDetails: userSessionDataProps | null;
//   setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
// };
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

interface UserMeta {
  firstName: string;
  lastName: string;
  accessLevel: string;
  accountType: string;
  messages: number[];
  stats: number[];
  invoices: number[];
}
interface Note {
  nid: number;
  session_id: number;
  note_type: string;
  note_owner: number;
  note_details: string;
  posted_at: Date;
  modified_at: Date;
}
const today = new Date();

const BookingsContentPrivateClient = ({
  signedInUserId,
  signedInUserEmail,
  userMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserProps) => {
  // const { userDetails, updateUser } = useContext(userDetailsContext);
  // updateUser(signedInUserId);

  const newNoteDefault = {
    nid: 0,
    session_id: 0,
    note_type: 'brief_note',
    note_owner: signedInUserId,
    note_details: '',
    posted_at: today,
    modified_at: today,
  };
  const [openedModal, setOpenedModal] = useState<boolean>(false);
  const [theModal, setTheModal] = useState<number>(0);
  const [notes, setNotes] = useState<Note[] | []>([]);
  const [newNote, setNewNote] = useState<Note>(newNoteDefault);
  const [statsFromQuery, setStatsFromQuery] = useState<any>([]);
  const [caseNotes, setCaseNotes] = useState<any>([]);
  const [schools, setSchools] = useState<any>([]);
  const [schoolFilter, setSchoolFilter] = useState(51);
  const { onOpen, onToggle } = useDisclosure();
  const [size, setSize] = useState('md');
  const [rows, setRows] = useState<any>([]);

  const navigate = useNavigate();

  const IsolatedModal = ({ session, id, modalState }: any) => {
    const { onOpen, onClose, isOpen } = useDisclosure();
    return (
      <Box as="section">
        <Modal
          onClose={() => {
            setTheModal(0);
            setOpenedModal(false);
          }}
          size={'full'}
          isOpen={theModal === id ? modalState : false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modal header</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {/* The body content for {JSON.stringify(session)} */}
              <Flex>
                <Box w={'30%'} p={'5'}>
                  <HStack>
                    <FormControl mb={'5'}>
                      <FormLabel>Session ID</FormLabel>
                      <Input value={id} isDisabled />
                    </FormControl>
                    <FormControl mb={'5'}>
                      <FormLabel>Note added by</FormLabel>
                      <Input
                        value={session['Central Client Database - ALL STAFF USAGE']}
                        isDisabled
                      />
                    </FormControl>
                  </HStack>
                  <FormControl mb={'5'}>
                    <FormLabel>Select note type: </FormLabel>
                    <Select
                      isRequired
                      onChange={(e) => handleNoteType(e)}
                      // value={newNote.note_type}
                    >
                      <option value="brief_note">Brief note (Short notes)</option>
                      <option value="session_note">Session note (Detailed notes)</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Note: </FormLabel>
                    <Textarea
                      // value={value}
                      // onChange={handleInputChange}
                      isRequired
                      placeholder="Enter your note details here"
                      size="3xl"
                      p={'5'}
                      onChange={(e) => handleNoteDetails(e)}
                      value={newNote.note_details}
                    />
                  </FormControl>
                  {/* {JSON.stringify(newNote)} */}
                  {/* <hr></hr> */}
                  {/* {JSON.stringify(userMeta)} */}
                </Box>

                <Box
                  w={'70%'}
                  h={'75vh'}
                  p={'5'}
                  bg={'#eaeaea'}
                  borderRadius={'5'}
                  overflowY={'scroll'}
                >
                  <FormLabel>Session notes history:</FormLabel>
                  {/* {JSON.stringify(notes)} */}
                  <TableContainer>
                    <Table variant="striped" colorScheme={'green'}>
                      {notes.length <= 0 && <TableCaption>No notes posted yet!</TableCaption>}
                      <Thead>
                        <Tr>
                          <Th>Note ID</Th>
                          <Th>Posted at</Th>
                          <Th>Posted by</Th>
                          <Th>Session Id</Th>
                          <Th>Note owner</Th>
                          <Th>Note type</Th>
                          <Th>Note details</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {notes &&
                          notes?.map((i: any, k: any) => {
                            return (
                              <Tr
                                key={k}
                                // onClick={() => {
                                //   handleModalOpen(i.id, i.fields);
                                // }}
                                _hover={{
                                  bg: 'brand.200',
                                }}
                              >
                                <Td>{i.id}</Td>
                                <Td>{i.createdAt}</Td>
                                <Td>{i.createdBy}</Td>
                                <Td>
                                  {i?.fields?.session_id} - {id}
                                </Td>
                                <Td>{i?.fields?.note_owner}</Td>
                                <Td>
                                  {i?.fields?.note_type === 'session_note'
                                    ? 'Session note'
                                    : 'Brief note'}
                                </Td>
                                <Td>{i?.fields?.note_details}</Td>
                                {/* <IsolatedModal
                                    session={i.fields}
                                    id={i.id}
                                    modalState={openedModal}
                                  /> */}
                              </Tr>
                            );
                          })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              </Flex>
              {/* The body content for {
              "Brief Notes":"PC:1357 - #TX - Mark Testing - Clinic ",
              "Session Start":"2023-09-01T14:00:00",
              "Session Type":"Therapy (TX)",
              "Session End":"2023-09-01T15:00:00",
              "Event Created by":"ahad@unlockinglanguage.co.uk",
              "Central Client Database - ALL STAFF USAGE":1357,
              "Session Invoiced?":false,
              "Block Sessions?":false}

              nid : note id generated by increment or UUID  - type: number | string
                session_id: this will be the session Id and will populate when the note is posted : number | string
                note_type: this is either brief_note or session_note - type : string
                note_owner: this is the session note owner aka who posted the note : type: string
                note_details: this is the actual note text - type: string | long text
              posted_at: time posted at - type: timestamp
              modified at: time modified at = type: timestamp */}
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  onClick={() => {
                    setTheModal(0);
                    setOpenedModal(false);
                  }}
                >
                  Close
                </Button>
                <Button
                  onClick={() => {
                    setTheModal(0);
                    setOpenedModal(false);
                  }}
                  bg={'brand.300'}
                  _hover={{
                    bg: 'brand.200',
                  }}
                  color={'white'}
                >
                  Add note
                </Button>
              </HStack>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    );
  };
  function handleModalOpen(rowId: any, rowSession: BookingFields) {
    console.log('We tried to open ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
    setOpenedModal(true);
    setTheModal(rowId);
    console.log(openedModal);
  }
  function handleModalClose(rowId: any, rowSession: any) {
    console.log('We tried to close ' + rowId + ' and the data was ' + JSON.stringify(rowSession));
    setOpenedModal(false);
    setTheModal(rowId);
    console.log(openedModal);
  }
  const [overlay, setOverlay] = useState<ReactNode>(<IsolatedModal session={0} />);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulProfilesTable = process.env.REACT_APP_UL_ALL_CLIENT_PROFILES;
  const allProfilesFullUrl = process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS;
  const userStats = userMeta && userMeta?.stats;
  const therapistsTable = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS;
  const clientQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
  // const statsStrings: any =
  //   localStorage.getItem('accountNumber') !== undefined && localStorage.getItem('stats');
  // const statsList = statsStrings.split(',').map(Number);
  const accountNumber = localStorage.getItem('accountNumber');

  // console.log('statsList: ', statsList);
  useEffect(() => {
    setSignedInUserType('clinical');
    const therapistCaseNotesEP = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
    const therapistSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
    const EP = process.env.REACT_APP_USER_DATA;

    setNewNote(newNoteDefault);
    const statsQuery = `let client_id := ${localStorage.getItem('accountNumber')};
    let today_date := today();
    let sessions := (select 'Session Stats')['Central Client Database - ALL STAFF USAGE'.Id = client_id].id;
    concat(sessions)`;
    getAllRowsByEncodedQuery(clientQueryEP, statsQuery).then((data: any) => {
      const allStats = data.split(',');
      setStatsFromQuery(allStats);
      console.log('statsQuery: ', allStats);
      console.log('accountNumberrrr:', accountNumber);

      accountNumber !== undefined &&
        allStats !== undefined &&
        getMultipleApiData(EP!, bearerToken!, allStats).then((values) => {
          setRows(values);
          console.log('here: ', values);
        });
    });
  }, [userStats]);

  interface BookingFields {
    'Block Sessions?': string;
    'Brief Notes': string;
    'Event Created by': string;
    'Session End': string;
    'Session Invoiced?': boolean;
    'Session Start': string;
    'Session Type': string;
    'Session rating': number;
    'Session comments': string;
  }

  interface Bookings {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: BookingFields;
  }

  const handleNoteType = (e: any) => {
    setNewNote({ ...newNote, note_type: e.target.value });
  };

  const handleNoteDetails = (e: any) => {
    let inputValue = e.target.value;
    setNewNote({ ...newNote, note_details: inputValue });
  };
  // Parse the timestamp as UTC
  const timestamp = momentTimezone.utc('2024-04-24T12:59:31');

  // Convert the timestamp to London local time
  const londonLocalTime = timestamp.tz('Europe/London').format('YYYY-MM-DD HH:mm');

  console.log('London local time:', londonLocalTime);
  const sessionHint = `Therapy (TX),
  Assessment (AX),
  Telephone call - Chargeable,
  Email - Chargeable,
  Preparation - Chargeable,
  Admin (AD),
  Meeting (MT)`;

  return (
    <>
      <Box ml={{ base: 0, md: 20 }} p="6">
        <Box p={6} bg="white">
          <HStack spacing={5} mb={10}>
            <BackButton navigate={navigate} />
            <Heading>Sessions</Heading>
          </HStack>
          <TableContainer>
            {localStorage.getItem('accountType') !== undefined &&
              localStorage.getItem('accountType') === 'Private Client' && (
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Session ID </Th>
                      <Th>
                        Session Type{' '}
                        <Tooltip label={sessionHint} style={{ cursor: 'pointer' }}>
                          <Icon mt={-1} />
                        </Tooltip>
                      </Th>
                      {/* <Th>Created At</Th> */}
                      {/* <Th>Created By</Th> */}
                      {/* <Th>Modified At</Th> */}
                      {/* <Th>Brief Notes</Th> */}
                      <Th>Session Start </Th>
                      <Th>Session End </Th>
                      <Th>Manage Session</Th>
                      <Th>Rate Session</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {/* .filter((i: any) => new Date(today) < new Date(i.fields['Session End'])) */}
                    {rows &&
                      rows
                        ?.sort((a: any, b: any) =>
                          moment(b.fields['Session Start'])
                            .format('YYYY-MM-DD HH:mm')
                            .localeCompare(
                              moment(a.fields['Session Start']).format('YYYY-MM-DD HH:mm')
                            )
                        )
                        .map((i: Bookings, k: any) => {
                          const businessDaysDiff = momentBusiness(
                            moment(i.fields['Session Start']).format('YYYY-MM-DD HH:mm')
                          ).businessDiff(momentBusiness(moment(today).format('YYYY-MM-DD HH:mm')));
                          const checkBefore =
                            moment(i.fields['Session Start']).isBefore(
                              moment(today).format('YYYY-MM-DD HH:mm')
                            ) === true
                              ? '-'
                              : '';

                          // Parse the timestamp as UTC
                          const timestampSessionStart = momentTimezone.utc(
                            i.fields['Session Start']
                          );
                          // Convert the timestamp to London local time
                          const londonLocalTimeSs = timestampSessionStart
                            .tz('Europe/London')
                            .format('YYYY-MM-DD HH:mm');

                          // Parse the timestamp as UTC
                          const timestampSessionEnd = momentTimezone.utc(i.fields['Session End']);
                          // Convert the timestamp to London local time
                          const londonLocalTimeSe = timestampSessionEnd
                            .tz('Europe/London')
                            .format('YYYY-MM-DD HH:mm');

                          return (
                            <Tr key={k} style={{ cursor: 'pointer' }}>
                              <Td>
                                {i.id}
                                {/* {JSON.stringify(i)} */}
                              </Td>
                              <Td>{i.fields['Session Type']}</Td>
                              {/* <Td>{i.createdAt}</Td> */}
                              {/* <Td>{i.fields['Event Created by']}</Td> */}
                              {/* <Td>{i.modifiedAt}</Td> */}
                              {/* <Td>{i.fields['Brief Notes']}</Td> */}
                              <Td>{londonLocalTimeSs}</Td>
                              <Td>{londonLocalTimeSe}</Td>
                              <Td>
                                {Number(checkBefore + businessDaysDiff) > 2 ? (
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                    onClick={() => {
                                      navigate(
                                        '/private-client-session/' +
                                          i.id +
                                          '?session_start=' +
                                          i.fields['Session Start'] +
                                          '&activeTab=manage'
                                      );
                                    }}
                                  >
                                    Manage session
                                    {/*                                   
                                  {businessDaysDiff} ={' '}
                                  {moment(i.fields['Session Start']).isBefore(
                                    moment(today).format('YYYY-MM-DD HH:MM')
                                  ) === true
                                    ? 'true'
                                    : 'false'} */}
                                  </Button>
                                ) : (
                                  <Text fontWeight={'bold'}>Cannot be changed</Text>
                                )}
                              </Td>
                              <Td>
                                {i.fields['Session rating'] === undefined ? (
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                    onClick={() => {
                                      navigate(
                                        '/private-client-session/' +
                                          i.id +
                                          '?session_start=' +
                                          i.fields['Session Start'] +
                                          '&activeTab=rate'
                                      );
                                    }}
                                  >
                                    Rate session
                                  </Button>
                                ) : (
                                  // <Text fontWeight={'bold'}>{i.fields['Session rating']}</Text>
                                  <Box
                                    onClick={() => {
                                      console.log('unable to rate');
                                    }}
                                    m={-5}
                                  >
                                    <VStack>
                                      <RatingStarsViewNoClick
                                        size={24}
                                        icon="StarIcon"
                                        scale={5}
                                        fillColor="gold"
                                        strokeColor="grey"
                                        ratingScore={Number(i.fields['Session rating'])}
                                        setRatingScore={console.log('unable to rate')}
                                      />
                                      {i.fields['Session comments'] !== undefined && (
                                        <Box
                                          width={'200px'}
                                          height={'auto'}
                                          display={'flex'}
                                          flexDirection={'row'}
                                          // overflow={'scroll'}
                                          style={{ textWrap: 'wrap' }}
                                          ml={-10}
                                        >
                                          <Text>{i.fields['Session comments']}</Text>
                                        </Box>
                                      )}
                                    </VStack>
                                  </Box>
                                )}
                              </Td>
                            </Tr>
                          );
                        })}
                    {rows === undefined && <TableCaption>Your sessions history</TableCaption>}
                  </Tbody>
                </Table>
              )}
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default BookingsContentPrivateClient;
