import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Grid,
  Text,
  Button,
  Spinner,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import ninoxTableUpdateRow from '../utils/ninoxTableUpdateRow';
// import { privateForm } from '../interfaces/privateForm';
import UserProfilePic from './UserProfilePic';

const ProfileClientTxBio = ({ title }: any) => {
  const [user, setUser]: any = useState();
  const [signUpError, setSignUpError] = useState<any>('');
  const [edit, setEdit] = useState(0);
  const userAccountNumber = localStorage.getItem('accountNumber');
  const [txProfilePic, setTxProfilePic] = useState<any>('');
  const [loadingIcon, setLoadingIcon] = useState<any>(0);
  const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;

  // console.log('userAccountNumber: ', userAccountNumber);
  function downloadFile(at: any, fileURL: any) {
    // console.log('at: ', at);
    // console.log('fileURL: ', fileURL);
    const options = {
      headers: {
        Authorization: at,
      },
    };
    fetch(fileURL, options)
      .then((res) => res.blob())
      .then((blob) => {
        let file = window.URL.createObjectURL(blob);
        // console.log(file);
        setTxProfilePic(file);
        // setDownloadedFile(file);
        setLoadingIcon(1);
        // window.location.assign(file);
      });
  }
  // const [form, setForm] = useState<any>(defaultForm);

  // function getUserData() {
  //   ninoxTableGetRow(
  //     process.env.REACT_APP_UL_NINOX_TABLES_ALL_CLIENTS!,
  //     Number(userAccountNumber),
  //     process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
  //   ).then((data: any) => {
  //     setUser(data);
  //     // setForm({
  //     //   ...form,
  //     //   firstName: data?.fields['First Name'],
  //     //   lastName: data?.fields['Last Name'],
  //     //   email: data?.fields['Primary Email Address'],
  //     //   contactNumber: data?.fields['Phone'],
  //     //   addressLineOne: data?.fields['Address'],
  //     //   addressLineTwo: data?.fields['Xero Contact Street Address'],
  //     //   city: data?.fields['Xero Contact Area Address'],
  //     //   postCode: data?.fields['Xero Contact Area Address'],
  //     //   password: '',
  //     //   cpassword: '',
  //     // });
  //   });
  // }

  useEffect(() => {
    // getUserData();
    downloadFile(bearerToken, localStorage.getItem('Headshot'));
  }, []);

  // https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/ey8729p64s59/tables/O/records/134/files/Rachel-Thomas.jpg
  //unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/ey8729p64s59/tables/O/records/number(last((select 'Our Therapists')['First Name' + " " + Surname = "Clarice Lau"].Id))/files/Clarice.jpg
  https: return (
    <Box w="100%">
      <Heading as={'h3'} size={'md'} my={10}>
        Therapist Bio
      </Heading>
      <Box display={'flex'} flexDirection={'column'} alignItems={'right'} justifyContent={'right'}>
        <HStack>
          <Box w="220px" mr={10}>
            {loadingIcon === 0 ? (
              <Box
                width={'76px'}
                height={'76px'}
                alignContent={'center'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Spinner
                  thickness="8px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="brand.200"
                  size="xl"
                />
                loading...
              </Box>
            ) : (
              txProfilePic && <UserProfilePic profilePic={txProfilePic!} imageWidth="200" />
            )}
          </Box>
          <Box w="80%">
            <Box
              width={'100%'}
              height={'auto'}
              bg={'white'}
              borderRadius={10}
              textAlign={'left'}
              p={10}
            >
              <Box
                width={'100%'}
                dangerouslySetInnerHTML={{ __html: localStorage.getItem('TherapistBio')! }}
              />
            </Box>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};
export default ProfileClientTxBio;
