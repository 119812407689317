import {
  Box,
  Button,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  StackDivider,
  Stat,
  StatGroup,
  StatNumber,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useBoolean,
  HStack,
} from '@chakra-ui/react';
import { BsBookmark, BsBookmarkFill, BsFillBookmarkFill } from 'react-icons/bs';
import moment from 'moment';
import getMultipleApiData from '../utils/getMultipleApiData';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '..';
import { LanguageContext } from '../App';
import { auth } from '../auth/fireBaseSetup';
import { SignedInUserPropsTherapist } from '../interfaces/SignedInUserPropsTherapist';
import getAllRowsByEncodedQuery from '../utils/getAllRowsByEncodedQuery';
import getAllRowsById from '../utils/getAllRowsById';
import getUserNinoxDataByEmail from '../utils/getUserNinoxDataByEmail';
import ninoxTableGetRow from '../utils/ninoxTableGetRow';
import { signOut } from './../utils/signOut';
import BookingsContentStudentsDashboard from './BookingsContentStudentsDashboard';
import { DashboardVideoList } from './DashboardVideoList';
import MessagesContentUsersDashboard from './MessagesContentUsersDashboard';

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import PopularResources from './PopularResources';

import { FaBell } from 'react-icons/fa';
import { FaRegBell } from 'react-icons/fa6';
import getDataFromEndPoint from '../utils/getDataFromEndPoint';
const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
const ulTeam = process.env.REACT_APP_UL_TEAM;
const adsEP = process.env.REACT_APP_ADS_LIST;
const regularSchoolsEP = process.env.REACT_APP_UL_SCHOOL_DATA_MAINSTREAM;
const caseNotesMainstream = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
const studentsFromSchool = caseNotesMainstream;

const DashboardSchoolContentMainstream = ({
  signedInUserId,
  setSignedInUserId,
  signedInUserEmail,
  setSignedInUserEmail,
  userMeta,
  setUserMeta,
  navWidth,
  setNavWidth,
  signedInUserType,
  setSignedInUserType,
}: SignedInUserPropsTherapist) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dbLoad, setDbLoad] = useState(0);
  const [dbStudents, setDbStudents] = useState(0);

  const [accessLevel, setAccessLevel] = useState('none');
  const [bios1, setBios1] = useState(0);
  const [bios2, setBios2] = useState(0);
  const [bios3, setBios3] = useState(0);
  const [myPop, setMyPop] = useState<any>(0);
  const [allSavedResources, setAllSavedResources] = useState<any>([]);

  const [adsList, setAdsList] = useState<any>([]);
  const [schoolsStudents, setSchoolsStudents] = useState<any>([]);
  const [schoolsTherapists, setSchoolsTherapists] = useState<any>([]);
  const accNumber = localStorage.getItem('accountNumber');
  const [schoolFilter, setSchoolFilter] = useState(signedInUserId);
  const userAccount = useContext(MyContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const schoolType = queryParameters.get('schoolType');
  const [sorter, setSorter] = useBoolean();
  const navigate = useNavigate();

  function loginMsSchoolAndSetUser(firebaseUser: any, data: any) {
    // getDataFromEndPoint(
    //   'https://unlockinglanguage.ninoxdb.com/v1/teams/axxHFr6ScmhSNogbC/databases/wgwsf2vlwfr9/tables/A'
    // ).then((data: any) => console.log('SENCO CHECK', data));

    setSignedInUserEmail(firebaseUser?.email!);
    if (userAccount.userAccountType === 'school') {
      if (firebaseUser?.email !== undefined) {
        const endPointSubscribersMs = `${process.env.REACT_APP_MS_SCHOOLS_TABLE_A_FILTER}?filters={"C1":"${firebaseUser?.email}"}`;
        getUserNinoxDataByEmail(endPointSubscribersMs, bearerToken).then((data: any) => {
          setSignedInUserId(data?._id);
          localStorage.setItem('accountNumber', data?._id);

          setSchoolFilter(data?._id);
          console.log('the id', data?._id);
          const schoolID = data?._id;
          const accountType = localStorage.getItem('accountType');
          const schoolType = accountType === 'Mainstream' ? 'Mainstream School' : 'SEN Schools';

          // console.log('SCHOOL ID', Number(data?._id));
          const msQueryEP = process.env.REACT_APP_MS_QUERY_EP;
          const baseMsAllStudentsQuery = `let school_id := ${Number(data?._id)};
          let students := (select Students)['Mainstream School'.Id = school_id];
          concat(students)`;
          const msSchoolStudents = process.env.REACT_APP_UL_STUDENTS_MAINSTREAM;
          getAllRowsByEncodedQuery(msQueryEP, baseMsAllStudentsQuery).then((data: any) => {
            const allStudents = data.split(',');
            data?.length > 0 &&
              getMultipleApiData(msSchoolStudents!, bearerToken!, allStudents).then(
                (myData: any) => {
                  setSchoolsStudents(
                    myData
                      .sort((a: any, b: any) =>
                        sorter === true
                          ? b?.fields['Student/Patient Name'].localeCompare(
                              a?.fields['Student/Patient Name']
                            )
                          : a?.fields['Student/Patient Name'].localeCompare(
                              b?.fields['Student/Patient Name']
                            )
                      )
                      .filter(
                        (i: any) =>
                          i.fields['End of Episode/ Discharge Code/ Description'] === undefined ||
                          i.fields['End of Episode/ Discharge Code/ Description'] === null ||
                          i.fields['End of Episode/ Discharge Code/ Description'] === ''
                      )
                  );
                }
              );
            // getCaseNoteCount(allStudents);
          });
          // getAllRowsById(studentsFromSchool).then((data: any) => {
          //   const myData = data;

          //   setSchoolsStudents(
          //     myData
          //       .filter((i: any) => i?.fields['Mainstream School'] === Number(schoolID))
          //       .sort((a: any, b: any) =>
          //         sorter === true
          //           ? b?.fields['Student/Patient Name'].localeCompare(
          //               a?.fields['Student/Patient Name']
          //             )
          //           : a?.fields['Student/Patient Name'].localeCompare(
          //               b?.fields['Student/Patient Name']
          //             )
          //       )
          //   );
          // });

          ninoxTableGetRow(
            regularSchoolsEP!,
            data?._id,
            process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
          ).then((data: any) => {
            setUserMeta({
              firstName: data?.fields['SENCO'],
              accountType: 'School',
            });
            localStorage.setItem('firstName', data?.fields['SENCO']);
            localStorage.setItem('contractedDays', data?.fields['Annual (full) days']);
            localStorage.setItem('accessLevel', data?.fields['Access level']);
            localStorage.setItem('schoolName', data?.fields['School Name']);
            localStorage.setItem('Therapist 1', data?.fields['Therapist 1']);
            localStorage.setItem('Therapist 2', data?.fields['Therapist 2']);
            localStorage.setItem('Therapist 3', data?.fields['Therapist 3']);
            localStorage.setItem('accountType', 'Mainstream');
            localStorage.setItem('invoices', data?.fields['Invoices']);
            localStorage.setItem('messagesStart', data?.fields['Messages start']);
            localStorage.setItem('accountEmail', data?.fields['SENCO Email']);

            const clientsQueryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
            const txEP = process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!;
            const txIdOne = `number(last((select 'Our Therapists')['First Name' + " " + Surname = "${data?.fields['Therapist 1']}"].Id))`;
            setMyPop(1);
            getAllRowsByEncodedQuery(clientsQueryEP, txIdOne).then((data: number) => {
              console.log('txIdOne', data);

              data &&
                ninoxTableGetRow(
                  process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                  data,
                  process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                ).then((txdata: any) => {
                  localStorage.setItem('TherapistBio1', txdata?.fields['Bio']);
                  localStorage.setItem('ShortBio1', txdata?.fields['Short Bio']);
                  txdata &&
                    ninoxTableGetRow(
                      process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                      data,
                      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                    ).then((txdata: any) => {
                      // console.log('Setting image');

                      // localStorage.setItem(
                      //   'Headshot',
                      //   process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                      //     data?.fields['Current Therapist Assigned'] +
                      //     '/files/' +
                      //     txdata?.fields['Headshot']
                      // );

                      localStorage.setItem(
                        'Headshot1',
                        process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                          txdata.id +
                          '/files/' +
                          txdata?.fields['Headshot']
                      );
                      // downloadFile(
                      //   bearerToken,
                      //   process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                      //     txIdOne +
                      //     '/files/' +
                      //     txdata?.fields['Headshot']
                      // );
                    });
                  setBios1(1);
                });
            });

            const txIdTwo = `number(last((select 'Our Therapists')['First Name' + " " + Surname = "${data
              ?.fields['Therapist 2']!}"].Id))`;
            getAllRowsByEncodedQuery(clientsQueryEP, txIdTwo).then((data: number) => {
              console.log('txIdTwo', data);

              data &&
                ninoxTableGetRow(
                  process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                  data,
                  process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                ).then((txdata: any) => {
                  localStorage.setItem('TherapistBio2', txdata?.fields['Bio']);
                  localStorage.setItem('ShortBio2', txdata?.fields['Short Bio']);
                  txdata &&
                    ninoxTableGetRow(
                      process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                      data,
                      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                    ).then((txdata: any) => {
                      // console.log('Setting image');
                      localStorage.setItem(
                        'Headshot2',
                        process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                          txdata.id +
                          '/files/' +
                          txdata?.fields['Headshot']
                      );
                      // downloadFile(
                      //   bearerToken,
                      //   process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                      //     txIdOne +
                      //     '/files/' +
                      //     txdata?.fields['Headshot']
                      // );
                    });
                  setBios2(1);
                });
            });

            const txIdThree = `number(last((select 'Our Therapists')['First Name' + " " + Surname = "${data
              ?.fields['Therapist 3']!}"].Id))`;
            getAllRowsByEncodedQuery(clientsQueryEP, txIdThree).then((data: any) => {
              console.log('txIdThree', data);

              data &&
                ninoxTableGetRow(
                  process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                  data,
                  process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                ).then((txdata: any) => {
                  localStorage.setItem('TherapistBio3', txdata?.fields['Bio']);
                  localStorage.setItem('ShortBio3', txdata?.fields['Short Bio']);
                  txdata &&
                    ninoxTableGetRow(
                      process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS!,
                      data,
                      process.env.REACT_APP_NINOX_API_BEARER_TOKEN!
                    ).then((txdata: any) => {
                      // console.log('Setting image');
                      localStorage.setItem(
                        'Headshot3',
                        process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                          txdata.id +
                          '/files/' +
                          txdata?.fields['Headshot']
                      );
                      // downloadFile(
                      //   bearerToken,
                      //   process.env.REACT_APP_UL_NINOX_TABLES_THERAPISTS! +
                      //     txIdOne +
                      //     '/files/' +
                      //     txdata?.fields['Headshot']
                      // );
                    });
                  setBios3(1);
                });
            });

            const savedResourcesQueryOnLoad = `let item := (select 'Saved / liked resources')[lower(text(userEmail)) = lower("${String(
              firebaseUser?.email
            )}")];
            let array := [""];
            for i in item do
              let userEmail := i.userEmail;
              let userId := i.userId;
              let resourceId := i.resourceId;
              let saveStatus := i.saveStatus;
              let fileExt := i.fileExt;
              let fileTitle := i.fileTitle;
              array := array(array, ["{'id':'" + i.Id + "','createdAt':'" + i.'Created on' + "','userEmail':'" +
              userEmail +
              "','fields':{'userId':'" +
              userId +
              "','resourceId':'" +
              resourceId +
              "','saveStatus':'" +
              saveStatus +
              "','fileExt':'" +
              fileExt +
              "','fileTitle':'" +
              fileTitle +
              "'}}"])
            end;
            join(slice(array, 1, length(array)), ",")`;

            getAllRowsByEncodedQuery(clientsQueryEP, savedResourcesQueryOnLoad).then(
              (data: any) => {
                const validJsonString = `[${data.replace(/'/g, '"')}]`;
                const array = JSON.parse(validJsonString);
                setAllSavedResources(array);
              }
            );
          });
          setDbStudents(data?._id);

          // getAllRowsById(studentsFromSchool).then((data) => {
          //   const myData = data;
          //   setCaseNotes(
          //     myData
          //       .filter((i: any) => i?.fields['Mainstream School'] === Number(data?._id))
          //       .sort((a: any, b: any) =>
          //         sorter === true
          //           ? b?.fields['Student/Patient Name'].localeCompare(
          //               a?.fields['Student/Patient Name']
          //             )
          //           : a?.fields['Student/Patient Name'].localeCompare(
          //               b?.fields['Student/Patient Name']
          //             )
          //       )
          //   );
          // });

          // getAllRowsById(adsEP).then((data) => {
          //   const ads = data;
          //   setAdsList(ads[0]?.fields);
          // });

          // const accessLevel = localStorage.getItem('accessLevel');
          // getAllRowsById(adsEP).then((data: any) => {
          //   const ads = data;
          //   // console.log(accessLevel);
          //   // console.log(
          //   //   ads.filter(
          //   //     (i: any) =>
          //   //       String(i.fields['subscription_level']) ===
          //   //         String(localStorage.getItem('accessLevel')) &&
          //   //       String(i.fields['ad_location']) === String(localStorage.getItem('accountType'))
          //   //   )
          //   // );
          //   setAdsList(
          //     ads.filter(
          //       (i: any) =>
          //         i.fields['subscription_level'] == localStorage.getItem('accessLevel') &&
          //         i.fields['ad_location'] == localStorage.getItem('accountType')
          //     )
          //   );
          // });

          const queryEP = process.env.REACT_APP_CLIENTS_QUERY_EP;
          const adsQuery = `let item := (select Ads)[text(subscription_level) = "${String(
            localStorage.getItem('accessLevel')
          )}" and ad_location = "${String(localStorage.getItem('accountType'))}"];
          let array := [""];
          for i in item do
            let id := number(i.Id);
            let ad_title := i.ad_title;
            let ad_image_url := i.ad_image_url;
            let target_url := i.target_url;
            let ad_location := i.ad_location;
            let subscription_level := i.subscription_level;
            array := array(array, ["{'id': " + id + ",'fields':{'ad_title':'" + ad_title + "','ad_image_url':'" +
                ad_image_url +
                "','target_url':'" +
                target_url +
                "','ad_location':'" +
                ad_location +
                "','subscription_level':'" +
                subscription_level +
                "'}}"])
          end;
           join(slice(array, 1, length(array)), ",")`;
          getAllRowsByEncodedQuery(queryEP, adsQuery).then((data: any) => {
            const validJsonString = `[${data.replace(/'/g, '"')}]`;
            const currentUsersAds = JSON.parse(validJsonString);
            setAdsList(currentUsersAds);
          });
        });
      }
    }
  }
  function checkOutAndLogout() {
    signOut();
    navigate('/login-error');
  }

  const AdCarousel = () => {
    const getConfigurableProps = () => ({
      showArrows: false,
      showStatus: false,
      showIndicators: true,
      infiniteLoop: true,
      showThumbs: false,
      useKeyboardArrows: true,
      autoPlay: true,
      stopOnHover: true,
      swipeable: true,
      dynamicHeight: false,
      emulateTouch: true,
      autoFocus: false,
      // thumbWidth: 100,
      selectedItem: 1,
      interval: 3000,
      transitionTime: 500,
      swipeScrollTolerance: 5,
      ariaLabel: 'Unlocking Language Advert',
    });

    return (
      <Carousel {...getConfigurableProps()}>
        {adsList.length <= 0
          ? 'No ads to show'
          : adsList?.map((i: any, k: any) => (
              <Link target="_blank" href={i.fields.target_url}>
                <Box key={k} display={'flex'} flexDirection={'column'} alignItems={'center'}>
                  <Box
                    backgroundImage={`https://drive.google.com/thumbnail?id=${i.fields?.ad_image_url}&sz=w400`}
                    backgroundSize={'contain'}
                    backgroundPosition={'center'}
                    backgroundRepeat={'no-repeat'}
                    width={'400px'}
                    height={'600px'}
                  />
                </Box>
              </Link>
            ))}
      </Carousel>
    );
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      setDbLoad(1);
      const endPointSubscribersMs = `${process.env.REACT_APP_MS_SCHOOLS_TABLE_A_FILTER}?filters={"C1":"${firebaseUser?.email}"}`;
      getUserNinoxDataByEmail(endPointSubscribersMs, bearerToken)
        .then((data: any) =>
          data === undefined ? checkOutAndLogout() : loginMsSchoolAndSetUser(firebaseUser, data)
        )
        .catch((error) => console.log(error));
    });
    return unsubscribe;
  }, []);

  useEffect(() => {}, [schoolFilter]);
  useEffect(() => {}, [bios1, bios2, bios3]);
  // useEffect(() => {}, [dbStudents]);

  return (
    <Box height={'100vh'} overflowY={'scroll'}>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        maxW="750xl"
        columns={{ base: 2, md: 2 }}
        mb={'-10'}
        mt={'-5'}
      >
        <Heading textAlign={'left'} as="h4" size="xl">
          {localStorage.getItem('schoolName')}
        </Heading>
        {/* <Box textAlign={'right'}>
          Welcome back,
          <Heading textAlign={'right'} as="h4" size="md" mt={'0'}>
            {localStorage.getItem('firstName')}
          </Heading>
        </Box> */}

        <HStack justifyContent={'right'}>
          <Box textAlign={'left'} ml={3} mr={5}>
            Welcome back
            <Heading textAlign={'right'} as="h4" size="md" mt={'0'}>
              {localStorage.getItem('firstName')} {localStorage.getItem('lastName')}{' '}
            </Heading>
          </Box>
          <HStack>
            {localStorage.getItem('notificationsCount') === undefined ||
            Number(localStorage.getItem('notificationsCount')) === 0 ? (
              <Link href={'messages'}>
                <HStack>
                  <FaRegBell size={30} />
                  <Text fontSize={20}>(0)</Text>
                </HStack>
              </Link>
            ) : (
              <Link href={'messages'}>
                <HStack>
                  <FaBell size={30} />
                  <Text fontSize={20}>({localStorage.getItem('notificationsCount')})</Text>
                </HStack>
              </Link>
            )}
          </HStack>
        </HStack>
      </SimpleGrid>
      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        p="10"
        // maxW="50xl"
        columns={[1, 2, 4, 4]}
        spacing={{ base: 5, lg: 8 }}
      >
        <Box
          background={'white'}
          borderRadius={10}
          padding={5}
          alignContent={'center'}
          textAlign={'center'}
          height={230}
        >
          <Text fontWeight={'bold'} mb={5}>
            Access level
          </Text>

          <CircularProgress value={100} size="140px" color="brand.100">
            <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
              {localStorage.getItem('accessLevel')}
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
        <Box
          background={'white'}
          borderRadius={10}
          padding={5}
          alignContent={'center'}
          textAlign={'center'}
          height={230}
        >
          <Text fontWeight={'bold'} mb={5}>
            Total students
          </Text>
          <CircularProgress value={100} size="140px" color="brand.200">
            <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
              {schoolsStudents.length}
            </CircularProgressLabel>
          </CircularProgress>
        </Box>{' '}
        <Box
          background={'white'}
          borderRadius={10}
          padding={5}
          alignContent={'center'}
          textAlign={'center'}
          height={230}
        >
          <Text fontWeight={'bold'} mb={5}>
            Contracted days
          </Text>
          <CircularProgress value={100} size="140px" color="brand.300">
            <CircularProgressLabel fontSize={30} fontWeight={'bold'}>
              {localStorage.getItem('contractedDays')}
            </CircularProgressLabel>
          </CircularProgress>
        </Box>
        <Box
          background={'white'}
          borderRadius={10}
          px={5}
          alignContent={'center'}
          textAlign={'center'}
          height={230}
        >
          <Text fontWeight={'bold'} mb={5} textAlign={'center'}>
            Current therapists
          </Text>
          <Box p={0} width={'100%'}>
            <VStack alignItems={'center'} width={'100%'}>
              {localStorage.getItem('Therapist 1') !== undefined &&
                String(localStorage.getItem('Therapist 1')).length > 0 && (
                  <Link href={'therapist-profile'} width={'100%'}>
                    <Button variant={'defaultButton'} mb={2} width={'100%'}>
                      {localStorage.getItem('Therapist 1')}
                    </Button>
                  </Link>
                )}

              {localStorage.getItem('Therapist 2') !== undefined &&
                String(localStorage.getItem('Therapist 2')).length > 0 && (
                  <Link href={'therapist-profile'} width={'100%'}>
                    <Button variant={'defaultButton'} mb={2} width={'100%'}>
                      {localStorage.getItem('Therapist 2')}
                    </Button>
                  </Link>
                )}

              {localStorage.getItem('Therapist 3') !== undefined &&
                String(localStorage.getItem('Therapist 3')).length > 0 && (
                  <Link href={'therapist-profile'} width={'100%'}>
                    <Button variant={'defaultButton'} mb={2} width={'100%'}>
                      {localStorage.getItem('Therapist 3')}
                    </Button>
                  </Link>
                )}
            </VStack>
          </Box>
        </Box>
      </SimpleGrid>

      <PopularResources myPop={myPop} setMyPop={setMyPop} />

      <Flex ml={{ base: 0, md: 20 }} mt={-10} p="10">
        <Box
          bg={'white'}
          borderRadius={10}
          p={5}
          width={'33.3%'}
          height={'700'}
          ml={0}
          overflowY={'hidden'}
        >
          <Box
            bg={'white'}
            minH={'300px'}
            height={'700'}
            pb={10}
            pr={5}
            pl={5}
            borderRadius={10}
            mt={0}
          >
            <Tabs variant="soft-rounded" colorScheme="green">
              <TabList>
                <Tab fontSize={18}>Latest videos</Tab>
                <Tab fontSize={18}>Saved resources ({allSavedResources.length})</Tab>
              </TabList>
              <TabPanels pt={5}>
                <TabPanel>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    height={540}
                    overflowY={'hidden'}
                  >
                    {/* <Heading as={'h3'} size={'md'} mb={10} mt={0}>
                      Latest videos
                    </Heading> */}
                    <Box width={'100%'} overflowY={'scroll'} height={'580px'}>
                      <DashboardVideoList
                        accountType={localStorage.getItem('accountType')!}
                        accessLevel={localStorage.getItem('accessLevel')!}
                      />
                    </Box>
                  </Box>
                </TabPanel>
                <TabPanel>
                  <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'640px'}>
                    <Heading as={'h3'} size={'md'} mb={10} mt={-5}>
                      Saved resources
                    </Heading>
                    <Box bg={'white'} pl={0} height={'440px'} width={'100%'}>
                      <Stack
                        divider={<StackDivider />}
                        spacing="4"
                        height={'420px'}
                        overflowY={'scroll'}
                        overflowX={'hidden'}
                        wrap={'wrap'}
                      >
                        {allSavedResources.map((i: any, k: any) => (
                          <Link
                            href={`/resource/?res_id=${i?.fields['resourceId']}&fileExt=${i?.fields['fileExt']}`}
                            key={k}
                            textDecoration={'none'}
                            cursor={'pointer'}
                          >
                            <Box cursor={'pointer'}>
                              <Heading size="xs" textTransform="uppercase">
                                <HStack>
                                  <BsBookmarkFill fill="black" />
                                  {/* <Text width={'100%'}>{i?.fields['fileTitle']?.slice(0, 20)}&nbsp;...</Text> */}
                                  <Text width={'100%'}>{i?.fields['fileTitle']}&nbsp;...</Text>
                                </HStack>
                              </Heading>
                              <Text pt="2" fontSize="sm" pl={6}>
                                Date saved: {moment(new Date(i.createdAt)).format('DD/MM/YYYY')}
                              </Text>
                            </Box>
                          </Link>
                        ))}
                        {/* {JSON.stringify(allSavedResources)} */}
                        {allSavedResources?.length === 0 && <Text>No saved resources</Text>}
                      </Stack>
                    </Box>
                  </Box>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
        <Box pl={5} width={'33.3%'} justifyContent={'right'}>
          <Box
            bg={'white'}
            minH={'300px'}
            height={'700'}
            pt={10}
            pb={10}
            pr={5}
            pl={5}
            borderRadius={10}
          >
            <Box display={'flex'} flexDirection={'column'} width={'100%'} height={'640px'}>
              <Heading as={'h3'} size={'md'} mb={10} mt={-5}>
                More information
              </Heading>
              <AdCarousel />
            </Box>
          </Box>
        </Box>

        <Box width={'33.3%'} bg="white" borderRadius={10} p={5} ml={5}>
          <Heading as={'h3'} size={'md'} mb={10} mt={0}>
            Messages
          </Heading>
          <Box bg={'white'}>
            <Stack
              divider={<StackDivider />}
              spacing="4"
              height={'590px'}
              overflowY={'scroll'}
              width={'100%'}
            >
              <MessagesContentUsersDashboard
                signedInUserId={signedInUserId}
                signedInUserEmail={signedInUserEmail}
                userMeta={userMeta}
                navWidth={navWidth}
                setNavWidth={setNavWidth}
                signedInUserType={signedInUserType}
                setSignedInUserType={setSignedInUserType}
              />
            </Stack>
          </Box>
        </Box>
      </Flex>

      <SimpleGrid
        ml={{ base: 0, md: 20 }}
        mt={-10}
        p="10"
        columns={[1, 1, 1, 1]}
        spacing={{ base: 5, lg: 8 }}
      >
        <Flex color="white">
          <Box w="100%" bg="white" color={'black'} p={5} borderRadius={10}>
            <BookingsContentStudentsDashboard
              schoolsStudents={schoolsStudents}
              setSchoolsStudents={setSchoolsStudents}
              signedInUserId={dbStudents}
              setSignedInUserId={setSignedInUserId}
              signedInUserEmail={signedInUserEmail}
              setSignedInUserEmail={setSignedInUserEmail}
              userMeta={userMeta}
              setUserMeta={setUserMeta}
              navWidth={navWidth}
              setNavWidth={setNavWidth}
              signedInUserType={signedInUserType}
              setSignedInUserType={setSignedInUserType}
            />
          </Box>
        </Flex>
      </SimpleGrid>
    </Box>
  );
};

export default DashboardSchoolContentMainstream;
