import { Box, Button, Divider, Link, Text } from '@chakra-ui/react';
import ReactApexChart from 'react-apexcharts';
interface DashboardDaysProps {
  txRadialDataMsFinal: [] | any;
  txRadialDataSsFinal: [] | any;
}

export default function DashboardTherapistDays({
  txRadialDataMsFinal,
  txRadialDataSsFinal,
}: DashboardDaysProps) {
  const combinedSchoolDays = [...txRadialDataMsFinal, ...txRadialDataSsFinal];
  // console.log(combinedSchoolDays);
  return (
    <Box bg={'white'} width={'100%'}>
      <Box overflowY={'scroll'} bg={'white'}>
        {combinedSchoolDays &&
          combinedSchoolDays.map((i: any, k: any) => {
            // console.log(i);
            // school: item.school,
            // remDays: data,
            // annualDays: item.annualFullDays,
            // schoolName: item.schoolName,
            // schoolType: item.schoolType,

            const radialHeight = 250;
            let optionsNew: any = {
              series: [(i.completedDays / i.annualDays) * 100],
              chart: {
                height: radialHeight,
                type: 'radialBar',
              },
              labels: [i.schoolName],
              colors: ['#40A83D', '#D6D944'],
              stroke: {
                curve: 'smooth',
                colors: ['transparent'],
              },
              plotOptions: {
                radialBar: {
                  hollow: {
                    size: '70%',
                  },
                  fill: {
                    type: 'gradient',
                    gradient: {
                      shade: 'dark',
                      type: 'vertical',
                      gradientToColors: ['#87D4F9'],
                      // stops: [0, 360],
                    },
                  },
                  startAngle: 0,
                  endAngle: 360,
                  dataLabels: {
                    show: true,

                    name: {
                      fontSize: 8,
                      color: 'black',
                      offsetY: 130,
                      textAlign: 'center',
                      display: 'inline-block',
                      wordBreak: 'break-word',
                    },
                    value: {
                      offsetY: 0,
                      fontSize: '14px',
                      fontWeight: 'bold',
                      color: undefined,
                      formatter: function () {
                        return i.remDays + `\n days remaining`;
                      },
                    },
                  },
                },
              },
            };

            return (
              <Box
                key={k}
                style={{ wordBreak: 'break-word', display: 'inline-block' }}
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                textAlign={'center'}
                cursor={'pointer'}
                bg={'white'}
              >
                <Text size={'sm'} fontWeight={'bold'}>
                  {i.schoolName}
                </Text>
                <Text fontSize={12} textTransform={'uppercase'}>
                  {i.schoolType === 'Ms' ? 'Mainstream' : 'SEN'}
                </Text>
                <ReactApexChart
                  options={optionsNew}
                  series={optionsNew.series}
                  type={'radialBar'}
                  height={radialHeight}
                />
                <Text
                  fontSize={12}
                  textTransform={'uppercase'}
                  fontWeight={'bold'}
                  color={'gray.400'}
                  textAlign={'center'}
                >
                  Completed Days: {i.completedDays}
                </Text>
                <Text
                  fontSize={12}
                  textTransform={'uppercase'}
                  fontWeight={'bold'}
                  color={'gray.400'}
                  textAlign={'center'}
                >
                  Total Days: {i.annualDays}
                </Text>
                <Link
                  href={`schools?schoolID=${i.school}&schoolType=${
                    i.schoolType === 'Ms' ? 'RegularSchool' : 'SENSchool'
                  }`}
                >
                  <Button mt={5} variant={'defaultButton'}>
                    {' '}
                    Go to Caseload
                  </Button>
                </Link>
                <Divider mt={10} borderBottom={'5px solid brand.400'} mb={5} />
              </Box>
            );
          })}
      </Box>
    </Box>
  );
}
