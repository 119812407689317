import {
  Box,
  Heading,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Link,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import React, { ReactNode, useEffect, useState } from 'react';
import { userSessionDataProps } from '../interfaces/userSessionDataProps';
import getMultipleApiData from '../utils/getMultipleApiData';
import getInvoiceById from '../utils/getInvoiceById';
import generateXeroAccessToken from '../utils/generateXeroAccessToken';
import { SignedInUserProps } from '../interfaces/SignedInUserProps';
import { UserMeta } from '../interfaces/UserMeta';
import { Base64 } from 'js-base64';
import { json } from 'stream/consumers';
import { link } from 'fs';
interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
  color: string;
}
type StateContextType = {
  userDetails: userSessionDataProps | null;
  setUserDetails: React.Dispatch<React.SetStateAction<userSessionDataProps>>;
};
type TodoContextType = {
  userId: number;
  setUserId: (userId: number) => void;
};

// https://login.xero.com/identity/connect/authorize?response_type=code&client_id=YOURCLIENTID&redirect_uri=YOURREDIRECTURI&scope=openid profile email accounting.transactions&state=123

// const xeroTokenEndpoint = 'https://identity.xero.com/connect/token';
// const xeroTokenEndpoint = 'https://api.xero.com/connections';
// const xeroTokenEndpoint = 'https://login.xero.com/identity/connect/authorize';
// const xeroTokenEndpoint = 'https://identity.xero.com/connect/token';

const newEP =
  'GET https://.../Invoices?IDs=220ddca8-3144-4085-9a88-2d72c5133734,88192a99-cbc5-4a66-bf1a-2f9fea2d36d0';
const xeroClientId = '3208D55D4ECC47F2A1B5F5C63CD0FA42';

const designppl_client_id = '5AE98389CA9F454AAAAD2D115D365720';
const designppl_client_secret = '1hHxdCoXHl93Wwm4Ba2FlO1jHzeBIo_LTBC62O35mc61KarX';
const designppl_scopes = 'accounting.transactions';

const xeroClientSecret = 'phEesOfnQtlAI0_vY6fptYqkoHG1lPqyI5M6qSt2efWyyaAv';
const xeroScopes = 'accounting.transactions';
// Authorization: 'Bearer ' +
//   {
//     access_token:
//       'eyJhbGciOiJSUzI1NiIsImtpZCI6IjFDQUY4RTY2NzcyRDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJISy1PWm5jdGJjQW8xbkp2MENZVmdWY09fQmsifQ.eyJuYmYiOjE2OTI3MjExMDUsImV4cCI6MTY5MjcyMjkwNSwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS54ZXJvLmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHkueGVyby5jb20vcmVzb3VyY2VzIiwiY2xpZW50X2lkIjoiMkQ2Q0M0NzFGRDY1NDg0RTk0Q0QxRjRERTQxMzY0RDkiLCJ4ZXJvX3VzZXJpZCI6IjQ0NzVjYzllLTFiMjAtNGUwZC1hMDBiLTVmNTUxOTIzM2QxMSIsImp0aSI6IkYwMzc2QzZDRTAwMTRBOThBNDUwMzAzMTIwNDVGMDA3IiwiYXV0aGVudGljYXRpb25fZXZlbnRfaWQiOiJkMzZiODFlMS0xNjg2LTRkOTQtYmUzYi0yMTBmODFjZmRiYmUiLCJzY29wZSI6WyJhY2NvdW50aW5nLnRyYW5zYWN0aW9ucyIsImFjY291bnRpbmcudHJhbnNhY3Rpb25zLnJlYWQiXX0.ZSuhzbRaOJHM_yVY5OGtXKv6qRr8eL1R7eAC9Wk_O2a_VSsV7McL4CmFT1hjc4c2BjxuI1mW9sRV3B32Q_YrnP4saZMEy-jMPyoWP_7QxVJHDkwgFqJrLfp82SzA5rLzNoVpxv6DHeCMDkq-veivzTTbvuni627clvEWULMR40eY5tAfnzREymt5kQwZKDiOBY7G8c4eMgkhqj05-HgeohQiDdWF97eOsev2_4ASDjrc36cR7oreSvyVe-fZo6IifJ8EhBbbkbHYwLFFTWQSNosJ21m4i6CT81OQsQSvzt4FRvg2z-Zma0Z5PAs--eBlVqDIvTHQPYV7PTmKBDnnMw',
//     expires_in: 1800,
//     token_type: 'Bearer',
//     scope: 'accounting.transactions accounting.transactions.read',
//   };
// Accept: application / json;]

// Notes:
// 1. We are able to generate the access token on local
// 2. We are NOT able to generate the access token or fetch invoices on the deployed link
// 3. However, we are able to access the invoices list and fetch the web invoice link from Postman
// 4. The issue seems to be caused by browser preflight requests - which a non browser or node api or node cloud function could resolve
// WHAT WE NEED
// https://api.xero.com/api.xro/2.0/Invoices/bcd749bc-75ae-47e1-ba83-53443fac2635/OnlineInvoice

const myAccessToken = {
  access_token:
    'eyJhbGciOiJSUzI1NiIsImtpZCI6IjFDQUY4RTY2NzcyRDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJISy1PWm5jdGJjQW8xbkp2MENZVmdWY09fQmsifQ.eyJuYmYiOjE2OTI3MjExMDUsImV4cCI6MTY5MjcyMjkwNSwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS54ZXJvLmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHkueGVyby5jb20vcmVzb3VyY2VzIiwiY2xpZW50X2lkIjoiMkQ2Q0M0NzFGRDY1NDg0RTk0Q0QxRjRERTQxMzY0RDkiLCJ4ZXJvX3VzZXJpZCI6IjQ0NzVjYzllLTFiMjAtNGUwZC1hMDBiLTVmNTUxOTIzM2QxMSIsImp0aSI6IkYwMzc2QzZDRTAwMTRBOThBNDUwMzAzMTIwNDVGMDA3IiwiYXV0aGVudGljYXRpb25fZXZlbnRfaWQiOiJkMzZiODFlMS0xNjg2LTRkOTQtYmUzYi0yMTBmODFjZmRiYmUiLCJzY29wZSI6WyJhY2NvdW50aW5nLnRyYW5zYWN0aW9ucyIsImFjY291bnRpbmcudHJhbnNhY3Rpb25zLnJlYWQiXX0.ZSuhzbRaOJHM_yVY5OGtXKv6qRr8eL1R7eAC9Wk_O2a_VSsV7McL4CmFT1hjc4c2BjxuI1mW9sRV3B32Q_YrnP4saZMEy-jMPyoWP_7QxVJHDkwgFqJrLfp82SzA5rLzNoVpxv6DHeCMDkq-veivzTTbvuni627clvEWULMR40eY5tAfnzREymt5kQwZKDiOBY7G8c4eMgkhqj05-HgeohQiDdWF97eOsev2_4ASDjrc36cR7oreSvyVe-fZo6IifJ8EhBbbkbHYwLFFTWQSNosJ21m4i6CT81OQsQSvzt4FRvg2z-Zma0Z5PAs--eBlVqDIvTHQPYV7PTmKBDnnMw',
  expires_in: 1800,
  token_type: 'Bearer',
  scope: 'accounting.transactions accounting.transactions.read',
};

async function getAllInvoicesNow(endPoint: string | any, bearerToken: string) {
  const response = await fetch(endPoint, {
    headers: {
      'Access-Control-Allow-Methods': 'POST',
      Authorization: bearerToken,
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      grant_type: 'client_credentials',
      scope: designppl_scopes,
    },
  });
  const myInvoices = await response.json();
  console.log(myInvoices);

  return myInvoices;
}
async function getTheInvoices(theEp: any, theToken: any) {
  const response = await fetch(theEp, {
    method: 'GET',
    headers: {
      // Authorization: theToken,
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
      // grant_type: 'client_credentials',
      // scope: designppl_scopes,
    },
  })
    .then((response) => {
      response.json();
    })
    .then((data) => console.log(data))
    .catch((error) => {
      console.error('Error:', error);
    });
  // const myInvoices = await response.json();
  // console.log(myInvoices);
  return response;
}
// const theToken = 'Bearer ' + myAccessToken.access_token;

// POST https://identity.xero.com/connect/token

// authorization: "Basic " + base64encode(client_id + ":" + client_secret)
// Content-Type: application/x-www-form-urlencoded

// grant_type=client_credentials
// scope={scopes}
const xeroTokenEndpoint = 'https://identity.xero.com/connect/token';
const invoiceEP = 'https://api.xero.com/api.xro/2.0/Invoices';

const the_client_id = '2D6CC471FD65484E94CD1F4DE41364D9';
const the_client_secret = 'HKuoWFqoX8YDrfqm5R0M37vu16kpa90CI3EkzhWstCrd1ByC';
const the_scopes = 'accounting.transactions';

const theBasicKeyForAccessToken = 'Basic ' + Base64.encode(the_client_id + ':' + the_client_secret);

// GET https://identity.xero.com/connect/token
// Authorization: "Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjFDQUY4RTY2NzcyRDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJISy1PWm5jdGJjQW8xbkp2MENZVmdWY09fQmsifQ.eyJuYmYiOjE2OTI3OTU4NTAsImV4cCI6MTY5Mjc5NzY1MCwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS54ZXJvLmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHkueGVyby5jb20vcmVzb3VyY2VzIiwiY2xpZW50X2lkIjoiMkQ2Q0M0NzFGRDY1NDg0RTk0Q0QxRjRERTQxMzY0RDkiLCJ4ZXJvX3VzZXJpZCI6IjQ0NzVjYzllLTFiMjAtNGUwZC1hMDBiLTVmNTUxOTIzM2QxMSIsImp0aSI6IjMzNUUwMDA3RDVFMTZCNjU5MjA1REVFNDJDNzEwNkU1IiwiYXV0aGVudGljYXRpb25fZXZlbnRfaWQiOiIyMWQzZGEwOC00ZmRkLTQyYzgtOTM1ZC0xZTM0Y2Q5ZTVkMzMiLCJzY29wZSI6WyJhY2NvdW50aW5nLnRyYW5zYWN0aW9ucyIsImFjY291bnRpbmcudHJhbnNhY3Rpb25zLnJlYWQiXX0.O9FGaVoRK8BoZ9mFJiExgSfhzRpKkY0jhcGOsuaA0n_6OZAdFxgDR0yZhfPRBl_NY95iwKkq4APitup0kJYyK7g-rL5_UnbcpU57IzQq8LlMkK_ZKMzDKvAvPDKyyAOtWNCRBPPmraOzk3dsFReUmutycNVqWMn9ulqt3GNnqbTDb0FjRZVxjSJFjyB2oS0NsLpZ0YfXvwyGLJYFXwPoXuuNHgXvZL1kmbrhc_XSEeo_fDV7E_MPf7nIOauZivxnpqsyZZUzmk4_pSSfo9RLaY3o-igbZMON-fnfaDP9_qqVpX47L9zFN63Y7pVFSkJ4_nbYD6n0S7A2ztE58PCXBw"
// Accept: 'application/json'

function getInvoices(endPoint: string, accessToken: string, tokenType: string) {
  const myData = fetch(endPoint, {
    headers: {
      Authorization: tokenType + ' ' + accessToken,
      'Content-Type': 'application/json',
    },
  })
    .then((response: any) => response.json())
    .then((data: any) => console.log('INVOICE DATA: ', data));
  console.log('Token used: ' + tokenType + ' ' + accessToken);
  return myData;
}

async function createAccessToken(endPoint: string, bearerToken: string) {
  const response = await fetch(endPoint, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: bearerToken,
    },
    body: new URLSearchParams({
      grant_type: 'client_credentials',
      scope: 'accounting.transactions accounting.transactions.read',
    }),
  });
  const TokenData = await response.json();
  // console.log('We go the access_token: ');
  // console.log(TokenData);
  // console.log('access_token: ' + TokenData.access_token);
  // console.log('token_type: ' + TokenData.token_type);

  const invoiceEP = 'https://api.xero.com/api.xro/2.0/Invoices';
  const swapiApi = 'https://swapi.dev/api/people';
  const singleInvoice = 'https://api.xero.com/api.xro/2.0/Invoices/INV-0001';
  // getInvoices(singleInvoice, TokenData.access_token, TokenData.token_type);
  console.log('TokenData:', TokenData);
  return TokenData;
}
// This token generator works
// createAccessToken(xeroTokenEndpoint, theBasicKeyForAccessToken);

// getMyInvoice(swapiApi, theToken);
// getMyInvoice(singleInvoice, theToken);
// getTheInvoices(swapiApi, theToken).then((data) => console.log('INVOICES DATA: ', data));

// POST https://identity.xero.com/connect/token
// authorization: "Basic " + base64encode(client_id + ":" + client_secret)
// Content-Type: application/x-www-form-urlencoded
// grant_type=client_credentials
// scope={scopes}

// curl --request POST \
// --url 'https://identity.xero.com/connect/token' \
// --header 'content-type: application/x-www-form-urlencoded' \
// --data grant_type=client_credentials \
// --data client_id=2D6CC471FD65484E94CD1F4DE41364D9 \
// --data client_secret=HKuoWFqoX8YDrfqm5R0M37vu16kpa90CI3EkzhWstCrd1ByC \

// curl --request GET \
// --url 'https://identity.xero.com/connect/token' \
// --header 'content-type: application/x-www-form-urlencoded' \
// --data grant_type=client_credentials \
// --data client_id=2D6CC471FD65484E94CD1F4DE41364D9 \
// --data client_secret=HKuoWFqoX8YDrfqm5R0M37vu16kpa90CI3EkzhWstCrd1ByC \

//   GET https://api.xero.com/api.xro/2.0/Invoices/INV-0001
// Authorization: "Bearer " + {
//   access_token:
//     'eyJhbGciOiJSUzI1NiIsImtpZCI6IjFDQUY4RTY2NzcyRDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJISy1PWm5jdGJjQW8xbkp2MENZVmdWY09fQmsifQ.eyJuYmYiOjE2OTI3MjExMDUsImV4cCI6MTY5MjcyMjkwNSwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS54ZXJvLmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHkueGVyby5jb20vcmVzb3VyY2VzIiwiY2xpZW50X2lkIjoiMkQ2Q0M0NzFGRDY1NDg0RTk0Q0QxRjRERTQxMzY0RDkiLCJ4ZXJvX3VzZXJpZCI6IjQ0NzVjYzllLTFiMjAtNGUwZC1hMDBiLTVmNTUxOTIzM2QxMSIsImp0aSI6IkYwMzc2QzZDRTAwMTRBOThBNDUwMzAzMTIwNDVGMDA3IiwiYXV0aGVudGljYXRpb25fZXZlbnRfaWQiOiJkMzZiODFlMS0xNjg2LTRkOTQtYmUzYi0yMTBmODFjZmRiYmUiLCJzY29wZSI6WyJhY2NvdW50aW5nLnRyYW5zYWN0aW9ucyIsImFjY291bnRpbmcudHJhbnNhY3Rpb25zLnJlYWQiXX0.ZSuhzbRaOJHM_yVY5OGtXKv6qRr8eL1R7eAC9Wk_O2a_VSsV7McL4CmFT1hjc4c2BjxuI1mW9sRV3B32Q_YrnP4saZMEy-jMPyoWP_7QxVJHDkwgFqJrLfp82SzA5rLzNoVpxv6DHeCMDkq-veivzTTbvuni627clvEWULMR40eY5tAfnzREymt5kQwZKDiOBY7G8c4eMgkhqj05-HgeohQiDdWF97eOsev2_4ASDjrc36cR7oreSvyVe-fZo6IifJ8EhBbbkbHYwLFFTWQSNosJ21m4i6CT81OQsQSvzt4FRvg2z-Zma0Z5PAs--eBlVqDIvTHQPYV7PTmKBDnnMw',
//   expires_in: 1800,
//   token_type: 'Bearer',
//   scope: 'accounting.transactions accounting.transactions.read',
// }
// Accept: application/json

const my_client_id = '2D6CC471FD65484E94CD1F4DE41364D9';
const my_client_secret = 'HKuoWFqoX8YDrfqm5R0M37vu16kpa90CI3EkzhWstCrd1ByC';

const oldcid = '5AE98389CA9F454AAAAD2D115D365720';
const oldcs = '5YIx2__5VHpjcBNaXg2ZVBwE8urN5OGyeFJLY1D0qD-M-jkz';
const myXeroBearerToken = 'Basic ' + Base64.encode(oldcid + ':' + oldcs);
const tokenEP = 'https://identity.xero.com/connect/token';
// getAllInvoicesNow(tokenEP, myXeroBearerToken).then((data) => console.log(data));

// DESIGNPPL

const InvoicesContent = ({ signedInUserId, signedInUserEmail, userMeta }: SignedInUserProps) => {
  const [rows, setRows] = useState<any>([]);
  const ulTeam = process.env.REACT_APP_UL_TEAM;
  const ulDb = process.env.REACT_APP_UL_DB;
  const ulInvoicesTable = process.env.REACT_APP_UL_INVOICES_TABLE;
  const userStats = userMeta && userMeta?.invoices;
  const invoicesStrings: any = localStorage.getItem('invoices');
  const invoicesList = invoicesStrings?.split(',').map(Number);
  useEffect(() => {
    const EP = process.env.REACT_APP_UL_INVOICES_LIST_TABLE;
    const bearerToken = process.env.REACT_APP_NINOX_API_BEARER_TOKEN;
    // GET https://api.xero.com/api.xro/2.0/Invoices/243216c5-369e-4056-ac67-05388f86dc81

    // userStats &&
    // getMultipleApiData(EP, bearerToken!, invoicesList).then((values) => {
    //   setRows(values);
    //   // console.log(values);
    // });

    // generateXeroAccessToken(
    //   xeroTokenEndpoint,
    //   designppl_client_id,
    //   designppl_client_secret,
    //   designppl_scopes
    // ).then((data) => {
    //   console.log(data);
    //   // const xeroAccessBearerToken = data.token_type + ' ' + data.access_token;
    //   // const xeroInvoicesApiEndPoint = 'https://api.xero.com/api.xro/2.0/Invoices/';
    //   // const xeroInvoiceId = 'INV-9625624';
    //   // console.log('xeroAccessBearerToken: ', xeroAccessBearerToken);
    //   // endpoint: string,
    //   // invoiceId: number,
    //   // bearerToken: any
    // });
    const mySavedToken =
      'Bearer eyJhbGciOiJSUzI1NiIsImtpZCI6IjFDQUY4RTY2NzcyRDZEQzAyOEQ2NzI2RkQwMjYxNTgxNTcwRUZDMTkiLCJ0eXAiOiJKV1QiLCJ4NXQiOiJISy1PWm5jdGJjQW8xbkp2MENZVmdWY09fQmsifQ.eyJuYmYiOjE2OTA1NjUyMTYsImV4cCI6MTY5MDU2NzAxNiwiaXNzIjoiaHR0cHM6Ly9pZGVudGl0eS54ZXJvLmNvbSIsImF1ZCI6Imh0dHBzOi8vaWRlbnRpdHkueGVyby5jb20vcmVzb3VyY2VzIiwiY2xpZW50X2lkIjoiMzIwOEQ1NUQ0RUNDNDdGMkExQjVGNUM2M0NEMEZBNDIiLCJqdGkiOiI5OTRGNzY0RjU5MzBGREMyMERBQkMyNzZBMTc2RTVEOSIsImF1dGhlbnRpY2F0aW9uX2V2ZW50X2lkIjoiN2VmNmM2YTYtNzNiMC00YTY1LWE1MWQtMDMyN2M5M2RkYThkIiwic2NvcGUiOlsiYXBwLmNvbm5lY3Rpb25zIiwibWFya2V0cGxhY2UuYmlsbGluZyJdfQ.XM8weuxOUCwRlyUE-W6VJs0bAdYMfH5PXcG97W5vtYklc4uNWHDgU1Zj2ZyriuiHNSx6704r5ypNJwyyNY9TR50pDEJEBTbkR-U1D4pLghWdUBMx6nf2wCB3Tn_xU5vqU9G29kY_vHcUfcK9vvFW7_SGJfW_uxxnY-hmFxuwdqRjc-O_6ky3UDzRm55ZM9nutClmxNCA6I-KiE5AQK_k-PzmBXsgrC-KE5hDGFNVpQALUIdk8azYtj2rtPzYme6aPQHgp5SNs15-DR5oonCZGohxuDkGoI0vrViqDyvyhcEUsnJCDKBsqbn5Ao7m03D1RqZouGz2eh6cGGyLWEXLcA';
    const invoiceId = '04ec2fc3-4f9f-493f-b371-a44a0db263b5';
    // const invoicesEndpoint = 'https://api.xero.com/api.xro/2.0/Invoices?IDs=';
    const invoicesEndpoint =
      'https://api.xero.com/api.xro/2.0/Invoice/04ec2fc3-4f9f-493f-b371-a44a0db263b5';
    // getInvoiceById(invoicesEndpoint, mySavedToken);

    // getInvoiceById(invoicesEndpoint).then((data) => console.log(data));

    // https://api.xero.com/api.xro/2.0/Invoices
  }, [userStats]);

  interface InvoiceFields {
    'Invoice Date': string;
    'Invoice Due Date': string;
    'Invoice Status': string;
    'Invoice Posted to Xero?': boolean;
    'Invoice No': string;
    Reference: string;
  }

  interface Invoices {
    createdAt: string;
    createdBy: string;
    id: string;
    modifiedAt: string;
    modifiedBy: string;
    fields: InvoiceFields;
  }
  // console.log(rows);
  return (
    <>
      <Box ml={{ base: 0, md: 60 }} p="6">
        <Box p={6} bg="white">
          <Heading pb={6}>Invoices</Heading>
          <TableContainer>
            <Table variant="simple">
              <Tabs variant="soft-rounded" colorScheme="green">
                <TabList>
                  <Tab>Outstanding</Tab>
                  <Tab>Paid</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Thead>
                      <Tr>
                        <Th>Invoice number</Th>
                        <Th>Invoice Date</Th>
                        <Th>Invoice Due Date</Th>
                        <Th>Invoice reference</Th>
                        <Th>Invoice Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {/* 
                    .filter(
                            (i: any) =>
                              i?.fields['Invoice Posted to Xero?'] === true &&
                              i?.fields['Invoice Status'] === 'Unpaid'
                          ) */}
                      {rows &&
                        rows?.map((i: any, k: any) => (
                          <Tr key={k}>
                            <Td>{i?.fields['Invoice No']}</Td>
                            <Td>{i?.fields['Invoice Date']}</Td>
                            <Td>{i?.fields['Invoice Due Date']}</Td>
                            <Td>{i?.fields['Reference']}</Td>
                            <Td>
                              {i?.fields['Invoice Status'] === 'Unpaid' ? (
                                <Link href={'/' + i.fields['Invoice No']}>
                                  <Button
                                    bg={'brand.300'}
                                    _hover={{
                                      bg: 'brand.200',
                                    }}
                                    color={'white'}
                                  >
                                    Make payment
                                  </Button>
                                </Link>
                              ) : (
                                i?.fields['Invoice Status']
                              )}
                            </Td>
                          </Tr>
                        ))}
                      {rows === undefined && <TableCaption>No invoices found</TableCaption>}
                    </Tbody>
                  </TabPanel>
                  <TabPanel>
                    <Thead>
                      <Tr>
                        <Th>Invoice number</Th>
                        <Th>Invoice Date</Th>
                        <Th>Invoice Due Date</Th>
                        <Th>Invoice reference</Th>
                        <Th>Invoice Status</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {rows &&
                        rows
                          ?.filter(
                            (i: any) =>
                              i?.fields['Invoice Posted to Xero?'] === true &&
                              i?.fields['Invoice Status'] === 'Paid'
                          )
                          .map((i: any, k: any) => (
                            <Tr key={k}>
                              <Td>{i.fields['Invoice No']}</Td>
                              <Td>{i.fields['Invoice Date']}</Td>
                              <Td>{i.fields['Invoice Due Date']}</Td>
                              <Td>{i.fields['Reference']}</Td>
                              <Td>
                                {i.fields['Invoice Status'] === 'Paid'
                                  ? 'Paid'
                                  : i.fields['Invoice Status']}
                              </Td>
                            </Tr>
                          ))}
                      {rows === undefined && <TableCaption>No invoices found</TableCaption>}
                    </Tbody>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default InvoicesContent;
