import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  FormLabel,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Divider,
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';

import moment from 'moment';
import { useState } from 'react';
import getAllRowsById from './../utils/getAllRowsById';
import ninoxTableAddRow from './../utils/ninoxTableAddRow';
import ninoxTableUpdateRow from './../utils/ninoxTableUpdateRow';

export const BookingsContentSingleTherapistUpdateNoteModal = ({
  note,
  id,
  modalState,
  theModal,
  setTheModal,
  updatedNote,
  setUpdatedNote,
  setOpenedModal,
  schoolType,
  therapistSessionNotesRegularSchoolsEP,
  therapistSessionNotesSENSchoolsEP,
  toast,
  bearerToken,
  getNotesAfterAdd,
  sessionNotes,
  setSessionNotes,
  sessionId,
  signedInUserEmail,
  noteLogs,
  setNoteLogs,
  setYearFilter,
  getAllNotesLogs,
  getAllSessionNotes,
}: any) => {
  const myNewNote = {
    Notes: note?.fields['Notes'],
  };

  const today = new Date(Date.now());
  const mainstreamSchoolNoteLogs = process.env.REACT_APP_UL_LOGS_MAINSTREAM;
  const senSchoolNoteLogs = process.env.REACT_APP_UL_LOGS_SEN;

  const queryParameters = new URLSearchParams(window.location.search);
  const schoolTypeFromUrl = queryParameters.get('schoolType');

  const NoteLogsEP =
    schoolTypeFromUrl === 'RegularSchool' ? mainstreamSchoolNoteLogs : senSchoolNoteLogs;
  const NotesEP =
    schoolType == 'RegularSchool'
      ? therapistSessionNotesRegularSchoolsEP
      : therapistSessionNotesSENSchoolsEP;
  const [newUdpatedNote, setNewUpdatedNote] = useState<string>(note?.fields['Notes']);
  const [updatedTypeOfContact, setUpdatedTypeOfContact] = useState<string>(
    note?.fields['Type of contact']
  );
  const [updatedDirectMinutes, setUpdatedDirectMinutes] = useState<string>(
    note?.fields['Direct Hours']
  );
  const [updatedIndirectMinutes, setUpdatedIndirectMinutes] = useState<string>(
    note?.fields['Indirect Hours']
  );
  const [dateAndTime, setDateAndTime] = useState<any>(today);
  const [formattedDate, setFormattedDate] = useState<any>(today);
  const [formattedTime, setFormattedTime] = useState<any>(today);
  const [formattedDateAndTime, setFormattedDateAndTime] = useState<any>('');
  const [formattedDateUpdated, setFormattedDateUpdated] = useState<any>('');
  const [formattedTimeUpdated, setFormattedTimeUpdated] = useState<any>('');
  const [dateWasUpdated, setDateWasUpdated] = useState<number>(0);
  const [formattedDateAndTimeUpdated, setFormattedDateAndTimeUpdated] = useState<any>('');
  const [dateAndTimeUpdated, setDateAndTimeUpdated] = useState<any>('');

  const [editNote, setEditNote] = useState(1);
  const theDate = moment(today).format('YYYY-MM-DD');
  const theTime = moment(today).format('HH:mm');
  const newNoteLogDefault = {
    Email: localStorage.getItem('accountEmail'),
    'Sessions + Notes': note.id,
    Text:
      updatedTypeOfContact !== 'None'
        ? newUdpatedNote +
          ' - Type of contact was: ' +
          updatedTypeOfContact +
          ' - Direct minutes updated was: ' +
          updatedDirectMinutes +
          ' - Indirect minutes updated was: ' +
          updatedIndirectMinutes +
          ' - Date and Time updated was: ' +
          formattedDateAndTimeUpdated
        : newUdpatedNote,
    accountNumber: sessionId,
    submittedAt: theDate + ' ' + theTime,
    'Session type': 'session_note ' + note.id,
  };
  const [newLog, setNewLog] = useState<any>(newNoteLogDefault);
  const updatedNoteToSend = {
    Notes: newUdpatedNote,
    'Type of contact': updatedTypeOfContact,
    'Direct Hours': updatedDirectMinutes,
    'Indirect Hours': updatedIndirectMinutes,
    Date: dateWasUpdated === 1 ? formattedDateUpdated : note?.fields['Date'],
    Time: dateWasUpdated === 1 ? formattedTimeUpdated : note?.fields['Time'],
  };
  function getHoursDifferenceForNotes(noteTime: any, currentNote: string) {
    const now = moment(new Date()); //todays date
    const end = moment(noteTime); // another date
    const duration = moment.duration(now.diff(end));
    const hours = duration.asHours().toFixed(0);
    let value = newUdpatedNote;
    // console.log('value: ', value);
    const show =
      Number(hours) <= Number(24) ? (
        <Textarea
          value={value}
          // onChange={handleInputChange}
          size="3xl"
          p={'5'}
          // onChange={(e) => setNewUpdatedNote({ ...newUdpatedNote, e.target.value })}
          mb={'5'}
          name="studentName"
          onChange={(e: any) => setNewUpdatedNote(e.target.value)}
        />
      ) : (
        <Text style={{ whiteSpace: 'pre-wrap' }}>
          {currentNote}\{/* {note?.fields['Notes']} */}
          {/* {newUdpatedNote.Notes} */}
        </Text>
      );

    return show;
  }
  function applySelectedSchoolYear(selectedYear: string) {
    console.log(selectedYear);
    let sYear = Number(selectedYear?.slice(0, 2));
    let eYear = Number(selectedYear?.slice(-2));

    const currentYearFilter = {
      startYear: sYear,
      endYear: eYear,
      yearRef: sYear + '/' + eYear,
    };
    setYearFilter(currentYearFilter);
  }
  function updateTheNote(e: any) {
    // console.log(newNoteLogDefault);
    setEditNote(1);
    setNewLog(newNoteLogDefault);
    updateNote(e);
    setTheModal(0);
    setOpenedModal(false);
    getAllNotesLogs();
    getAllSessionNotes();
    setFormattedDateAndTimeUpdated('');
  }
  function getHoursDifference(noteTime: any) {
    const now = moment(new Date()); //todays date
    const end = moment(noteTime); // another date
    const duration = moment.duration(now.diff(end));
    const hours = duration.asHours().toFixed(2);
    const showEdit =
      Number(hours) <= Number(24) ? (
        editNote === 0 ? (
          <>
            <Button
              variant={'defaultButton'}
              onClick={(e) => {
                updateTheNote(e);
              }}
            >
              Save updated note
            </Button>
            <Button
              onClick={(e) => {
                setEditNote(1);
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            variant={'defaultButton'}
            onClick={(e) => {
              setEditNote(0);
              // setNewUpdatedNote(note?.fields['Notes']);
            }}
          >
            Update note
          </Button>
        )
      ) : (
        // <Button isDisabled={true}>Edit</Button>
        <small>Unable to edit note as 24hrs have passed</small>
      );

    return showEdit;
  }
  const updateNote = (e: any) => {
    try {
      ninoxTableUpdateRow(
        NotesEP,
        Number(id),
        { fields: updatedNoteToSend },
        String(bearerToken)
      ).then(() => {
        addNoteLogWhenUpdated(e);
        getAllNotesLogs();
        getAllSessionNotes();
        toast({
          title: 'Note udpated.',
          description: 'Your updated note has been saved',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
      });
    } catch {
      toast({
        title: 'Note update error',
        description:
          'The note was note updated. There was an error. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const addNoteLogWhenUpdated = (e: any) => {
    try {
      ninoxTableAddRow(NoteLogsEP, { fields: newLog }, bearerToken).then(() => {
        toast({
          title: 'Log successfully added.',
          description: 'Your log has been added to the system.',
          status: 'success',
          duration: 6000,
          isClosable: true,
        });
        getAllNotesLogs();
      });
    } catch {
      toast({
        title: 'Notes error',
        description: 'You note was not added to the system. Please email the systems administrator',
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  };
  const typeOfContacts = [
    'Assessment',
    'Observation',
    '1:1 session',
    'Group session',
    'Meeting/ Discussion',
    'Telephone',
    'Email',
    'Other',
    'DNA',
  ];
  const handleTypeOfContact = (e: any) => {
    setUpdatedTypeOfContact(e.target.value);
  };
  const handleDirectMinutes = (e: any) => {
    setUpdatedDirectMinutes(e.target.value);
  };
  const handleIndirectMinutes = (e: any) => {
    setUpdatedIndirectMinutes(e.target.value);
  };
  const handleDateChange = (e: any) => {
    let inputValue = e;
    // console.log(e);
    // new Date().toLocaleDateString('en-GB', {hour: '2-digit', minute: '2-digit', second: '2-digit'})}}
    // let newTime = addHours(inputValue, 1);
    setDateAndTimeUpdated(inputValue);

    const theDateNew = moment(inputValue).format('YYYY-MM-DD');
    const theTimeNew = moment(inputValue).format('HH:mm:ss');
    const theDateAndTimeNew = moment(inputValue).format('DD/MM/YYYY HH:mm');
    // setNewNote({
    //   ...newNote,
    //   Date: theDate,
    //   Time: theTime,
    // });
    setFormattedDateUpdated(theDateNew);
    setFormattedTimeUpdated(theTimeNew);
    setFormattedDateAndTimeUpdated(theDateNew + ' ' + theTimeNew);
    setDateWasUpdated(1);
  };
  return (
    <Box as="section">
      <Modal
        onClose={() => {
          setTheModal(0);
          setOpenedModal(false);
        }}
        size={'3xl'}
        isOpen={theModal === id ? modalState : false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Session details & notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={'5'}>
              <Text fontWeight={'bold'}>Note ID</Text>
              <Text>{note?.id}</Text>
            </Box>
            <Box mb={'5'}>
              <HStack>
                {editNote === 0 ? (
                  <FormControl>
                    <FormLabel>Time and date: </FormLabel>
                    <Text mb={5}>
                      {note?.fields['Date']} {note?.fields['Time']}
                    </Text>

                    <HStack mb={5}>
                      <DatePicker
                        selected={dateAndTimeUpdated}
                        timeIntervals={15}
                        onChange={handleDateChange}
                        showTimeSelect
                        dateFormat="Pp"
                        customInput={<Button>Change date and time</Button>}
                        required={true}
                      />
                    </HStack>
                    {formattedDateAndTimeUpdated !== '' && (
                      <>
                        <FormLabel>Updated Time and date: </FormLabel>
                        <Text mb={5}>{formattedDateAndTimeUpdated}</Text>
                      </>
                    )}
                  </FormControl>
                ) : (
                  <Box>
                    <Text fontWeight={'bold'}>Date &amp; Time</Text>
                    <Text>
                      {note?.fields['Date']} {note?.fields['Time']}
                    </Text>
                  </Box>
                )}
              </HStack>
            </Box>
            <Box mb={'5'}>
              {editNote === 0 ? (
                <FormControl mb={'5'}>
                  <FormLabel>Direct minutes: </FormLabel>
                  <HStack mb={5}>
                    <NumberInput size="lg" maxW={32} value={updatedDirectMinutes} min={0}>
                      <NumberInputField onChange={(e) => handleDirectMinutes(e)} />
                    </NumberInput>
                  </HStack>
                </FormControl>
              ) : (
                <FormControl mb={'5'}>
                  <Text fontWeight={'bold'}>Direct minutes</Text>
                  <Text>{note?.fields['Direct Hours']}</Text>
                </FormControl>
              )}
            </Box>
            <Box mb={'5'}>
              {editNote === 0 ? (
                <FormControl mb={'5'}>
                  <FormLabel>Indirect minutes: </FormLabel>
                  <HStack mb={5}>
                    <NumberInput size="lg" maxW={32} value={updatedIndirectMinutes} min={0}>
                      <NumberInputField onChange={(e) => handleIndirectMinutes(e)} />
                    </NumberInput>
                  </HStack>
                </FormControl>
              ) : (
                <FormControl mb={'5'}>
                  <Text fontWeight={'bold'}>Indirect minutes</Text>
                  <Text>{note?.fields['Indirect Hours']}</Text>
                </FormControl>
              )}
            </Box>
            <Box mb={'5'}>
              <Text>
                {editNote === 0 ? (
                  <FormControl mb={'5'}>
                    <Text fontWeight={'bold'} mb={2}>
                      Type of contact
                    </Text>
                    <Select
                      isRequired
                      onChange={(e) => handleTypeOfContact(e)}
                      value={updatedTypeOfContact}
                    >
                      {typeOfContacts?.map((i: any, k: number) => {
                        return (
                          <option value={i} key={k}>
                            {i}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  <FormControl mb={'5'}>
                    <Text fontWeight={'bold'} mb={2}>
                      Type of contact
                    </Text>
                    <Text>{note?.fields['Type of contact']}</Text>
                  </FormControl>
                )}
              </Text>
            </Box>
            <Box mb={'5'}>
              <FormControl mb="5%">
                <Text fontWeight={'bold'}>Notes:</Text>
                {editNote === 0 ? (
                  getHoursDifferenceForNotes(
                    moment(note?.createdAt).format('YYYY-MM-DD HH:mm'),
                    note?.fields['Notes']
                  )
                ) : (
                  <Text style={{ whiteSpace: 'pre-wrap' }}>{note?.fields['Notes']}</Text>
                )}
              </FormControl>
              {noteLogs && noteLogs.length >= 1 && (
                <FormControl>
                  <Accordion allowMultiple>
                    <AccordionItem>
                      <h2>
                        <AccordionButton>
                          <Box as="span" flex="1" textAlign="left">
                            Logs
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        {noteLogs
                          .filter(
                            (i: any) => i.fields['Session type'] === `session_note ${note?.id}`
                          )
                          .map((i: any, k: number) => (
                            <>
                              <Text>{i.fields['Text']}</Text>
                              <Divider color={'gray.300'} mt={5} mb={5} />
                            </>
                          ))}
                        {noteLogs.filter(
                          (i: any) => i.fields['Session type'] === `session_note ${note?.id}`
                        ).length === 0 && 'No logs found'}
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                </FormControl>
              )}
            </Box>
          </ModalBody>
          <ModalFooter>
            <HStack>
              {getHoursDifference(moment(moment(note?.createdAt).format('YYYY-MM-DD HH:mm')))}
              <Button
                onClick={() => {
                  setEditNote(1);
                  setTheModal(0);
                  setOpenedModal(false);
                }}
              >
                Close
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
