import React, { useState } from 'react';
import { Box, Icon, Stack, Text } from '@chakra-ui/react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { StarIcon } from '@chakra-ui/icons';
const RatingStarsViewNoClick = React.forwardRef(
  ({ size, icon, scale, fillColor, strokeColor, ratingScore, setRatingScore }: any, ref: any) => {
    const buttons = [];

    const handleTheRatingStars = (idx: any) => {
      if (!isNaN(idx)) {
        // allow user to click first icon and set rating to zero if rating is already 1
        if (ratingScore === 1 && idx === 1) {
          setRatingScore(0);
        } else {
          setRatingScore(idx);
        }
      }
    };

    const RatingIcon = ({ fill }: any) => {
      return (
        <StarIcon
          //   name={icon}
          w={8}
          h={8}
          color={fillColor}
          stroke={strokeColor}
          fillOpacity={fill ? '100%' : '0'}
        />
      );
    };

    const RatingButton = ({ idx, fill }: any) => {
      return (
        <Box
          as="button"
          aria-label={`Rate ${idx}`}
          height={`${size}px`}
          width={`${size}px`}
          // variant="unstyled"
          mx={1}
          // onClick={() => handleTheRatingStars(idx)}
          _focus={{ outline: 0 }}
        >
          <RatingIcon fill={fill} />
        </Box>
      );
    };

    for (let i = 1; i <= scale; i++) {
      buttons.push(<RatingButton key={i} idx={i} fill={i <= ratingScore} />);
    }

    return (
      <Stack isInline mt={8} justify="start">
        <input name="rating" type="hidden" value={ratingScore} ref={ref} />
        {buttons}
        <Box width={`${size * 1.5}px`} textAlign="center" ml={5}>
          <Text fontSize="sm" textTransform="uppercase">
            Rating
          </Text>
          <Text fontSize="2xl" fontWeight="semibold" lineHeight="1.2em">
            {ratingScore}
          </Text>
        </Box>
      </Stack>
    );
  }
);

export default RatingStarsViewNoClick;
